import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';

import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import axios from 'axios';

import {
  updateDOCID,
  updateOVID,
  updateSAMPLE,
  updateSAMPLEID,
  updateGROUP,
  updateINST,
  updateKIND,
  updateCHST,
  updateCHST_IMG,
  updateEXPP,
  updateRESPP,
  updateEXPDATE,
  updateINPP,
  updateBMEMO
} from 'actions/overviewArray';

import {
  uploadLoading
} from 'actions/settings'


import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';


const OverviewDetail = props => {
  const { className, history, ...rest } = props;
  const classes = useStyles();

  const inputFile = useRef(null);


  const [documentId, setDocumentId] = useState('');
  const [overviewId, setOverviewId] = useState('');
  const [sample, setSample] = useState('');
  const [sampleId, setSampleId] = useState('');
  const [group, setGroup] = useState('');
  const [institution, setInstitution] = useState('');
  const [kind, setKind] = useState('');
  const [chst, setChst] = useState('');
  // const [chstImg, setChstImg] = useState('');
  const [expp, setExpp] = useState('');
  const [respp, setRespp] = useState('');
  // const [expdate, setExpdate] = useState( new Date() );
  const [expdate, setExpdate] = useState(null);
  const [inpp, setInpp] = useState('');
  const [bmemo, setBmemo] = useState('');

  // const [file, setFile] = useState([]);
  const [attachedFile, setAttachedFile] = useState();
  // const [img, setImg] = useState();
  const [inputImg, setInputImg] = useState('');


  const selector_docid = useSelector((state) => state.overviewArray.docid);
  const selector_ovid = useSelector((state) => state.overviewArray.ovid);
  const selector_sample = useSelector((state) => state.overviewArray.sample);
  const selector_sampleID = useSelector((state) => state.overviewArray.sampleID);
  const selector_group = useSelector((state) => state.overviewArray.group);
  const selector_inst = useSelector((state) => state.overviewArray.inst);
  const selector_kind = useSelector((state) => state.overviewArray.kind);
  const selector_chst = useSelector((state) => state.overviewArray.chst); //picture
  // const selector_chst_img = useSelector((state) => state.overviewArray.chst_img); //picture
  const selector_expp = useSelector((state) => state.overviewArray.expp);
  const selector_respp = useSelector((state) => state.overviewArray.respp);

  const selector_expdate = useSelector((state) => state.overviewArray.expdate);
  // console.log(typeof selector_expdate);
  const selector_inpp = useSelector((state) => state.overviewArray.inpp);
  const selector_bmemo = useSelector((state) => state.overviewArray.bmemo);
  
  const selector_loading = useSelector((state) => state.settings.loading);
  // console.log(selector_loading)
  
  // console.log(typeof selector_expdate)
  // console.log(selector_expdate)


  const handleDocumentIdChange = (event) => {
    event.persist();
    props.updateDOCID(event.target.value);
    setDocumentId(event.target.value);
  }

  const handleOverviewIdChange = (event) => {
    event.persist();
    props.updateOVID(event.target.value);
    setOverviewId(event.target.value);
  }


  const handleSampleChange = (event) => {
    event.persist();
    props.updateSAMPLE(event.target.value);
    setSample(event.target.value);

  }

  const handleSampleIdChange = (event) => {
    event.persist();
    props.updateSAMPLEID(event.target.value);
    setSampleId(event.target.value);
  }

  const handleGroupChange = (event) => {
    event.persist();
    props.updateGROUP(event.target.value);
    setGroup(event.target.value);
  }

  const handleInstitutionChange = (event) => {
    event.persist();
    props.updateINST(event.target.value);
    setInstitution(event.target.value);
  }

  const handleKindChange = (event) => {
    event.persist();
    props.updateKIND(event.target.value);
    setKind(event.target.value);
  }

  const handleChstChange = (event) => {
    event.persist();
    props.updateCHST(event.target.value);
    setChst(event.target.value);
  }

  const handleExppChange = (event) => {
    event.persist();
    props.updateEXPP(event.target.value);
    setExpp(event.target.value);
  }

  const handleResppChange = (event) => {
    event.persist();
    props.updateRESPP(event.target.value);
    setRespp(event.target.value);
  }

  const handleExpdateChange = (date) => {
    // event.persist();
    props.updateEXPDATE(date);
    setExpdate(date);
  }

  const handleInppChange = (event) => {
    event.persist();
    props.updateINPP(event.target.value);
    setInpp(event.target.value);
  }

  const handleBmemoChange = (event) => {
    event.persist();
    props.updateBMEMO(event.target.value);
    setBmemo(event.target.value);
  }

  const showFileDialog = (e) => {
    inputFile.current.click();
  }


  const doPost = async(e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("documentId", '1YLB8');
    formData.append("value", 'pp_td_gp_ftd');
    // formData.append("type", 'CSV');
    formData.append("type", 'IMAGE');
    // console.log(attachedFile)
    formData.append('file', attachedFile);


    // for(const val of formData.values()){
    //   console.log(val);
    // }

    axios.post(`http://20.89.138.172/input/fileup/1YLB8`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data; charset=utf-8;',
      },
    })
    // .then( res => {
    //   console.log(res);
    //   // return JSON.stringify(res);
    //   return res;
    // })
    .then(responseData => {
      // console.log("responseData:" + JSON.stringify(responseData));
      console.log("responseData:" , responseData);

      if(responseData.resultCode === '000'){
        //success
        console.log('successs to upload file');

      } else {
        console.log(`failed to upload file: ${responseData.data.resultMessage}`);
        alert(`ファイルアップロードに失敗しました。\n${responseData.data.resultMessage}`);
      }
    })
    .catch( err => {
      console.log(err);
      // var response = JSON.parse(err.response.data);
      var response = err.response.data;
      console.log(response);

      alert(`ファイルアップロードに失敗しました。\n${response.resultMessage}`);
    });

    // axios.post(`http://localhost:3003/upload/UFLWO`, formData, {
    //   headers: {
    //     'Content-Type': 'multipart/form-data; charset=utf-8;',
    //   },
    // })
    // .then( res => {
    //   console.log(res);
    // })
  }


  const fileDialogChange = (e) => {
    // setFile([...file, e.target.files[0]]);

    var path = (window.URL || window.webkitURL).createObjectURL(e.target.files[0]);
    // console.log('path', path);


    // console.log(e.target.files[0]);

    const [file1] = e.target.files;

    // setAttachedFile( file1 );
    // setInputImg(URL.createObjectURL(file1));

    // setImg(URL.createObjectURL(file1));

    props.updateCHST({inputValue: file1.name, inputImage: file1, inputPreview: URL.createObjectURL(file1)});
    // setChst(file1.name);

    // props.updateCHST_IMG(URL.createObjectURL(file1));
    // setChstImg(URL.createObjectURL(file1));

  }


  useEffect(() => {
    setDocumentId(selector_docid);
    setOverviewId(selector_ovid);
    setSample(selector_sample);
    setSampleId(selector_sampleID);
    setGroup(selector_group);
    setInstitution(selector_inst);
    setKind(selector_kind);
    // setChst(selector_chst.name);
    // setChstImg(selector_chst_img);
    setExpp(selector_expp);
    setRespp(selector_respp);
    setExpdate(selector_expdate);
    setInpp(selector_inpp);
    setBmemo(selector_bmemo);

  }, [
      selector_docid, selector_ovid, selector_sample, selector_sampleID, selector_group, 
      selector_inst, selector_kind, selector_chst, selector_expp,
      selector_respp, selector_expdate, selector_inpp, selector_bmemo
  ]);


  // useEffect(() => {
  //   console.log('GET')
  //   setDocumentId(selector_docid);
  //   setOverviewId(selector_ovid);
  //   setSample(selector_sample);
  //   setSampleId(selector_sampleID);
  //   setGroup(selector_group);
  //   setInstitution(selector_inst);
  //   setKind(selector_kind);
  //   setChst(selector_chst);
  //   setChstImg(selector_chst_img);
  //   setExpp(selector_expp);
  //   setRespp(selector_respp);
  //   setExpdate(selector_expdate);
  //   setInpp(selector_inpp);
  //   setBmemo(selector_bmemo);


  // },[
  //   selector_docid, selector_ovid, selector_sample, selector_sampleID, selector_group, 
  //   selector_inst, selector_kind, selector_chst, selector_chst_img, selector_expp,
  //   selector_respp, selector_expdate, selector_inpp, selector_bmemo
  // ]);

  // console.log(selector_loading)
  if (selector_loading) {
    return <></>
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">概要</Typography>} style={{ backgroundColor: 'lightgray' }} />

          <Divider />

          <CardContent>

            <Grid container spacing={2}>

              <Grid item md={6} xs={6}>
                <TextField
                  label="ドキュメントID"
                  name="docid"
                  fullWidth
                  onChange={handleDocumentIdChange}
                  type="text"
                  value={documentId}
                  variant="outlined"
                  disabled
                />
              </Grid>

              {/* <Grid item md={6} xs={6}>
                <TextField
                  label="概要ID"
                  name="ovid"
                  fullWidth
                  onChange={handleOverviewIdChange}
                  type="text"
                  value={overviewId}
                  variant="outlined"
                  disabled
                />
              </Grid> */}

              <Grid item md={6} xs={6}>
                <TextField
                  label="試料総称名または略称名"
                  name="sample"
                  fullWidth
                  onChange={handleSampleChange}
                  type="text"
                  value={sample}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="試料識別名：（材料の履歴や状態等を示す）"
                  name="sampleId"
                  fullWidth
                  onChange={handleSampleIdChange}
                  type="text"
                  value={sampleId}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="試料作成グループ名またはメーカ名"
                  name="group"
                  fullWidth
                  onChange={handleGroupChange}
                  type="text"
                  value={group}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="機関名"
                  name="institution"
                  fullWidth
                  onChange={handleInstitutionChange}
                  type="text"
                  value={institution}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="種類"
                  name="kind"
                  fullWidth
                  onChange={handleKindChange}
                  type="text"
                  value={kind}
                  variant="outlined"
                />
              </Grid>

              <Grid container item md={6} xs={6} justify="center">
                {/*
                <TextField
                  label="化学構造図（主な成分あるいは代表的な構造図、模式図）"
                  name="chst"
                  fullWidth
                  onChange={handleChstChange}
                  type="text"
                  value={chst}
                  variant="outlined"
                  onClick={showFileDialog}
                /> */}

                {/* <div className={classes.div_image}> */}
                <div style={{width:'100%', display: 'flex',  justifyContent:'center', alignItems:'center', alignContent:'center'}}>
                  <Typography variant="body2">化学構造図（主な成分あるいは代表的な構造図、模式図）</Typography>

                  <input type="file" class="form-control" id="file" multiple accept="image/*"  ref={inputFile} style={{display: 'none'}} 
                    onChange={fileDialogChange}
                  />
                  <button onClick={showFileDialog}>ファイル選択</button>
                  <></>
                  {/* <button onClick={doPost}>POST</button> */}
                </div>

                  {/* <string>Uploaded files:</string>{file.map( x => x.name).join(',')} */}
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="実験者・計算者"
                  name="expp"
                  fullWidth
                  onChange={handleExppChange}
                  type="text"
                  value={expp}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="責任者名"
                  name="respp"
                  fullWidth
                  onChange={handleResppChange}
                  type="text"
                  value={respp}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container direction="row" justifyContent="flex-start" alignItems='center'>
                    <Typography variant="body1">実験日</Typography>
                    <KeyboardDatePicker
                      disableToolbar
                      fullWidth
                      showTodayButton={true}
                      autoOk
                      variant="inline"
                      id="date-picker-dialog"
                      format="yyyy-MM-dd"
                      fontSize="30px"
                      // label="実験日"
                      value={expdate || null}
                      onChange={handleExpdateChange}
                      KeyboardButtonProps={{ 'aria-label': 'change date', }}
                      className={classes.datepicker}
                      InputProps={{
                        style: {
                          fontSize: 16,
                          height: 44
                        }
                      }}
                      style={{
                        width: 180,
                        marginLeft: 20,
                        marginRight: 20,
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>

              </Grid>


              <Grid item md={6} xs={6}>
                <TextField
                  label="入力者のみ"
                  name="inpp"
                  fullWidth
                  onChange={handleInppChange}
                  type="text"
                  value={inpp}
                  variant="outlined"
                />
              </Grid>


              <Grid item md={6} xs={6}>
                <TextField
                  label="概要"
                  name="bmemo"
                  fullWidth
                  onChange={handleBmemoChange}
                  type="text"
                  value={bmemo}
                  variant="outlined"
                />
              </Grid>

              <Grid container item md={12}></Grid>

            </Grid>

            <Divider />

            <Grid container spacing={1}>

              <Grid container item lg={12} md={12} xs={12} justifyContent='center' alignItems="center" spacing={1}>
                <Typography variant="body" style={{paddingTop:10}}>{selector_chst.inputValue}</Typography>
                {/* <Typography variant="body" style={{paddingTop:10}}>melong</Typography> */}
              </Grid>
              <Grid container item lg={12} md={12} xs={12} justifyContent='center' alignItems="center">
              {selector_chst.inputPreview && (
                <img src={selector_chst.inputPreview} style={{ height: 240 }} />
                // alt={file && file.name}
                ) }
              </Grid>

            </Grid>

          </CardContent>

        </Card>
      </div>
    </div>
  );
};

OverviewDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateDOCID,
    updateOVID,
    updateSAMPLE,
    updateSAMPLEID,
    updateGROUP,
    updateINST,
    updateKIND,
    updateCHST,
    updateCHST_IMG,
    updateEXPP,
    updateRESPP,
    updateEXPDATE,
    updateINPP,
    updateBMEMO,

    uploadLoading
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    overviewArray: state.overviewArray,
    settings: state.settings
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OverviewDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },
  custom: {
    transform: 'translate(14px, -6px) scale(0.75)'
  }
  

  // div_image: {
  //   flexDirection: "column",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   alignContent: "center",
  // }

}));
