import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updateHOM_ENZYME,
  updateHOM_FUNGUS,
} from 'actions/SynthesisArray';


import { makeLabelVal } from "common/util";

import {
  updateOpenDialog,
} from "actions/settings";

import { InputDialog } from "views/InputDialog"

import {
  HOM_ENZYME,
  HOM_FUNGUS,
} from "constants.js";


/**
 * 高分子生成.作り方
 */
const HowtomakeDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');

  const [hom_enzymeValue, setHom_enzymeValue] = useState('');
  const [hom_fungusValue, setHom_fungusValue] = useState('');

  const selector_hom_enzyme = useSelector((state) => state.SynthesisArray.hom_enzyme);
  const selector_hom_fungus = useSelector((state) => state.SynthesisArray.hom_fungus);

  const selector_openDialog = useSelector((state) => state.settings.openDialog);

  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {
      case HOM_ENZYME:
        setHom_enzymeValue(makeLabelVal(new_val));
        props.updateHOM_ENZYME(new_val);
        break;


      case HOM_FUNGUS:
        setHom_fungusValue(makeLabelVal(new_val));
        props.updateHOM_FUNGUS(new_val);
        break;
    }
  }


  const showChargerSearchDialog = (selected) => {

    setSelectedNode(selected);

    switch (selected) {
      case HOM_ENZYME:
        setDialogLabel('酵素名');
        break;

      case HOM_FUNGUS:
        setDialogLabel('菌体名');
        break;
    }

    props.updateOpenDialog(true);
  }


  useEffect(() => {
    setHom_enzymeValue(makeLabelVal(selector_hom_enzyme));
    setHom_fungusValue(makeLabelVal(selector_hom_fungus));
  }, []);



  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'作り⽅'} saveInput={saveInput} selectedNode={selectedNode} />

        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">作り⽅</Typography>} style={{ backgroundColor: 'lightgray' }} />

          <Divider />

          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6} xs={6}>
                <TextField
                  label="酵素名"
                  name={HOM_ENZYME}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(HOM_ENZYME) }}
                  onBeforeInput={() => { showChargerSearchDialog(HOM_ENZYME) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(HOM_ENZYME) } }}
                  type="text"
                  value={hom_enzymeValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="菌体名"
                  name={HOM_FUNGUS}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(HOM_FUNGUS) }}
                  onBeforeInput={() => { showChargerSearchDialog(HOM_FUNGUS) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(HOM_FUNGUS) } }}
                  type="text"
                  value={hom_fungusValue || ''}
                  variant="outlined"
                />
              </Grid>

            </Grid>
          </CardContent>

        </Card>
      </div>
    </div>
  );
};

HowtomakeDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateHOM_ENZYME,
    updateHOM_FUNGUS,

    updateOpenDialog
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    SynthesisArray: state.SynthesisArray,
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HowtomakeDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
