import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { makeLabelVal } from "common/util";

import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updateFRICTION_COEFFICIENT
} from 'actions/propertyArray';


import {
  updateOpenDialog,
} from "actions/settings";

import { InputDialog } from "views/InputDialog"

import {
  FRICTION_COEFFICIENT
} from "constants.js";


/**
 * 固体物性.摩擦係数
 */
const FrictionCoefficientDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');

  const [friction_coefficientValue, setFriction_coefficientValue] = useState('');

  const selector_friction_coefficient = useSelector((state) => state.propertyArray.friction_coefficient);

  const selector_openDialog = useSelector((state) => state.settings.openDialog);

  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {
      case FRICTION_COEFFICIENT:
        setFriction_coefficientValue(makeLabelVal(new_val));
        props.updateFRICTION_COEFFICIENT(new_val);
        break;
    }

  }

  const showChargerSearchDialog = (selected) => {

    setSelectedNode(selected);

    switch(selected){
      case FRICTION_COEFFICIENT:
        setDialogLabel('摩擦係数');
        break;
    }

    props.updateOpenDialog(true);
  }


  useEffect(() => {
    setFriction_coefficientValue(makeLabelVal(selector_friction_coefficient));
  }, []);


  return (
    <div className={classes.root}>
      <div className={classes.content}>


        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'熱力学量'} saveInput={saveInput} selectedNode={selectedNode} />

        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">摩擦係数</Typography>} style={{ backgroundColor: 'lightgray' }} />
          <Divider />

          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6} xs={6}>
                <TextField
                  label="摩擦係数"
                  name={FRICTION_COEFFICIENT}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(FRICTION_COEFFICIENT) }}
                  onBeforeInput={() => { showChargerSearchDialog(FRICTION_COEFFICIENT) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(FRICTION_COEFFICIENT) } }}
                  type="text"
                  value={friction_coefficientValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

FrictionCoefficientDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};


const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateFRICTION_COEFFICIENT,

    updateOpenDialog,
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    propertyArray: state.propertyArray,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FrictionCoefficientDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
