import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
  AppBar, Toolbar,
  // Badge,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Box,
  Hidden, IconButton,

  TextField,
  Button,
} from '@material-ui/core';

import { TreeDialog } from "views/TreeDialog"

import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { doLogout } from 'actions/user.js';

import { CATEGORY, updateCategory } from 'actions/settings';
import { useHistory } from "react-router-dom";

import { CATEGORY_LABEL, CATEGORY_SETTING } from 'constants';

import { WAS_CONFIG } from 'config.js';

import {
  updateOpenTreeDialog,

  uploadLoading
} from "actions/settings";
// import { request } from 'http';


import { makeLabelVal } from "common/util";
import { set } from 'date-fns';
import { tail } from 'underscore';
// import { updateFILM, updateHYOMEN } from 'actions/propertyArray';

const Topbar = props => {
  const { className, onSidebarOpen, doLogout, settings, ...rest } = props;
  const history = useHistory();

  const classes = useStyles();


  const [selectedCategory, setSelectedCategory] = useState('概要');
  const selector_category = useSelector((state) => state.settings.category);

  const selector_openTreeDialog = useSelector((state) => state.settings.openTreeDialog);

  // const [mitsudoValue, setMitsudoValue] = useState('');

  const selector_loading = useSelector((state) => state.settings.loading);



  const selector_docid = useSelector((state) => state.overviewArray.docid);
  const selector_ovid = useSelector((state) => state.overviewArray.ovid);
  const selector_sample = useSelector((state) => state.overviewArray.sample);
  const selector_sampleID = useSelector((state) => state.overviewArray.sampleID);
  const selector_group = useSelector((state) => state.overviewArray.group);
  const selector_inst = useSelector((state) => state.overviewArray.inst);
  const selector_kind = useSelector((state) => state.overviewArray.kind);
  const selector_chst = useSelector((state) => state.overviewArray.chst);
  const selector_expp = useSelector((state) => state.overviewArray.expp);
  const selector_respp = useSelector((state) => state.overviewArray.respp);
  const selector_expdate = useSelector((state) => state.overviewArray.expdate);
  const selector_inpp = useSelector((state) => state.overviewArray.inpp);
  const selector_bmemo = useSelector((state) => state.overviewArray.bmemo);


  //固体物性.熱力学量
  const selector_pp_td_den = useSelector((state) => state.propertyArray.pp_td_den);
  const selector_pp_td_sv = useSelector((state) => state.propertyArray.pp_td_sv);
  const selector_pp_td_ar = useSelector((state) => state.propertyArray.pp_td_ar);
  const selector_pp_td_st = useSelector((state) => state.propertyArray.pp_td_st);
  const selector_pp_td_trop = useSelector((state) => state.propertyArray.pp_td_trop);
  const selector_pp_td_enthal = useSelector((state) => state.propertyArray.pp_td_enthal);
  const selector_pp_td_cr = useSelector((state) => state.propertyArray.pp_td_cr);
  const selector_pp_td_te = useSelector((state) => state.propertyArray.pp_td_te);


  //固体物性.熱力学量.気体透過性
  const selector_pp_td_gp_wp = useSelector((state) => state.propertyArray.pp_td_gp_wp);
  const selector_pp_td_gp_mp = useSelector((state) => state.propertyArray.pp_td_gp_mp);
  const selector_pp_td_gp_ftd = useSelector((state) => state.propertyArray.pp_td_gp_ftd);
  const selector_pp_td_gp_tpd = useSelector((state) => state.propertyArray.pp_td_gp_tpd);
  const selector_pp_td_gp_hd = useSelector((state) => state.propertyArray.pp_td_gp_hd);


  //固体物性.熱力学量.流動特性
  const selector_pp_td_fc_mv_sd = useSelector((state) => state.propertyArray.pp_td_fc_mv_sd);
  const selector_pp_td_fc_mv_mtd = useSelector((state) => state.propertyArray.pp_td_fc_mv_mtd);
  const selector_pp_td_fc_mv_pd = useSelector((state) => state.propertyArray.pp_td_fc_mv_pd);
  const selector_pp_td_fc_vc = useSelector((state) => state.propertyArray.pp_td_fc_vc);


  //固体物性.熱力学量.比熱
  const selector_pp_td_sp_spc = useSelector((state) => state.propertyArray.pp_td_sp_spc);
  const selector_pp_td_sp_std = useSelector((state) => state.propertyArray.pp_td_sp_std);

  //固体物性.熱力学量.熱伝導率
  const selector_pp_td_tc_ttd = useSelector((state) => state.propertyArray.pp_td_tc_ttd);
  const selector_pp_td_tc_dsd = useSelector((state) => state.propertyArray.pp_td_tc_dsd);
  const selector_pp_td_tc_cpd = useSelector((state) => state.propertyArray.pp_td_tc_cpd);


  //固体物性.熱力学量.熱拡散係数
  const selector_pp_td_tdif_cmm = useSelector((state) => state.propertyArray.pp_td_tdif_cmm);
  const selector_pp_td_tdif_amo = useSelector((state) => state.propertyArray.pp_td_tdif_amo);

  //固体物性.熱力学量.各種温度
  const selector_pp_td_temp_Tg_gpd = useSelector((state) => state.propertyArray.pp_td_temp_Tg_gpd);
  const selector_pp_td_temp_mlp_mpd = useSelector((state) => state.propertyArray.pp_td_temp_mlp_mpd);
  const selector_pp_td_temp_DSC = useSelector((state) => state.propertyArray.pp_td_temp_DSC);
  const selector_pp_td_temp_TGA = useSelector((state) => state.propertyArray.pp_td_temp_TGA);
  const selector_pp_td_temp_wr5 = useSelector((state) => state.propertyArray.pp_td_temp_wr5);
  const selector_pp_td_temp_emb = useSelector((state) => state.propertyArray.pp_td_temp_emb);
  const selector_pp_td_temp_HDT = useSelector((state) => state.propertyArray.pp_td_temp_HDT);

  //固体物性.熱力学量.燃焼特性
  const selector_pp_td_bchar_FIT = useSelector((state) => state.propertyArray.pp_td_bchar_FIT);
  const selector_pp_td_bchar_hdc = useSelector((state) => state.propertyArray.pp_td_bchar_hdc);
  const selector_pp_td_bchar_ch = useSelector((state) => state.propertyArray.pp_td_bchar_ch);
  const selector_pp_td_bchar_oi = useSelector((state) => state.propertyArray.pp_td_bchar_oi);
  const selector_pp_td_bchar_hrp = useSelector((state) => state.propertyArray.pp_td_bchar_hrp);


  //固体物性.機械強度特性
  const selector_pp_ms_crt = useSelector((state) => state.mechanicalArray.pp_ms_crt);
  const selector_pp_ms_strrel = useSelector((state) => state.mechanicalArray.pp_ms_strrel);
  const selector_pp_ms_crack = useSelector((state) => state.mechanicalArray.pp_ms_crack);
  const selector_pp_ms_frat = useSelector((state) => state.mechanicalArray.pp_ms_frat);
  const selector_pp_ms_fatp = useSelector((state) => state.mechanicalArray.pp_ms_fatp);
  const selector_pp_ms_hard = useSelector((state) => state.mechanicalArray.pp_ms_hard);


  //固体物性.機械強度特性.応力歪曲線.引張
  const selector_pp_ms_ssc_ten_degstretch = useSelector((state) => state.mechanicalArray.pp_ms_ssc_ten_degstretch);
  const selector_pp_ms_ssc_ten_degela = useSelector((state) => state.mechanicalArray.pp_ms_ssc_ten_degela);
  const selector_pp_ms_ssc_ten_rupstr = useSelector((state) => state.mechanicalArray.pp_ms_ssc_ten_rupstr);
  const selector_pp_ms_ssc_ten_sttd = useSelector((state) => state.mechanicalArray.pp_ms_ssc_ten_sttd);
  const selector_pp_ms_ssc_ten_stpd = useSelector((state) => state.mechanicalArray.pp_ms_ssc_ten_stpd);
  const selector_pp_ms_ssc_ten_baimage = useSelector((state) => state.mechanicalArray.pp_ms_ssc_ten_baimage);

  //固体物性.機械強度特性.応力歪曲線.曲げ試験
  const selector_pp_ms_ssc_bend_bdstretch = useSelector((state) => state.mechanicalArray.pp_ms_ssc_bend_bdstretch);
  const selector_pp_ms_ssc_bend_bdela = useSelector((state) => state.mechanicalArray.pp_ms_ssc_bend_bdela);
  const selector_pp_ms_ssc_bend_brstr = useSelector((state) => state.mechanicalArray.pp_ms_ssc_bend_brstr);
  const selector_pp_ms_ssc_bend_btd = useSelector((state) => state.mechanicalArray.pp_ms_ssc_bend_btd);
  const selector_pp_ms_ssc_bend_bpd = useSelector((state) => state.mechanicalArray.pp_ms_ssc_bend_bpd);
  const selector_pp_ms_ssc_bend_bbaimage = useSelector((state) => state.mechanicalArray.pp_ms_ssc_bend_bbaimage);


  //固体物性.機械強度特性.粘弾性測定
  const selector_pp_ms_vem_vtd = useSelector((state) => state.mechanicalArray.pp_ms_vem_vtd);
  const selector_pp_ms_vem_vpd = useSelector((state) => state.mechanicalArray.pp_ms_vem_vpd);
  const selector_pp_ms_vem_gdash = useSelector((state) => state.mechanicalArray.pp_ms_vem_gdash);
  const selector_pp_ms_vem_gdash2 = useSelector((state) => state.mechanicalArray.pp_ms_vem_gdash2);
  const selector_pp_ms_vem_ltan = useSelector((state) => state.mechanicalArray.pp_ms_vem_ltan);

  //固体物性.機械強度特性.衝撃試験
  const selector_pp_ms_sht_Char_ctd = useSelector((state) => state.mechanicalArray.pp_ms_sht_Char_ctd);
  const selector_pp_ms_sht_Izod_itd = useSelector((state) => state.mechanicalArray.pp_ms_sht_Izod_itd);


  //固体物性.摩擦係数
  const selector_friction_coefficient = useSelector((state) => state.propertyArray.friction_coefficient);

  //固体物性.酵素分解
  const selector_enzymatic_decomposition_rate = useSelector((state) => state.propertyArray.enzymatic_decomposition_rate);
  const selector_enzymatic_enzymatic_reaction = useSelector((state) => state.propertyArray.enzymatic_enzymatic_reaction);

  //固体物性.生分解度
  const selector_biodegradability_curve = useSelector((state) => state.propertyArray.biodegradability_curve);

  //固体物性.崩壊度
  const selector_bar_graph = useSelector((state) => state.propertyArray.bar_graph);

  //固体物性.構造
  const selector_structure_xray_graph = useSelector((state) => state.propertyArray.structure_xray_graph);
  const selector_structure_microscope_image = useSelector((state) => state.propertyArray.structure_microscope_image);
  const selector_structure_crystallinity_degree = useSelector((state) => state.propertyArray.structure_crystallinity_degree);


  //高分子生成.作り方
  const selector_hom_enzyme = useSelector((state) => state.SynthesisArray.hom_enzyme); //酵素名
  const selector_hom_fungus = useSelector((state) => state.SynthesisArray.hom_fungus); //菌体名


  //高分子生成.作り方.購入
  const selector_buy_maker = useSelector((state) => state.SynthesisArray.buy_maker);
  const selector_buy_lot_number = useSelector((state) => state.SynthesisArray.buy_lot_number);

  //高分子生成.作り方.製造プロセス
  const selector_buy_pmaker = useSelector((state) => state.SynthesisArray.buy_pmaker);
  const selector_buy_plot_number = useSelector((state) => state.SynthesisArray.buy_plot_number);
  const selector_process_synthesis = useSelector((state) => state.SynthesisArray.process_synthesis);
  const selector_process_machine = useSelector((state) => state.SynthesisArray.process_machine);
  const selector_process_theother = useSelector((state) => state.SynthesisArray.process_theother);

  //高分子生成.作り方.重合名.連鎖重合
  const selector_hom_prize_chain_add_rad_LRP = useSelector((state) => state.SynthesisArray.hom_prize_chain_add_rad_LRP);
  const selector_hom_prize_chain_add_cati_LCP = useSelector((state) => state.SynthesisArray.hom_prize_chain_add_cati_LCP);
  const selector_hom_prize_chain_add_anio_LAP = useSelector((state) => state.SynthesisArray.hom_prize_chain_add_anio_LAP);
  const selector_hom_prize_chain_add_crd = useSelector((state) => state.SynthesisArray.hom_prize_chain_add_crd);
  const selector_hom_prize_chain_rop_CROP = useSelector((state) => state.SynthesisArray.hom_prize_chain_rop_CROP);
  const selector_hom_prize_chain_rop_AROP = useSelector((state) => state.SynthesisArray.hom_prize_chain_rop_AROP);
  const selector_hom_prize_chain_rop_RROP = useSelector((state) => state.SynthesisArray.hom_prize_chain_rop_RROP);
  const selector_hom_prize_chain_rop_TMCR_ROMP = useSelector((state) => state.SynthesisArray.hom_prize_chain_rop_TMCR_ROMP);
  const selector_hom_prize_chain_CGCP = useSelector((state) => state.SynthesisArray.hom_prize_chain_CGCP);


  //高分子生成.作り方.重合名.逐次重合
  const selector_hom_prize_SGP_pcon_NASP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_NASP);
  const selector_hom_prize_SGP_pcon_ANSP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_ANSP);
  const selector_hom_prize_SGP_pcon_ARNSP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_ARNSP);
  const selector_hom_prize_SGP_pcon_ARESP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_ARESP);
  const selector_hom_prize_SGP_pcon_OCP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_OCP);
  const selector_hom_prize_SGP_pcon_TMCP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_TMCP);
  const selector_hom_prize_SGP_pcon_EP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_EP);
  const selector_hom_prize_SGP_padd_PTDB = useSelector((state) => state.SynthesisArray.hom_prize_SGP_padd_PTDB);
  const selector_hom_prize_SGP_padd_ROPA = useSelector((state) => state.SynthesisArray.hom_prize_SGP_padd_ROPA);
  const selector_hom_prize_SGP_padd_CYPA = useSelector((state) => state.SynthesisArray.hom_prize_SGP_padd_CYPA);
  const selector_hom_prize_SGP_padd_OTPA = useSelector((state) => state.SynthesisArray.hom_prize_SGP_padd_OTPA);
  const selector_hom_prize_SGP_adco = useSelector((state) => state.SynthesisArray.hom_prize_SGP_adco);


  //高分子生成.反応条件
  const selector_rcon_pH = useSelector((state) => state.SynthesisArray.rcon_pH);
  const selector_rcon_rtemp = useSelector((state) => state.SynthesisArray.rcon_rtemp);
  const selector_rcon_OTRC = useSelector((state) => state.SynthesisArray.rcon_OTRC);

  //高分子生成.結果
  const selector_rslt_RT = useSelector((state) => state.SynthesisArray.rslt_RT);
  const selector_rslt_MCR = useSelector((state) => state.SynthesisArray.rslt_MCR);
  const selector_rslt_rrate = useSelector((state) => state.SynthesisArray.rslt_rrate);
  const selector_rslt_CY = useSelector((state) => state.SynthesisArray.rslt_CY);
  const selector_rslt_prate_tur = useSelector((state) => state.SynthesisArray.rslt_prate_tur);
  const selector_rslt_prate_occ = useSelector((state) => state.SynthesisArray.rslt_prate_occ);
  const selector_rslt_prate_pdata = useSelector((state) => state.SynthesisArray.rslt_prate_pdata);

  // 高分子生成.バイオモノマー生成
  const selector_bmsyn_react = useSelector((state) => state.SynthesisArray.bmsyn_react);
  const selector_bmsyn_reacs = useSelector((state) => state.SynthesisArray.bmsyn_reacs);
  const selector_bmsyn_yield = useSelector((state) => state.SynthesisArray.bmsyn_yield);

    // 高分子生成.バイオモノマー生成..生産速度
  const selector_bmsyn_pros_fgtb = useSelector((state) => state.SynthesisArray.bmsyn_pros_fgtb);
  const selector_bmsyn_pros_csd = useSelector((state) => state.SynthesisArray.bmsyn_pros_csd);
  const selector_bmsyn_pros_scon = useSelector((state) => state.SynthesisArray.bmsyn_pros_scon);
  const selector_bmsyn_pros_pdt = useSelector((state) => state.SynthesisArray.bmsyn_pros_pdt);

  // 計算.分子動力学計算
  const selector_MD_MDp = useSelector((state) => state.calculationArray.MD_MDp);

  // 計算.分子動力学計算.計算条件 
  const selector_MD_cond_ens = useSelector((state) => state.calculationArray.MD_cond_ens);
  const selector_MD_cond_frc_ftbl = useSelector((state) => state.calculationArray.MD_cond_frc_ftbl);
  const selector_MD_cond_icrd_icimage = useSelector((state) => state.calculationArray.MD_cond_icrd_icimage);
  const selector_MD_cond_icrd_icPDB = useSelector((state) => state.calculationArray.MD_cond_icrd_icPDB);
  const selector_MD_cond_icrd_icDCD = useSelector((state) => state.calculationArray.MD_cond_icrd_icDCD);
  const selector_MD_cond_icrd_icPSF = useSelector((state) => state.calculationArray.MD_cond_icrd_icPSF);
  const selector_MD_cond_otcond = useSelector((state) => state.calculationArray.MD_cond_otcond);

  // 計算.分子動力学計算.計算結果
  const selector_MD_crslt_Thdyd_TvsT = useSelector((state) => state.calculationArray.MD_crslt_Thdyd_TvsT);
  const selector_MD_crslt_Thdyd_PvsT = useSelector((state) => state.calculationArray.MD_crslt_Thdyd_PvsT);
  const selector_MD_crslt_Thdyd_EvsT = useSelector((state) => state.calculationArray.MD_crslt_Thdyd_EvsT);
  const selector_MD_crslt_Dyd = useSelector((state) => state.calculationArray.MD_crslt_Dyd);
  // 計算.量子化学計算
  const selector_QC_QCp = useSelector((state) => state.calculationArray.QC_QCp);
  const selector_QC_QCbc = useSelector((state) => state.calculationArray.QC_QCbc);
  const selector_QC_QCap = useSelector((state) => state.calculationArray.QC_QCap);

  // 測定法.分光.赤外分光法
  const selector_spy_inf_fti = useSelector((state) => state.measurementArray.spy_inf_fti);
  const selector_spy_nmr = useSelector((state) => state.measurementArray.spy_nmr);
  const selector_spy_uvi = useSelector((state) => state.measurementArray.spy_uvi);
  const selector_spy_flu = useSelector((state) => state.measurementArray.spy_flu);
  const selector_spy_ram = useSelector((state) => state.measurementArray.spy_ram);
  const selector_spy_cd = useSelector((state) => state.measurementArray.spy_cd);
  const selector_spy_ls = useSelector((state) => state.measurementArray.spy_ls);
  const selector_spy_ell = useSelector((state) => state.measurementArray.spy_ell);
  const selector_spy_nsc = useSelector((state) => state.measurementArray.spy_nsc);
  // 測定法.分光.X線
  const selector_spy_xra_pho = useSelector((state) => state.measurementArray.spy_xra_pho);
  const selector_spy_xra_dif = useSelector((state) => state.measurementArray.spy_xra_dif);
  const selector_spy_xra_xs_sag = useSelector((state) => state.measurementArray.spy_xra_xs_sag);
  const selector_spy_xra_xs_wid = useSelector((state) => state.measurementArray.spy_xra_xs_wid);

  // 測定法.クロマトグラフィー
  const selector_chr_gas = useSelector((state) => state.measurementArray.chr_gas);
  const selector_chr_hpl_gpc = useSelector((state) => state.measurementArray.chr_hpl_gpc);
  const selector_chr_hpl_sec = useSelector((state) => state.measurementArray.chr_hpl_sec);
  // 測定法.顕微鏡法
  const selector_mic_afm = useSelector((state) => state.measurementArray.mic_afm);
  const selector_mic_sem = useSelector((state) => state.measurementArray.mic_sem);
  const selector_mic_tem = useSelector((state) => state.measurementArray.mic_tem);
  const selector_mic_opt = useSelector((state) => state.measurementArray.mic_opt);
  const selector_mic_flm = useSelector((state) => state.measurementArray.mic_flm);
  const selector_mic_cls = useSelector((state) => state.measurementArray.mic_cls);
  const selector_mic_spr = useSelector((state) => state.measurementArray.mic_spr);
  const selector_mic_qcm = useSelector((state) => state.measurementArray.mic_qcm);
  const selector_mic_pol = useSelector((state) => state.measurementArray.mic_pol);
  // 測定法.熱分析
  const selector_tha_tga = useSelector((state) => state.measurementArray.tha_tga);
  const selector_tha_dta = useSelector((state) => state.measurementArray.tha_dta);
  const selector_tha_dsc = useSelector((state) => state.measurementArray.tha_dsc);
  // 測定法
  const selector_ea = useSelector((state) => state.measurementArray.ea);
  const selector_msm = useSelector((state) => state.measurementArray.msm);
  const selector_gep = useSelector((state) => state.measurementArray.gep);
  const selector_cona = useSelector((state) => state.measurementArray.cona);
  const selector_sten = useSelector((state) => state.measurementArray.sten);
  const selector_sfor = useSelector((state) => state.measurementArray.sfor);
  const selector_bod = useSelector((state) => state.measurementArray.bod);

  //分解.化学分解
  const selector_chd = useSelector((state) => state.degradationArray.chd);
  // 分解.生分解.植種情報
  const selector_biod_pls_loc = useSelector((state) => state.degradationArray.biod_pls_loc);
  const selector_biod_pls_bdate = useSelector((state) => state.degradationArray.biod_pls_bdate);
  const selector_biod_pls_btime = useSelector((state) => state.degradationArray.biod_pls_btime);
  const selector_biod_pls_toc = useSelector((state) => state.degradationArray.biod_pls_toc);
  const selector_biod_pls_nita = useSelector((state) => state.degradationArray.biod_pls_nita);
  const selector_biod_pls_bmass = useSelector((state) => state.degradationArray.biod_pls_bmass);

  // 分解.生分解.植種情報.分解データ
  const selector_biod_pls_dd_rc_ph = useSelector((state) => state.degradationArray.biod_pls_dd_rc_ph);
  const selector_biod_pls_dd_rc_btemp = useSelector((state) => state.degradationArray.biod_pls_dd_rc_btemp);
  const selector_biod_pls_dd_rc_both = useSelector((state) => state.degradationArray.biod_pls_dd_rc_both);
  const selector_biod_pls_dd_brlt_brt = useSelector((state) => state.degradationArray.biod_pls_dd_brlt_brt);
  const selector_biod_pls_dd_brlt_dr = useSelector((state) => state.degradationArray.biod_pls_dd_brlt_dr);
  const selector_biod_pls_dd_brlt_dp = useSelector((state) => state.degradationArray.biod_pls_dd_brlt_dp);
  const selector_biod_pls_dd_brlt_dbod = useSelector((state) => state.degradationArray.biod_pls_dd_brlt_dbod);


  const makeData = (selector, label) => {

    let ret = {
      [label + '_type'] : selector.inputType || '',
      [label] : selector.inputValue || '',
      [label + '_unit']: selector.inputUnit || '',
      [label + '_cf'] : selector.inputFactor || '',
      [label + '_cmt'] : selector.inputComment || ''
    };
 
    return ret;
  }




  const fileUpload = (docid, type, file) => {
    let getCsrf = '';

    let allcookies = document.cookie.split('; ');
    // console.log(allcookies)
    allcookies.forEach(c => {
      let r = c.split('=');
      // console.log(r)
      if(r[0] == 'csrftoken'){
          getCsrf = r[1];
          return getCsrf
      }
    });

    // console.log(getCsrf)


    file.map(f => {

      const formData =  new FormData();
      formData.append('documentID', docid);
      formData.append('type', type);
      formData.append('value', f.key);
      formData.append('file', f.file);
      formData.append('category', f.category)
    //   for (let value of formData.entries()) { 
    //     console.log(value); 
    // }

      fetch(`http://localhost:8000/input/fileup/` + docid, {
          method: 'POST',
          headers: {
            "X-CSRFToken": getCsrf},
          body: formData,
        })
        .then(response => {
          // console.log('response:', response)
          return response.json();
        })
        .then(responseData => {
          // console.log('responseData:', JSON.stringify(responseData) )
          // console.log(responseData.resultCode)
        })

      
    })
  }

  const doPostNewData = () => {
    let imgData = [];
    let csvData = [];

    const divideImage = (selector,data, category) => {
      imgData = [...imgData, { 'key' : data, 'file': selector.inputImage, 'category': category}]
      return imgData;
  }

  const divideCSV = (selector,data, category) => {
    csvData = [...csvData, { 'key' : data, 'file': selector.inputCSVFile, 'category': category}];
    return csvData
  }

    // fetch(`${WAS_CONFIG.HOSTNAME}/input/new/`,

    var ovdata = {"sample": `${selector_sample}`, "sampleID": `${selector_sampleID}`, "group": `${selector_group}`,
      "inst": `${selector_inst}`, "kind": `${selector_kind}`,
      "chst": `${selector_chst.inputValue || ''}`,
      "expp": `${selector_expp}`, "respp": `${selector_respp}`, "expdate": `${selector_expdate}`,
      "inpp": `${selector_inpp}`, "bmemo": `${selector_bmemo}`};
    
    if (selector_chst.inputImage) imgData = divideImage(selector_chst, 'chst', 'ov');


    // ovdata = JSON.stringify(ovdata);
    // console.log(ovdata);

    // var ppdata = {};
    // var ppdata = {'docid': '123123', 'phprid': 0,};
    var ppdata = {};


    //固体物性.熱力学量
    ppdata = {...ppdata, ...makeData(selector_pp_td_den, 'pp_td_den')};
    if (selector_pp_td_den.inputImage) 
      imgData = divideImage(selector_pp_td_den,'pp_td_den', 'phpr');
    if (typeof selector_pp_td_den.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_den,'pp_td_den', 'phpr');

  
    ppdata = {...ppdata, ...makeData(selector_pp_td_sv, 'pp_td_sv')};
    if (selector_pp_td_sv.inputImage) 
      imgData = divideImage(selector_pp_td_sv, 'pp_td_sv', 'phpr');
    if (typeof selector_pp_td_sv.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_sv,'pp_td_sv', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_td_ar, 'pp_td_ar')};
    if (selector_pp_td_ar.inputImage) 
      imgData = divideImage(selector_pp_td_ar,'pp_td_ar', 'phpr');
    if (typeof selector_pp_td_ar.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_ar,'pp_td_ar', 'phpr');


    ppdata = {...ppdata, ...makeData(selector_pp_td_st, 'pp_td_st')};
    if (selector_pp_td_st.inputImage) 
      imgData = divideImage(selector_pp_td_st,'pp_td_st', 'phpr');
    if (typeof selector_pp_td_st.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_st,'pp_td_st', 'phpr');

    
    ppdata = {...ppdata, ...makeData(selector_pp_td_trop, 'pp_td_trop')};
    if (selector_pp_td_trop.inputImage) 
      imgData = divideImage(selector_pp_td_trop,'pp_td_trop', 'phpr');
    if (typeof selector_pp_td_trop.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_trop,'pp_td_trop', 'phpr');

    
    ppdata = {...ppdata, ...makeData(selector_pp_td_enthal, 'pp_td_enthal')};
    if (selector_pp_td_enthal.inputImage) 
      imgData = divideImage(selector_pp_td_enthal,'pp_td_enthal', 'phpr');
    if (typeof selector_pp_td_enthal.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_enthal,'pp_td_enthal', 'phpr');

    
    ppdata = {...ppdata, ...makeData(selector_pp_td_cr, 'pp_td_cr')};
    if (selector_pp_td_cr.inputImage) 
      imgData = divideImage(selector_pp_td_cr,'pp_td_cr', 'phpr');
    if (typeof selector_pp_td_cr.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_cr,'pp_td_cr', 'phpr');

    
    ppdata = {...ppdata, ...makeData(selector_pp_td_te, 'pp_td_te')};
    if (selector_pp_td_te.inputImage) 
      imgData = divideImage(selector_pp_td_te,'pp_td_te', 'phpr');
    if (typeof selector_pp_td_te.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_te,'pp_td_te', 'phpr');

    

    //固体物性.熱力学量.気体透過性
    ppdata = {...ppdata, ...makeData(selector_pp_td_gp_wp, 'pp_td_gp_wp')};
    if (selector_pp_td_gp_wp.inputImage) 
      imgData = divideImage(selector_pp_td_gp_wp,'pp_td_gp_wp', 'phpr');
    if (typeof selector_pp_td_gp_wp.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_gp_wp,'pp_td_gp_wp', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_td_gp_mp, 'pp_td_gp_mp')};
    if (selector_pp_td_gp_mp.inputImage) 
      imgData = divideImage(selector_pp_td_gp_mp,'pp_td_gp_mp', 'phpr');
    if (typeof selector_pp_td_gp_mp.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_gp_mp,'pp_td_gp_mp', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_td_gp_ftd, 'pp_td_gp_ftd')};
    if (selector_pp_td_gp_ftd.inputImage) 
      imgData = divideImage(selector_pp_td_gp_ftd,'pp_td_gp_ftd', 'phpr');
    if (typeof selector_pp_td_gp_ftd.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_gp_ftd,'pp_td_gp_ftd', 'phpr');
    
    ppdata = {...ppdata, ...makeData(selector_pp_td_gp_tpd, 'pp_td_gp_tpd')};
    if (selector_pp_td_gp_tpd.inputImage) 
      imgData = divideImage(selector_pp_td_gp_tpd,'pp_td_gp_tpd', 'phpr');
    if (typeof selector_pp_td_gp_tpd.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_gp_tpd,'pp_td_gp_tpd', 'phpr');
    
    ppdata = {...ppdata, ...makeData(selector_pp_td_gp_hd, 'pp_td_gp_hd')};
    if (selector_pp_td_gp_hd.inputImage) 
      imgData = divideImage(selector_pp_td_gp_hd,'pp_td_gp_hd', 'phpr');
    if (typeof selector_pp_td_gp_hd.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_gp_hd,'pp_td_gp_hd', 'phpr');

    //固体物性.熱力学量.流動特性
    ppdata = {...ppdata, ...makeData(selector_pp_td_fc_mv_sd, 'pp_td_fc_mv_sd')};
    if (selector_pp_td_fc_mv_sd.inputImage) 
      imgData = divideImage(selector_pp_td_fc_mv_sd,'pp_td_fc_mv_sd', 'phpr');
    if (typeof selector_pp_td_fc_mv_sd.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_fc_mv_sd,'pp_td_fc_mv_sd', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_td_fc_mv_mtd, 'pp_td_fc_mv_mtd')};
    if (selector_pp_td_fc_mv_mtd.inputImage) 
      imgData = divideImage(selector_pp_td_fc_mv_mtd,'pp_td_fc_mv_mtd', 'phpr');
    if (typeof selector_pp_td_fc_mv_mtd.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_fc_mv_mtd,'pp_td_fc_mv_mtd', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_td_fc_mv_pd, 'pp_td_fc_mv_pd')};
    if (selector_pp_td_fc_mv_pd.inputImage) 
      imgData = divideImage(selector_pp_td_fc_mv_pd,'pp_td_fc_mv_pd', 'phpr');
    if (typeof selector_pp_td_fc_mv_pd.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_fc_mv_pd,'pp_td_fc_mv_pd', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_td_fc_vc, 'pp_td_fc_vc')};
    if (selector_pp_td_fc_vc.inputImage) 
      imgData = divideImage(selector_pp_td_fc_vc,'pp_td_fc_vc', 'phpr');
    if (typeof selector_pp_td_fc_vc.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_fc_vc,'pp_td_fc_vc', 'phpr');

    //固体物性.熱力学量.比熱
    ppdata = {...ppdata, ...makeData(selector_pp_td_sp_spc, 'pp_td_sp_spc')};
    if (selector_pp_td_sp_spc.inputImage) 
      imgData = divideImage(selector_pp_td_sp_spc,'pp_td_sp_spc', 'phpr');
    if (typeof selector_pp_td_sp_spc.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_sp_spc,'pp_td_sp_spc', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_td_sp_std, 'pp_td_sp_std')};
    if (selector_pp_td_sp_std.inputImage) 
      imgData = divideImage(selector_pp_td_sp_std,'pp_td_sp_std', 'phpr');
    if (typeof selector_pp_td_sp_std.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_sp_std,'pp_td_sp_std', 'phpr');


    //固体物性.熱力学量.熱伝導率
    ppdata = {...ppdata, ...makeData(selector_pp_td_tc_ttd, 'pp_td_tc_ttd')};
    if (selector_pp_td_tc_ttd.inputImage) 
      imgData = divideImage(selector_pp_td_tc_ttd,'pp_td_tc_ttd', 'phpr');
    if (typeof selector_pp_td_tc_ttd.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_tc_ttd,'pp_td_tc_ttd', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_td_tc_dsd, 'pp_td_tc_dsd')};
    if (selector_pp_td_tc_dsd.inputImage) 
      imgData = divideImage(selector_pp_td_tc_dsd,'pp_td_tc_dsd', 'phpr');
    if (typeof selector_pp_td_tc_dsd.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_tc_dsd,'pp_td_tc_dsd', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_td_tc_cpd, 'pp_td_tc_cpd')};
    if (selector_pp_td_tc_cpd.inputImage) 
      imgData = divideImage(selector_pp_td_tc_cpd,'pp_td_tc_cpd', 'phpr');
    if (typeof selector_pp_td_tc_cpd.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_tc_cpd,'pp_td_tc_cpd', 'phpr');


    //固体物性.熱力学量.熱拡散係数
    ppdata = {...ppdata, ...makeData(selector_pp_td_tdif_cmm, 'pp_td_tdif_cmm')};
    if (selector_pp_td_tdif_cmm.inputImage) 
      imgData = divideImage(selector_pp_td_tdif_cmm,'pp_td_tdif_cmm', 'phpr');
    if (typeof selector_pp_td_tdif_cmm.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_tdif_cmm,'pp_td_tdif_cmm', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_td_tdif_amo, 'pp_td_tdif_amo')};
    if (selector_pp_td_tdif_amo.inputImage) 
      imgData = divideImage(selector_pp_td_tdif_amo,'pp_td_tdif_amo', 'phpr');
    if (typeof selector_pp_td_tdif_amo.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_tdif_amo,'pp_td_tdif_amo', 'phpr');

    //固体物性.熱力学量.各種温度
    ppdata = {...ppdata, ...makeData(selector_pp_td_temp_Tg_gpd, 'pp_td_temp_Tg_gpd')};
    if (selector_pp_td_temp_Tg_gpd.inputImage) 
      imgData = divideImage(selector_pp_td_temp_Tg_gpd,'pp_td_temp_Tg_gpd', 'phpr');
    if (typeof selector_pp_td_temp_Tg_gpd.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_temp_Tg_gpd,'pp_td_temp_Tg_gpd', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_td_temp_mlp_mpd, 'pp_td_temp_mlp_mpd')};
    if (selector_pp_td_temp_mlp_mpd.inputImage) 
      imgData = divideImage(selector_pp_td_temp_mlp_mpd,'pp_td_temp_mlp_mpd', 'phpr');
    if (typeof selector_pp_td_temp_mlp_mpd.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_temp_mlp_mpd,'pp_td_temp_mlp_mpd', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_td_temp_DSC, 'pp_td_temp_DSC')};
    if (selector_pp_td_temp_DSC.inputImage) 
      imgData = divideImage(selector_pp_td_temp_DSC,'pp_td_temp_DSC', 'phpr');
    if (typeof selector_pp_td_temp_DSC.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_temp_DSC,'pp_td_temp_DSC', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_td_temp_TGA, 'pp_td_temp_TGA')};
    if (selector_pp_td_temp_TGA.inputImage) 
      imgData = divideImage(selector_pp_td_temp_TGA,'pp_td_temp_TGA', 'phpr');
    if (typeof selector_pp_td_temp_TGA.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_temp_TGA,'pp_td_temp_TGA', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_td_temp_wr5, 'pp_td_temp_wr5')};
    if (selector_pp_td_temp_wr5.inputImage) 
      imgData = divideImage(selector_pp_td_temp_wr5,'pp_td_temp_wr5', 'phpr');
    if (typeof selector_pp_td_temp_wr5.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_temp_wr5,'pp_td_temp_wr5', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_td_temp_emb, 'pp_td_temp_emb')};
    if (selector_pp_td_temp_emb.inputImage) 
      imgData = divideImage(selector_pp_td_temp_emb,'pp_td_temp_emb', 'phpr');
    if (typeof selector_pp_td_temp_emb.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_temp_emb,'pp_td_temp_emb', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_td_temp_HDT, 'pp_td_temp_HDT')};
    if (selector_pp_td_temp_HDT.inputImage) 
      imgData = divideImage(selector_pp_td_temp_HDT,'pp_td_temp_HDT', 'phpr');
    if (typeof selector_pp_td_temp_HDT.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_temp_HDT,'pp_td_temp_HDT', 'phpr');


    //固体物性.熱力学量.燃焼特性
    ppdata = {...ppdata, ...makeData(selector_pp_td_bchar_FIT, 'pp_td_bchar_FIT')};
    if (selector_pp_td_bchar_FIT.inputImage) 
      imgData = divideImage(selector_pp_td_bchar_FIT,'pp_td_bchar_FIT', 'phpr');
    if (typeof selector_pp_td_bchar_FIT.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_bchar_FIT,'pp_td_bchar_FIT', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_td_bchar_hdc, 'pp_td_bchar_hdc')};
    if (selector_pp_td_bchar_hdc.inputImage) 
      imgData = divideImage(selector_pp_td_bchar_hdc,'pp_td_bchar_hdc', 'phpr');
    if (typeof selector_pp_td_bchar_hdc.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_bchar_hdc,'pp_td_bchar_hdc', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_td_bchar_ch, 'pp_td_bchar_ch')};
    if (selector_pp_td_bchar_ch.inputImage) 
      imgData = divideImage(selector_pp_td_bchar_ch,'pp_td_bchar_ch', 'phpr');
    if (typeof selector_pp_td_bchar_ch.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_bchar_ch,'pp_td_bchar_ch', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_td_bchar_oi, 'pp_td_bchar_oi')};
    if (selector_pp_td_bchar_oi.inputImage) 
      imgData = divideImage(selector_pp_td_bchar_oi,'pp_td_bchar_oi', 'phpr');
    if (typeof selector_pp_td_bchar_oi.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_bchar_oi,'pp_td_bchar_oi', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_td_bchar_hrp, 'pp_td_bchar_hrp')};
    if (selector_pp_td_bchar_hrp.inputImage) 
      imgData = divideImage(selector_pp_td_bchar_hrp,'pp_td_bchar_hrp', 'phpr');
    if (typeof selector_pp_td_bchar_hrp.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_td_bchar_hrp,'pp_td_bchar_hrp', 'phpr');


    //固体物性.機械強度特性
    ppdata = {...ppdata, ...makeData(selector_pp_ms_crt, 'pp_ms_crt')};
    if (selector_pp_ms_crt.inputImage) 
      imgData = divideImage(selector_pp_ms_crt,'pp_ms_crt', 'phpr');
    if (typeof selector_pp_ms_crt.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_crt,'pp_ms_crt', 'phpr');
    
    ppdata = {...ppdata, ...makeData(selector_pp_ms_strrel, 'pp_ms_strrel')};
    if (selector_pp_ms_strrel.inputImage) 
      imgData = divideImage(selector_pp_ms_strrel,'pp_ms_strrel', 'phpr');
    if (typeof selector_pp_ms_strrel.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_strrel,'pp_ms_strrel', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_ms_crack, 'pp_ms_crack')};
    if (selector_pp_ms_crack.inputImage) 
      imgData = divideImage(selector_pp_ms_crack,'pp_ms_crack', 'phpr');
    if (typeof selector_pp_ms_crack.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_crack,'pp_ms_crack', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_ms_frat, 'pp_ms_frat')};
    if (selector_pp_ms_frat.inputImage) 
      imgData = divideImage(selector_pp_ms_frat,'pp_ms_frat', 'phpr');
    if (typeof selector_pp_ms_frat.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_frat,'pp_ms_frat', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_ms_fatp, 'pp_ms_fatp')};
    if (selector_pp_ms_fatp.inputImage) 
      imgData = divideImage(selector_pp_ms_fatp,'pp_ms_fatp', 'phpr');
    if (typeof selector_pp_ms_fatp.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_fatp,'pp_ms_fatp', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_ms_hard, 'pp_ms_hard')};
    if (selector_pp_ms_hard.inputImage) 
      imgData = divideImage(selector_pp_ms_hard,'pp_ms_hard', 'phpr');
    if (typeof selector_pp_ms_hard.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_hard,'pp_ms_hard', 'phpr');


    //固体物性.機械強度特性.応力歪曲線.引張
    ppdata = {...ppdata, ...makeData(selector_pp_ms_ssc_ten_degstretch, 'pp_ms_ssc_ten_degstretch')};
    if (selector_pp_ms_ssc_ten_degstretch.inputImage) 
      imgData = divideImage(selector_pp_ms_ssc_ten_degstretch,'pp_ms_ssc_ten_degstretch', 'phpr');
    if (typeof selector_pp_ms_ssc_ten_degstretch.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_ssc_ten_degstretch,'pp_ms_ssc_ten_degstretch', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_ms_ssc_ten_degela, 'pp_ms_ssc_ten_degela')};
    if (selector_pp_ms_ssc_ten_degela.inputImage) 
      imgData = divideImage(selector_pp_ms_ssc_ten_degela,'pp_ms_ssc_ten_degela', 'phpr');
    if (typeof selector_pp_ms_ssc_ten_degela.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_ssc_ten_degela,'pp_ms_ssc_ten_degela', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_ms_ssc_ten_rupstr, 'pp_ms_ssc_ten_rupstr')};
    if (selector_pp_ms_ssc_ten_rupstr.inputImage) 
      imgData = divideImage(selector_pp_ms_ssc_ten_rupstr,'pp_ms_ssc_ten_rupstr', 'phpr');
    if (typeof selector_pp_ms_ssc_ten_rupstr.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_ssc_ten_rupstr,'pp_ms_ssc_ten_rupstr', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_ms_ssc_ten_sttd, 'pp_ms_ssc_ten_sttd')};
    if (selector_pp_ms_ssc_ten_sttd.inputImage) 
      imgData = divideImage(selector_pp_ms_ssc_ten_sttd,'pp_ms_ssc_ten_sttd', 'phpr');
    if (typeof selector_pp_ms_ssc_ten_sttd.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_ssc_ten_sttd,'pp_ms_ssc_ten_sttd', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_ms_ssc_ten_stpd, 'pp_ms_ssc_ten_stpd')};
    if (selector_pp_ms_ssc_ten_stpd.inputImage) 
      imgData = divideImage(selector_pp_ms_ssc_ten_stpd,'pp_ms_ssc_ten_stpd', 'phpr');
    if (typeof selector_pp_ms_ssc_ten_stpd.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_ssc_ten_stpd,'pp_ms_ssc_ten_stpd', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_ms_ssc_ten_baimage, 'pp_ms_ssc_ten_baimage')};
    if (selector_pp_ms_ssc_ten_baimage.inputImage) 
      imgData = divideImage(selector_pp_ms_ssc_ten_baimage,'pp_ms_ssc_ten_baimage', 'phpr');
    if (typeof selector_pp_ms_ssc_ten_baimage.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_ssc_ten_baimage,'pp_ms_ssc_ten_baimage', 'phpr');

    //固体物性.機械強度特性.応力歪曲線.曲げ試験
    ppdata = {...ppdata, ...makeData(selector_pp_ms_ssc_bend_bdstretch, 'pp_ms_ssc_bend_bdstretch')};
    if (selector_pp_ms_ssc_bend_bdstretch.inputImage) 
      imgData = divideImage(selector_pp_ms_ssc_bend_bdstretch,'pp_ms_ssc_bend_bdstretch', 'phpr');
    if (typeof selector_pp_ms_ssc_bend_bdstretch.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_ssc_bend_bdstretch,'pp_ms_ssc_bend_bdstretch', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_ms_ssc_bend_bdela, 'pp_ms_ssc_bend_bdela')};
    if (selector_pp_ms_ssc_bend_bdela.inputImage) 
      imgData = divideImage(selector_pp_ms_ssc_bend_bdela,'pp_ms_ssc_bend_bdela', 'phpr');
    if (typeof selector_pp_ms_ssc_bend_bdela.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_ssc_bend_bdela,'pp_ms_ssc_bend_bdela', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_ms_ssc_bend_brstr, 'pp_ms_ssc_bend_brstr')};
    if (selector_pp_ms_ssc_bend_brstr.inputImage) 
      imgData = divideImage(selector_pp_ms_ssc_bend_brstr,'pp_ms_ssc_bend_brstr', 'phpr');
    if (typeof selector_pp_ms_ssc_bend_brstr.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_ssc_bend_brstr,'pp_ms_ssc_bend_brstr', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_ms_ssc_bend_btd, 'pp_ms_ssc_bend_btd')};
    if (selector_pp_ms_ssc_bend_btd.inputImage) 
      imgData = divideImage(selector_pp_ms_ssc_bend_btd,'pp_ms_ssc_bend_btd', 'phpr');
    if (typeof selector_pp_ms_ssc_bend_btd.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_ssc_bend_btd,'pp_ms_ssc_bend_btd', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_ms_ssc_bend_bpd, 'pp_ms_ssc_bend_bpd')};
    if (selector_pp_ms_ssc_bend_bpd.inputImage) 
      imgData = divideImage(selector_pp_ms_ssc_bend_bpd,'pp_ms_ssc_bend_bpd', 'phpr');
    if (typeof selector_pp_ms_ssc_bend_bpd.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_ssc_bend_bpd,'pp_ms_ssc_bend_bpd', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_ms_ssc_bend_bbaimage, 'pp_ms_ssc_bend_bbaimage')};
    if (selector_pp_ms_ssc_bend_bbaimage.inputImage) 
      imgData = divideImage(selector_pp_ms_ssc_bend_bbaimage,'pp_ms_ssc_bend_bbaimage', 'phpr');
    if (typeof selector_pp_ms_ssc_bend_bbaimage.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_ssc_bend_bbaimage,'pp_ms_ssc_bend_bbaimage', 'phpr');


    //固体物性.機械強度特性.粘弾性測定
    ppdata = {...ppdata, ...makeData(selector_pp_ms_vem_vtd, 'pp_ms_vem_vtd')};
    if (selector_pp_ms_vem_vtd.inputImage) 
      imgData = divideImage(selector_pp_ms_vem_vtd,'pp_ms_vem_vtd', 'phpr');
    if (typeof selector_pp_ms_vem_vtd.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_vem_vtd,'pp_ms_vem_vtd', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_ms_vem_vpd, 'pp_ms_vem_vpd')};
    if (selector_pp_ms_vem_vpd.inputImage) 
      imgData = divideImage(selector_pp_ms_vem_vpd,'pp_ms_vem_vpd', 'phpr');
    if (typeof selector_pp_ms_vem_vpd.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_vem_vpd,'pp_ms_vem_vpd', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_ms_vem_gdash, 'pp_ms_vem_gdash')};
    if (selector_pp_ms_vem_gdash.inputImage) 
      imgData = divideImage(selector_pp_ms_vem_gdash,'pp_ms_vem_gdash', 'phpr');
    if (typeof selector_pp_ms_vem_gdash.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_vem_gdash,'pp_ms_vem_gdash', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_ms_vem_gdash2, 'pp_ms_vem_gdash2')};
    if (selector_pp_ms_vem_gdash2.inputImage) 
      imgData = divideImage(selector_pp_ms_vem_gdash2,'pp_ms_vem_gdash2', 'phpr');
    if (typeof selector_pp_ms_vem_gdash2.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_vem_gdash2,'pp_ms_vem_gdash2', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_ms_vem_ltan, 'pp_ms_vem_ltan')};
    if (selector_pp_ms_vem_ltan.inputImage) 
      imgData = divideImage(selector_pp_ms_vem_ltan,'pp_ms_vem_ltan', 'phpr');
    if (typeof selector_pp_ms_vem_ltan.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_vem_ltan,'pp_ms_vem_ltan', 'phpr');


    //固体物性.機械強度特性.衝撃試験
    ppdata = {...ppdata, ...makeData(selector_pp_ms_sht_Char_ctd, 'pp_ms_sht_Char_ctd')};
    if (selector_pp_ms_sht_Char_ctd.inputImage) 
      imgData = divideImage(selector_pp_ms_sht_Char_ctd,'pp_ms_sht_Char_ctd', 'phpr');
    if (typeof selector_pp_ms_sht_Char_ctd.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_sht_Char_ctd,'pp_ms_sht_Char_ctd', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_pp_ms_sht_Izod_itd, 'pp_ms_sht_Izod_itd')};
    if (selector_pp_ms_sht_Izod_itd.inputImage) 
      imgData = divideImage(selector_pp_ms_sht_Izod_itd,'pp_ms_sht_Izod_itd', 'phpr');
    if (typeof selector_pp_ms_sht_Izod_itd.inputCSVFile === 'object') 
      csvData = divideCSV(selector_pp_ms_sht_Izod_itd,'pp_ms_sht_Izod_itd', 'phpr');


    //固体物性.摩擦係数
    ppdata = {...ppdata, ...makeData(selector_friction_coefficient, 'pp_fric')};
    if (selector_friction_coefficient.inputImage) 
      imgData = divideImage(selector_friction_coefficient,'pp_fric', 'phpr');
    if (typeof selector_friction_coefficient.inputCSVFile === 'object') 
      csvData = divideCSV(selector_friction_coefficient,'pp_fric', 'phpr');


    //固体物性.酵素分解
    ppdata = {...ppdata, ...makeData(selector_enzymatic_decomposition_rate, 'pp_enzy_decr')};
    if (selector_enzymatic_decomposition_rate.inputImage) 
      imgData = divideImage(selector_enzymatic_decomposition_rate,'pp_enzy_decr', 'phpr');
    if (typeof selector_enzymatic_decomposition_rate.inputCSVFile === 'object') 
      csvData = divideCSV(selector_enzymatic_decomposition_rate,'pp_enzy_decr', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_enzymatic_enzymatic_reaction, 'pp_enzy_activ_ereac')};
    if (selector_enzymatic_enzymatic_reaction.inputImage) 
      imgData = divideImage(selector_enzymatic_enzymatic_reaction,'pp_enzy_activ_ereac', 'phpr');
    if (typeof selector_enzymatic_enzymatic_reaction.inputCSVFile === 'object') 
      csvData = divideCSV(selector_enzymatic_enzymatic_reaction,'pp_enzy_activ_ereac', 'phpr');

    //固体物性.生分解度
    ppdata = {...ppdata, ...makeData(selector_biodegradability_curve, 'pp_biodegra_bdgraph_bdcurve')};
    if (selector_biodegradability_curve.inputImage) 
      imgData = divideImage(selector_biodegradability_curve,'pp_biodegra_bdgraph_bdcurve', 'phpr');
    if (typeof selector_biodegradability_curve.inputCSVFile === 'object') 
      csvData = divideCSV(selector_biodegradability_curve,'pp_biodegra_bdgraph_bdcurve', 'phpr');

    //固体物性.崩壊度
    ppdata = {...ppdata, ...makeData(selector_bar_graph, 'pp_degdegra_wtchg_barg')};
    if (selector_bar_graph.inputImage) 
      imgData = divideImage(selector_bar_graph,'pp_degdegra_wtchg_barg', 'phpr');
    if (typeof selector_bar_graph.inputCSVFile === 'object') 
      csvData = divideCSV(selector_bar_graph,'pp_degdegra_wtchg_barg', 'phpr');


    //固体物性.構造
    ppdata = {...ppdata, ...makeData(selector_structure_xray_graph, 'pp_struct_xray_graph')};
    if (selector_structure_xray_graph.inputImage) 
      imgData = divideImage(selector_structure_xray_graph,'pp_struct_xray_graph', 'phpr');
    if (typeof selector_structure_xray_graph.inputCSVFile === 'object') 
      csvData = divideCSV(selector_structure_xray_graph,'pp_struct_xray_graph', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_structure_microscope_image, 'pp_struct_micro_mimg')};
    if (selector_structure_microscope_image.inputImage) 
      imgData = divideImage(selector_structure_microscope_image,'pp_struct_micro_mimg', 'phpr');
    if (typeof selector_structure_microscope_image.inputCSVFile === 'object') 
      csvData = divideCSV(selector_structure_microscope_image,'pp_struct_micro_mimg', 'phpr');

    ppdata = {...ppdata, ...makeData(selector_structure_crystallinity_degree, 'pp_struct_dcrysta')};
    if (selector_structure_crystallinity_degree.inputImage) 
      imgData = divideImage(selector_structure_crystallinity_degree,'pp_struct_dcrysta', 'phpr');
    if (typeof selector_structure_crystallinity_degree.inputCSVFile === 'object') 
      csvData = divideCSV(selector_structure_crystallinity_degree,'pp_struct_dcrysta', 'phpr');

    // ppdata = JSON.stringify(ppdata);
    // console.log(ppdata);
    



    // var syndata = {};
    // var syndata = {'docid': 0, 'synid': 0,};
    var syndata = {};

    //高分子生成.作り方
    syndata = {...syndata, ...makeData(selector_hom_enzyme, 'hom_enzyme')};
    if (selector_hom_enzyme.inputImage) 
      imgData = divideImage(selector_hom_enzyme,'hom_enzyme', 'syn');
    if (typeof selector_hom_enzyme.inputCSVFile === 'object') 
      csvData = divideCSV(selector_hom_enzyme,'hom_enzyme', 'syn');

    syndata = {...syndata, ...makeData(selector_hom_fungus, 'hom_fungus')};
    if (selector_hom_fungus.inputImage) 
      imgData = divideImage(selector_hom_fungus,'hom_fungus', 'syn');
    if (typeof selector_hom_fungus.inputCSVFile === 'object') 
      csvData = divideCSV(selector_hom_fungus,'hom_fungus', 'syn');



    //高分子生成.作り方.購入
    syndata = {...syndata, ...makeData(selector_buy_maker, 'hom_buy_maker')};
    if (selector_buy_maker.inputImage) 
      imgData = divideImage(selector_buy_maker,'hom_buy_maker', 'syn');
    if (typeof selector_buy_maker.inputCSVFile === 'object') 
      csvData = divideCSV(selector_buy_maker,'hom_buy_maker', 'syn');

    syndata = {...syndata, ...makeData(selector_buy_lot_number, 'hom_buy_lot')};
    if (selector_buy_lot_number.inputImage) 
      imgData = divideImage(selector_buy_lot_number,'hom_buy_lot', 'syn');
    if (typeof selector_buy_lot_number.inputCSVFile === 'object') 
      csvData = divideCSV(selector_buy_lot_number,'hom_buy_lot', 'syn');



    //高分子生成.作り方.製造プロセス
    syndata = {...syndata, ...makeData(selector_buy_pmaker, 'hom_proc_mat_pch_pmaker')};
    if (selector_buy_pmaker.inputImage) 
      imgData = divideImage(selector_buy_pmaker,'hom_proc_mat_pch_pmaker', 'syn');
    if (typeof selector_buy_pmaker.inputCSVFile === 'object') 
      csvData = divideCSV(selector_buy_pmaker,'hom_proc_mat_pch_pmaker', 'syn');

    syndata = {...syndata, ...makeData(selector_buy_plot_number, 'hom_proc_mat_pch_plot')};
    if (selector_buy_plot_number.inputImage) 
      imgData = divideImage(selector_buy_plot_number,'hom_proc_mat_pch_plot', 'syn');
    if (typeof selector_buy_plot_number.inputCSVFile === 'object') 
      csvData = divideCSV(selector_buy_plot_number,'hom_proc_mat_pch_plot', 'syn');

    syndata = {...syndata, ...makeData(selector_process_synthesis, 'hom_proc_mat_synth')};
    if (selector_process_synthesis.inputImage) 
      imgData = divideImage(selector_process_synthesis,'hom_proc_mat_synth', 'syn');
    if (typeof selector_process_synthesis.inputCSVFile === 'object') 
      csvData = divideCSV(selector_process_synthesis,'hom_proc_mat_synth', 'syn');

    syndata = {...syndata, ...makeData(selector_process_machine, 'hom_proc_mach')};
    if (selector_process_machine.inputImage) 
      imgData = divideImage(selector_process_machine,'hom_proc_mach', 'syn');
    if (typeof selector_process_machine.inputCSVFile === 'object') 
      csvData = divideCSV(selector_process_machine,'hom_proc_mach', 'syn');

    syndata = {...syndata, ...makeData(selector_process_theother, 'hom_proc_other')};
    if (selector_process_theother.inputImage) 
      imgData = divideImage(selector_process_theother,'hom_proc_other', 'syn');
    if (typeof selector_process_theother.inputCSVFile === 'object') 
      csvData = divideCSV(selector_process_theother,'hom_proc_other', 'syn');


    //高分子生成.作り方.重合名.連鎖重合
    syndata = {...syndata, ...makeData(selector_hom_prize_chain_add_rad_LRP, 'hom_prize_chain_add_rad_LRP')};
    if (selector_hom_prize_chain_add_rad_LRP.inputImage) 
      imgData = divideImage(selector_hom_prize_chain_add_rad_LRP,'hom_prize_chain_add_rad_LRP', 'syn');
    if (typeof selector_hom_prize_chain_add_rad_LRP.inputCSVFile === 'object') 
      csvData = divideCSV(selector_hom_prize_chain_add_rad_LRP,'hom_prize_chain_add_rad_LRP', 'syn');

    syndata = {...syndata, ...makeData(selector_hom_prize_chain_add_cati_LCP, 'hom_prize_chain_add_cati_LCP')};
    if (selector_hom_prize_chain_add_cati_LCP.inputImage) 
      imgData = divideImage(selector_hom_prize_chain_add_cati_LCP,'hom_prize_chain_add_cati_LCP', 'syn');
    if (typeof selector_hom_prize_chain_add_cati_LCP.inputCSVFile === 'object') 
      csvData = divideCSV(selector_hom_prize_chain_add_cati_LCP,'hom_prize_chain_add_cati_LCP', 'syn');

    syndata = {...syndata, ...makeData(selector_hom_prize_chain_add_anio_LAP, 'hom_prize_chain_add_anio_LAP')};
    if (selector_hom_prize_chain_add_anio_LAP.inputImage) 
      imgData = divideImage(selector_hom_prize_chain_add_anio_LAP,'hom_prize_chain_add_anio_LAP', 'syn');
    if (typeof selector_hom_prize_chain_add_anio_LAP.inputCSVFile === 'object') 
      csvData = divideCSV(selector_hom_prize_chain_add_anio_LAP,'hom_prize_chain_add_anio_LAP', 'syn');

    syndata = {...syndata, ...makeData(selector_hom_prize_chain_add_crd, 'hom_prize_chain_add_crd')};
    if (selector_hom_prize_chain_add_crd.inputImage) 
      imgData = divideImage(selector_hom_prize_chain_add_crd,'hom_prize_chain_add_crd', 'syn');
    if (typeof selector_hom_prize_chain_add_crd.inputCSVFile === 'object') 
      csvData = divideCSV(selector_hom_prize_chain_add_crd,'hom_prize_chain_add_crd', 'syn');

    syndata = {...syndata, ...makeData(selector_hom_prize_chain_rop_CROP, 'hom_prize_chain_rop_CROP')};
    if (selector_hom_prize_chain_rop_CROP.inputImage) 
      imgData = divideImage(selector_hom_prize_chain_rop_CROP,'hom_prize_chain_rop_CROP', 'syn');
    if (typeof selector_hom_prize_chain_rop_CROP.inputCSVFile === 'object') 
      csvData = divideCSV(selector_hom_prize_chain_rop_CROP,'hom_prize_chain_rop_CROP', 'syn');

    syndata = {...syndata, ...makeData(selector_hom_prize_chain_rop_AROP, 'hom_prize_chain_rop_AROP')};
    if (selector_hom_prize_chain_rop_AROP.inputImage) 
      imgData = divideImage(selector_hom_prize_chain_rop_AROP,'hom_prize_chain_rop_AROP', 'syn');
    if (typeof selector_hom_prize_chain_rop_AROP.inputCSVFile === 'object') 
      csvData = divideCSV(selector_hom_prize_chain_rop_AROP,'hom_prize_chain_rop_AROP', 'syn');

    syndata = {...syndata, ...makeData(selector_hom_prize_chain_rop_RROP, 'hom_prize_chain_rop_RROP')};
    if (selector_hom_prize_chain_rop_RROP.inputImage) 
      imgData = divideImage(selector_hom_prize_chain_rop_RROP,'hom_prize_chain_rop_RROP', 'syn');
    if (typeof selector_hom_prize_chain_rop_RROP.inputCSVFile === 'object') 
      csvData = divideCSV(selector_hom_prize_chain_rop_RROP,'hom_prize_chain_rop_RROP', 'syn');

    syndata = {...syndata, ...makeData(selector_hom_prize_chain_rop_TMCR_ROMP, 'hom_prize_chain_rop_TMCR_ROMP')};
    if (selector_hom_prize_chain_rop_TMCR_ROMP.inputImage) 
      imgData = divideImage(selector_hom_prize_chain_rop_TMCR_ROMP,'hom_prize_chain_rop_TMCR_ROMP', 'syn');
    if (typeof selector_hom_prize_chain_rop_TMCR_ROMP.inputCSVFile === 'object') 
      csvData = divideCSV(selector_hom_prize_chain_rop_TMCR_ROMP,'hom_prize_chain_rop_TMCR_ROMP', 'syn');

    syndata = {...syndata, ...makeData(selector_hom_prize_chain_CGCP, 'hom_prize_chain_CGCP')};
    if (selector_hom_prize_chain_CGCP.inputImage) 
      imgData = divideImage(selector_hom_prize_chain_CGCP,'hom_prize_chain_CGCP', 'syn');
    if (typeof selector_hom_prize_chain_CGCP.inputCSVFile === 'object') 
      csvData = divideCSV(selector_hom_prize_chain_CGCP,'hom_prize_chain_CGCP', 'syn');


    //高分子生成.作り方.重合名.逐次重合
    syndata = {...syndata, ...makeData(selector_hom_prize_SGP_pcon_NASP, 'hom_prize_SGP_pcon_NASP')};
    if (selector_hom_prize_SGP_pcon_NASP.inputImage) 
      imgData = divideImage(selector_hom_prize_SGP_pcon_NASP,'hom_prize_SGP_pcon_NASP', 'syn');
    if (typeof selector_hom_prize_SGP_pcon_NASP.inputCSVFile === 'object') 
      csvData = divideCSV(selector_hom_prize_SGP_pcon_NASP,'hom_prize_SGP_pcon_NASP', 'syn');

    syndata = {...syndata, ...makeData(selector_hom_prize_SGP_pcon_ANSP, 'hom_prize_SGP_pcon_ANSP')};
    if (selector_hom_prize_SGP_pcon_ANSP.inputImage) 
      imgData = divideImage(selector_hom_prize_SGP_pcon_ANSP,'hom_prize_SGP_pcon_ANSP', 'syn');
    if (typeof selector_hom_prize_SGP_pcon_ANSP.inputCSVFile === 'object') 
      csvData = divideCSV(selector_hom_prize_SGP_pcon_ANSP,'hom_prize_SGP_pcon_ANSP', 'syn');

    syndata = {...syndata, ...makeData(selector_hom_prize_SGP_pcon_ARNSP, 'hom_prize_SGP_pcon_ARNSP')};
    if (selector_hom_prize_SGP_pcon_ARNSP.inputImage) 
      imgData = divideImage(selector_hom_prize_SGP_pcon_ARNSP,'hom_prize_SGP_pcon_ARNSP', 'syn');
    if (typeof selector_hom_prize_SGP_pcon_ARNSP.inputCSVFile === 'object')
      csvData = divideCSV(selector_hom_prize_SGP_pcon_ARNSP,'hom_prize_SGP_pcon_ARNSP', 'syn');

    syndata = {...syndata, ...makeData(selector_hom_prize_SGP_pcon_ARESP, 'hom_prize_SGP_pcon_ARESP')};
    if (selector_hom_prize_SGP_pcon_ARESP.inputImage) 
      imgData = divideImage(selector_hom_prize_SGP_pcon_ARESP,'hom_prize_SGP_pcon_ARESP', 'syn');
    if (typeof selector_hom_prize_SGP_pcon_ARESP.inputCSVFile === 'object')
      csvData = divideCSV(selector_hom_prize_SGP_pcon_ARESP,'hom_prize_SGP_pcon_ARESP', 'syn');

    syndata = {...syndata, ...makeData(selector_hom_prize_SGP_pcon_OCP, 'hom_prize_SGP_pcon_OCP')};
    if (selector_hom_prize_SGP_pcon_OCP.inputImage) 
      imgData = divideImage(selector_hom_prize_SGP_pcon_OCP,'hom_prize_SGP_pcon_OCP', 'syn');
    if (typeof selector_hom_prize_SGP_pcon_OCP.inputCSVFile === 'object') 
      csvData = divideCSV(selector_hom_prize_SGP_pcon_OCP,'hom_prize_SGP_pcon_OCP', 'syn');

    syndata = {...syndata, ...makeData(selector_hom_prize_SGP_pcon_TMCP, 'hom_prize_SGP_pcon_TMCP')};
    if (selector_hom_prize_SGP_pcon_TMCP.inputImage) 
      imgData = divideImage(selector_hom_prize_SGP_pcon_TMCP,'hom_prize_SGP_pcon_TMCP', 'syn');
    if (typeof selector_hom_prize_SGP_pcon_TMCP.inputCSVFile === 'object') 
      csvData = divideCSV(selector_hom_prize_SGP_pcon_TMCP,'hom_prize_SGP_pcon_TMCP', 'syn');

    syndata = {...syndata, ...makeData(selector_hom_prize_SGP_pcon_EP, 'hom_prize_SGP_pcon_EP')};
    if (selector_hom_prize_SGP_pcon_EP.inputImage) 
      imgData = divideImage(selector_hom_prize_SGP_pcon_EP,'hom_prize_SGP_pcon_EP', 'syn');
    if (typeof selector_hom_prize_SGP_pcon_EP.inputCSVFile === 'object') 
      csvData = divideCSV(selector_hom_prize_SGP_pcon_EP,'hom_prize_SGP_pcon_EP', 'syn');

    syndata = {...syndata, ...makeData(selector_hom_prize_SGP_padd_PTDB, 'hom_prize_SGP_padd_PTDB')};
    if (selector_hom_prize_SGP_padd_PTDB.inputImage) 
      imgData = divideImage(selector_hom_prize_SGP_padd_PTDB,'hom_prize_SGP_padd_PTDB', 'syn');
    if (typeof selector_hom_prize_SGP_padd_PTDB.inputCSVFile === 'object') 
      csvData = divideCSV(selector_hom_prize_SGP_padd_PTDB,'hom_prize_SGP_padd_PTDB', 'syn');

    syndata = {...syndata, ...makeData(selector_hom_prize_SGP_padd_ROPA, 'hom_prize_SGP_padd_ROPA')};
    if (selector_hom_prize_SGP_padd_ROPA.inputImage) 
      imgData = divideImage(selector_hom_prize_SGP_padd_ROPA,'hom_prize_SGP_padd_ROPA', 'syn');
    if (typeof selector_hom_prize_SGP_padd_ROPA.inputCSVFile === 'object') 
      csvData = divideCSV(selector_hom_prize_SGP_padd_ROPA,'hom_prize_SGP_padd_ROPA', 'syn');

    syndata = {...syndata, ...makeData(selector_hom_prize_SGP_padd_CYPA, 'hom_prize_SGP_padd_CYPA')};
    if (selector_hom_prize_SGP_padd_CYPA.inputImage) 
      imgData = divideImage(selector_hom_prize_SGP_padd_CYPA,'hom_prize_SGP_padd_CYPA', 'syn');
    if (typeof selector_hom_prize_SGP_padd_CYPA.inputCSVFile === 'object') 
      csvData = divideCSV(selector_hom_prize_SGP_padd_CYPA,'hom_prize_SGP_padd_CYPA', 'syn');

    syndata = {...syndata, ...makeData(selector_hom_prize_SGP_padd_OTPA, 'hom_prize_SGP_padd_OTPA')};
    if (selector_hom_prize_SGP_padd_OTPA.inputImage) 
      imgData = divideImage(selector_hom_prize_SGP_padd_OTPA,'hom_prize_SGP_padd_OTPA', 'syn');
    if (typeof selector_hom_prize_SGP_padd_OTPA.inputCSVFile === 'object') 
      csvData = divideCSV(selector_hom_prize_SGP_padd_OTPA,'hom_prize_SGP_padd_OTPA', 'syn');

    syndata = {...syndata, ...makeData(selector_hom_prize_SGP_adco, 'hom_prize_SGP_adco')};
    if (selector_hom_prize_SGP_adco.inputImage) 
      imgData = divideImage(selector_hom_prize_SGP_adco,'hom_prize_SGP_adco', 'syn');
    if (typeof selector_hom_prize_SGP_adco.inputCSVFile === 'object') 
      csvData = divideCSV(selector_hom_prize_SGP_adco,'hom_prize_SGP_adco', 'syn');


    //高分子生成.反応条件
    syndata = {...syndata, ...makeData(selector_rcon_pH, 'rcon_pH')};
    if (selector_rcon_pH.inputImage) 
      imgData = divideImage(selector_rcon_pH,'rcon_pH', 'syn');
    if (typeof selector_rcon_pH.inputCSVFile === 'object') 
      csvData = divideCSV(selector_rcon_pH,'rcon_pH', 'syn');

    syndata = {...syndata, ...makeData(selector_rcon_rtemp, 'rcon_rtemp')};
    if (selector_rcon_rtemp.inputImage) 
      imgData = divideImage(selector_rcon_rtemp,'rcon_rtemp', 'syn');
    if (typeof selector_rcon_rtemp.inputCSVFile === 'object') 
      csvData = divideCSV(selector_rcon_rtemp,'rcon_rtemp', 'syn');

    syndata = {...syndata, ...makeData(selector_rcon_OTRC, 'rcon_OTRC')};
    if (selector_rcon_OTRC.inputImage) 
      imgData = divideImage(selector_rcon_OTRC,'rcon_OTRC', 'syn');
    if (typeof selector_rcon_OTRC.inputCSVFile === 'object') 
      csvData = divideCSV(selector_rcon_OTRC,'rcon_OTRC', 'syn');

    //高分子生成.結果
    syndata = {...syndata, ...makeData(selector_rslt_RT, 'rslt_RT')};
    if (selector_rslt_RT.inputImage) 
      imgData = divideImage(selector_rslt_RT,'rslt_RT', 'syn');
    if (typeof selector_rslt_RT.inputCSVFile === 'object') 
      csvData = divideCSV(selector_rslt_RT,'rslt_RT', 'syn');

    syndata = {...syndata, ...makeData(selector_rslt_MCR, 'rslt_MCR')};
    if (selector_rslt_MCR.inputImage) 
      imgData = divideImage(selector_rslt_MCR,'rslt_MCR', 'syn');
    if (typeof selector_rslt_MCR.inputCSVFile === 'object') 
      csvData = divideCSV(selector_rslt_MCR,'rslt_MCR', 'syn');

    syndata = {...syndata, ...makeData(selector_rslt_rrate, 'rslt_rrate')};
    if (selector_rslt_rrate.inputImage) 
      imgData = divideImage(selector_rslt_rrate,'rslt_rrate', 'syn');
    if (typeof selector_rslt_rrate.inputCSVFile === 'object') 
      csvData = divideCSV(selector_rslt_rrate,'rslt_rrate', 'syn');

    syndata = {...syndata, ...makeData(selector_rslt_CY, 'rslt_CY')};
    if (selector_rslt_CY.inputImage) 
      imgData = divideImage(selector_rslt_CY,'rslt_CY', 'syn');
    if (typeof selector_rslt_CY.inputCSVFile === 'object') 
      csvData = divideCSV(selector_rslt_CY,'rslt_CY', 'syn');

    syndata = {...syndata, ...makeData(selector_rslt_prate_tur, 'rslt_prate_tur')};
    if (selector_rslt_prate_tur.inputImage) 
      imgData = divideImage(selector_rslt_prate_tur,'rslt_prate_tur', 'syn');
    if (typeof selector_rslt_prate_tur.inputCSVFile === 'object') 
      csvData = divideCSV(selector_rslt_prate_tur,'rslt_prate_tur', 'syn');

    syndata = {...syndata, ...makeData(selector_rslt_prate_occ, 'rslt_prate_occ')};
    if (selector_rslt_prate_occ.inputImage) 
      imgData = divideImage(selector_rslt_prate_occ,'rslt_prate_occ', 'syn');
    if (typeof selector_rslt_prate_occ.inputCSVFile === 'object') 
      csvData = divideCSV(selector_rslt_prate_occ,'rslt_prate_occ', 'syn');

    syndata = {...syndata, ...makeData(selector_rslt_prate_pdata, 'rslt_prate_pdata')};
    if (selector_rslt_prate_pdata.inputImage) 
      imgData = divideImage(selector_rslt_prate_pdata,'rslt_prate_pdata', 'syn');
    if (typeof selector_rslt_prate_pdata.inputCSVFile === 'object') 
      csvData = divideCSV(selector_rslt_prate_pdata,'rslt_prate_pdata', 'syn');


  // 高分子生成.バイオモノマー生成
  syndata = {...syndata, ...makeData(selector_bmsyn_react, 'bmsyn_react')};
  if (selector_bmsyn_react.inputImage) 
    imgData = divideImage(selector_bmsyn_react,'bmsyn_react', 'syn');
  if (typeof selector_bmsyn_react.inputCSVFile === 'object') 
    csvData = divideCSV(selector_bmsyn_react,'bmsyn_react', 'syn');

  syndata = {...syndata, ...makeData(selector_bmsyn_reacs, 'bmsyn_reacs')};
  if (selector_bmsyn_reacs.inputImage) 
    imgData = divideImage(selector_bmsyn_reacs,'bmsyn_reacs', 'syn');
  if (typeof selector_bmsyn_reacs.inputCSVFile === 'object') 
    csvData = divideCSV(selector_bmsyn_reacs,'bmsyn_reacs', 'syn');

  syndata = {...syndata, ...makeData(selector_bmsyn_yield, 'bmsyn_yield')};
  if (selector_bmsyn_yield.inputImage) 
    imgData = divideImage(selector_bmsyn_yield,'bmsyn_yield', 'syn');
  if (typeof selector_bmsyn_yield.inputCSVFile === 'object') 
    csvData = divideCSV(selector_bmsyn_yield,'bmsyn_yield', 'syn');

    // 高分子生成.バイオモノマー生成..生産速度
  syndata = {...syndata, ...makeData(selector_bmsyn_pros_fgtb, 'bmsyn_pros_fgtb')};
  if (selector_bmsyn_pros_fgtb.inputImage) 
    imgData = divideImage(selector_bmsyn_pros_fgtb,'bmsyn_pros_fgtb', 'syn');
  if (typeof selector_bmsyn_pros_fgtb.inputCSVFile === 'object') 
    csvData = divideCSV(selector_bmsyn_pros_fgtb,'bmsyn_pros_fgtb', 'syn');

  syndata = {...syndata, ...makeData(selector_bmsyn_pros_csd, 'bmsyn_pros_csd')};
  if (selector_bmsyn_pros_csd.inputImage) 
    imgData = divideImage(selector_bmsyn_pros_csd,'bmsyn_pros_csd', 'syn');
  if (typeof selector_bmsyn_pros_csd.inputCSVFile === 'object') 
    csvData = divideCSV(selector_bmsyn_pros_csd,'bmsyn_pros_csd', 'syn');

  syndata = {...syndata, ...makeData(selector_bmsyn_pros_scon, 'bmsyn_pros_scon')};
  if (selector_bmsyn_pros_scon.inputImage) 
    imgData = divideImage(selector_bmsyn_pros_scon,'bmsyn_pros_scon', 'syn');
  if (typeof selector_bmsyn_pros_scon.inputCSVFile === 'object') 
    csvData = divideCSV(selector_bmsyn_pros_scon,'bmsyn_pros_scon', 'syn');

  syndata = {...syndata, ...makeData(selector_bmsyn_pros_pdt, 'bmsyn_pros_pdt')};
  if (selector_bmsyn_pros_pdt.inputImage) 
    imgData = divideImage(selector_bmsyn_pros_pdt,'bmsyn_pros_pdt', 'syn');
  if (typeof selector_bmsyn_pros_pdt.inputCSVFile === 'object') 
    csvData = divideCSV(selector_bmsyn_pros_pdt,'bmsyn_pros_pdt', 'syn');

     // 計算
    let caldata = {};

    // 計算.分子動力学計算
    caldata = {...caldata, ...makeData(selector_MD_MDp, 'MD_MDp')};
    if (selector_MD_MDp.inputImage) 
      imgData = divideImage(selector_MD_MDp,'MD_MDp', 'cal');
    if (typeof selector_MD_MDp.inputCSVFile === 'object') 
      csvData = divideCSV(selector_MD_MDp,'MD_MDp', 'cal');

    // 計算.分子動力学計算.計算条件 
    caldata = {...caldata, ...makeData(selector_MD_cond_ens, 'MD_cond_ens')};
    if (selector_MD_cond_ens.inputImage) 
      imgData = divideImage(selector_MD_cond_ens,'MD_cond_ens', 'cal');
    if (typeof selector_MD_cond_ens.inputCSVFile === 'object') 
      csvData = divideCSV(selector_MD_cond_ens,'MD_cond_ens', 'cal');


    caldata = {...caldata, ...makeData(selector_MD_cond_frc_ftbl, 'MD_cond_frc_ftbl')};
    if (selector_MD_cond_frc_ftbl.inputImage) 
      imgData = divideImage(selector_MD_cond_frc_ftbl,'MD_cond_frc_ftbl', 'cal');
    if (typeof selector_MD_cond_frc_ftbl.inputCSVFile === 'object') 
      csvData = divideCSV(selector_MD_cond_frc_ftbl,'MD_cond_frc_ftbl', 'cal');

    caldata = {...caldata, ...makeData(selector_MD_cond_icrd_icimage, 'MD_cond_icrd_icimage')};
    if (selector_MD_cond_icrd_icimage.inputImage) 
      imgData = divideImage(selector_MD_cond_icrd_icimage,'MD_cond_icrd_icimage', 'cal');
    if (typeof selector_MD_cond_icrd_icimage.inputCSVFile === 'object') 
      csvData = divideCSV(selector_MD_cond_icrd_icimage,'MD_cond_icrd_icimage', 'cal');

    caldata = {...caldata, ...makeData(selector_MD_cond_icrd_icPDB, 'MD_cond_icrd_icPDB')};
    if (selector_MD_cond_icrd_icPDB.inputImage) 
      imgData = divideImage(selector_MD_cond_icrd_icPDB,'MD_cond_icrd_icPDB', 'cal');
    if (typeof selector_MD_cond_icrd_icPDB.inputCSVFile === 'object') 
      csvData = divideCSV(selector_MD_cond_icrd_icPDB,'MD_cond_icrd_icPDB', 'cal');

    caldata = {...caldata, ...makeData(selector_MD_cond_icrd_icDCD, 'MD_cond_icrd_icDCD')};
    if (selector_MD_cond_icrd_icDCD.inputImage) 
      imgData = divideImage(selector_MD_cond_icrd_icDCD,'MD_cond_icrd_icDCD', 'cal');
    if (typeof selector_MD_cond_icrd_icDCD.inputCSVFile === 'object') 
      csvData = divideCSV(selector_MD_cond_icrd_icDCD,'MD_cond_icrd_icDCD', 'cal');

    caldata = {...caldata, ...makeData(selector_MD_cond_icrd_icPSF, 'MD_cond_icrd_icPSF')};
    if (selector_MD_cond_icrd_icPSF.inputImage) 
      imgData = divideImage(selector_MD_cond_icrd_icPSF,'MD_cond_icrd_icPSF', 'cal');
    if (typeof selector_MD_cond_icrd_icPSF.inputCSVFile === 'object') 
      csvData = divideCSV(selector_MD_cond_icrd_icPSF,'MD_cond_icrd_icPSF', 'cal');

    caldata = {...caldata, ...makeData(selector_MD_cond_otcond, 'MD_cond_otcond')};
    if (selector_MD_cond_otcond.inputImage) 
      imgData = divideImage(selector_MD_cond_otcond,'MD_cond_otcond', 'cal');
    if (typeof selector_MD_cond_otcond.inputCSVFile === 'object') 
      csvData = divideCSV(selector_MD_cond_otcond,'MD_cond_otcond', 'cal');

    // 計算.分子動力学計算.計算結果
    caldata = {...caldata, ...makeData(selector_MD_crslt_Thdyd_TvsT, 'MD_crslt_Thdyd_TvsT')};
    if (selector_MD_crslt_Thdyd_TvsT.inputImage) 
      imgData = divideImage(selector_MD_crslt_Thdyd_TvsT,'MD_crslt_Thdyd_TvsT', 'cal');
    if (typeof selector_MD_crslt_Thdyd_TvsT.inputCSVFile === 'object') 
      csvData = divideCSV(selector_MD_crslt_Thdyd_TvsT,'MD_crslt_Thdyd_TvsT', 'cal');

    caldata = {...caldata, ...makeData(selector_MD_crslt_Thdyd_PvsT, 'MD_crslt_Thdyd_PvsT')};
    if (selector_MD_crslt_Thdyd_PvsT.inputImage) 
      imgData = divideImage(selector_MD_crslt_Thdyd_PvsT,'MD_crslt_Thdyd_PvsT', 'cal');
    if (typeof selector_MD_crslt_Thdyd_PvsT.inputCSVFile === 'object') 
      csvData = divideCSV(selector_MD_crslt_Thdyd_PvsT,'MD_crslt_Thdyd_PvsT', 'cal');

    caldata = {...caldata, ...makeData(selector_MD_crslt_Thdyd_EvsT, 'MD_crslt_Thdyd_EvsT')};
    if (selector_MD_crslt_Thdyd_EvsT.inputImage) 
      imgData = divideImage(selector_MD_crslt_Thdyd_EvsT,'MD_crslt_Thdyd_EvsT', 'cal');
    if (typeof selector_MD_crslt_Thdyd_EvsT.inputCSVFile === 'object') 
      csvData = divideCSV(selector_MD_crslt_Thdyd_EvsT,'MD_crslt_Thdyd_EvsT', 'cal');

    caldata = {...caldata, ...makeData(selector_MD_crslt_Dyd, 'MD_crslt_Dyd')};
    if (selector_MD_crslt_Dyd.inputImage) 
      imgData = divideImage(selector_MD_crslt_Dyd,'MD_crslt_Dyd', 'cal');
    if (typeof selector_MD_crslt_Dyd.inputCSVFile === 'object') 
      csvData = divideCSV(selector_MD_crslt_Dyd,'MD_crslt_Dyd', 'cal');

    // 計算.量子化学計算
    caldata = {...caldata, ...makeData(selector_QC_QCp, 'QC_QCp')};
    if (selector_QC_QCp.inputImage) 
      imgData = divideImage(selector_QC_QCp,'QC_QCp', 'cal');
    if (typeof selector_QC_QCp.inputCSVFile === 'object') 
      csvData = divideCSV(selector_QC_QCp,'QC_QCp', 'cal');

    caldata = {...caldata, ...makeData(selector_QC_QCbc, 'QC_QCbc')};
    if (selector_QC_QCbc.inputImage) 
      imgData = divideImage(selector_QC_QCbc,'QC_QCbc', 'cal');
    if (typeof selector_QC_QCbc.inputCSVFile === 'object') 
      csvData = divideCSV(selector_QC_QCbc,'QC_QCbc', 'cal');

    caldata = {...caldata, ...makeData(selector_QC_QCap, 'QC_QCap')};
    if (selector_QC_QCap.inputImage) 
      imgData = divideImage(selector_QC_QCap,'QC_QCap', 'cal');
    if (typeof selector_QC_QCap.inputCSVFile === 'object') 
      csvData = divideCSV(selector_QC_QCap,'QC_QCap', 'cal');

    
  let meamdata = {}
    
  // 測定法.分光.赤外分光法
  meamdata = {...meamdata, ...makeData(selector_spy_inf_fti, 'spy_inf_fti')};
  if (selector_spy_inf_fti.inputImage) 
    imgData = divideImage(selector_spy_inf_fti,'spy_inf_fti', 'meam');
  if (typeof selector_spy_inf_fti.inputCSVFile === 'object') 
    csvData = divideCSV(selector_spy_inf_fti,'spy_inf_fti', 'meam');

  meamdata = {...meamdata, ...makeData(selector_spy_nmr, 'spy_nmr')};
  if (selector_spy_nmr.inputImage) 
    imgData = divideImage(selector_spy_nmr,'spy_nmr', 'meam');
  if (typeof selector_spy_nmr.inputCSVFile === 'object') 
    csvData = divideCSV(selector_spy_nmr,'spy_nmr', 'meam');

  meamdata = {...meamdata, ...makeData(selector_spy_uvi, 'spy_uvi')};
  if (selector_spy_uvi.inputImage) 
    imgData = divideImage(selector_spy_uvi,'spy_uvi', 'meam');
  if (typeof selector_spy_uvi.inputCSVFile === 'object') 
    csvData = divideCSV(selector_spy_uvi,'spy_uvi', 'meam');

  meamdata = {...meamdata, ...makeData(selector_spy_flu, 'spy_flu')};
  if (selector_spy_flu.inputImage) 
    imgData = divideImage(selector_spy_flu,'spy_flu', 'meam');
  if (typeof selector_spy_flu.inputCSVFile === 'object') 
    csvData = divideCSV(selector_spy_flu,'spy_flu', 'meam');

  meamdata = {...meamdata, ...makeData(selector_spy_ram, 'spy_ram')};
  if (selector_spy_ram.inputImage) 
    imgData = divideImage(selector_spy_ram,'spy_ram', 'meam');
  if (typeof selector_spy_ram.inputCSVFile === 'object') 
    csvData = divideCSV(selector_spy_ram,'spy_ram', 'meam');

  meamdata = {...meamdata, ...makeData(selector_spy_cd, 'spy_cd')};
  if (selector_spy_cd.inputImage) 
    imgData = divideImage(selector_spy_cd,'spy_cd', 'meam');
  if (typeof selector_spy_cd.inputCSVFile === 'object') 
    csvData = divideCSV(selector_spy_cd,'spy_cd', 'meam');

  meamdata = {...meamdata, ...makeData(selector_spy_ls, 'spy_ls')};
  if (selector_spy_ls.inputImage) 
    imgData = divideImage(selector_spy_ls,'spy_ls', 'meam');
  if (typeof selector_spy_ls.inputCSVFile === 'object') 
    csvData = divideCSV(selector_spy_ls,'spy_ls', 'meam');

  meamdata = {...meamdata, ...makeData(selector_spy_ell, 'spy_ell')};
  if (selector_spy_ell.inputImage) 
    imgData = divideImage(selector_spy_ell,'spy_ell', 'meam');
  if (typeof selector_spy_ell.inputCSVFile === 'object') 
    csvData = divideCSV(selector_spy_ell,'spy_ell', 'meam');

  meamdata = {...meamdata, ...makeData(selector_spy_nsc, 'spy_nsc')};
  if (selector_spy_nsc.inputImage) 
    imgData = divideImage(selector_spy_nsc,'spy_nsc', 'meam');
  if (typeof selector_spy_nsc.inputCSVFile === 'object') 
    csvData = divideCSV(selector_spy_nsc,'spy_nsc', 'meam');

  // 測定法.分光.X線
  meamdata = {...meamdata, ...makeData(selector_spy_xra_pho, 'spy_xra_pho')};
  if (selector_spy_xra_pho.inputImage) 
    imgData = divideImage(selector_spy_xra_pho,'spy_xra_pho', 'meam');
  if (typeof selector_spy_xra_pho.inputCSVFile === 'object') 
    csvData = divideCSV(selector_spy_xra_pho,'spy_xra_pho', 'meam');

  meamdata = {...meamdata, ...makeData(selector_spy_xra_dif, 'spy_xra_dif')};
  if (selector_spy_xra_dif.inputImage) 
    imgData = divideImage(selector_spy_xra_dif,'spy_xra_dif', 'meam');
  if (typeof selector_spy_xra_dif.inputCSVFile === 'object') 
    csvData = divideCSV(selector_spy_xra_dif,'spy_xra_dif', 'meam');

  meamdata = {...meamdata, ...makeData(selector_spy_xra_xs_sag, 'spy_xra_xs_sag')};
  if (selector_spy_xra_xs_sag.inputImage) 
    imgData = divideImage(selector_spy_xra_xs_sag,'spy_xra_xs_sag', 'meam');
  if (typeof selector_spy_xra_xs_sag.inputCSVFile === 'object') 
    csvData = divideCSV(selector_spy_xra_xs_sag,'spy_xra_xs_sag', 'meam');

  meamdata = {...meamdata, ...makeData(selector_spy_xra_xs_wid, 'spy_xra_xs_wid')};
  if (selector_spy_xra_xs_wid.inputImage) 
    imgData = divideImage(selector_spy_xra_xs_wid,'spy_xra_xs_wid', 'meam');
  if (typeof selector_spy_xra_xs_wid.inputCSVFile === 'object') 
    csvData = divideCSV(selector_spy_xra_xs_wid,'spy_xra_xs_wid', 'meam');

  // 測定法.クロマトグラフィー
  meamdata = {...meamdata, ...makeData(selector_chr_gas, 'chr_gas')};
  if (selector_chr_gas.inputImage) 
    imgData = divideImage(selector_chr_gas,'chr_gas', 'meam');
  if (typeof selector_chr_gas.inputCSVFile === 'object') 
    csvData = divideCSV(selector_chr_gas,'chr_gas', 'meam');

  meamdata = {...meamdata, ...makeData(selector_chr_hpl_gpc, 'chr_hpl_gpc')};
  if (selector_chr_hpl_gpc.inputImage) 
    imgData = divideImage(selector_chr_hpl_gpc,'chr_hpl_gpc', 'meam');
  if (typeof selector_chr_hpl_gpc.inputCSVFile === 'object') 
    csvData = divideCSV(selector_chr_hpl_gpc,'chr_hpl_gpc', 'meam');

  meamdata = {...meamdata, ...makeData(selector_chr_hpl_sec, 'chr_hpl_sec')};
  if (selector_chr_hpl_sec.inputImage) 
    imgData = divideImage(selector_chr_hpl_sec,'chr_hpl_sec', 'meam');
  if (typeof selector_chr_hpl_sec.inputCSVFile === 'object') 
    csvData = divideCSV(selector_chr_hpl_sec,'chr_hpl_sec', 'meam');

  // 測定法.顕微鏡法
  meamdata = {...meamdata, ...makeData(selector_mic_afm, 'mic_afm')};
  if (selector_mic_afm.inputImage) 
    imgData = divideImage(selector_mic_afm,'mic_afm', 'meam');
  if (typeof selector_mic_afm.inputCSVFile === 'object') 
    csvData = divideCSV(selector_mic_afm,'mic_afm', 'meam');

  meamdata = {...meamdata, ...makeData(selector_mic_sem, 'mic_sem')};
  if (selector_mic_sem.inputImage) 
    imgData = divideImage(selector_mic_sem,'mic_sem', 'meam');
  if (typeof selector_mic_sem.inputCSVFile === 'object') 
    csvData = divideCSV(selector_mic_sem,'mic_sem', 'meam');

  meamdata = {...meamdata, ...makeData(selector_mic_tem, 'mic_tem')};
  if (selector_mic_tem.inputImage) 
    imgData = divideImage(selector_mic_tem,'mic_tem', 'meam');
  if (typeof selector_mic_tem.inputCSVFile === 'object') 
    csvData = divideCSV(selector_mic_tem,'mic_tem', 'meam');

  meamdata = {...meamdata, ...makeData(selector_mic_opt, 'mic_opt')};
  if (selector_mic_opt.inputImage) 
    imgData = divideImage(selector_mic_opt,'mic_opt', 'meam');
  if (typeof selector_mic_opt.inputCSVFile === 'object') 
    csvData = divideCSV(selector_mic_opt,'mic_opt', 'meam');

  meamdata = {...meamdata, ...makeData(selector_mic_flm, 'mic_flm')};
  if (selector_mic_flm.inputImage) 
    imgData = divideImage(selector_mic_flm,'mic_flm', 'meam');
  if (typeof selector_mic_flm.inputCSVFile === 'object') 
    csvData = divideCSV(selector_mic_flm,'mic_flm', 'meam');

  meamdata = {...meamdata, ...makeData(selector_mic_cls, 'mic_cls')};
  if (selector_mic_cls.inputImage) 
    imgData = divideImage(selector_mic_cls,'mic_cls', 'meam');
  if (typeof selector_mic_cls.inputCSVFile === 'object') 
    csvData = divideCSV(selector_mic_cls,'mic_cls', 'meam');

  meamdata = {...meamdata, ...makeData(selector_mic_spr, 'mic_spr')};
  if (selector_mic_spr.inputImage) 
    imgData = divideImage(selector_mic_spr,'mic_spr', 'meam');
  if (typeof selector_mic_spr.inputCSVFile === 'object') 
    csvData = divideCSV(selector_mic_spr,'mic_spr', 'meam');

  meamdata = {...meamdata, ...makeData(selector_mic_qcm, 'mic_qcm')};
  if (selector_mic_qcm.inputImage) 
    imgData = divideImage(selector_mic_qcm,'mic_qcm', 'meam');
  if (typeof selector_mic_qcm.inputCSVFile === 'object') 
    csvData = divideCSV(selector_mic_qcm,'mic_qcm', 'meam');

  meamdata = {...meamdata, ...makeData(selector_mic_pol, 'mic_pol')};
  if (selector_mic_pol.inputImage) 
    imgData = divideImage(selector_mic_pol,'mic_pol', 'meam');
  if (typeof selector_mic_pol.inputCSVFile === 'object') 
    csvData = divideCSV(selector_mic_pol,'mic_pol', 'meam');

  // 測定法.熱分析
  meamdata = {...meamdata, ...makeData(selector_tha_tga, 'tha_tga')};
  if (selector_tha_tga.inputImage) 
    imgData = divideImage(selector_tha_tga,'tha_tga', 'meam');
  if (typeof selector_tha_tga.inputCSVFile === 'object') 
    csvData = divideCSV(selector_tha_tga,'tha_tga', 'meam');

  meamdata = {...meamdata, ...makeData(selector_tha_dta, 'tha_dta')};
  if (selector_tha_dta.inputImage) 
    imgData = divideImage(selector_tha_dta,'tha_dta', 'meam');
  if (typeof selector_tha_dta.inputCSVFile === 'object') 
    csvData = divideCSV(selector_tha_dta,'tha_dta', 'meam');

  meamdata = {...meamdata, ...makeData(selector_tha_dsc, 'tha_dsc')};
  if (selector_tha_dsc.inputImage) 
    imgData = divideImage(selector_tha_dsc,'tha_dsc', 'meam');
  if (typeof selector_tha_dsc.inputCSVFile === 'object') 
    csvData = divideCSV(selector_tha_dsc,'tha_dsc', 'meam');

  // // 測定法
  meamdata = {...meamdata, ...makeData(selector_ea, 'ea')};
  if (selector_ea.inputImage) 
    imgData = divideImage(selector_ea,'ea', 'meam');
  if (typeof selector_ea.inputCSVFile === 'object') 
    csvData = divideCSV(selector_ea,'ea', 'meam');

  meamdata = {...meamdata, ...makeData(selector_msm, 'msm')};
  if (selector_msm.inputImage) 
    imgData = divideImage(selector_msm, 'msm', 'meam');
  if (typeof selector_msm.inputCSVFile === 'object') 
    csvData = divideCSV(selector_msm, 'msm', 'meam');

  meamdata = {...meamdata, ...makeData(selector_gep, 'gep')};
  if (selector_gep.inputImage) 
    imgData = divideImage(selector_gep,'gep', 'meam');
  if (typeof selector_gep.inputCSVFile === 'object') 
    csvData = divideCSV(selector_gep,'gep', 'meam');

  meamdata = {...meamdata, ...makeData(selector_cona, 'cona')};
  if (selector_cona.inputImage) 
    imgData = divideImage(selector_cona,'cona', 'meam');
  if (typeof selector_cona.inputCSVFile === 'object') 
    csvData = divideCSV(selector_cona,'cona', 'meam');
  
  meamdata = {...meamdata, ...makeData(selector_sten, 'sten')};
  if (selector_sten.inputImage) 
    imgData = divideImage(selector_sten,'sten', 'meam');
  if (typeof selector_sten.inputCSVFile === 'object') 
    csvData = divideCSV(selector_sten,'sten', 'meam');

  meamdata = {...meamdata, ...makeData(selector_sfor, 'sfor')};
  if (selector_sfor.inputImage) 
    imgData = divideImage(selector_sfor,'sfor', 'meam');
  if (typeof selector_sfor.inputCSVFile === 'object') 
    csvData = divideCSV(selector_sfor,'sfor', 'meam');

  meamdata = {...meamdata, ...makeData(selector_bod, 'bod')};
  if (selector_bod.inputImage) 
    imgData = divideImage(selector_bod,'bod', 'meam');
  if (typeof selector_bod.inputCSVFile === 'object') 
    csvData = divideCSV(selector_bod,'bod', 'meam');

  // console.log(meamdata)

  // // 分解
  let degrdata = {};

  //分解.化学分解
  degrdata = {...degrdata, ...makeData(selector_chd, 'chd')};
  if (selector_chd.inputImage) 
    imgData = divideImage(selector_chd, 'chd', 'degr');
  if (typeof selector_chd.inputCSVFile === 'object') 
    csvData = divideCSV(selector_chd,'chd', 'degr');

  // 分解.生分解.植種情報
  degrdata = {...degrdata, ...makeData(selector_biod_pls_loc, 'biod_pls_loc')};
  if (selector_biod_pls_loc.inputImage) 
    imgData = divideImage(selector_biod_pls_loc,'biod_pls_loc', 'degr');
  if (typeof selector_biod_pls_loc.inputCSVFile === 'object') 
    csvData = divideCSV(selector_biod_pls_loc,'biod_pls_loc', 'degr');

  degrdata = {...degrdata, ...makeData(selector_biod_pls_bdate, 'biod_pls_bdate')};
  if (selector_biod_pls_bdate.inputImage) 
    imgData = divideImage(selector_biod_pls_bdate,'biod_pls_bdate', 'degr');
  if (typeof selector_biod_pls_bdate.inputCSVFile === 'object') 
    csvData = divideCSV(selector_biod_pls_bdate,'biod_pls_bdate', 'degr');

  degrdata = {...degrdata, ...makeData(selector_biod_pls_btime, 'biod_pls_btime')};
  if (selector_biod_pls_btime.inputImage) 
    imgData = divideImage(selector_biod_pls_btime,'biod_pls_btime', 'degr');
  if (typeof selector_biod_pls_btime.inputCSVFile === 'object') 
    csvData = divideCSV(selector_biod_pls_btime,'biod_pls_btime', 'degr');

  degrdata = {...degrdata, ...makeData(selector_biod_pls_toc, 'biod_pls_toc')};
  if (selector_biod_pls_toc.inputImage) 
    imgData = divideImage(selector_biod_pls_toc,'biod_pls_toc', 'degr');
  if (typeof selector_biod_pls_toc.inputCSVFile === 'object') 
    csvData = divideCSV(selector_biod_pls_toc,'biod_pls_toc', 'degr');

  degrdata = {...degrdata, ...makeData(selector_biod_pls_nita, 'biod_pls_nita')};
  if (selector_biod_pls_nita.inputImage) 
    imgData = divideImage(selector_biod_pls_nita,'biod_pls_nita', 'degr');
  if (typeof selector_biod_pls_nita.inputCSVFile === 'object') 
    csvData = divideCSV(selector_biod_pls_nita,'biod_pls_nita', 'degr');

  degrdata = {...degrdata, ...makeData(selector_biod_pls_bmass, 'biod_pls_bmass')};
  if (selector_biod_pls_bmass.inputImage) 
    imgData = divideImage(selector_biod_pls_bmass,'biod_pls_bmass', 'degr');
  if (typeof selector_biod_pls_bmass.inputCSVFile === 'object') 
    csvData = divideCSV(selector_biod_pls_bmass,'biod_pls_bmass', 'degr');

  // 分解.生分解.植種情報.分解データ
  degrdata = {...degrdata, ...makeData(selector_biod_pls_dd_rc_ph, 'biod_pls_dd_rc_ph')};
  if (selector_biod_pls_dd_rc_ph.inputImage) 
    imgData = divideImage(selector_biod_pls_dd_rc_ph,'biod_pls_dd_rc_ph', 'degr');
  if (typeof selector_biod_pls_dd_rc_ph.inputCSVFile === 'object') 
    csvData = divideCSV(selector_biod_pls_dd_rc_ph,'biod_pls_dd_rc_ph', 'degr');

  degrdata = {...degrdata, ...makeData(selector_biod_pls_dd_rc_btemp, 'biod_pls_dd_rc_btemp')};
  if (selector_biod_pls_dd_rc_btemp.inputImage) 
    imgData = divideImage(selector_biod_pls_dd_rc_btemp,'biod_pls_dd_rc_btemp', 'degr');
  if (typeof selector_biod_pls_dd_rc_btemp.inputCSVFile === 'object') 
    csvData = divideCSV(selector_biod_pls_dd_rc_btemp,'biod_pls_dd_rc_btemp', 'degr');

  degrdata = {...degrdata, ...makeData(selector_biod_pls_dd_rc_both, 'biod_pls_dd_rc_both')};
  if (selector_biod_pls_dd_rc_both.inputImage) 
    imgData = divideImage(selector_biod_pls_dd_rc_both,'biod_pls_dd_rc_both', 'degr');
  if (typeof selector_biod_pls_dd_rc_both.inputCSVFile === 'object') 
    csvData = divideCSV(selector_biod_pls_dd_rc_both,'biod_pls_dd_rc_both', 'degr');

  degrdata = {...degrdata, ...makeData(selector_biod_pls_dd_brlt_brt, 'biod_pls_dd_brlt_brt')};
  if (selector_biod_pls_dd_brlt_brt.inputImage) 
    imgData = divideImage(selector_biod_pls_dd_brlt_brt,'biod_pls_dd_brlt_brt', 'degr');
  if (typeof selector_biod_pls_dd_brlt_brt.inputCSVFile === 'object') 
    csvData = divideCSV(selector_biod_pls_dd_brlt_brt,'biod_pls_dd_brlt_brt', 'degr');

  degrdata = {...degrdata, ...makeData(selector_biod_pls_dd_brlt_dr, 'biod_pls_dd_brlt_dr')};
  if (selector_biod_pls_dd_brlt_dr.inputImage) 
    imgData = divideImage(selector_biod_pls_dd_brlt_dr,'biod_pls_dd_brlt_dr', 'degr');
  if (typeof selector_biod_pls_dd_brlt_dr.inputCSVFile === 'object') 
    csvData = divideCSV(selector_biod_pls_dd_brlt_dr,'biod_pls_dd_brlt_dr', 'degr');

  degrdata = {...degrdata, ...makeData(selector_biod_pls_dd_brlt_dp, 'biod_pls_dd_brlt_dp')};
  if (selector_biod_pls_dd_brlt_dp.inputImage) 
    imgData = divideImage(selector_biod_pls_dd_brlt_dp,'biod_pls_dd_brlt_dp', 'degr');
  if (typeof selector_biod_pls_dd_brlt_dp.inputCSVFile === 'object') 
    csvData = divideCSV(selector_biod_pls_dd_brlt_dp,'biod_pls_dd_brlt_dp', 'degr');

  degrdata = {...degrdata, ...makeData(selector_biod_pls_dd_brlt_dbod, 'biod_pls_dd_brlt_dbod')};
  if (selector_biod_pls_dd_brlt_dbod.inputImage) 
    imgData = divideImage(selector_biod_pls_dd_brlt_dbod,'biod_pls_dd_brlt_dbod', 'degr');
  if (typeof selector_biod_pls_dd_brlt_dbod.inputCSVFile === 'object') 
    csvData = divideCSV(selector_biod_pls_dd_brlt_dbod,'biod_pls_dd_brlt_dbod', 'degr');

    

    


    // syndata = JSON.stringify(syndata);
    // console.log(selector_docid);
    // console.log(ppdata);
    // console.log(syndata)

    // console.log(imgData);
    // console.log(csvData);

    var form_data = new FormData();
    // form_data.append("document_id", "");
    // form_data.append("ov", json_str);
    // form_data.append("ov", JSON.stringify(ovdata));
    form_data.append('ov', JSON.stringify(ovdata) );
    form_data.append('phpr', JSON.stringify(ppdata) );
    form_data.append('syn', JSON.stringify(syndata) );
    form_data.append('cal', JSON.stringify(caldata) );
    form_data.append('meam', JSON.stringify(meamdata) );
    form_data.append('degr', JSON.stringify(degrdata) );

  
    let getCsrf = '';

    let allcookies = document.cookie.split('; ');
    // console.log(allcookies)
    allcookies.forEach(c => {
      let r = c.split('=');
      // console.log(r)
      if(r[0] == 'csrftoken'){
          getCsrf = r[1];
          return getCsrf
      }
    });

 


    if (selector_docid) {
      fetch(`http://localhost:8000/input/update/` + selector_docid ,
      {
        method: 'POST',
        headers: {
          "X-CSRFToken": getCsrf,
          "Authorization": 'Basic dGVzdDAxQGV4YW1wbGUuY29tOnQyMjIyMg=='},
          body: form_data,
          redirect: 'follow'
      })
      .then( response => {
        // if(response.ok) {
          // console.log("response:", response);
          // console.log("response.text:", response.text());
          return response.json();
        // }


        // throw new Error(`Network response was not ok.`);
      }).then(responseData => {

        // console.log("responseData:" + JSON.stringify(responseData));

        if(responseData.resultCode === '000'){
          //success
          alert(`docid:${selector_docid}を更新しました。`);

          //TODO: イメージファイルをアップロードする
          
          fileUpload(selector_docid, 'IMAGE',imgData)
          fileUpload(selector_docid, 'CSV',csvData)
          // aa.then(response => console.log(response))



        } else {
          console.log(responseData);
          alert(`保存処理に失敗しました。\n${responseData.data}`);
          // return 1;
        }
      })
      .catch( error => {
        // console.error(error);
        // alert('ログインへ失敗しました。');
        // return 2;
      });
    } else {
      fetch(`http://localhost:8000/input/new/`,
      {
        method: 'POST',
        headers: {
          "X-CSRFToken": getCsrf,
          "Authorization": 'Basic dGVzdDAxQGV4YW1wbGUuY29tOnQyMjIyMg=='},
          body: form_data,
          redirect: 'follow'
      })
      .then( response => {
        // if(response.ok) {
          // console.log("response:", response);
          // console.log("response.text:", response.text());
          return response.json();
        // }


        // throw new Error(`Network response was not ok.`);
      }).then(responseData => {

        // console.log("responseData:" + JSON.stringify(responseData));

        if(responseData.resultCode === '000'){
          //success
          alert(`保存処理に成功しました。\ndocid:${responseData.document_id}`);

          //TODO: イメージファイルをアップロードする
          
          fileUpload(responseData.document_id, 'IMAGE',imgData);
          fileUpload(responseData.document_id, 'CSV',csvData);
          // aa.then(response => console.log(response))



        } else {
          alert(`保存処理に失敗しました。\n${responseData.resultMessage}`);
          // return 1;
        }
      })
      .catch( error => {
        // console.error(error);
        // alert('ログインへ失敗しました。');
        // return 2;
      });
      
    }
  }



  const showTreeDialog = () => {
    props.updateOpenTreeDialog(true);
  }

  const handleSelectedCategory = (event) => {

    //when changed, move to root category

    // if (selectedCategory !== event.target.value){


      switch(event.target.value){
        // // case "--選択--":
        // case CATEGORY_LABEL.CHOICE:
        //   history.push("/");
        //   props.updateCategory(event.target.value);
        //   setSelectedCategory(event.target.value);
        //   break;

        
        // case "概要":
        case CATEGORY_LABEL.OVERVIEW:
          history.push("/overview");
          props.updateCategory(event.target.value);
          setSelectedCategory(event.target.value);
          break;

        // case "高分子物性":
        case CATEGORY_LABEL.PROPERTY:
          history.push("/property");
          props.updateCategory(event.target.value);
          setSelectedCategory(event.target.value);
          break;

        // case "高分子生成":
        case CATEGORY_LABEL.SYNTHESIS:
          history.push("/synthesis");
          props.updateCategory(event.target.value);
          setSelectedCategory(event.target.value);
          break;
        
        // 20220829 望月
        // case "計算":
        case CATEGORY_LABEL.CALCULATION:
          history.push("/calculation");
          props.updateCategory(event.target.value);
          setSelectedCategory(event.target.value);
          break;

        // 20220831 望月
        // case "測定法":
        case CATEGORY_LABEL.MEASUREMENT:
          history.push("/measurement");
          props.updateCategory(event.target.value);
          setSelectedCategory(event.target.value);
          break;

        // 20220824 望月
        // case "分解":
        case CATEGORY_LABEL.DEGRADATION:
          history.push("/degradation");
          props.updateCategory(event.target.value);
          setSelectedCategory(event.target.value);
          break;

        default:
          alert("準備中です");
          break;
      }
    // }

  }


  useEffect(() => {
      // console.log("selector_category - " + selector_category);

    if ( selector_category !== ''){
      setSelectedCategory(selector_category);
      // localStorage.setItem(CATEGORY_SETTING, selector_category);
    }
  }, [selector_category]);



  


  useEffect(() => {
    //when screen refresh

    // console.log("** when refresh screen - " + selector_category);
    
    // if (selector_category === ''){
    //   let val = localStorage.getItem(CATEGORY_SETTING);
    //   // console.log("setting " + val);
    //   setSelectedCategory(val);
    //   props.updateCategory(val);
    // }
    

    props.uploadLoading(true)
    // console.log(aa)
    
  },
  []);


  return (
    <>
    <TreeDialog  />

    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>

        <Grid container item
          wrap='nowrap'
          direction="row" justifyContent="flex-start" alignItems="center"
          spacing={2} >

          <Grid item lg={2} md={3} sm={3}>
            <FormControl sx={{ m: 1 }} variant="standard" fullWidth>

              <InputLabel variant="outlined" id="demo-simple-select-label-2">カテゴリー</InputLabel>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedCategory}
                label="Kind"
                onChange={handleSelectedCategory}
                variant="outlined"
                MenuProps={{ style: { width: 200 } }}
                fullWidth
              >

                <MenuItem value={'概要'}>{CATEGORY_LABEL.OVERVIEW}</MenuItem>
                <MenuItem value={'高分子物性'}>{CATEGORY_LABEL.PROPERTY}</MenuItem>
                <MenuItem value={'高分子生成'}>{CATEGORY_LABEL.SYNTHESIS}</MenuItem>
                <MenuItem value={'計算'}>{CATEGORY_LABEL.CALCULATION}</MenuItem>
                <MenuItem value={'測定法'}>{CATEGORY_LABEL.MEASUREMENT}</MenuItem>
                <MenuItem value={'分解'}>{CATEGORY_LABEL.DEGRADATION}</MenuItem>

              </Select>
            </FormControl>
          </Grid>


          <Grid item lg={4} md={1} sm={1} zeroMinWidth={true}></Grid>

          <Grid item lg={2} md={2} sm={3} >
          {/* 
            <Button
              color="primary"
              variant="outlined"
              fullWidth
              size="large"
              onClick={showTreeDialog}
            >
              ツリー検索
            </Button>
            */}
          </Grid>



          <Grid item lg={2} md={2} sm={3}>
  {/* 
            <TextField
              label="入力項目検索"
              name="searchInput"
              fullWidth
              type="text"
              // value={inputUnit || ''}
              variant="outlined"
              // onChange={(e) => setInputUnit(e.target.value)}
            />
            */}
          </Grid> 



          <Grid item lg={1} md={2} sm={2}>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              size="large"
              // onClick={handleSubmit}
              className={classes.ok_btn}
              onClick={()=> {doPostNewData()}}
            >
              保存
            </Button>
          </Grid>


          <Grid item lg={1} md={2} sm={2}>
            <Button
              color="secondary"
              variant="outlined"
              fullWidth
              size="large"
              className={classes.cancel_btn}
              // onClick={handleSubmit}
              onClick={() => window.location.replace("http://localhost:8000/index/")}
            >
              トップ
            </Button>
          </Grid>


        </Grid>

{/*
         <Hidden mdDown>

          <IconButton
            className={classes.signOutButton}
            color="inherit"
            // onClick={handleLogout}
          >
            <InputIcon />
          </IconButton>
        </Hidden>


        <Hidden mdDown>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            melong
            <MenuIcon />
          </IconButton>
        </Hidden> */}
      </Toolbar>
    </AppBar>
    </>
  );
};

Topbar.propTypes = {
  // history: PropTypes.object,
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};


const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    doLogout,
    updateCategory,
    updateOpenTreeDialog,
    uploadLoading,

   

   }, dispatch)
}

let mapStateToProps = (state) => {
  return {
    user: state.user,
    settings: state.settings,
    overviewArray: state.overviewArray,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Topbar);


const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: theme.palette.white,
    padding: 10
    // height:100,
    // color: theme.palette.black,
    // color: "#FFFFFF",
    // backgroundColor: "#2a3f54",
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  ok_btn: {
    // minWidth: '110px',
  },
  cancel_btn: {
    // minWidth: '110px',
    // fontSize:12
  }
}));

