export const INPUT_TYPE = {
  CHOICE: '選択',
  TEXT : 'テキスト',
  NUMBER: '数値',
  PICTURE: '画像',
  CSV: 'CSV'
}


export const CATEGORY_LABEL = {

  // CHOICE: '選択',
  OVERVIEW : '概要',
  PROPERTY : '高分子物性',
  SYNTHESIS : '高分子生成',
  CALCULATION: '計算',
  DEGRADATION: '分解',
  MEASUREMENT: '測定法'

}

//localstorage for topbar category menu
export const CATEGORY_SETTING = 'category_setting';



//概要
export const DOCID = 'docid';
export const OVID = 'ovid';
export const SAMPLE = 'sample';
export const SAMPLEID = 'sampleID';
export const GROUP = 'group';
export const INST = 'inst';
export const KIND = 'kind';
export const CHST = 'chst';
export const EXPP = 'expp';
export const RESPP = 'respp';
export const EXPDATE = 'expdate';
export const INPP = 'inpp';
export const BMEMO = 'bmemo';



//熱力学量
export const PP_TD_DEN = 'pp_td_den';
export const PP_TD_SV = 'pp_td_sv';
export const PP_TD_AR = 'pp_td_ar';
export const PP_TD_ST = 'pp_td_st';
export const PP_TD_TROP = 'pp_td_trop';
export const PP_TD_ENTHAL = 'pp_td_enthal';
export const PP_TD_CR = 'pp_td_cr';
export const PP_TD_TE = 'pp_td_te';

//熱力学量.気体透過性

export const PP_TD_GP_WP = 'pp_td_gp_wp';
export const PP_TD_GP_MP = 'pp_td_gp_mp';
export const PP_TD_GP_FTD = 'pp_td_gp_ftd';
export const PP_TD_GP_TPD = 'pp_td_gp_tpd'; //酸素指数
export const PP_TD_GP_HD = 'pp_td_gp_hd';//耐熱性


//熱力学量.流動特性
export const PP_TD_FC_MV_SD = 'pp_td_fc_mv_sd';
export const PP_TD_FC_MV_MTD = 'pp_td_fc_mv_mtd';
export const PP_TD_FC_MV_PD = 'pp_td_fc_mv_pd';
export const PP_TD_FC_VC = 'pp_td_fc_vc';


//熱力学量.比熱
export const PP_TD_SP_SPC = 'pp_td_sp_spc';
export const PP_TD_SP_STD = 'pp_td_sp_std';

//熱力学量.熱伝導率
export const PP_TD_TC_TTD = 'pp_td_tc_ttd';
export const PP_TD_TC_DSD = 'pp_td_tc_dsd';
export const PP_TD_TC_CPD = 'pp_td_tc_cpd';


//熱力学量.熱拡散係数
export const PP_TD_TDIF_CMM = 'pp_td_tdif_cmm';
export const PP_TD_TDIF_AMO = 'pp_td_tdif_amo';


//物性.熱力学量.各種温度
export const PP_TD_TEMP_TG_GPD = 'pp_td_temp_Tg_gpd';
export const PP_TD_TEMP_MLP_MPD = 'pp_td_temp_mlp_mpd';
export const PP_TD_TEMP_DSC = 'pp_td_temp_DSC';
export const PP_TD_TEMP_TGA = 'pp_td_temp_TGA';
export const PP_TD_TEMP_WR5 = 'pp_td_temp_wr5';
export const PP_TD_TEMP_EMB = 'pp_td_temp_emb';
export const PP_TD_TEMP_HDT = 'pp_td_temp_HDT';


//物性.熱力学量.燃焼特性
export const PP_TD_BCHAR_FIT = 'pp_td_bchar_FIT'; //引火温度
export const PP_TD_BCHAR_HDC = 'pp_td_bchar_hdc'; //熱分解曲線
export const PP_TD_BCHAR_CH = 'pp_td_bchar_ch'; //燃焼熱
export const PP_TD_BCHAR_OI = 'pp_td_bchar_oi'; //酸素指数
export const PP_TD_BCHAR_HRP = 'pp_td_bchar_hrp';//耐熱性


//物性.機械強度特性.応力歪曲線.引張
export const PP_MS_SSC_TEN_DEGSTRETCH = 'PP_MS_SSC_TEN_DEGSTRETCH';
export const PP_MS_SSC_TEN_DEGELA = 'PP_MS_SSC_TEN_DEGELA';
export const PP_MS_SSC_TEN_RUPSTR = 'PP_MS_SSC_TEN_RUPSTR';
export const PP_MS_SSC_TEN_STTD = 'PP_MS_SSC_TEN_STTD';
export const PP_MS_SSC_TEN_STPD = 'PP_MS_SSC_TEN_STPD';
export const PP_MS_SSC_TEN_BAIMAGE = 'PP_MS_SSC_TEN_BAIMAGE';


//物性.機械強度特性.応力歪曲線.曲げ試験
export const PP_MS_SSC_BEND_BDSTRETCH = 'PP_MS_SSC_BEND_BDSTRETCH';
export const PP_MS_SSC_BEND_BDELA = 'PP_MS_SSC_BEND_BDELA';
export const PP_MS_SSC_BEND_BRSTR = 'PP_MS_SSC_BEND_BRSTR';
export const PP_MS_SSC_BEND_BTD = 'PP_MS_SSC_BEND_BTD';
export const PP_MS_SSC_BEND_BPD = 'PP_MS_SSC_BEND_BPD';
export const PP_MS_SSC_BEND_BBAIMAGE = 'PP_MS_SSC_BEND_BBAIMAGE';


//物性.機械強度特性.粘弾性測定
export const PP_MS_VEM_VTD = 'PP_MS_VEM_VTD'; //粘弾性測定.温度依存性
export const PP_MS_VEM_VPD = 'PP_MS_VEM_VPD'; //粘弾性測定.圧⼒依存性
export const PP_MS_VEM_GDASH = 'PP_MS_VEM_GDASH'; //粘弾性測定.G'
export const PP_MS_VEM_GDASH2 = 'PP_MS_VEM_GDASH2'; //粘弾性測定.G''
export const PP_MS_VEM_LTAN = 'PP_MS_VEM_LTAN'; //粘弾性測定.損失正接


//物性.機械強度特性.衝撃試験
export const PP_MS_SHT_CHAR_CTD = 'PP_MS_SHT_CHAR_CTD'; //衝撃試験.Charpy.温度依存性
export const PP_MS_SHT_IZOD_ITD = 'PP_MS_SHT_IZOD_ITD'; //衝撃試験.Izod.温度依存性


//物性.機械強度特性
export const PP_MS_CRT = 'PP_MS_CRT'; //クリープ試験
export const PP_MS_STRREL = 'PP_MS_STRREL'; //sFkitress relaxation
export const PP_MS_CRACK = 'PP_MS_CRACK'; //クラック
export const PP_MS_FRAT = 'PP_MS_FRAT'; //fracture toughness
export const PP_MS_FATP = 'PP_MS_FATP'; //fatigue properties
export const PP_MS_HARD = 'PP_MS_HARD'; //硬さ


//物性.摩擦係数
export const FRICTION_COEFFICIENT = 'friction_coefficient'; //摩擦係数

//物性.酵素分解
export const ENZYMATIC_DECOMPOSITION_RATE = 'enzymatic_decomposition_rate'; //酵素分解.分解率
export const ENZYMATIC_ENZYMATIC_REACTION = 'enzymatic_enzymatic_reaction'; //酵素分解.酵素反応データ

//物性.生分解度.生分解度グラフ.
export const BIODEGRADABILITY_CURVE = 'biodegradability_curve'; //物性.生分解度.生分解度グラフ.曲線データ

//物性.崩壊度
export const BAR_GRAPH = 'bar_graph'; //物性.崩壊度.重量変化グラフ.棒グラフデータ


//物性.構造
export const STRUCTURE_XRAY_GRAPH = 'structure_xray_graph'; //構造.X線.グラフ
export const STRUCTURE_MICROSCOPE_IMAGE = 'structure_microscope_image'; //構造.顕微鏡法.画像
export const STRUCTURE_CRYSTALLINITY_DEGREE = 'structure_crystallinity_degree'; //構造.結晶化度



//作り方=============================


//作り方.購入
export const BUY_MAKER = 'buy_maker';
export const BUY_LOT_NUMBER = 'buy_lot_number';


//作り方.製造プロセス
export const BUY_PMAKER = 'buy_pmaker'; //元の⾼分⼦.購⼊.メーカー
export const BUY_PLOT_NUMBER = 'buy_plot_number'; //元の⾼分⼦.購⼊.ロット番号
export const PROCESS_SYNTHESIS = 'process_synthesis'; //元の⾼分⼦.合成
export const PROCESS_MACHINE = 'process_machine'; //製造プロセス.機械名
export const PROCESS_THEOTHER = 'process_theother'; //製造プロセス.その他



//作り方.酵素名
export const HOM_ENZYME = 'hom_enzyme';

//作り方.菌体名
export const HOM_FUNGUS = 'hom_fungus'



// 作り方.重合名
//重合名.連鎖重合.付加重合.ラジカル重合.リビングラジカル重合
export const HOM_PRIZE_CHAIN_ADD_RAD_LRP = 'hom_prize_chain_add_rad_LRP';

//重合名.連鎖重合.付加重合.カチオン重合.リビングカチオン重合
export const HOM_PRIZE_CHAIN_ADD_CATI_LCP = 'hom_prize_chain_add_cati_LCP';

//重合名.連鎖重合.付加重合.アニオン重合.リビングアニオン重合
export const HOM_PRIZE_CHAIN_ADD_ANIO_LAP = 'hom_prize_chain_add_anio_LAP';

//重合名.連鎖重合.付加重合.配位重合
export const HOM_PRIZE_CHAIN_ADD_CRD = 'hom_prize_chain_add_crd';

//重合名.連鎖重合.開環重合.カチオン開環重合
export const HOM_PRIZE_CHAIN_ROP_CROP = 'hom_prize_chain_rop_CROP';

//重合名.連鎖重合.開環重合.アニオン開環重合
export const HOM_PRIZE_CHAIN_ROP_AROP = 'hom_prize_chain_rop_AROP';

//重合名.連鎖重合.開環重合.ラジカル開環重合
export const HOM_PRIZE_CHAIN_ROP_RROP = 'hom_prize_chain_rop_RROP';

//重合名.連鎖重合.開環重合.遷移金属触媒を用いた開環重合.開環メタセシス重合
export const HOM_PRIZE_CHAIN_ROP_TMCR_ROMP = 'hom_prize_chain_rop_TMCR_ROMP';

//重合名.連鎖重合.連鎖縮合重合
export const HOM_PRIZE_CHAIN_CGCP = 'hom_prize_chain_CGCP';




//作り方.重合名.逐次重合.重縮合.求核アシル置換重合
export const HOM_PRIZE_SGP_PCON_NASP = 'hom_prize_SGP_pcon_NASP';

//作り方.重合名.逐次重合.重縮合.脂肪族求核置換重合
export const HOM_PRIZE_SGP_PCON_ANSP = 'hom_prize_SGP_pcon_ANSP';

//作り方.重合名.逐次重合.重縮合.芳香族求核置換重合
export const HOM_PRIZE_SGP_PCON_ARNSP = 'hom_prize_SGP_pcon_ARNSP';

//作り方.重合名.逐次重合.重縮合.芳香族求電子置換重合
export const HOM_PRIZE_SGP_PCON_ARESP = 'hom_prize_SGP_pcon_ARESP';

//作り方.重合名.逐次重合.重縮合.酸化カップリング重合
export const HOM_PRIZE_SGP_PCON_OCP = 'hom_prize_SGP_pcon_OCP';

//作り方.重合名.逐次重合.重縮合.遷移金属触媒重合
export const HOM_PRIZE_SGP_PCON_TMCP = 'hom_prize_SGP_pcon_TMCP';

//作り方.重合名.逐次重合.重縮合.電解重合
export const HOM_PRIZE_SGP_PCON_EP = 'hom_prize_SGP_pcon_EP';

//作り方.重合名.逐次重合.重付加.二重結合への重付加
export const HOM_PRIZE_SGP_PADD_PTDB = 'hom_prize_SGP_padd_PTDB';

//作り方.重合名.逐次重合.重付加.開環重付加
export const HOM_PRIZE_SGP_PADD_ROPA = 'hom_prize_SGP_padd_ROPA';

//作り方.重合名.逐次重合.重付加.環化重付加
export const HOM_PRIZE_SGP_PADD_CYPA = 'hom_prize_SGP_padd_CYPA';

//作り方.重合名.逐次重合.重付加.その他
export const HOM_PRIZE_SGP_PADD_OTPA = 'hom_prize_SGP_padd_OTPA';

//作り方.重合名.逐次重合.付加縮合
export const HOM_PRIZE_SGP_ADCO = 'hom_prize_SGP_adco';



//反応条件.pH
export const RCON_PH = 'rcon_pH';

//反応条件.温度
export const RCON_RTEMP = 'rcon_rtemp';

//反応条件.その他
export const RCON_OTRC = 'rcon_OTRC';



//結果.反応時間
export const RSLT_RT = 'rslt_RT';

//結果.モノマー消費率
export const RSLT_MCR = 'rslt_MCR';


//結果.反応速度
export const RSLT_RRATE = 'rslt_rrate';

//結果.収量
export const RSLT_CY = 'rslt_CY';

//結果.生産速度.菌体濁度
export const RSLT_PRATE_TUR = 'rslt_prate_tur';

//結果.生産速度.炭素原濃度
export const RSLT_PRATE_OCC = 'rslt_prate_occ';

//結果.生産速度.生産データ
export const RSLT_PRATE_PDATA = 'rslt_prate_pdata';

// バイオモノマー生成.反応時間
export const BMSYN_REACT = 'bmsyn_react';
// バイオモノマー生成.反応速度
export const BMSYN_REACS = 'bmsyn_reacs';
// バイオモノマー生成.収量
export const BMSYN_YIELD = 'bmsyn_yield';

// バイオモノマー生成.生産速度.菌体濁度
export const BMSYN_PROS_FGTB = 'bmsyn_pros_fgtb';
// バイオモノマー生成.生産速度.炭素源濃度
export const BMSYN_PROS_CSD = 'bmsyn_pros_csd';
// バイオモノマー生成.生産速度.糖消費
export const BMSYN_PROS_SCON = 'bmsyn_pros_scon';
// バイオモノマー生成.生産速度.生産データ
export const BMSYN_PROS_PDT = 'bmsyn_pros_pdt';


// 計算　望月
// 分子動力学計算.プログラム
export const MD_MDP = 'MD_MDp'
// 分子動力学計算.計算条件.アンサンブル
export const MD_COND_ENS = 'MD_cond_ens'
// 分子動力学計算.計算条件.力場.表
export const MD_COND_FRC_FTBL = 'MD_cond_frc_ftbl'
// 分子動力学計算.計算条件.初期座標.画像
export const MD_COND_ICRD_ICIMAGE = 'MD_cond_icrd_icimage'
// 分子動力学計算.計算条件.初期座標.PDB
export const MD_COND_ICRD_ICPDB = 'MD_cond_icrd_icPDB'
// 分子動力学計算.計算条件.初期座標.DCD
export const MD_COND_ICRD_ICDCD = 'MD_cond_icrd_icDCD'
// 分子動力学計算.計算条件.初期座標.PSF
export const MD_COND_ICRD_ICPSF = 'MD_cond_icrd_icPSF'
// 分子動力学計算.計算条件.その他計算条件
export const MD_COND_OTCOND = 'MD_cond_otcond'
// 分子動力学計算.計算結果.熱力学データ.温度vs時間
export const MD_CRSLT_THDYD_TVST = 'MD_crslt_Thdyd_TvsT'
// 分子動力学計算.計算結果.熱力学データ.圧力vs時間
export const MD_CRSLT_THDYD_PVST = 'MD_crslt_Thdyd_PvsT'
// 分子動力学計算.計算結果.熱力学データ.エネルギーvs時間
export const MD_CRSLT_THDYD_EVST = 'MD_crslt_Thdyd_EvsT'
// 分子動力学計算.計算結果.力学データ
export const MD_CRSLT_DYD = 'MD_crslt_Dyd'
// 量子化学計算.プログラム
export const QC_QCP = 'QC_QCp'
// 量子化学計算.基底関数
export const QC_QCBC = 'QC_QCbc'
// 量子化学計算.近似法
export const QC_QCAP = 'QC_QCap'


// 測定法　望月
// 分光.赤外分光法.フーリエ変換赤外分光法
export const SPY_INF_FTI = "spy_inf_fti"
//分光.核磁気共鳴分光法
export const SPY_NMR = "spy_nmr"
// 分光.紫外・可視分光法
export const SPY_UVI = "spy_uvi"
// 分光.蛍光分光法
export const SPY_FLU = "spy_flu"
// 分光.ラマン分光法
export const SPY_RAM = "spy_ram"
// 分光.円二色性
export const SPY_CD = "spy_cd"
// 分光.光散乱法
export const SPY_LS = "spy_ls"
// 分光.分光エリプソメトリー
export const SPY_ELL = "spy_ell"
// 分光.中性子散乱法
export const SPY_NSC = "spy_nsc"
//===== 分光.X線.X線光電子分光法
export const SPY_XRA_PHO = "spy_xra_pho"
// 分光.X線.X線回折法
export const SPY_XRA_DIF = "spy_xra_dif"
//===== 分光.X線.X線散乱法.小角
export const SPY_XRA_XS_SAG  = "spy_xra_xs_sag"
// 分光.X線.X線散乱法.広角
export const SPY_XRA_XS_WID = "spy_xra_xs_wid"
// クロマトグラフィー.ガスクロマトグラフィー
export const CHR_GAS = "chr_gas"
// クロマトグラフィー.高速液体クロマトグラフィー.ゲル濾過クロマトグラフィー
export const CHR_HPL_GPC = "chr_hpl_gpc"
// クロマトグラフィー.高速液体クロマトグラフィー.サイズ排除クロマトグラフィー
export const CHR_HPL_SEC = "chr_hpl_sec"
// 顕微鏡法.原子間力顕微鏡法
export const MIC_AFM = "mic_afm"
// 顕微鏡法.走査型電子顕微鏡法
export const MIC_SEM = "mic_sem"
// 顕微鏡法.透過型電子顕微鏡法
export const MIC_TEM = "mic_tem"
// 顕微鏡法.光学顕微鏡法
export const MIC_OPT = "mic_opt"
// 顕微鏡法.蛍光顕微鏡法
export const MIC_FLM = "mic_flm"
// 顕微鏡法.共焦点レーザー顕微鏡法
export const MIC_CLS = "mic_cls"
// 顕微鏡法.表面プラズモン共鳴法
export const MIC_SPR = "mic_spr"
// 顕微鏡法.水晶振動子マイクロバランス法
export const MIC_QCM = "mic_qcm"
// 顕微鏡法.偏光顕微鏡
export const MIC_POL = "mic_pol"
// 熱分析.熱重量分析
export const THA_TGA = "tha_tga"
// 熱分析.示差熱分析
export const THA_DTA = "tha_dta"
// 熱分析.示差走査熱量測定
export const THA_DSC = "tha_dsc"
// 元素分析
export const EA = "ea"
// 質量分析法
export const MSM = "msm"
// ゲル電気泳動
export const GEP = "gep"
// 接触角測定
export const CONA = "cona"
// 表面張力測定
export const STEN = "sten"
//表面力測定
export const SFOR = "sfor"
// 生物化学的酸素要求量測定
export const BOD = "bod"



//20220822 望月

// 化学分解
export const CHD = 'chd'
// 生分解.植種情報.場所
export const BIOD_PLS_LOC = 'biod_pls_loc'
// 生分解.植種情報.日時
export const BIOD_PLS_BDATE = 'biod_pls_bdate'
// 生分解.植種情報.時刻
export const BIOD_PLS_BTIME = 'biod_pls_btime'
// 生分解.植種情報.全有機炭素(TOC)
export const BIOD_PLS_TOC = 'biod_pls_toc'
// 生分解.植種情報.窒素量
export const BIOD_PLS_NITA = 'biod_pls_nita'
// 生分解.植種情報.微生物量
export const BIOD_PLS_BMASS = 'biod_pls_bmass'
// 生分解.植種情報.分解データ.反応条件.pH
export const BIOD_PLS_DD_RC_PH = 'biod_pls_dd_rc_ph'
// 生分解.植種情報.分解データ.反応条件.温度
export const BIOD_PLS_DD_RC_BTEMP = 'biod_pls_dd_rc_btemp'
// 生分解.植種情報.分解データ.反応条件.その他
export const BIOD_PLS_DD_RC_BOTH = 'biod_pls_dd_rc_both'
// 生分解.植種情報.分解データ.結果.反応時間
export const BIOD_PLS_DD_BRLT_BRT = 'biod_pls_dd_brlt_brt'
// 生分解.植種情報.分解データ.結果.分解速度
export const BIOD_PLS_DD_BRLT_DR = 'biod_pls_dd_brlt_dr'
// 生分解.植種情報.分解データ.結果.分解産物
export const BIOD_PLS_DD_BRLT_DP = 'biod_pls_dd_brlt_dp'
// 生分解.植種情報.分解データ.結果.生物化学的酸素要求量（BOD）測定
export const BIOD_PLS_DD_BRLT_DBOD = 'biod_pls_dd_brlt_dbod'






