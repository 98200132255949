import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';

import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updateRSLT_RT,
  updateRSLT_MCR,
  updateRSLT_RRATE,
  updateRSLT_CY,
  updateRSLT_PRATE_TUR,
  updateRSLT_PRATE_OCC,
  updateRSLT_PRATE_PDATA
} from 'actions/SynthesisArray';


import { makeLabelVal } from "common/util";

import {
  RSLT_RT,
  RSLT_MCR,
  RSLT_RRATE,
  RSLT_CY,
  RSLT_PRATE_TUR,
  RSLT_PRATE_OCC,
  RSLT_PRATE_PDATA,
} from "constants.js";

import {
  updateOpenDialog,
} from "actions/settings";

import { InputDialog } from "views/InputDialog"


const ResultDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');

  const [rslt_RTValue, setRslt_RTValue] = useState('');
  const [rslt_MCRValue, setRslt_MCRValue] = useState('');
  const [rslt_rrateValue, setRslt_rrateValue] = useState('');
  const [rslt_CYValue, setRslt_CYValue] = useState('');
  const [rslt_prate_turValue, setRslt_prate_turValue] = useState('');
  const [rslt_prate_occValue, setRslt_prate_occValue] = useState('');
  const [rslt_prate_pdataValue, setRslt_prate_pdataValue] = useState('');


  const selector_rslt_RT = useSelector((state) => state.SynthesisArray.rslt_RT);
  const selector_rslt_MCR = useSelector((state) => state.SynthesisArray.rslt_MCR);
  const selector_rslt_rrate = useSelector((state) => state.SynthesisArray.rslt_rrate);
  const selector_rslt_CY = useSelector((state) => state.SynthesisArray.rslt_CY);
  const selector_rslt_prate_tur = useSelector((state) => state.SynthesisArray.rslt_prate_tur);
  const selector_rslt_prate_occ = useSelector((state) => state.SynthesisArray.rslt_prate_occ);
  const selector_rslt_prate_pdata = useSelector((state) => state.SynthesisArray.rslt_prate_pdata);


  const selector_openDialog = useSelector((state) => state.settings.openDialog);


  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {
    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {
      case RSLT_RT:
        setRslt_RTValue(makeLabelVal(new_val));
        props.updateRSLT_RT(new_val);
        break;


      case RSLT_MCR:
        setRslt_MCRValue(makeLabelVal(new_val));
        props.updateRSLT_MCR(new_val);
        break;


      case RSLT_RRATE:
        setRslt_rrateValue(makeLabelVal(new_val));
        props.updateRSLT_RRATE(new_val);
        break;

      case RSLT_CY:
        setRslt_CYValue(makeLabelVal(new_val));
        props.updateRSLT_CY(new_val);
        break;


      case RSLT_PRATE_TUR:
        setRslt_prate_turValue(makeLabelVal(new_val));
        props.updateRSLT_PRATE_TUR(new_val);
        break;

      case RSLT_PRATE_OCC:
        setRslt_prate_occValue(makeLabelVal(new_val));
        props.updateRSLT_PRATE_OCC(new_val);
        break;

      case RSLT_PRATE_PDATA:
        setRslt_prate_pdataValue(makeLabelVal(new_val));
        props.updateRSLT_PRATE_PDATA(new_val);
        break;

      default:
        console.error("Unknown inputor", new_val);
        break;
    }
  }


  const showChargerSearchDialog = (selected) => {

    setSelectedNode(selected);

    switch (selected) {
      case RSLT_RT:
        setDialogLabel('反応時間');
        break;

      case RSLT_MCR:
        setDialogLabel('モノマー消費率');
        break;

      case RSLT_RRATE:
        setDialogLabel('反応速度');
        break;

      case RSLT_CY:
        setDialogLabel('収量');
        break;

      case RSLT_PRATE_TUR:
        setDialogLabel('菌体濁度');
        break;

      case RSLT_PRATE_OCC:
        setDialogLabel('炭素原濃度');
        break;

      case RSLT_PRATE_PDATA:
        setDialogLabel('生産データ');
        break;

      default:
        console.error("Unknown inputor", new_val);
        break;
    }

    props.updateOpenDialog(true);

  }


  useEffect(() => {

    setRslt_RTValue(makeLabelVal(selector_rslt_RT));
    setRslt_MCRValue(makeLabelVal(selector_rslt_MCR));
    setRslt_rrateValue(makeLabelVal(selector_rslt_rrate));
    setRslt_CYValue(makeLabelVal(selector_rslt_CY));
    setRslt_prate_turValue(makeLabelVal(selector_rslt_prate_tur));
    setRslt_prate_occValue(makeLabelVal(selector_rslt_prate_occ));
    setRslt_prate_pdataValue(makeLabelVal(selector_rslt_prate_pdata));

  }, []);



  return (
    <div className={classes.root}>
      <div className={classes.content}>

      <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'結果'} saveInput={saveInput} selectedNode={selectedNode} />


        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">結果</Typography>} style={{ backgroundColor: 'lightgray' }} />

          <Divider />

          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6} xs={6}>
                <TextField
                  label="反応時間"
                  name={RSLT_RT}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(RSLT_RT) }}
                  onBeforeInput={() => { showChargerSearchDialog(RSLT_RT) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(RSLT_RT) } }}
                  type="text"
                  value={rslt_RTValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="モノマー消費率"
                  name={RSLT_MCR}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(RSLT_MCR) }}
                  onBeforeInput={() => { showChargerSearchDialog(RSLT_MCR) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(RSLT_MCR) } }}
                  type="text"
                  value={rslt_MCRValue || ''}
                  variant="outlined"
                />
              </Grid>


              <Grid item md={6} xs={6}>
                <TextField
                  label="反応速度"
                  name={RSLT_RRATE}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(RSLT_RRATE) }}
                  onBeforeInput={() => { showChargerSearchDialog(RSLT_RRATE) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(RSLT_RRATE) } }}
                  type="text"
                  value={rslt_rrateValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="収量"
                  name={RSLT_CY}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(RSLT_CY) }}
                  onBeforeInput={() => { showChargerSearchDialog(RSLT_CY) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(RSLT_CY) } }}
                  type="text"
                  value={rslt_CYValue || ''}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid container item md={12} spacing={0}></Grid>
                <Grid item md={12} spacing={0}>
                  <Typography variant="h4">⽣産速度</Typography>
                </Grid>

              </Grid>

              <Divider variant="inset" />

              <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="菌体濁度"
                  name={RSLT_PRATE_TUR}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(RSLT_PRATE_TUR) }}
                  onBeforeInput={() => { showChargerSearchDialog(RSLT_PRATE_TUR) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(RSLT_PRATE_TUR) } }}
                  type="text"
                  value={rslt_prate_turValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="炭素原濃度"
                  name={RSLT_PRATE_OCC}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(RSLT_PRATE_OCC) }}
                  onBeforeInput={() => { showChargerSearchDialog(RSLT_PRATE_OCC) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(RSLT_PRATE_OCC) } }}
                  type="text"
                  value={rslt_prate_occValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="生産データ"
                  name={RSLT_PRATE_PDATA}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(RSLT_PRATE_PDATA) }}
                  onBeforeInput={() => { showChargerSearchDialog(RSLT_PRATE_PDATA) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(RSLT_PRATE_PDATA) } }}
                  type="text"
                  value={rslt_prate_pdataValue || ''}
                  variant="outlined"
                />
              </Grid>

              </Grid>

          </CardContent>

        </Card>
      </div>
    </div>
  );
};

ResultDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateRSLT_RT,
    updateRSLT_MCR,
    updateRSLT_RRATE,
    updateRSLT_CY,
    updateRSLT_PRATE_TUR,
    updateRSLT_PRATE_OCC,
    updateRSLT_PRATE_PDATA,

    updateOpenDialog
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    SynthesisArray: state.SynthesisArray,
    settings: state.settings
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ResultDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
