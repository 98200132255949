import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updateHOM_PRIZE_CHAIN_ADD_RAD_LRP,
  updateHOM_PRIZE_CHAIN_ADD_CATI_LCP,
  updateHOM_PRIZE_CHAIN_ADD_ANIO_LAP,
  updateHOM_PRIZE_CHAIN_ADD_CRD,
  updateHOM_PRIZE_CHAIN_ROP_CROP,
  updateHOM_PRIZE_CHAIN_ROP_AROP,
  updateHOM_PRIZE_CHAIN_ROP_RROP,
  updateHOM_PRIZE_CHAIN_ROP_TMCR_ROMP,
  updateHOM_PRIZE_CHAIN_CGCP,
} from 'actions/SynthesisArray';


import { makeLabelVal } from "common/util";



import {
  HOM_PRIZE_CHAIN_ADD_RAD_LRP,
  HOM_PRIZE_CHAIN_ADD_CATI_LCP,
  HOM_PRIZE_CHAIN_ADD_ANIO_LAP,
  HOM_PRIZE_CHAIN_ADD_CRD,

  HOM_PRIZE_CHAIN_ROP_CROP,
  HOM_PRIZE_CHAIN_ROP_AROP,
  HOM_PRIZE_CHAIN_ROP_RROP,
  HOM_PRIZE_CHAIN_ROP_TMCR_ROMP,
  HOM_PRIZE_CHAIN_CGCP
} from "constants.js";

import {
  updateOpenDialog,
} from "actions/settings";

import { InputDialog } from "views/InputDialog"


/**
 * 高分子生成.作り方.重合名.連鎖重合
 */
const PolymerizationChainDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');

  const [addRdaLrpValue, setAddRdaLrpValue] = useState('');
  const [addCatiLcpValue, setAddCatiLcpValue] = useState('');
  const [addAnioLapValue, setAddAnioLapValue] = useState('');
  const [addCrdValue, setAddCrdValue] = useState('');
  const [ropCropValue, setRopCropValue] = useState('');
  const [ropAropValue, setRopAropValue] = useState('');
  const [ropRropValue, setRopRropValue] = useState('');
  const [ropTmcrRompValue, setRopTmcrRompValue] = useState('');
  const [cgcpValue, setCgcpValue] = useState('');


  const selector_hom_prize_chain_add_rad_LRP = useSelector((state) => state.SynthesisArray.hom_prize_chain_add_rad_LRP);
  const selector_hom_prize_chain_add_cati_LCP = useSelector((state) => state.SynthesisArray.hom_prize_chain_add_cati_LCP);
  const selector_hom_prize_chain_add_anio_LAP = useSelector((state) => state.SynthesisArray.hom_prize_chain_add_anio_LAP);
  const selector_hom_prize_chain_add_crd = useSelector((state) => state.SynthesisArray.hom_prize_chain_add_crd);
  const selector_hom_prize_chain_rop_CROP = useSelector((state) => state.SynthesisArray.hom_prize_chain_rop_CROP);
  const selector_hom_prize_chain_rop_AROP = useSelector((state) => state.SynthesisArray.hom_prize_chain_rop_AROP);
  const selector_hom_prize_chain_rop_RROP = useSelector((state) => state.SynthesisArray.hom_prize_chain_rop_RROP);
  const selector_hom_prize_chain_rop_TMCR_ROMP = useSelector((state) => state.SynthesisArray.hom_prize_chain_rop_TMCR_ROMP);
  const selector_hom_prize_chain_CGCP = useSelector((state) => state.SynthesisArray.hom_prize_chain_CGCP);


  const selector_openDialog = useSelector((state) => state.settings.openDialog);


  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };


    switch (selectedNode) {
      case HOM_PRIZE_CHAIN_ADD_RAD_LRP:
        setAddRdaLrpValue(makeLabelVal(new_val));
        props.updateHOM_PRIZE_CHAIN_ADD_RAD_LRP(new_val);
        break;

      case HOM_PRIZE_CHAIN_ADD_CATI_LCP:
        setAddCatiLcpValue(makeLabelVal(new_val));
        props.updateHOM_PRIZE_CHAIN_ADD_CATI_LCP(new_val);
        break;

      case HOM_PRIZE_CHAIN_ADD_ANIO_LAP:
        setAddAnioLapValue(makeLabelVal(new_val));
        props.updateHOM_PRIZE_CHAIN_ADD_ANIO_LAP(new_val);
        break;

      case HOM_PRIZE_CHAIN_ADD_CRD:
        setAddCrdValue(makeLabelVal(new_val));
        props.updateHOM_PRIZE_CHAIN_ADD_CRD(new_val);
        break;

      case HOM_PRIZE_CHAIN_ROP_CROP:
        setRopCropValue(makeLabelVal(new_val));
        props.updateHOM_PRIZE_CHAIN_ROP_CROP(new_val);
        break;

      case HOM_PRIZE_CHAIN_ROP_AROP:
        setRopAropValue(makeLabelVal(new_val));
        props.updateHOM_PRIZE_CHAIN_ROP_AROP(new_val);
        break;

      case HOM_PRIZE_CHAIN_ROP_RROP:
        setRopRropValue(makeLabelVal(new_val));
        props.updateHOM_PRIZE_CHAIN_ROP_RROP(new_val);
        break;

      case HOM_PRIZE_CHAIN_ROP_TMCR_ROMP:
        setRopTmcrRompValue(makeLabelVal(new_val));
        props.updateHOM_PRIZE_CHAIN_ROP_TMCR_ROMP(new_val);
        break;

      case HOM_PRIZE_CHAIN_CGCP:
        setCgcpValue(makeLabelVal(new_val));
        props.updateHOM_PRIZE_CHAIN_CGCP(new_val);
        break;
    }

  }


  const showChargerSearchDialog = (selected) => {

    setSelectedNode(selected);

    switch (selected) {
      case HOM_PRIZE_CHAIN_ADD_RAD_LRP:
        setDialogLabel('リビングラジカル重合');
        break;

      case HOM_PRIZE_CHAIN_ADD_CATI_LCP:
        setDialogLabel('リビングカチオン重合');
        break;

      case HOM_PRIZE_CHAIN_ADD_ANIO_LAP:
        setDialogLabel('リビングアニオン重合');
        break;

      case HOM_PRIZE_CHAIN_ADD_CRD:
        setDialogLabel('配位重合');
        break;

      case HOM_PRIZE_CHAIN_ROP_CROP:
        setDialogLabel('カチオン開環重合');
        break;

      case HOM_PRIZE_CHAIN_ROP_AROP:
        setDialogLabel('アニオン開環重合');
        break;

      case HOM_PRIZE_CHAIN_ROP_RROP:
        setDialogLabel('ラジカル開環重合');
        break;

      case HOM_PRIZE_CHAIN_ROP_TMCR_ROMP:
        setDialogLabel('開環メタセシス重合');
        break;

      case HOM_PRIZE_CHAIN_CGCP:
        setDialogLabel('連鎖縮合重合');
        break;
    }

    props.updateOpenDialog(true);
  }


  useEffect(() => {

    setAddRdaLrpValue(makeLabelVal(selector_hom_prize_chain_add_rad_LRP));
    setAddCatiLcpValue(makeLabelVal(selector_hom_prize_chain_add_cati_LCP));
    setAddAnioLapValue(makeLabelVal(selector_hom_prize_chain_add_anio_LAP));
    setAddCrdValue(makeLabelVal(selector_hom_prize_chain_add_crd));
    setRopCropValue(makeLabelVal(selector_hom_prize_chain_rop_CROP));
    setRopAropValue(makeLabelVal(selector_hom_prize_chain_rop_AROP));
    setRopRropValue(makeLabelVal(selector_hom_prize_chain_rop_RROP));
    setRopTmcrRompValue(makeLabelVal(selector_hom_prize_chain_rop_TMCR_ROMP));
    setCgcpValue(makeLabelVal(selector_hom_prize_chain_CGCP));

  }, []);



  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'重合名'} saveInput={saveInput} selectedNode={selectedNode} />


        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">重合名.連鎖重合</Typography>} style={{ backgroundColor: 'lightgray' }}
            subheader={<Typography variant="h5">- 作り方 -</Typography>}
          />


          <Divider />

          <CardContent>

            <Grid item md={12} spacing={0}>
              <Typography variant="h4" >付加重合</Typography>
            </Grid>

            <Divider variant="inset" />

            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="ラジカル重合.リビングラジカル重合"
                  name={HOM_PRIZE_CHAIN_ADD_RAD_LRP}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(HOM_PRIZE_CHAIN_ADD_RAD_LRP) }}
                  onBeforeInput={() => { showChargerSearchDialog(HOM_PRIZE_CHAIN_ADD_RAD_LRP) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(HOM_PRIZE_CHAIN_ADD_RAD_LRP) } }}
                  type="text"
                  value={addRdaLrpValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="カチオン重合.リビングカチオン重合"
                  name={HOM_PRIZE_CHAIN_ADD_CATI_LCP}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(HOM_PRIZE_CHAIN_ADD_CATI_LCP) }}
                  onBeforeInput={() => { showChargerSearchDialog(HOM_PRIZE_CHAIN_ADD_CATI_LCP) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(HOM_PRIZE_CHAIN_ADD_CATI_LCP) } }}
                  type="text"
                  value={addCatiLcpValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="アニオン重合.リビングアニオン重合"
                  name={HOM_PRIZE_CHAIN_ADD_ANIO_LAP}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(HOM_PRIZE_CHAIN_ADD_ANIO_LAP) }}
                  onBeforeInput={() => { showChargerSearchDialog(HOM_PRIZE_CHAIN_ADD_ANIO_LAP) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(HOM_PRIZE_CHAIN_ADD_ANIO_LAP) } }}
                  type="text"
                  value={addAnioLapValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="配位重合"
                  name={HOM_PRIZE_CHAIN_ADD_CRD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(HOM_PRIZE_CHAIN_ADD_CRD) }}
                  onBeforeInput={() => { showChargerSearchDialog(HOM_PRIZE_CHAIN_ADD_CRD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(HOM_PRIZE_CHAIN_ADD_CRD) } }}
                  type="text"
                  value={addCrdValue || ''}
                  variant="outlined"
                />
              </Grid>

            </Grid>


            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>
              <Grid item md={12} spacing={0}>
                <Typography variant="h4">開環重合</Typography>
              </Grid>

            </Grid>

            <Divider variant="inset" />

            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="カチオン開環重合"
                  name={HOM_PRIZE_CHAIN_ROP_CROP}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(HOM_PRIZE_CHAIN_ROP_CROP) }}
                  onBeforeInput={() => { showChargerSearchDialog(HOM_PRIZE_CHAIN_ROP_CROP) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(HOM_PRIZE_CHAIN_ROP_CROP) } }}
                  type="text"
                  value={ropCropValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="アニオン開環重合"
                  name={HOM_PRIZE_CHAIN_ROP_AROP}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(HOM_PRIZE_CHAIN_ROP_AROP) }}
                  onBeforeInput={() => { showChargerSearchDialog(HOM_PRIZE_CHAIN_ROP_AROP) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(HOM_PRIZE_CHAIN_ROP_AROP) } }}
                  type="text"
                  value={ropAropValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="ラジカル開環重合"
                  name={HOM_PRIZE_CHAIN_ROP_RROP}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(HOM_PRIZE_CHAIN_ROP_RROP) }}
                  onBeforeInput={() => { showChargerSearchDialog(HOM_PRIZE_CHAIN_ROP_RROP) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(HOM_PRIZE_CHAIN_ROP_RROP) } }}
                  type="text"
                  value={ropRropValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="遷移金属触媒を用いた開環重合.開環メタセシス重合"
                  name={HOM_PRIZE_CHAIN_ROP_TMCR_ROMP}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(HOM_PRIZE_CHAIN_ROP_TMCR_ROMP) }}
                  onBeforeInput={() => { showChargerSearchDialog(HOM_PRIZE_CHAIN_ROP_TMCR_ROMP) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(HOM_PRIZE_CHAIN_ROP_TMCR_ROMP) } }}
                  type="text"
                  value={ropTmcrRompValue || ''}
                  variant="outlined"
                />
              </Grid>

            </Grid>


            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>
              <Grid item md={12} spacing={0}>
              </Grid>

            </Grid>

            <Divider variant="inset" />

            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="連鎖縮合重合"
                  name={HOM_PRIZE_CHAIN_CGCP}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(HOM_PRIZE_CHAIN_CGCP) }}
                  onBeforeInput={() => { showChargerSearchDialog(HOM_PRIZE_CHAIN_CGCP) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(HOM_PRIZE_CHAIN_CGCP) } }}
                  type="text"
                  value={cgcpValue || ''}
                  variant="outlined"
                />
              </Grid>

            </Grid>

          </CardContent>

        </Card>
      </div>
    </div>
  );
};

PolymerizationChainDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateHOM_PRIZE_CHAIN_ADD_RAD_LRP,
    updateHOM_PRIZE_CHAIN_ADD_CATI_LCP,
    updateHOM_PRIZE_CHAIN_ADD_ANIO_LAP,
    updateHOM_PRIZE_CHAIN_ADD_CRD,
    updateHOM_PRIZE_CHAIN_ROP_CROP,
    updateHOM_PRIZE_CHAIN_ROP_AROP,
    updateHOM_PRIZE_CHAIN_ROP_RROP,
    updateHOM_PRIZE_CHAIN_ROP_TMCR_ROMP,
    updateHOM_PRIZE_CHAIN_CGCP,

    updateOpenDialog
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    SynthesisArray: state.SynthesisArray,
    settings: state.settings
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PolymerizationChainDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
}));
