import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link as RouterLink, withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { makeLabelVal } from "common/util";


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { InputDialog } from "views/InputDialog"

import {
  updateSTRUCTURE_XRAY_GRAPH,
  updateSTRUCTURE_MICROSCOPE_IMAGE,
  updateSTRUCTURE_CRYSTALLINITY_DEGREE

} from 'actions/propertyArray';

import {
  updateOpenDialog,
} from "actions/settings";


import {
  STRUCTURE_XRAY_GRAPH,
  STRUCTURE_MICROSCOPE_IMAGE,
  STRUCTURE_CRYSTALLINITY_DEGREE,
} from "constants.js";


/**
 * 固体物性.構造
 */
const StructureDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');

  const [structure_xray_graphValue, setStructure_xray_graphValue] = useState('');
  const [structure_microscope_imageValue, setStructure_microscope_imageValue] = useState('');
  const [structure_crystallinity_degreeValue, setStructure_crystallinity_degreeValue] = useState('');

  const selector_structure_xray_graph = useSelector((state) => state.propertyArray.structure_xray_graph);
  const selector_structure_microscope_image = useSelector((state) => state.propertyArray.structure_microscope_image);
  const selector_structure_crystallinity_degree = useSelector((state) => state.propertyArray.structure_crystallinity_degree);

  const selector_openDialog = useSelector((state) => state.settings.openDialog);

  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
      
    };

    switch (selectedNode) {
      case STRUCTURE_XRAY_GRAPH:
        setStructure_xray_graphValue(makeLabelVal(new_val));
        props.updateSTRUCTURE_XRAY_GRAPH(new_val);
        break;

      case STRUCTURE_MICROSCOPE_IMAGE:
        setStructure_microscope_imageValue(makeLabelVal(new_val));
        props.updateSTRUCTURE_MICROSCOPE_IMAGE(new_val);
        break;

      case STRUCTURE_CRYSTALLINITY_DEGREE:
        setStructure_crystallinity_degreeValue(makeLabelVal(new_val));
        props.updateSTRUCTURE_CRYSTALLINITY_DEGREE(new_val);
        break;
    }
  }


  const showChargerSearchDialog = (selected) => {
    setSelectedNode(selected);

    switch(selected){
      case STRUCTURE_XRAY_GRAPH:
        setDialogLabel('グラフ');
        break;

      case STRUCTURE_MICROSCOPE_IMAGE:
        setDialogLabel('画像');
        break;

      case STRUCTURE_CRYSTALLINITY_DEGREE:
        setDialogLabel('結晶化度');
        break;
    }

    props.updateOpenDialog(true);
  }


  useEffect(() => {

    setStructure_xray_graphValue(makeLabelVal(selector_structure_xray_graph));
    setStructure_microscope_imageValue(makeLabelVal(selector_structure_microscope_image));
    setStructure_crystallinity_degreeValue(makeLabelVal(selector_structure_crystallinity_degree));

  }, []);


  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'構造'} saveInput={saveInput} selectedNode={selectedNode} />

        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">構造</Typography>} style={{ backgroundColor: 'lightgray' }} />
          <Divider />

          <CardContent>

            <Grid item md={12} spacing={0}>
              <Typography variant="h4">X線</Typography>
            </Grid>

            <Divider variant="inset" />

            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="グラフ"
                  name={STRUCTURE_XRAY_GRAPH}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(STRUCTURE_XRAY_GRAPH) }}
                  onBeforeInput={() => { showChargerSearchDialog(STRUCTURE_XRAY_GRAPH) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(STRUCTURE_XRAY_GRAPH) } }}
                  type="text"
                  value={structure_xray_graphValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>

              <Grid container item md={12} spacing={0}></Grid>
            </Grid>


            <Grid item md={12} spacing={0}>
              <Typography variant="h4">顕微鏡法</Typography>
            </Grid>

            <Divider variant="inset" />


            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="画像"
                  name={STRUCTURE_MICROSCOPE_IMAGE}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(STRUCTURE_MICROSCOPE_IMAGE) }}
                  onBeforeInput={() => { showChargerSearchDialog(STRUCTURE_MICROSCOPE_IMAGE) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(STRUCTURE_MICROSCOPE_IMAGE) } }}
                  type="text"
                  value={structure_microscope_imageValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid container item md={12} spacing={0}></Grid>
            </Grid>


            <Grid item md={12} spacing={0}>
              <Typography variant="h4">その他</Typography>
            </Grid>


            <Divider variant="inset" />

            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="結晶化度"
                  name={STRUCTURE_CRYSTALLINITY_DEGREE}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(STRUCTURE_CRYSTALLINITY_DEGREE) }}
                  onBeforeInput={() => { showChargerSearchDialog(STRUCTURE_CRYSTALLINITY_DEGREE) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(STRUCTURE_CRYSTALLINITY_DEGREE) } }}
                  type="text"
                  value={structure_crystallinity_degreeValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid container item md={12} spacing={0}></Grid>
            </Grid>

          </CardContent>
        </Card>
      </div>
    </div>
  );
};

StructureDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateSTRUCTURE_XRAY_GRAPH,
    updateSTRUCTURE_MICROSCOPE_IMAGE,
    updateSTRUCTURE_CRYSTALLINITY_DEGREE,

    updateOpenDialog,
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    propertyArray: state.propertyArray,
    settings: state.settings
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StructureDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
