import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';


import {
  updatePP_MS_VEM_VTD,
  updatePP_MS_VEM_VPD,
  updatePP_MS_VEM_GDASH,
  updatePP_MS_VEM_GDASH2,
  updatePP_MS_VEM_LTAN,

} from 'actions/mechanicalArray';

import {
  PP_MS_VEM_VTD,
  PP_MS_VEM_VPD,
  PP_MS_VEM_GDASH,
  PP_MS_VEM_GDASH2,
  PP_MS_VEM_LTAN
} from "constants.js";


import { makeLabelVal } from "common/util";

import {
  updateOpenDialog,
} from "actions/settings";

import { InputDialog } from "views/InputDialog"


/**
 * 固体物性.機械強度特性.粘弾性測定
 */
const ViscoelasticDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');

  const selector_pp_ms_vem_vtd = useSelector((state) => state.mechanicalArray.pp_ms_vem_vtd);
  const selector_pp_ms_vem_vpd = useSelector((state) => state.mechanicalArray.pp_ms_vem_vpd);
  const selector_pp_ms_vem_gdash = useSelector((state) => state.mechanicalArray.pp_ms_vem_gdash);
  const selector_pp_ms_vem_gdash2 = useSelector((state) => state.mechanicalArray.pp_ms_vem_gdash2);
  const selector_pp_ms_vem_ltan = useSelector((state) => state.mechanicalArray.pp_ms_vem_ltan);


  //温度依存性
  const [pp_ms_vem_vtdValue, setPp_ms_vem_vtdValue] = useState('');

  //圧⼒依存性
  const [pp_ms_vem_vpdValue, setPp_ms_vem_vpdValue] = useState('');

  //G'
  const [pp_ms_vem_gdashValue, setPp_ms_vem_gdashValue] = useState('');

  //G''
  const [pp_ms_vem_gdash2Value, setPp_ms_vem_gdash2Value] = useState('');

  //損失正接
  const [pp_ms_vem_ltanValue, setPp_ms_vem_ltanValue] = useState('');

  const selector_openDialog = useSelector((state) => state.settings.openDialog);

  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };


    switch (selectedNode) {
      case PP_MS_VEM_VTD:
        setPp_ms_vem_vtdValue(makeLabelVal(new_val));
        props.updatePP_MS_VEM_VTD(new_val);
        break;


      case PP_MS_VEM_VPD:
        setPp_ms_vem_vpdValue(makeLabelVal(new_val));
        props.updatePP_MS_VEM_VPD(new_val);
        break;


      case PP_MS_VEM_GDASH:
        setPp_ms_vem_gdashValue(makeLabelVal(new_val));
        props.updatePP_MS_VEM_GDASH(new_val);
        break;


      case PP_MS_VEM_GDASH2:
        setPp_ms_vem_gdash2Value(makeLabelVal(new_val));
        props.updatePP_MS_VEM_GDASH2(new_val);
        break;

      case PP_MS_VEM_LTAN:
        setPp_ms_vem_ltanValue(makeLabelVal(new_val));
        props.updatePP_MS_VEM_LTAN(new_val);
        break;
    }
  }


  const showChargerSearchDialog = (selected) => {
    setSelectedNode(selected);

    switch(selected){
      case PP_MS_VEM_VTD:
        setDialogLabel('温度依存性');
        break;

      case PP_MS_VEM_VPD:
        setDialogLabel('圧⼒依存性');
        break;

      case PP_MS_VEM_GDASH:
        setDialogLabel("G'");
        break;

      case PP_MS_VEM_GDASH2:
        setDialogLabel("G''");
        break;

      case PP_MS_VEM_LTAN:
        setDialogLabel("損失正接");
        break;
    }
    props.updateOpenDialog(true);
  }


  useEffect(() => {

    setPp_ms_vem_vtdValue(makeLabelVal(selector_pp_ms_vem_vtd));
    setPp_ms_vem_vpdValue(makeLabelVal(selector_pp_ms_vem_vpd));
    setPp_ms_vem_gdashValue(makeLabelVal(selector_pp_ms_vem_gdash));
    setPp_ms_vem_gdash2Value(makeLabelVal(selector_pp_ms_vem_gdash2));
    setPp_ms_vem_ltanValue(makeLabelVal(selector_pp_ms_vem_ltan));

  }, []);


  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'粘弾性測定'} saveInput={saveInput} selectedNode={selectedNode} />

        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">粘弾性測定</Typography>} style={{ backgroundColor: 'lightgray' }}
            subheader={<Typography variant="h5">- 機械強度特性 -</Typography>}
          />
          <Divider />

          <CardContent>

            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="温度依存性"
                  name={PP_MS_VEM_VTD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_VEM_VTD) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_VEM_VTD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_VEM_VTD) } }}
                  type="text"
                  value={pp_ms_vem_vtdValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="圧⼒依存性"
                  name={PP_MS_VEM_VPD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_VEM_VPD) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_VEM_VPD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_VEM_VPD) } }}
                  type="text"
                  value={pp_ms_vem_vpdValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="G'"
                  name={PP_MS_VEM_GDASH}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_VEM_GDASH) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_VEM_GDASH) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_VEM_GDASH) } }}
                  type="text"
                  value={pp_ms_vem_gdashValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="G''"
                  name={PP_MS_VEM_GDASH2}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_VEM_GDASH2) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_VEM_GDASH2) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_VEM_GDASH2) } }}
                  type="text"
                  value={pp_ms_vem_gdash2Value || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="損失正接"
                  name={PP_MS_VEM_LTAN}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_VEM_LTAN) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_VEM_LTAN) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_VEM_LTAN) } }}
                  type="text"
                  value={pp_ms_vem_ltanValue || ''}
                  variant="outlined"
                />
              </Grid>

            </Grid>

          </CardContent>
        </Card>
      </div>
    </div>
  );
};

ViscoelasticDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({

    updatePP_MS_VEM_VTD,
    updatePP_MS_VEM_VPD,
    updatePP_MS_VEM_GDASH,
    updatePP_MS_VEM_GDASH2,
    updatePP_MS_VEM_LTAN,

    updateOpenDialog

  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    mechanicalArray: state.mechanicalArray,
    settings: state.settings
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ViscoelasticDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
