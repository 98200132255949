/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  List, ListItem, Button, colors, Collapse, Typography,
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ErrorIcon from '@material-ui/icons/Error';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';


const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));



const SidebarNav = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [openUsage, setOpenUsage] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  }

  const handleClick2 = () => {
    setOpen2(!open2);
  }

  const handleUsage = () => {
    setOpenUsage(!openUsage);
  }

  return (
    <List
      className={clsx(classes.root, className)}
    >
      <ListItem className={classes.item} disableGutters key={'Dashboard'}>
        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/dashboard'}>
          <div className={classes.icon}><DashboardIcon /></div>Dashboard
        </Button>
      </ListItem>


      <ListItem className={classes.item} disableGutters key={'APIlist'}>
        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/apis'}>
          <div className={classes.icon}><NotificationsIcon /></div>APIリスト
        </Button>
      </ListItem>

      <ListItem className={classes.item} disableGutters key={'Blacklist'}>
        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/blacklist'}>
          <div className={classes.icon}><PeopleIcon /></div>ブラックリスト
        </Button>
      </ListItem>

      <ListItem className={classes.item} disableGutters key={'Blocklist'}>
        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/blocklist'}>
          <div className={classes.icon}><ErrorIcon  /></div>遮断履歴
        </Button>
      </ListItem>

      <ListItem className={classes.item} disableGutters key={'settings'}>
        <Button activeClassName={classes.active} className={classes.button} onClick={handleClick2} >
          <div className={classes.icon}><MonetizationOnIcon /></div>設定
        </Button>
        {open2 ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open2} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem className={classes.sub_item} key={'しきい値'}>
            <Button activeClassName={classes.active} className={classes.button}
              component={CustomRouterLink} to={'/settings/threshold'}>
              <div className={classes.icon}><ArrowRightIcon /></div>しきい値
            </Button>
          </ListItem>
        </List>
      </Collapse>


    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
};

export default SidebarNav;


const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
  },
  sub_item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 26,
  },
  button: {
    // color: colors.blueGrey[800],
    color: "#2a3f54",
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    // color: "#2a3f54",
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    // color: "#000000",
    // backgroundColor: "#b7b7b7",
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
      // color: "#000000"
    }
  }
}));