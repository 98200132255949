import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link as RouterLink, withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { makeLabelVal } from "common/util";

import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updateBIODEGRADABILITY_CURVE,
} from 'actions/propertyArray';


import {
  updateOpenDialog,
} from "actions/settings";

import { InputDialog } from "views/InputDialog"

import {
  BIODEGRADABILITY_CURVE
} from "constants";


/**
 * 固体物性.⽣分解度
 */
const BiodegradabilityDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');

  const [biodegradability_curveValue, setBiodegradability_curveValue] = useState('');

  const selector_biodegradability_curve = useSelector((state) => state.propertyArray.biodegradability_curve);

  const selector_openDialog = useSelector((state) => state.settings.openDialog);

  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {
      case BIODEGRADABILITY_CURVE:
        setBiodegradability_curveValue(makeLabelVal(new_val));
        props.updateBIODEGRADABILITY_CURVE(new_val);
        break;
    }

  }

  const showChargerSearchDialog = (selected) => {

    setSelectedNode(selected);

    switch (selected) {
      case BIODEGRADABILITY_CURVE:
        setDialogLabel('曲線データ')
        break;
    }

    props.updateOpenDialog(true);
  }


  useEffect(() => {
    setBiodegradability_curveValue(makeLabelVal(selector_biodegradability_curve));
  }, []);


  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'⽣分解度'} saveInput={saveInput} selectedNode={selectedNode} />

        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">⽣分解度</Typography>} style={{ backgroundColor: 'lightgray' }} />
          <Divider />

          <CardContent>

            <Grid item md={12} spacing={0}>
              <Typography variant="h4">生分解度グラフ</Typography>
            </Grid>

            <Divider variant="inset" />

            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="曲線データ"
                  name={BIODEGRADABILITY_CURVE}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(BIODEGRADABILITY_CURVE) }}
                  onBeforeInput={() => { showChargerSearchDialog(BIODEGRADABILITY_CURVE) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(BIODEGRADABILITY_CURVE) } }}
                  type="text"
                  value={biodegradability_curveValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>

              <Grid container item md={12} spacing={0}></Grid>
            </Grid>

          </CardContent>

        </Card>
      </div>
    </div>
  );
};


BiodegradabilityDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};


const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateBIODEGRADABILITY_CURVE,

    updateOpenDialog,
  }, dispatch);
};


let mapStateToProps = (state) => {
  return {
    propertyArray: state.propertyArray,
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BiodegradabilityDetail));


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
