import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updateBUY_PMAKER,
  updateBUY_PLOT_NUMBER,
  updatePROCESS_SYNTHESIS,
  updatePROCESS_MACHINE,
  updatePROCESS_THEOTHER

} from 'actions/SynthesisArray';


import { makeLabelVal } from "common/util";


import {
  BUY_PMAKER,
  BUY_PLOT_NUMBER,
  PROCESS_SYNTHESIS,
  PROCESS_MACHINE,
  PROCESS_THEOTHER,
} from "constants.js";

import {
  updateOpenDialog,
} from "actions/settings";

import { InputDialog } from "views/InputDialog"


/**
 * 高分子生成.作り方.製造プロセス
 */
const ProcessDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');

  const [buyPmakerValue, setBuyPmakerValue] = useState('');
  const [buyPlotNumberValue, setBuyPlotNumberValue] = useState('');
  const [processSynthesisValue, setProcessSynthesisValue] = useState('');
  const [processMachineValue, setProcessMachineValue] = useState('');
  const [processTheotherValue, setProcessTheotherValue] = useState('');

  const selector_buy_pmaker = useSelector((state) => state.SynthesisArray.buy_pmaker);
  const selector_buy_plot_number = useSelector((state) => state.SynthesisArray.buy_plot_number);
  const selector_process_synthesis = useSelector((state) => state.SynthesisArray.process_synthesis);
  const selector_process_machine = useSelector((state) => state.SynthesisArray.process_machine);
  const selector_process_theother = useSelector((state) => state.SynthesisArray.process_theother);


  const selector_openDialog = useSelector((state) => state.settings.openDialog);


  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {
      case BUY_PMAKER:
        setBuyPmakerValue(makeLabelVal(new_val));
        props.updateBUY_PMAKER(new_val);
        break;


      case BUY_PLOT_NUMBER:
        setBuyPlotNumberValue(makeLabelVal(new_val));
        props.updateBUY_PLOT_NUMBER(new_val);
        break;


      case PROCESS_SYNTHESIS:
        setProcessSynthesisValue(makeLabelVal(new_val));
        props.updatePROCESS_SYNTHESIS(new_val);
        break;

      case PROCESS_MACHINE:
        setProcessMachineValue(makeLabelVal(new_val));
        props.updatePROCESS_MACHINE(new_val);
        break;


      case PROCESS_THEOTHER:
        setProcessTheotherValue(makeLabelVal(new_val));
        props.updatePROCESS_THEOTHER(new_val);
        break;
    }
  }


  const showChargerSearchDialog = (selected) => {

    setSelectedNode(selected);

    switch (selected) {
      case BUY_PMAKER:
        setDialogLabel('購入.メーカー');
        break;

      case BUY_PLOT_NUMBER:
        setDialogLabel('購入.ロット番号');
        break;

      case PROCESS_SYNTHESIS:
        setDialogLabel('合成');
        break;

      case PROCESS_MACHINE:
        setDialogLabel('機械名');
        break;

      case PROCESS_THEOTHER:
        setDialogLabel('その他');
        break;
    }

    props.updateOpenDialog(true);
  }


  useEffect(() => {
    setBuyPmakerValue(makeLabelVal(selector_buy_pmaker));
    setBuyPlotNumberValue(makeLabelVal(selector_buy_plot_number));
    setProcessSynthesisValue(makeLabelVal(selector_process_synthesis));
    setProcessMachineValue(makeLabelVal(selector_process_machine));
    setProcessTheotherValue(makeLabelVal(selector_process_theother));
  }, []);



  return (
    <div className={classes.root}>
      <div className={classes.content}>


        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'製造プロセス'} saveInput={saveInput} selectedNode={selectedNode} />

        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">製造プロセス</Typography>} style={{ backgroundColor: 'lightgray' }}
            subheader={<Typography variant="h5">- 作り方 -</Typography>}
          />


          <Divider />

          <CardContent>

            <Grid item md={12} spacing={0}>
              <Typography variant="h4" >元の⾼分⼦</Typography>
            </Grid>

            <Divider variant="inset" />


            {/* <Grid item md={12} spacing={0}>
              <Grid container item md={12} spacing={0}></Grid>
              <Grid container md={12} spacing={0}></Grid>
              <Typography variant="h5">購⼊</Typography>
            </Grid> */}


            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="購入.メーカー"
                  name={BUY_PMAKER}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(BUY_PMAKER) }}
                  onBeforeInput={() => { showChargerSearchDialog(BUY_PMAKER) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(BUY_PMAKER) } }}
                  type="text"
                  value={buyPmakerValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="購入.ロット番号"
                  name={BUY_PLOT_NUMBER}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(BUY_PLOT_NUMBER) }}
                  onBeforeInput={() => { showChargerSearchDialog(BUY_PLOT_NUMBER) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(BUY_PLOT_NUMBER) } }}
                  type="text"
                  value={buyPlotNumberValue || ''}
                  variant="outlined"
                />
                <Grid container item md={12}></Grid>

              </Grid>

            </Grid>


            <Grid container spacing={2}>
              <Grid item md={6} xs={6}>
                <TextField
                  label="合成"
                  name={PROCESS_SYNTHESIS}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PROCESS_SYNTHESIS) }}
                  onBeforeInput={() => { showChargerSearchDialog(PROCESS_SYNTHESIS) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PROCESS_SYNTHESIS) } }}
                  type="text"
                  value={processSynthesisValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid container item md={12} spacing={0}></Grid>

            </Grid>



            <Divider variant="inset" />


            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="機械名"
                  name={PROCESS_MACHINE}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PROCESS_MACHINE) }}
                  onBeforeInput={() => { showChargerSearchDialog(PROCESS_MACHINE) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PROCESS_MACHINE) } }}
                  type="text"
                  value={processMachineValue || ''}
                  variant="outlined"
                />
              </Grid>


              <Grid item md={6} xs={6}>
                <TextField
                  label="その他"
                  name={PROCESS_THEOTHER}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PROCESS_THEOTHER) }}
                  onBeforeInput={() => { showChargerSearchDialog(PROCESS_THEOTHER) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PROCESS_THEOTHER) } }}
                  type="text"
                  value={processTheotherValue || ''}
                  variant="outlined"
                />
              </Grid>


            </Grid>
          </CardContent>

        </Card>
      </div>
    </div>
  );
};

ProcessDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};


const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateBUY_PMAKER,
    updateBUY_PLOT_NUMBER,
    updatePROCESS_SYNTHESIS,
    updatePROCESS_MACHINE,
    updatePROCESS_THEOTHER,

    updateOpenDialog
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    SynthesisArray: state.SynthesisArray,
    settings: state.settings
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProcessDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
