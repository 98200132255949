import React, { useState, useEffect, useRef ,memo} from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { makeStyles } from '@material-ui/styles';
import {
  Grid,

  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,

  FormControl,
  InputLabel,
  MenuItem,
  Select,

  Button,
  Divider,
} from '@material-ui/core';

import {
  updateOpenDialog,
} from "actions/settings";

// テスト中
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';



import {
  PP_TD_DEN,
  PP_TD_SV,
  PP_TD_AR,
  PP_TD_ST,
  PP_TD_TROP,
  PP_TD_ENTHAL,
  PP_TD_CR,
  PP_TD_TE,

  PP_TD_GP_WP,
  PP_TD_GP_MP,
  PP_TD_GP_FTD,
  PP_TD_GP_TPD,
  PP_TD_GP_HD,

  PP_TD_FC_MV_SD,
  PP_TD_FC_MV_MTD,
  PP_TD_FC_MV_PD,
  PP_TD_FC_VC,

  PP_TD_SP_SPC,
  PP_TD_SP_STD,

  PP_TD_TC_TTD,
  PP_TD_TC_DSD,
  PP_TD_TC_CPD,

  PP_TD_TDIF_CMM,
  PP_TD_TDIF_AMO,

  PP_TD_TEMP_TG_GPD,
  PP_TD_TEMP_MLP_MPD,
  PP_TD_TEMP_DSC,
  PP_TD_TEMP_TGA,
  PP_TD_TEMP_WR5,
  PP_TD_TEMP_EMB,
  PP_TD_TEMP_HDT,


  PP_TD_BCHAR_FIT,
  PP_TD_BCHAR_HDC,
  PP_TD_BCHAR_CH,
  PP_TD_BCHAR_OI,
  PP_TD_BCHAR_HRP,


  PP_MS_CRT,
  PP_MS_STRREL,
  PP_MS_CRACK,
  PP_MS_FRAT,
  PP_MS_FATP,
  PP_MS_HARD,

  FRICTION_COEFFICIENT,

  ENZYMATIC_DECOMPOSITION_RATE,
  ENZYMATIC_ENZYMATIC_REACTION,


  BIODEGRADABILITY_CURVE,

  BAR_GRAPH,

  STRUCTURE_XRAY_GRAPH,
  STRUCTURE_MICROSCOPE_IMAGE,
  STRUCTURE_CRYSTALLINITY_DEGREE,



  PP_MS_SSC_TEN_DEGSTRETCH,
  PP_MS_SSC_TEN_DEGELA,
  PP_MS_SSC_TEN_RUPSTR,
  PP_MS_SSC_TEN_STTD,
  PP_MS_SSC_TEN_STPD,
  PP_MS_SSC_TEN_BAIMAGE,
  PP_MS_SSC_BEND_BDSTRETCH,
  PP_MS_SSC_BEND_BDELA,
  PP_MS_SSC_BEND_BRSTR,
  PP_MS_SSC_BEND_BTD,
  PP_MS_SSC_BEND_BPD,
  PP_MS_SSC_BEND_BBAIMAGE,



  PP_MS_VEM_VTD,
  PP_MS_VEM_VPD,
  PP_MS_VEM_GDASH,
  PP_MS_VEM_GDASH2,
  PP_MS_VEM_LTAN,


  PP_MS_SHT_CHAR_CTD,
  PP_MS_SHT_IZOD_ITD,


  BUY_MAKER,
  BUY_LOT_NUMBER,

  BUY_PMAKER,
  BUY_PLOT_NUMBER,
  PROCESS_SYNTHESIS,
  PROCESS_MACHINE,
  PROCESS_THEOTHER,

  //作り方.酵素名
  HOM_ENZYME,

  //作り方.菌体名
  HOM_FUNGUS,

  HOM_PRIZE_CHAIN_ADD_RAD_LRP,
  HOM_PRIZE_CHAIN_ADD_CATI_LCP,
  HOM_PRIZE_CHAIN_ADD_ANIO_LAP,
  HOM_PRIZE_CHAIN_ADD_CRD,
  HOM_PRIZE_CHAIN_ROP_CROP,
  HOM_PRIZE_CHAIN_ROP_AROP,
  HOM_PRIZE_CHAIN_ROP_RROP,
  HOM_PRIZE_CHAIN_ROP_TMCR_ROMP,
  HOM_PRIZE_CHAIN_CGCP,


  HOM_PRIZE_SGP_PCON_NASP,
  HOM_PRIZE_SGP_PCON_ANSP,
  HOM_PRIZE_SGP_PCON_ARNSP,
  HOM_PRIZE_SGP_PCON_ARESP,
  HOM_PRIZE_SGP_PCON_OCP,
  HOM_PRIZE_SGP_PCON_TMCP,
  HOM_PRIZE_SGP_PCON_EP,
  HOM_PRIZE_SGP_PADD_PTDB,
  HOM_PRIZE_SGP_PADD_ROPA,
  HOM_PRIZE_SGP_PADD_CYPA,
  HOM_PRIZE_SGP_PADD_OTPA,
  HOM_PRIZE_SGP_ADCO,


  RCON_PH,
  RCON_RTEMP,
  RCON_OTRC,


  RSLT_RT,
  RSLT_MCR,
  RSLT_RRATE,
  RSLT_CY,
  RSLT_PRATE_TUR,
  RSLT_PRATE_OCC,
  RSLT_PRATE_PDATA,

  BMSYN_REACT,
  BMSYN_REACS,
  BMSYN_YIELD,
  
  BMSYN_PROS_FGTB,
  BMSYN_PROS_CSD,
  BMSYN_PROS_SCON,
  BMSYN_PROS_PDT,

  
  // 計算　望月
  MD_MDP,
  MD_COND_ENS,
  MD_COND_FRC_FTBL,
  MD_COND_ICRD_ICIMAGE,
  MD_COND_ICRD_ICPDB,
  MD_COND_ICRD_ICDCD,
  MD_COND_ICRD_ICPSF,
  MD_COND_OTCOND,
  MD_CRSLT_THDYD_TVST,
  MD_CRSLT_THDYD_PVST,
  MD_CRSLT_THDYD_EVST,
  MD_CRSLT_DYD, 
  QC_QCP ,
  QC_QCBC,
  QC_QCAP,




  // 測定法　望月
  SPY_INF_FTI,
  SPY_NMR,
  SPY_UVI,
  SPY_FLU,
  SPY_RAM,
  SPY_CD,
  SPY_LS,
  SPY_ELL,
  SPY_NSC,
  SPY_XRA_PHO,
  SPY_XRA_DIF,
  SPY_XRA_XS_SAG,
  SPY_XRA_XS_WID,
  CHR_GAS,
  CHR_HPL_GPC,
  CHR_HPL_SEC,
  MIC_AFM,
  MIC_SEM,
  MIC_TEM,
  MIC_OPT,
  MIC_FLM,
  MIC_CLS,
  MIC_SPR,
  MIC_QCM,
  MIC_POL,
  THA_TGA,
  THA_DTA,
  THA_DSC,
  EA,
  MSM,
  GEP,
  CONA,
  STEN,
  SFOR,
  BOD,



// 分解 望月

CHD,
BIOD_PLS_LOC,
BIOD_PLS_BDATE,
BIOD_PLS_BTIME,
BIOD_PLS_TOC,
BIOD_PLS_NITA,
BIOD_PLS_BMASS,
BIOD_PLS_DD_RC_PH,
BIOD_PLS_DD_RC_BTEMP,
BIOD_PLS_DD_RC_BOTH,
BIOD_PLS_DD_BRLT_BRT,
BIOD_PLS_DD_BRLT_DR,
BIOD_PLS_DD_BRLT_DP,
BIOD_PLS_DD_BRLT_DBOD,

} from "constants.js";


import Papa from "papaparse";

import { INPUT_TYPE } from "constants";


// https://medium.com/how-to-react/how-to-parse-or-read-csv-files-in-reactjs-81e8ee4870b0
//CSV preview

// テスト中
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps() {
  const [num, setNum] = useState(0)
  return {
    id: `simple-tab-${num}`,
    'aria-controls': `simple-tabpanel-${num}`,
  };
}



const InputDialog = (props) => {

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
  setValue(newValue);
};


  const { dialogLabel, dialogTitle, saveInput, selectedNode } = props;

  const classes = useStyles();

  const inputFile = useRef(null);

  const selector_openDialog = useSelector((state) => state.settings.openDialog);

  const selector_pp_td_den = useSelector((state) => state.propertyArray.pp_td_den);
  const selector_pp_td_sv = useSelector((state) => state.propertyArray.pp_td_sv);
  const selector_pp_td_ar = useSelector((state) => state.propertyArray.pp_td_ar);
  const selector_pp_td_st = useSelector((state) => state.propertyArray.pp_td_st);
  const selector_pp_td_trop = useSelector((state) => state.propertyArray.pp_td_trop);
  const selector_pp_td_enthal = useSelector((state) => state.propertyArray.pp_td_enthal);
  const selector_pp_td_cr = useSelector((state) => state.propertyArray.pp_td_cr);
  const selector_pp_td_te = useSelector((state) => state.propertyArray.pp_td_te);

  const selector_pp_td_gp_wp = useSelector((state) => state.propertyArray.pp_td_gp_wp);
  const selector_pp_td_gp_mp = useSelector((state) => state.propertyArray.pp_td_gp_mp);
  const selector_pp_td_gp_ftd = useSelector((state) => state.propertyArray.pp_td_gp_ftd);
  const selector_pp_td_gp_tpd = useSelector((state) => state.propertyArray.pp_td_gp_tpd);
  const selector_pp_td_gp_hd = useSelector((state) => state.propertyArray.pp_td_gp_hd);


  const selector_pp_td_fc_mv_sd = useSelector((state) => state.propertyArray.pp_td_fc_mv_sd);
  const selector_pp_td_fc_mv_mtd = useSelector((state) => state.propertyArray.pp_td_fc_mv_mtd);
  const selector_pp_td_fc_mv_pd = useSelector((state) => state.propertyArray.pp_td_fc_mv_pd);
  const selector_pp_td_fc_vc = useSelector((state) => state.propertyArray.pp_td_fc_vc);


  const selector_pp_td_sp_spc = useSelector((state) => state.propertyArray.pp_td_sp_spc);
  const selector_pp_td_sp_std = useSelector((state) => state.propertyArray.pp_td_sp_std);


  const selector_pp_td_tc_ttd = useSelector((state) => state.propertyArray.pp_td_tc_ttd);
  const selector_pp_td_tc_dsd = useSelector((state) => state.propertyArray.pp_td_tc_dsd);
  const selector_pp_td_tc_cpd = useSelector((state) => state.propertyArray.pp_td_tc_cpd);

  const selector_pp_td_tdif_cmm = useSelector((state) => state.propertyArray.pp_td_tdif_cmm);
  const selector_pp_td_tdif_amo = useSelector((state) => state.propertyArray.pp_td_tdif_amo);

  const selector_pp_td_temp_Tg_gpd = useSelector((state) => state.propertyArray.pp_td_temp_Tg_gpd);
  const selector_pp_td_temp_mlp_mpd = useSelector((state) => state.propertyArray.pp_td_temp_mlp_mpd);
  const selector_pp_td_temp_DSC = useSelector((state) => state.propertyArray.pp_td_temp_DSC);
  const selector_pp_td_temp_TGA = useSelector((state) => state.propertyArray.pp_td_temp_TGA);
  const selector_pp_td_temp_wr5 = useSelector((state) => state.propertyArray.pp_td_temp_wr5);
  const selector_pp_td_temp_emb = useSelector((state) => state.propertyArray.pp_td_temp_emb);
  const selector_pp_td_temp_HDT = useSelector((state) => state.propertyArray.pp_td_temp_HDT);


  const selector_pp_td_bchar_FIT = useSelector((state) => state.propertyArray.pp_td_bchar_FIT);
  const selector_pp_td_bchar_hdc = useSelector((state) => state.propertyArray.pp_td_bchar_hdc);
  const selector_pp_td_bchar_ch = useSelector((state) => state.propertyArray.pp_td_bchar_ch);
  const selector_pp_td_bchar_oi = useSelector((state) => state.propertyArray.pp_td_bchar_oi);
  const selector_pp_td_bchar_hrp = useSelector((state) => state.propertyArray.pp_td_bchar_hrp);


  const selector_pp_ms_crt = useSelector((state) => state.mechanicalArray.pp_ms_crt);
  const selector_pp_ms_strrel = useSelector((state) => state.mechanicalArray.pp_ms_strrel);
  const selector_pp_ms_crack = useSelector((state) => state.mechanicalArray.pp_ms_crack);
  const selector_pp_ms_frat = useSelector((state) => state.mechanicalArray.pp_ms_frat);
  const selector_pp_ms_fatp = useSelector((state) => state.mechanicalArray.pp_ms_fatp);
  const selector_pp_ms_hard = useSelector((state) => state.mechanicalArray.pp_ms_hard);

  const selector_friction_coefficient = useSelector((state) => state.propertyArray.friction_coefficient);

  const selector_enzymatic_decomposition_rate = useSelector((state) => state.propertyArray.enzymatic_decomposition_rate);
  const selector_enzymatic_enzymatic_reaction = useSelector((state) => state.propertyArray.enzymatic_enzymatic_reaction);

  const selector_biodegradability_curve = useSelector((state) => state.propertyArray.biodegradability_curve);

  const selector_bar_graph = useSelector((state) => state.propertyArray.bar_graph);

  const selector_structure_xray_graph = useSelector((state) => state.propertyArray.structure_xray_graph);
  const selector_structure_microscope_image = useSelector((state) => state.propertyArray.structure_microscope_image);
  const selector_structure_crystallinity_degree = useSelector((state) => state.propertyArray.structure_crystallinity_degree);



  const selector_pp_ms_ssc_ten_degstretch = useSelector((state) => state.mechanicalArray.pp_ms_ssc_ten_degstretch);
  const selector_pp_ms_ssc_ten_degela = useSelector((state) => state.mechanicalArray.pp_ms_ssc_ten_degela);
  const selector_pp_ms_ssc_ten_rupstr = useSelector((state) => state.mechanicalArray.pp_ms_ssc_ten_rupstr);
  const selector_pp_ms_ssc_ten_sttd = useSelector((state) => state.mechanicalArray.pp_ms_ssc_ten_sttd);
  const selector_pp_ms_ssc_ten_stpd = useSelector((state) => state.mechanicalArray.pp_ms_ssc_ten_stpd);
  const selector_pp_ms_ssc_ten_baimage = useSelector((state) => state.mechanicalArray.pp_ms_ssc_ten_baimage);


  const selector_pp_ms_ssc_bend_bdstretch = useSelector((state) => state.mechanicalArray.pp_ms_ssc_bend_bdstretch);
  const selector_pp_ms_ssc_bend_bdela = useSelector((state) => state.mechanicalArray.pp_ms_ssc_bend_bdela);
  const selector_pp_ms_ssc_bend_brstr = useSelector((state) => state.mechanicalArray.pp_ms_ssc_bend_brstr);
  const selector_pp_ms_ssc_bend_btd = useSelector((state) => state.mechanicalArray.pp_ms_ssc_bend_btd);
  const selector_pp_ms_ssc_bend_bpd = useSelector((state) => state.mechanicalArray.pp_ms_ssc_bend_bpd);
  const selector_pp_ms_ssc_bend_bbaimage = useSelector((state) => state.mechanicalArray.pp_ms_ssc_bend_bbaimage);


  const selector_pp_ms_vem_vtd = useSelector((state) => state.mechanicalArray.pp_ms_vem_vtd);
  const selector_pp_ms_vem_vpd = useSelector((state) => state.mechanicalArray.pp_ms_vem_vpd);
  const selector_pp_ms_vem_gdash = useSelector((state) => state.mechanicalArray.pp_ms_vem_gdash);
  const selector_pp_ms_vem_gdash2 = useSelector((state) => state.mechanicalArray.pp_ms_vem_gdash2);
  const selector_pp_ms_vem_ltan = useSelector((state) => state.mechanicalArray.pp_ms_vem_ltan);

  const selector_pp_ms_sht_Char_ctd = useSelector((state) => state.mechanicalArray.pp_ms_sht_Char_ctd);
  const selector_pp_ms_sht_Izod_itd = useSelector((state) => state.mechanicalArray.pp_ms_sht_Izod_itd);



  const selector_buy_maker = useSelector((state) => state.SynthesisArray.buy_maker);
  const selector_buy_lot_number = useSelector((state) => state.SynthesisArray.buy_lot_number);

  const selector_buy_pmaker = useSelector((state) => state.SynthesisArray.buy_pmaker);
  const selector_buy_plot_number = useSelector((state) => state.SynthesisArray.buy_plot_number);
  const selector_process_synthesis = useSelector((state) => state.SynthesisArray.process_synthesis);
  const selector_process_machine = useSelector((state) => state.SynthesisArray.process_machine);
  const selector_process_theother = useSelector((state) => state.SynthesisArray.process_theother);


  const selector_hom_enzyme = useSelector((state) => state.SynthesisArray.hom_enzyme);
  const selector_hom_fungus = useSelector((state) => state.SynthesisArray.hom_fungus);


  const selector_hom_prize_chain_add_rad_LRP = useSelector((state) => state.SynthesisArray.hom_prize_chain_add_rad_LRP);
  const selector_hom_prize_chain_add_cati_LCP = useSelector((state) => state.SynthesisArray.hom_prize_chain_add_cati_LCP);
  const selector_hom_prize_chain_add_anio_LAP = useSelector((state) => state.SynthesisArray.hom_prize_chain_add_anio_LAP);
  const selector_hom_prize_chain_add_crd = useSelector((state) => state.SynthesisArray.hom_prize_chain_add_crd);
  const selector_hom_prize_chain_rop_CROP = useSelector((state) => state.SynthesisArray.hom_prize_chain_rop_CROP);
  const selector_hom_prize_chain_rop_AROP = useSelector((state) => state.SynthesisArray.hom_prize_chain_rop_AROP);
  const selector_hom_prize_chain_rop_RROP = useSelector((state) => state.SynthesisArray.hom_prize_chain_rop_RROP);
  const selector_hom_prize_chain_rop_TMCR_ROMP = useSelector((state) => state.SynthesisArray.hom_prize_chain_rop_TMCR_ROMP);
  const selector_hom_prize_chain_CGCP = useSelector((state) => state.SynthesisArray.hom_prize_chain_CGCP);


  const selector_hom_prize_SGP_pcon_NASP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_NASP);
  const selector_hom_prize_SGP_pcon_ANSP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_ANSP);
  const selector_hom_prize_SGP_pcon_ARNSP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_ARNSP);
  const selector_hom_prize_SGP_pcon_ARESP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_ARESP);
  const selector_hom_prize_SGP_pcon_OCP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_OCP);
  const selector_hom_prize_SGP_pcon_TMCP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_TMCP);
  const selector_hom_prize_SGP_pcon_EP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_EP);
  const selector_hom_prize_SGP_padd_PTDB = useSelector((state) => state.SynthesisArray.hom_prize_SGP_padd_PTDB);
  const selector_hom_prize_SGP_padd_ROPA = useSelector((state) => state.SynthesisArray.hom_prize_SGP_padd_ROPA);
  const selector_hom_prize_SGP_padd_CYPA = useSelector((state) => state.SynthesisArray.hom_prize_SGP_padd_CYPA);
  const selector_hom_prize_SGP_padd_OTPA = useSelector((state) => state.SynthesisArray.hom_prize_SGP_padd_OTPA);
  const selector_hom_prize_SGP_adco = useSelector((state) => state.SynthesisArray.hom_prize_SGP_adco);


  const selector_rcon_pH = useSelector((state) => state.SynthesisArray.rcon_pH);
  const selector_rcon_rtemp = useSelector((state) => state.SynthesisArray.rcon_rtemp);
  const selector_rcon_OTRC = useSelector((state) => state.SynthesisArray.rcon_OTRC);


  const selector_rslt_RT = useSelector((state) => state.SynthesisArray.rslt_RT);
  const selector_rslt_MCR = useSelector((state) => state.SynthesisArray.rslt_MCR);
  const selector_rslt_rrate = useSelector((state) => state.SynthesisArray.rslt_rrate);
  const selector_rslt_CY = useSelector((state) => state.SynthesisArray.rslt_CY);
  const selector_rslt_prate_tur = useSelector((state) => state.SynthesisArray.rslt_prate_tur);
  const selector_rslt_prate_occ = useSelector((state) => state.SynthesisArray.rslt_prate_occ);
  const selector_rslt_prate_pdata = useSelector((state) => state.SynthesisArray.rslt_prate_pdata);

  const selector_bmsyn_react = useSelector((state) => state.SynthesisArray.bmsyn_react);
  const selector_bmsyn_reacs = useSelector((state) => state.SynthesisArray.bmsyn_reacs);
  const selector_bmsyn_yield = useSelector((state) => state.SynthesisArray.bmsyn_yield);

  const selector_bmsyn_pros_fgtb = useSelector((state) => state.SynthesisArray.bmsyn_pros_fgtb);
  const selector_bmsyn_pros_csd = useSelector((state) => state.SynthesisArray.bmsyn_pros_csd);
  const selector_bmsyn_pros_scon = useSelector((state) => state.SynthesisArray.bmsyn_pros_scon);
  const selector_bmsyn_pros_pdt = useSelector((state) => state.SynthesisArray.bmsyn_pros_pdt);

  // 計算 望月
  const selector_MD_MDp = useSelector((state) => state.calculationArray.MD_MDp);
  const selector_MD_cond_ens = useSelector((state) => state.calculationArray.MD_cond_ens);
  const selector_MD_cond_frc_ftbl = useSelector((state) => state.calculationArray.MD_cond_frc_ftbl);
  const selector_MD_cond_icrd_icimage = useSelector((state) => state.calculationArray.MD_cond_icrd_icimage);
  const selector_MD_cond_icrd_icPDB = useSelector((state) => state.calculationArray.MD_cond_icrd_icPDB);
  const selector_MD_cond_icrd_icDCD = useSelector((state) => state.calculationArray.MD_cond_icrd_icDCD);
  const selector_MD_cond_icrd_icPSF = useSelector((state) => state.calculationArray.MD_cond_icrd_icPSF);
  const selector_MD_cond_otcond = useSelector((state) => state.calculationArray.MD_cond_otcond);
  const selector_MD_crslt_Thdyd_TvsT = useSelector((state) => state.calculationArray.MD_crslt_Thdyd_TvsT);
  const selector_MD_crslt_Thdyd_PvsT = useSelector((state) => state.calculationArray.MD_crslt_Thdyd_PvsT);
  const selector_MD_crslt_Thdyd_EvsT = useSelector((state) => state.calculationArray.MD_crslt_Thdyd_EvsT);
  const selector_MD_crslt_Dyd = useSelector((state) => state.calculationArray.MD_crslt_Dyd);
  const selector_QC_QCp = useSelector((state) => state.calculationArray.QC_QCp);
  const selector_QC_QCbc = useSelector((state) => state.calculationArray.QC_QCbc);
  const selector_QC_QCap = useSelector((state) => state.calculationArray.QC_QCap);

  // 測定法 望月
  const selector_spy_inf_fti = useSelector((state) => state.measurementArray.spy_inf_fti);
  const selector_spy_nmr = useSelector((state) => state.measurementArray.spy_nmr);
  const selector_spy_uvi = useSelector((state) => state.measurementArray.spy_uvi);
  const selector_spy_flu = useSelector((state) => state.measurementArray.spy_flu);
  const selector_spy_ram = useSelector((state) => state.measurementArray.spy_ram);
  const selector_spy_cd = useSelector((state) => state.measurementArray.spy_cd);
  const selector_spy_ls = useSelector((state) => state.measurementArray.spy_ls);
  const selector_spy_ell = useSelector((state) => state.measurementArray.spy_ell);
  const selector_spy_nsc = useSelector((state) => state.measurementArray.spy_nsc);
  const selector_spy_xra_pho = useSelector((state) => state.measurementArray.spy_xra_pho);
  const selector_spy_xra_dif = useSelector((state) => state.measurementArray.spy_xra_dif);
  const selector_spy_xra_xs_sag = useSelector((state) => state.measurementArray.spy_xra_xs_sag);
  const selector_spy_xra_xs_wid = useSelector((state) => state.measurementArray.spy_xra_xs_wid);
  const selector_chr_gas = useSelector((state) => state.measurementArray.chr_gas);
  const selector_chr_hpl_gpc = useSelector((state) => state.measurementArray.chr_hpl_gpc);
  const selector_chr_hpl_sec = useSelector((state) => state.measurementArray.chr_hpl_sec);
  const selector_mic_afm = useSelector((state) => state.measurementArray.mic_afm);
  const selector_mic_sem = useSelector((state) => state.measurementArray.mic_sem);
  const selector_mic_tem = useSelector((state) => state.measurementArray.mic_tem);
  const selector_mic_opt = useSelector((state) => state.measurementArray.mic_opt);
  const selector_mic_flm = useSelector((state) => state.measurementArray.mic_flm);
  const selector_mic_cls = useSelector((state) => state.measurementArray.mic_cls);
  const selector_mic_spr = useSelector((state) => state.measurementArray.mic_spr);
  const selector_mic_qcm = useSelector((state) => state.measurementArray.mic_qcm);
  const selector_mic_pol = useSelector((state) => state.measurementArray.mic_pol);
  const selector_tha_tga = useSelector((state) => state.measurementArray.tha_tga);
  const selector_tha_dta = useSelector((state) => state.measurementArray.tha_dta);
  const selector_tha_dsc = useSelector((state) => state.measurementArray.tha_dsc);
  const selector_ea = useSelector((state) => state.measurementArray.ea);
  const selector_msm = useSelector((state) => state.measurementArray.msm);
  const selector_gep = useSelector((state) => state.measurementArray.gep);
  const selector_cona = useSelector((state) => state.measurementArray.cona);
  const selector_sten = useSelector((state) => state.measurementArray.sten);
  const selector_sfor = useSelector((state) => state.measurementArray.sfor);
  const selector_bod = useSelector((state) => state.measurementArray.bod);

  //分解
  const selector_chd = useSelector((state) => state.degradationArray.chd);
  const selector_biod_pls_loc = useSelector((state) => state.degradationArray.biod_pls_loc);
  const selector_biod_pls_bdate = useSelector((state) => state.degradationArray.biod_pls_bdate);
  const selector_biod_pls_btime = useSelector((state) => state.degradationArray.biod_pls_btime);
  const selector_biod_pls_toc = useSelector((state) => state.degradationArray.biod_pls_toc);
  const selector_biod_pls_nita = useSelector((state) => state.degradationArray.biod_pls_nita);
  const selector_biod_pls_bmass = useSelector((state) => state.degradationArray.biod_pls_bmass);
  const selector_biod_pls_dd_rc_ph = useSelector((state) => state.degradationArray.biod_pls_dd_rc_ph);
  const selector_biod_pls_dd_rc_btemp = useSelector((state) => state.degradationArray.biod_pls_dd_rc_btemp);
  const selector_biod_pls_dd_rc_both = useSelector((state) => state.degradationArray.biod_pls_dd_rc_both);
  const selector_biod_pls_dd_brlt_brt = useSelector((state) => state.degradationArray.biod_pls_dd_brlt_brt);
  const selector_biod_pls_dd_brlt_dr = useSelector((state) => state.degradationArray.biod_pls_dd_brlt_dr);
  const selector_biod_pls_dd_brlt_dp = useSelector((state) => state.degradationArray.biod_pls_dd_brlt_dp);
  const selector_biod_pls_dd_brlt_dbod = useSelector((state) => state.degradationArray.biod_pls_dd_brlt_dbod);


  



  const [inputType, setInputType] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [inputUnit, setInputUnit] = useState('');
  const [inputFactor, setInputFactor] = useState('');
  const [inputComment, setInputComment] = useState('');
  const [isError, setError] = useState(false);
  // const [isCommentError, setCommentError] = useState(false);
  // const [isValueError, setValueError] = useState(false);
  // const [isCheck, setCheck] = useState(false);


  // ファイルの実態
  const [inputImg, setInputImg] = useState('');
  // imageプレビュー用のパス
  const [previewImg, setPreviewImg] = useState('');

  const [inputCSVFile, setInputCSVFile] = useState('');

  const [tableHeader, setTableHeader] = useState([]);
  const [tableValue, setTableValue] = useState([]);


  const [num, setNum] = useState(2)

  function a11yProps() {

    return {
      id: `simple-tab-${num}`,
      'aria-controls': `simple-tabpanel-${num}`,
    };
  }
  

  const showFileDialog = (e) => {
    inputFile.current.click();
  }

  const imageFileDialogChange = (e) => {
    // console.log(e.target.files[0])
    var path = (window.URL || window.webkitURL).createObjectURL(e.target.files[0]);
    // console.log("path: " + path);
    const [file1] = e.target.files;
    // console.log(file1)
    // setFile( file1 );
    // setInputImg(URL.createObjectURL(file1));
    

    setInputImg(file1);
    setPreviewImg(URL.createObjectURL(file1))


    // console.log(file1.originalname);
    // console.log(file1.name);
    setInputValue(file1.name);
  }

  //select file at the dialogbox
  const csvFileDialogChange = (e) => {
    var path = (window.URL || window.webkitURL).createObjectURL(e.target.files[0]);
    // console.log("path: " + path);
    const [file1] = e.target.files;

    setInputValue(file1.name);

    setInputCSVFile(file1);
    // setPreviewCSV(URL.createObjectURL(file1))

    Papa.parse(file1, {
      header: true,
      skipemptyLines: true,
      complete: function (results) {

        const colArr = [];
        const valArr = [];

        results.data.map((d) => {
          colArr.push(Object.keys(d));
          valArr.push(Object.values(d));
        });

        setTableHeader(colArr[0]);
        setTableValue(valArr);
      },
    });
  }


  //parse remote file
  //NOTE: https://www.papaparse.com/docs
  const loadCSVFile = (csvFile) => {
    // console.log('csvFile: ' + csvFile);
    if (typeof csvFile !== 'undefined' && csvFile !== '') {

      //parse remote file
      Papa.parse(csvFile, {
        download: true,
        header: true,
        skipemptyLines: true,
        complete: function (results) {

          // console.log(results);
          const colArr = [];
          const valArr = [];

          results.data.map((d) => {
            colArr.push(Object.keys(d));
            valArr.push(Object.values(d));
          });

          setTableHeader(colArr[0]);
          setTableValue(valArr);
        },
      });
    } else {
      setTableHeader();
      setTableValue();
    }
  }


  const doKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      saveInput(inputType, inputValue, inputUnit, inputFactor, inputComment,
        inputType == INPUT_TYPE.PICTURE ? inputImg : '',
        inputType == INPUT_TYPE.CSV ? inputCSVFile : '',
        inputType == INPUT_TYPE.PICTURE ? previewImg : '',
        );
      closeDialog();
    }
    else if (ev.key === 'Escape') {
      closeDialog();
    }
  }


  const closeDialog = () => {
    props.updateOpenDialog(false);
  }


  const loadValueSub = (selector) => {
    // console.log(selector)
    setInputType(selector.inputType);
    setInputValue(selector.inputValue);
    setInputUnit(selector.inputUnit);
    setInputFactor(selector.inputFactor);
    setInputComment(selector.inputComment);

    setInputImg(selector.inputImage);
    setInputCSVFile(selector.inputCSVFile);
    loadCSVFile(selector.inputCSVFile);

    setPreviewImg(selector.inputPreview);

  }


  const loadValue = (selected) => {
    switch (selected) {
      case PP_TD_DEN:
        loadValueSub(selector_pp_td_den);
        break;

      case PP_TD_SV:
        loadValueSub(selector_pp_td_sv);
        break;

      case PP_TD_AR:
        loadValueSub(selector_pp_td_ar);
        break;

      case PP_TD_ST:
        loadValueSub(selector_pp_td_st);
        break;

      case PP_TD_TROP:
        loadValueSub(selector_pp_td_trop);
        break;

      case PP_TD_ENTHAL:
        loadValueSub(selector_pp_td_enthal);
        break;

      case PP_TD_CR:
        loadValueSub(selector_pp_td_cr);
        break;

      case PP_TD_TE:
        loadValueSub(selector_pp_td_te);
        break;

      // 気体透過性
      case PP_TD_GP_WP:
        loadValueSub(selector_pp_td_gp_wp);
        break;

      case PP_TD_GP_MP:
        loadValueSub(selector_pp_td_gp_mp);
        break;

      case PP_TD_GP_FTD:
        loadValueSub(selector_pp_td_gp_ftd);
        break;

      case PP_TD_GP_TPD:
        loadValueSub(selector_pp_td_gp_tpd);
        break;

      case PP_TD_GP_HD:
        loadValueSub(selector_pp_td_gp_hd);
        break;

      //流動特性
      case PP_TD_FC_MV_SD:
        loadValueSub(selector_pp_td_fc_mv_sd);
        break;

      case PP_TD_FC_MV_MTD:
        loadValueSub(selector_pp_td_fc_mv_mtd);
        break;

      case PP_TD_FC_MV_PD:
        loadValueSub(selector_pp_td_fc_mv_pd);
        break;

      case PP_TD_FC_VC:
        loadValueSub(selector_pp_td_fc_vc);
        break;

      //比熱
      case PP_TD_SP_SPC:
        loadValueSub(selector_pp_td_sp_spc);
        break;

      case PP_TD_SP_STD:
        loadValueSub(selector_pp_td_sp_std);
        break;


      //熱伝導率
      case PP_TD_TC_TTD:
        loadValueSub(selector_pp_td_tc_ttd);
        break;

      case PP_TD_TC_DSD:
        loadValueSub(selector_pp_td_tc_dsd);
        break;

      case PP_TD_TC_CPD:
        loadValueSub(selector_pp_td_tc_cpd);
        break;


      //熱力学量.熱拡散係数
      case PP_TD_TDIF_CMM:
        loadValueSub(selector_pp_td_tdif_cmm);
        break;

      case PP_TD_TDIF_AMO:
        loadValueSub(selector_pp_td_tdif_amo);
        break;


      //熱力学量.各種温度
      case PP_TD_TEMP_TG_GPD:
        loadValueSub(selector_pp_td_temp_Tg_gpd);
        break;

      case PP_TD_TEMP_MLP_MPD:
        loadValueSub(selector_pp_td_temp_mlp_mpd);
        break;

      case PP_TD_TEMP_DSC:
        loadValueSub(selector_pp_td_temp_DSC);
        break;

      case PP_TD_TEMP_TGA:
        loadValueSub(selector_pp_td_temp_TGA);
        break;

      case PP_TD_TEMP_WR5:
        loadValueSub(selector_pp_td_temp_wr5);
        break;

      case PP_TD_TEMP_EMB:
        loadValueSub(selector_pp_td_temp_emb);
        break;

      case PP_TD_TEMP_HDT:
        loadValueSub(selector_pp_td_temp_HDT);
        break;


      //熱力学量.燃焼特性
      case PP_TD_BCHAR_FIT:
        loadValueSub(selector_pp_td_bchar_FIT);
        break;

      case PP_TD_BCHAR_HDC:
        loadValueSub(selector_pp_td_bchar_hdc);
        break;

      case PP_TD_BCHAR_CH:
        loadValueSub(selector_pp_td_bchar_ch);
        break;

      case PP_TD_BCHAR_OI:
        loadValueSub(selector_pp_td_bchar_oi);
        break;

      case PP_TD_BCHAR_HRP:
        loadValueSub(selector_pp_td_bchar_hrp);
        break;


      //物性.機械強度特性
      case PP_MS_CRT:
        loadValueSub(selector_pp_ms_crt);
        break;

      case PP_MS_STRREL:
        loadValueSub(selector_pp_ms_strrel);
        break;

      case PP_MS_CRACK:
        loadValueSub(selector_pp_ms_crack);
        break;

      case PP_MS_FRAT:
        loadValueSub(selector_pp_ms_frat);
        break;

      case PP_MS_FATP:
        loadValueSub(selector_pp_ms_fatp);
        break;

      case PP_MS_HARD:
        loadValueSub(selector_pp_ms_hard);
        break;


      //機械強度特性.応力歪曲線
      case PP_MS_SSC_TEN_DEGSTRETCH:
        loadValueSub(selector_pp_ms_ssc_ten_degstretch);
        break;

      case PP_MS_SSC_TEN_DEGELA:
        loadValueSub(selector_pp_ms_ssc_ten_degela);
        break;

      case PP_MS_SSC_TEN_RUPSTR:
        loadValueSub(selector_pp_ms_ssc_ten_rupstr);
        break;

      case PP_MS_SSC_TEN_STTD:
        loadValueSub(selector_pp_ms_ssc_ten_sttd);
        break;

      case PP_MS_SSC_TEN_STPD:
        loadValueSub(selector_pp_ms_ssc_ten_stpd);
        break;

      case PP_MS_SSC_TEN_BAIMAGE:
        loadValueSub(selector_pp_ms_ssc_ten_baimage);
        break;

      case PP_MS_SSC_BEND_BDSTRETCH:
        loadValueSub(selector_pp_ms_ssc_bend_bdstretch);
        break;

      case PP_MS_SSC_BEND_BDELA:
        loadValueSub(selector_pp_ms_ssc_bend_bdela);
        break;

      case PP_MS_SSC_BEND_BRSTR:
        loadValueSub(selector_pp_ms_ssc_bend_brstr);
        break;

      case PP_MS_SSC_BEND_BTD:
        loadValueSub(selector_pp_ms_ssc_bend_btd);
        break;

      case PP_MS_SSC_BEND_BPD:
        loadValueSub(selector_pp_ms_ssc_bend_bpd);
        break;

      case PP_MS_SSC_BEND_BBAIMAGE:
        loadValueSub(selector_pp_ms_ssc_bend_bbaimage);
        break;


      //固体物性.機械強度特性.粘弾性測定
      case PP_MS_VEM_VTD:
        loadValueSub(selector_pp_ms_vem_vtd);
        break;

      case PP_MS_VEM_VPD:
        loadValueSub(selector_pp_ms_vem_vpd);
        break;

      case PP_MS_VEM_GDASH:
        loadValueSub(selector_pp_ms_vem_gdash);
        break;

      case PP_MS_VEM_GDASH2:
        loadValueSub(selector_pp_ms_vem_gdash2);
        break;


      case PP_MS_VEM_LTAN:
        loadValueSub(selector_pp_ms_vem_ltan);
        break;


      //固体物性.機械強度特性.衝撃試験
      case PP_MS_SHT_CHAR_CTD:
        loadValueSub(selector_pp_ms_sht_Char_ctd);
        break;

      case PP_MS_SHT_IZOD_ITD:
        loadValueSub(selector_pp_ms_sht_Izod_itd);
        break;


      //摩擦係数
      case FRICTION_COEFFICIENT:
        loadValueSub(selector_friction_coefficient);
        break;


      //固体物性.酵素分解
      case ENZYMATIC_DECOMPOSITION_RATE:
        loadValueSub(selector_enzymatic_decomposition_rate);
        break;

      case ENZYMATIC_ENZYMATIC_REACTION:
        loadValueSub(selector_enzymatic_enzymatic_reaction);
        break;


      //固体物性.⽣分解度
      case BIODEGRADABILITY_CURVE:
        loadValueSub(selector_biodegradability_curve);
        break;


      //固体物性.崩壊度
      case BAR_GRAPH:
        loadValueSub(selector_bar_graph);
        break;


      //固体物性.構造
      case STRUCTURE_XRAY_GRAPH:
        loadValueSub(selector_structure_xray_graph);
        break;

      case STRUCTURE_MICROSCOPE_IMAGE:
        loadValueSub(selector_structure_microscope_image);
        break;

      case STRUCTURE_CRYSTALLINITY_DEGREE:
        loadValueSub(selector_structure_crystallinity_degree);
        break;


      //高分子生成.作り方
      case HOM_ENZYME:
        loadValueSub(selector_hom_enzyme);
        break;


      case HOM_FUNGUS:
        loadValueSub(selector_hom_fungus);
        break;


      //高分子生成.作り方.購入
      case BUY_MAKER:
        loadValueSub(selector_buy_maker);
        break;

      case BUY_LOT_NUMBER:
        loadValueSub(selector_buy_lot_number);
        break;


      //高分子生成.作り方.製造プロセス
      case BUY_PMAKER:
        loadValueSub(selector_buy_pmaker);
        break;

      case BUY_PLOT_NUMBER:
        loadValueSub(selector_buy_plot_number);
        break;

      case PROCESS_SYNTHESIS:
        loadValueSub(selector_process_synthesis);
        break;

      case PROCESS_MACHINE:
        loadValueSub(selector_process_machine);
        break;

      case PROCESS_THEOTHER:
        loadValueSub(selector_process_theother);
        break;


      //作り方.重合名.連鎖重合
      case HOM_PRIZE_CHAIN_ADD_RAD_LRP:
        loadValueSub(selector_hom_prize_chain_add_rad_LRP);
        break;

      case HOM_PRIZE_CHAIN_ADD_CATI_LCP:
        loadValueSub(selector_hom_prize_chain_add_cati_LCP);
        break;

      case HOM_PRIZE_CHAIN_ADD_ANIO_LAP:
        loadValueSub(selector_hom_prize_chain_add_anio_LAP);
        break;

      case HOM_PRIZE_CHAIN_ADD_CRD:
        loadValueSub(selector_hom_prize_chain_add_crd);
        break;

      case HOM_PRIZE_CHAIN_ROP_CROP:
        loadValueSub(selector_hom_prize_chain_rop_CROP);
        break;

      case HOM_PRIZE_CHAIN_ROP_AROP:
        loadValueSub(selector_hom_prize_chain_rop_AROP);
        break;

      case HOM_PRIZE_CHAIN_ROP_RROP:
        loadValueSub(selector_hom_prize_chain_rop_RROP);
        break;

      case HOM_PRIZE_CHAIN_ROP_TMCR_ROMP:
        loadValueSub(selector_hom_prize_chain_rop_TMCR_ROMP);
        break;

      case HOM_PRIZE_CHAIN_CGCP:
        loadValueSub(selector_hom_prize_chain_CGCP);
        break;


      //作り方.重合名.逐次重合
      case HOM_PRIZE_SGP_PCON_NASP:
        loadValueSub(selector_hom_prize_SGP_pcon_NASP);
        break;

      case HOM_PRIZE_SGP_PCON_ANSP:
        loadValueSub(selector_hom_prize_SGP_pcon_ANSP);
        break;

      case HOM_PRIZE_SGP_PCON_ARNSP:
        loadValueSub(selector_hom_prize_SGP_pcon_ARNSP);
        break;

      case HOM_PRIZE_SGP_PCON_ARESP:
        loadValueSub(selector_hom_prize_SGP_pcon_ARESP);
        break;

      case HOM_PRIZE_SGP_PCON_OCP:
        loadValueSub(selector_hom_prize_SGP_pcon_OCP);
        break;

      case HOM_PRIZE_SGP_PCON_TMCP:
        loadValueSub(selector_hom_prize_SGP_pcon_TMCP);
        break;

      case HOM_PRIZE_SGP_PCON_EP:
        loadValueSub(selector_hom_prize_SGP_pcon_EP);
        break;

      case HOM_PRIZE_SGP_PADD_PTDB:
        loadValueSub(selector_hom_prize_SGP_padd_PTDB);
        break;

      case HOM_PRIZE_SGP_PADD_ROPA:
        loadValueSub(selector_hom_prize_SGP_padd_ROPA);
        break;

      case HOM_PRIZE_SGP_PADD_CYPA:
        loadValueSub(selector_hom_prize_SGP_padd_CYPA);
        break;

      case HOM_PRIZE_SGP_PADD_OTPA:
        loadValueSub(selector_hom_prize_SGP_padd_OTPA);
        break;

      case HOM_PRIZE_SGP_ADCO:
        loadValueSub(selector_hom_prize_SGP_adco);
        break;


      //高分子生成.反応条件
      case RCON_PH:
        loadValueSub(selector_rcon_pH);
        break;

      case RCON_RTEMP:
        loadValueSub(selector_rcon_rtemp);
        break;

      case RCON_OTRC:
        loadValueSub(selector_rcon_OTRC);
        break;


      //高分子生成.結果
      case RSLT_RT:
        loadValueSub(selector_rslt_RT);
        break;

      case RSLT_MCR:
        loadValueSub(selector_rslt_MCR);
        break;

      case RSLT_RRATE:
        loadValueSub(selector_rslt_rrate);
        break;

      case RSLT_CY:
        loadValueSub(selector_rslt_CY);
        break;

      case RSLT_PRATE_TUR:
        loadValueSub(selector_rslt_prate_tur);
        break;

      case RSLT_PRATE_OCC:
        loadValueSub(selector_rslt_prate_occ);
        break;

      case RSLT_PRATE_PDATA:
        loadValueSub(selector_rslt_prate_pdata);
        break;
      
      // 高分子生成.バイオモノマー生成

      case BMSYN_REACT:
        loadValueSub(selector_bmsyn_react);
        break;
      
      case BMSYN_REACS:
        loadValueSub(selector_bmsyn_reacs);
        break;
      
      case BMSYN_YIELD:
        loadValueSub(selector_bmsyn_yield);
        break;

      // 高分子生成.バイオモノマー生成.生産速度      
      case BMSYN_PROS_FGTB:
        loadValueSub(selector_bmsyn_pros_fgtb);
        break;

      case BMSYN_PROS_CSD:
        loadValueSub(selector_bmsyn_pros_csd);
        break;
      
      
      case BMSYN_PROS_SCON:
        loadValueSub(selector_bmsyn_pros_scon);
        break;

          
      case BMSYN_PROS_PDT:
        loadValueSub(selector_bmsyn_pros_pdt);
        break;

            
      // 計算　望月
      case  MD_MDP:
        loadValueSub(selector_MD_MDp);
        break;
      case  MD_COND_ENS:
        loadValueSub(selector_MD_cond_ens);
        break;
      case  MD_COND_FRC_FTBL:
        loadValueSub(selector_MD_cond_frc_ftbl);
        break;
      case  MD_COND_ICRD_ICIMAGE:
        loadValueSub(selector_MD_cond_icrd_icimage);
        break;
      case  MD_COND_ICRD_ICPDB:
        loadValueSub(selector_MD_cond_icrd_icPDB);
        break;
      case  MD_COND_ICRD_ICDCD:
        loadValueSub(selector_MD_cond_icrd_icDCD);
        break;
      case  MD_COND_ICRD_ICPSF:
        loadValueSub(selector_MD_cond_icrd_icPSF);
        break;
      case  MD_COND_OTCOND:
        loadValueSub(selector_MD_cond_otcond);
        break;
      case MD_CRSLT_THDYD_TVST:
        loadValueSub(selector_MD_crslt_Thdyd_TvsT);
        break;
      case  MD_CRSLT_THDYD_PVST:
        loadValueSub(selector_MD_crslt_Thdyd_PvsT);
        break;
      case  MD_CRSLT_THDYD_EVST:
        loadValueSub(selector_MD_crslt_Thdyd_EvsT);
        break;
      case  MD_CRSLT_DYD:
        loadValueSub(selector_MD_crslt_Dyd);
        break; 
      case  QC_QCP:
        loadValueSub(selector_QC_QCp);
        break; 
      case  QC_QCBC:
        loadValueSub(selector_QC_QCbc);
        break;
      case  QC_QCAP:
        loadValueSub(selector_QC_QCap);
        break;




      // 測定法　望月
      case SPY_INF_FTI:
        loadValueSub(selector_spy_inf_fti);
        break;
      case SPY_NMR:
        loadValueSub(selector_spy_nmr);
        break;
      case SPY_UVI:
        loadValueSub(selector_spy_uvi);
        break;
      case SPY_FLU:
        loadValueSub(selector_spy_flu);
        break;
      case SPY_RAM:
        loadValueSub(selector_spy_ram);
        break;
      case SPY_CD:
        loadValueSub(selector_spy_cd);
        break;
      case SPY_LS:
        loadValueSub(selector_spy_ls);
        break;
      case SPY_ELL:
        loadValueSub(selector_spy_ell);
        break;
      case SPY_NSC:
        loadValueSub(selector_spy_nsc);
        break;
      case SPY_XRA_PHO:
        loadValueSub(selector_spy_xra_pho);
        break;
      case SPY_XRA_DIF:
        loadValueSub(selector_spy_xra_dif);
        break;
      case SPY_XRA_XS_SAG:
        loadValueSub(selector_spy_xra_xs_sag);
        break;
      case SPY_XRA_XS_WID:
        loadValueSub(selector_spy_xra_xs_wid);
        break;
      case CHR_GAS:
        loadValueSub(selector_chr_gas);
        break;
      case CHR_HPL_GPC:
        loadValueSub(selector_chr_hpl_gpc);
        break;
      case CHR_HPL_SEC:
        loadValueSub(selector_chr_hpl_sec);
        break;
      case MIC_AFM:
        loadValueSub(selector_mic_afm);
        break;
      case MIC_SEM:
        loadValueSub(selector_mic_sem);
        break;
      case MIC_TEM:
        loadValueSub(selector_mic_tem);
        break;
      case MIC_OPT:
        loadValueSub(selector_mic_opt);
        break;
      case MIC_FLM:
        loadValueSub(selector_mic_flm);
        break;
      case MIC_CLS:
        loadValueSub(selector_mic_cls);
        break;
      case MIC_SPR:
        loadValueSub(selector_mic_spr);
        break;
      case MIC_QCM:
        loadValueSub(selector_mic_qcm);
        break;
      case MIC_POL:
        loadValueSub(selector_mic_pol);
        break;
      case THA_TGA:
        loadValueSub(selector_tha_tga);
        break;
      case THA_DTA:
        loadValueSub(selector_tha_dta);
        break;
      case THA_DSC:
        loadValueSub(selector_tha_dsc);
        break;
      case EA:
        loadValueSub(selector_ea);
        break;
      case MSM:
        loadValueSub(selector_msm);
        break;
      case GEP:
        loadValueSub(selector_gep);
        break;
      case CONA:
        loadValueSub(selector_cona);
        break;
      case STEN:
        loadValueSub(selector_sten);
        break;
      case SFOR:
        loadValueSub(selector_sfor);
        break;

      case BOD:
        loadValueSub(selector_bod);
        break;



      // 分解 望月

      case CHD:
        loadValueSub(selector_chd);
        break;
      case BIOD_PLS_LOC:
        loadValueSub(selector_biod_pls_loc);
        break;
      case BIOD_PLS_BDATE:
        loadValueSub(selector_biod_pls_bdate);
        break;
      case BIOD_PLS_BTIME:
        loadValueSub(selector_biod_pls_btime);
        break;
      case BIOD_PLS_TOC:
        loadValueSub(selector_biod_pls_toc);
        break;
      case BIOD_PLS_NITA:
        loadValueSub(selector_biod_pls_nita);
        break;
      case BIOD_PLS_BMASS:
        loadValueSub(selector_biod_pls_bmass);
        break;
      case BIOD_PLS_DD_RC_PH:
        loadValueSub(selector_biod_pls_dd_rc_ph);
        break;
      case BIOD_PLS_DD_RC_BTEMP:
        loadValueSub(selector_biod_pls_dd_rc_btemp);
        break;
      case BIOD_PLS_DD_RC_BOTH:
        loadValueSub(selector_biod_pls_dd_rc_both);
        break;
      case BIOD_PLS_DD_BRLT_BRT:
        loadValueSub(selector_biod_pls_dd_brlt_brt);
        break;
      case BIOD_PLS_DD_BRLT_DR:
        loadValueSub(selector_biod_pls_dd_brlt_dr);
        break;
      case BIOD_PLS_DD_BRLT_DP:
        loadValueSub(selector_biod_pls_dd_brlt_dp);
        break;
      case BIOD_PLS_DD_BRLT_DBOD:
        loadValueSub(selector_biod_pls_dd_brlt_dbod);
        break;

    }
  }



  const onInputValueChange = (val) => {
    if (inputType === INPUT_TYPE.NUMBER) {
      var val2 = val.replace(',', '').replace('.', '');
      setError(isNaN(val2));
    }
  }
  const AAA = (val) => {
    console.log(inputValue)
    if (typeof inputValue === 'undefined') {
      setCommentError(true);
    }
  }

  // ダイアログのボタン閉じるか

  // const BBB = (val) => {
  //   console.log(inputValue, inputType, inputComment, val)
  //   if (val.name === 'inputType' && typeof val.value !== 'undefined'){
  //     if (typeof inputValue !== 'undefined' && inputValue !== ''){
  //       setCheck(true);
  //     }
  //   } else if (val.name === 'inputValue' && typeof val.value !== 'undefined' && val.value !== ''){
  //     if (typeof inputType !== 'undefined') {
  //       setCheck(true);
  //     }
  //   }
  // }


  const onChangeType = (val) => {
    if (val === INPUT_TYPE.NUMBER) {
      // console.log("inputValue: "+inputValue);
      // console.log("val: " + val);
      if (typeof inputValue !== 'undefined') {
        var val2 = inputValue.replace(',', '').replace('.', '');
        setError(isNaN(val2));
      }
    } else {
      setError(false);
    }
  }


  useEffect(() => {

    // console.log("isOpen: " + selector_openDialog);
    // console.log("inputValue: " + inputValue);
    // console.log("selectedNode: " + selectedNode);
    // console.log(inputImg);
    // console.log(inputCSVFile);
    loadValue(selectedNode);

  }, [selector_openDialog, selectedNode]);

  // テスト中

  // const tab = () => {
  //   return(
      
  //   )
  // }

  // console.log(tab())

//   const addTab = () => {
//     let tabContainer = document.querySelector('#simple-tab-2');
//     console.log(tabContainer)
//     tabContainer.innerHTML = '<Tab label="Item Three" {...a11yProps(2)} />'
//   }

// console.log([...Array(num)])


  return (
    <div className={classes.root}>


      <Dialog open={selector_openDialog}
        onClose={() => { closeDialog() }}
        fullWidth={true}
        maxWidth="sm">

        <DialogTitle align="center">
          <Typography variant="h4">{dialogLabel}</Typography>
          <Typography variant="body2">- {dialogTitle} -</Typography>
        </DialogTitle>

        <DialogContent>

 {/* テスト中 */}
      {/* <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">

          {[...Array(num)].map((nu, index) => {
            return (<Tab label={index + 1} {...a11yProps(index)} />)
          }
          
          )}
          <button onClick={() => setNum(num+1)}>+</button>
        </Tabs>


      </AppBar>
      <TabPanel value={value} index={0}>
        Item One
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2} >

      </TabPanel> */}


          <Grid container spacing={2}>

          <Grid item md={2} xs={2}></Grid>
            <Grid item md={8} xs={8} style={{textAlign:'center'}}>
              <h5>値のみ、コメントのみの入力はできません。</h5>
            </Grid>
           <Grid item md={2} xs={2}></Grid>
            


            <Grid item md={2} xs={2}></Grid>
            <Grid item md={8} xs={8}>
            

              <FormControl sx={{ m: 1 }} variant="standard" fullWidth>
                <InputLabel variant="outlined" id="demo-simple-select-label-2">タイプ</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name='inputType'
                  value={inputType}
                  label="Kind"
                  onChange={(e) => { setInputType(e.target.value); onChangeType(e.target.value);}}
                  variant="outlined"
                  MenuProps={{ style: { width: 300 } }}
                  autoFocus
                >
                  {/* <MenuItem value=''>{INPUT_TYPE.CHOICE}</MenuItem> */}
                  <MenuItem value={INPUT_TYPE.TEXT}>{INPUT_TYPE.TEXT}</MenuItem>
                  <MenuItem value={INPUT_TYPE.NUMBER}>{INPUT_TYPE.NUMBER}</MenuItem>
                  <MenuItem value={INPUT_TYPE.PICTURE}>{INPUT_TYPE.PICTURE}</MenuItem>
                  <MenuItem value={INPUT_TYPE.CSV}>{INPUT_TYPE.CSV}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2} xs={2}></Grid>


            <Grid item md={2} xs={2}></Grid>
            <Grid item md={8} xs={8}>
              {
                inputType === INPUT_TYPE.PICTURE ?
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                    <Typography variant="body2"> {previewImg || inputValue ? inputValue : '画像を選択してください。'}</Typography>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="file" class="form-control" id="file" multiple accept="image/*" ref={inputFile} style={{ display: 'none' }}
                      onChange={imageFileDialogChange}
                    />
                    <button onClick={showFileDialog}>ファイル選択</button>
                  </div>
                  :
                  inputType === INPUT_TYPE.CSV ?

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                      <Typography variant="body2"> {inputCSVFile ? inputValue : 'CSVを選択してください。'}</Typography>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <input type="file" class="form-control" id="file" multiple accept=".xlsx, .xls, .csv" ref={inputFile} style={{ display: 'none', margin: "10px auto" }}
                        onChange={csvFileDialogChange}
                      />
                      <button onClick={showFileDialog}>ファイル選択</button>
                    </div>
                    :
                    <TextField
                      label="値"
                      name="inputValue"
                      fullWidth
                      type="text"
                      value={inputValue || ''}
                      variant="outlined"
                      error={isError}
                      helperText={isError ? '数値を入力してください。' : ''}
                      onChange={(e) => { setInputValue(e.target.value); onInputValueChange(e.target.value);}}
                      onKeyPress={(ev) => doKeyPress(ev)}
                    />
              }
            </Grid>
            <Grid item md={2} xs={2}></Grid>


            <Grid item md={2} xs={2}></Grid>
            <Grid item md={8} xs={8}>
              <TextField
                label="単位"
                name="inputUnit"
                fullWidth
                type="text"
                value={inputUnit || ''}
                variant="outlined"
                onChange={(e) => setInputUnit(e.target.value)}
                onKeyPress={(ev) => doKeyPress(ev)}
              />
            </Grid>
            <Grid item md={2} xs={2}></Grid>



            <Grid item md={2} xs={2}></Grid>
            <Grid item md={8} xs={8}>
              <TextField
                label="換算係数"
                name="inputFactor"
                fullWidth
                type="text"
                value={inputFactor || ''}
                variant="outlined"
                onChange={(e) => setInputFactor(e.target.value)}
                onKeyPress={(ev) => doKeyPress(ev)}
              />
            </Grid>
            <Grid item md={2} xs={2}></Grid>


            <Grid item md={2} xs={2}></Grid>
            <Grid item md={8} xs={8}>
              <TextField
                label="コメント"
                name="inputComment"
                fullWidth
                type="text"
                value={inputComment || ''}
                variant="outlined"
                onChange={(e) => {setInputComment(e.target.value); }}
                onKeyPress={(ev) => doKeyPress(ev)}
              />
            </Grid>
            <Grid item md={2} xs={2}></Grid>

            <Grid item md={12} xs={12}></Grid>
          </Grid>


          {/* preview */}
          <Divider style={{ marginLeft: 20, marginRight: 20 }} />
          {/* {inputCSVFile} */}
          {inputType === INPUT_TYPE.PICTURE ?
            <Grid container spacing={1}>
              <Grid container item lg={12} md={12} xs={12} justifyContent='center' alignItems="center" spacing={0} style={{ paddingTop: 10 }}>
                <Typography variant="body">{previewImg || inputValue? 'Preview: ' + inputValue : ''}</Typography>
              </Grid>
              <Grid container item lg={12} md={12} xs={12} justifyContent='center' alignItems="center">
                {previewImg && (<img src={previewImg} style={{ height: 200 }} />)}
              </Grid>
            </Grid>
            :
            inputType === INPUT_TYPE.CSV ? (
              <Grid container spacing={1}>
                <Grid container item lg={12} md={12} xs={12} justifyContent='center' alignItems="center" spacing={0} style={{ paddingTop: 10 }}>
                  {/* <Grid item lg={1} md={1} sm={1}></Grid>
                <Grid container item lg={10} md={10} sm={10} justifyContent='center' alignItems="center" style={{backgroundColor:'lightgray'}}> */}
                  <Typography variant="body" >{inputCSVFile ? 'Preview: ' + inputValue : ''}</Typography>
                  {/* <Typography variant="body2" >{inputCSVFile}</Typography> */}
                  {/* </Grid>
                <Grid item lg={1} md={1} sm={1}></Grid> */}
                </Grid>
                <Grid container item lg={12} md={12} xs={12} justifyContent='center' alignItems="center">

                  <table className={classes.table}>
                    <thead style={{ background: "darkgray", color: "white" }}>
                      <tr >
                        {tableHeader && tableHeader.slice(0, 5).map((rows, index) => {
                          return <th key={index} className={classes.th}>{rows}</th>;
                        })}
                      </tr>
                    </thead>

                    <tbody >
                      {tableValue && tableValue.slice(0, 5).map((value, index) => {
                        return (
                          <tr key={index} >
                            {value.slice(0, 5).map((val, i) => {
                              return <td key={i} className={classes.tr}>{val}</td>;
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                </Grid>
              </Grid>
            )
              :
              (<></>)}

        </DialogContent>

        <DialogActions style={{ padding: '20px 0px 20px 0px' }}>

          <Grid container item className={classes.action}
            direction="row" alignItems="center" justifyContent="center">

            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              className={classes.action}
              // disabled={isError}
              onClick={() => {
                saveInput(inputType, inputValue, inputUnit, inputFactor, inputComment,
                  inputType == INPUT_TYPE.PICTURE ? inputImg : '',
                  inputType == INPUT_TYPE.CSV ? inputCSVFile : '',
                  inputType == INPUT_TYPE.PICTURE ? previewImg : '',
                  );
                closeDialog();
              }}
            >
              入力&nbsp;<Typography variant='caption' style={{ color: "inherit" }}>[Enter]</Typography>
            </Button>

            <Grid item md={1} xs={1}></Grid>

            <Button
              variant="outlined"
              size="large"
              onClick={() => closeDialog()}
              className={classes.action}
            >
              閉じる&nbsp;<Typography variant='caption' style={{ color: "inherit" }}>[ESC]</Typography>
            </Button>

            {/* <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              className={classes.action}
              onClick={() => {
                setInputType('');
                setInputValue('');
                setInputUnit('');
                setInputFactor('');
                setInputComment('');
            
                saveInput(inputType, inputValue, inputUnit, inputFactor, inputComment,
                  inputType == INPUT_TYPE.PICTURE ? inputImg : '',
                  inputType == INPUT_TYPE.CSV ? inputCSVFile : '');
                // closeDialog();
              }}
            >
              クリア&nbsp;<Typography variant='caption' style={{ color: "inherit" }}>[Enter]</Typography>
            </Button> */}
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};


const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateOpenDialog,
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    settings: state.settings,
    mechanicalArray: state.mechanicalArray,
    SynthesisArray: state.SynthesisArray,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputDialog);


const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(1),
  },

  table: {
    width: "600px",
    minWidth: "400px",
    maxWidth: "800px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#777",
    borderCollapse: "collapse",
    // padding: "10px",
  },
  th: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#777",
    padding: "4px",
    fontSize: 14,
    align: "center",
  },
  tr: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#777",
    padding: "4px",
    fontSize: 14,
  }
}));