import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { AuthRouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {

  // Settings as SettingsView,
  // SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,


  PropertyMain as PropertyMainView,
  Thermal as ThermalView,
  Gas as GasView,

  FlowCharacteristics as FlowView,
  SpecificHeat as SpecificHeatView,
  ThermalConductibity as ThermalConductibityView,
  ThermalDiffusion as ThermalDiffusionView,
  Tempratures as TempraturesView,
  BurningCharacteristics as BurningCharacteristicsView,

  Mechanical as MechanicalView,
  StressStrainCurve as StressStrainCurveView,
  Viscoelastic as ViscoelasticView,

  ShockTest as ShockTestView,

  FrictionCoefficient as FrictionCoefficientView,

  EnzymaticDecomposition as EnzymaticDecompositionView,

  Biodegradability as BiodegradabilityView,

  Degradation as DegradationView,
  Structure as StructureView,



  SynthesisMain as SynthesisMainView,
  Howtomake as HowtomakeView,
  Buy as BuyView,
  Process as ProcessView,
  PolymerizationChain as PolymerizationChainView,
  PolymerizationStep as PolymerizationStepView,
  ReactionCondition as ReactionConditionView,
  Result as ResultView,
  BiomonomerSynthesis as BiomonomerSynthesisView,
  ProductionSpeed as ProductionSpeedView,

  OverviewMain as OverviewMainView,

  // 20220822 望月追加
  CalculationMain as CalculationMainView,
  MolecularDynamics as MolecularDynamicsView,
  CalculationConditions as CalculationConditionsView,
  CalculationResult as CalculationResultView,
  QuantumChemistryCalculation as QuantumChemistryCalculationView,

  // 20220829 望月追加
  MeasurementMain as MeasurementMainView,
  MeasurementMethod as MeasurementMethodView,
  Spectroscopy as SpectroscopyView,
  InfraredSpectroscopy as InfraredSpectroscopyView,
  Xray as XrayView,
  Chromatography as ChromatographyView,
  Microscopy as MicroscopyView,
  ThermalAnalysis as ThermalAnalysisView,

  // 20220822 望月追加
  DegradationMain as DegradationMainView,
  ChemicalDegradation as ChemicalDegradationView,
  Biodegradation as BiodegradationView,
  DegradationData as DegradationDataView,



} from './views';


import thunkMiddleware from 'redux-thunk'
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './reducers'
import { tempSetUser } from 'actions/user';
import { createHashHistory } from 'history'

export const history = createHashHistory()


const middleware = applyMiddleware(thunkMiddleware)
const store = createStore(
  reducer,
  composeWithDevTools(middleware)
  );



const Routes = (props) => {

  const {className, ...rest } = props;


  return (
    
    <Provider store={store}>

    <Switch>
      <Redirect exact from="/" to="/overview" />

      {/* ⾼分⼦概要  macromolecular overview */}
      <RouteWithLayout component={OverviewMainView} exact layout={MainLayout} path="/overview" />


      {/* macromolecular 固体物性 */}
      <RouteWithLayout component={PropertyMainView} exact layout={MainLayout} path="/property" />
      <RouteWithLayout component={ThermalView} exact layout={MainLayout} path="/property/thermal" />
      <RouteWithLayout component={GasView} exact layout={MainLayout} path="/property/thermal/gas" />
      <RouteWithLayout component={FlowView} exact layout={MainLayout} path="/property/thermal/flowcharacteristics" />
      <RouteWithLayout component={SpecificHeatView} exact layout={MainLayout} path="/property/thermal/specificheat" />
      <RouteWithLayout component={ThermalConductibityView} exact layout={MainLayout} path="/property/thermal/thermalconductibity" />
      <RouteWithLayout component={ThermalDiffusionView} exact layout={MainLayout} path="/property/thermal/thermaldiffusion" />
      <RouteWithLayout component={ThermalDiffusionView} exact layout={MainLayout} path="/property/thermal/thermaldiffusion" />
      <RouteWithLayout component={TempraturesView} exact layout={MainLayout} path="/property/thermal/tempratures" />
      <RouteWithLayout component={BurningCharacteristicsView} exact layout={MainLayout} path="/property/thermal/burningcharacteristics" />


      <RouteWithLayout component={MechanicalView} exact layout={MainLayout} path="/property/mechanical" />
      <RouteWithLayout component={StressStrainCurveView} exact layout={MainLayout} path="/property/mechanical/stressstraincurve" />
      <RouteWithLayout component={ViscoelasticView} exact layout={MainLayout} path="/property/mechanical/viscoelastic" />
      <RouteWithLayout component={ShockTestView} exact layout={MainLayout} path="/property/mechanical/shocktest" />


      <RouteWithLayout component={FrictionCoefficientView} exact layout={MainLayout} path="/property/frictioncoefficient" />
      <RouteWithLayout component={EnzymaticDecompositionView} exact layout={MainLayout} path="/property/enzymaticdecomposition" />
      <RouteWithLayout component={BiodegradabilityView} exact layout={MainLayout} path="/property/biodegradability" />
      <RouteWithLayout component={DegradationView} exact layout={MainLayout} path="/property/degradation" />
      <RouteWithLayout component={StructureView} exact layout={MainLayout} path="/property/structure" />


      {/* ⾼分⼦⽣成  macromolecular synthesis */}
      <RouteWithLayout component={SynthesisMainView} exact layout={MainLayout} path="/synthesis/" />
      <RouteWithLayout component={HowtomakeView} exact layout={MainLayout} path="/synthesis/howtomake" />
      <RouteWithLayout component={BuyView} exact layout={MainLayout} path="/synthesis/howtomake/buy" />
      <RouteWithLayout component={ProcessView} exact layout={MainLayout} path="/synthesis/howtomake/process" />
      <RouteWithLayout component={PolymerizationChainView} exact layout={MainLayout} path="/synthesis/howtomake/polymerization/chain" />
      <RouteWithLayout component={PolymerizationStepView} exact layout={MainLayout} path="/synthesis/howtomake/polymerization/step" />


      <RouteWithLayout component={ReactionConditionView} exact layout={MainLayout} path="/synthesis/reactioncondition" />
      <RouteWithLayout component={ResultView} exact layout={MainLayout} path="/synthesis/result" />

      <RouteWithLayout component={BiomonomerSynthesisView} exact layout={MainLayout} path="/synthesis/biomonomersynthesis" />
      <RouteWithLayout component={ProductionSpeedView} exact layout={MainLayout} path="/synthesis/biomonomersynthesis/productionspeed" />



     {/* 計算 calculation 望月 */}
      <RouteWithLayout component={CalculationMainView} exact layout={MainLayout} path="/calculation/" />
      <RouteWithLayout component={MolecularDynamicsView} exact layout={MainLayout} path="/calculation/moleculardynamics" />
      <RouteWithLayout component={CalculationConditionsView} exact layout={MainLayout} path="/calculation/moleculardynamics/calculationconditions" />
      <RouteWithLayout component={CalculationResultView} exact layout={MainLayout} path="/calculation/moleculardynamics/calculationresult" />

      <RouteWithLayout component={QuantumChemistryCalculationView} exact layout={MainLayout} path="/calculation/quantumchemistrycalculation" />

      {/* 測定法　Measurement 望月 */}
      <RouteWithLayout component={MeasurementMainView} exact layout={MainLayout} path="/measurement" />
      <RouteWithLayout component={MeasurementMethodView} exact layout={MainLayout} path="/measurement/measurementmethod" />
      <RouteWithLayout component={SpectroscopyView} exact layout={MainLayout} path="/measurement/spectroscopy" />
      <RouteWithLayout component={InfraredSpectroscopyView} exact layout={MainLayout} path="/measurement/spectroscopy/infraredspectroscopy" />
      <RouteWithLayout component={XrayView} exact layout={MainLayout} path="/measurement/spectroscopy/xray" />    
      <RouteWithLayout component={ChromatographyView} exact layout={MainLayout} path="/measurement/chromatography" />
      <RouteWithLayout component={MicroscopyView} exact layout={MainLayout} path="/measurement/microscopy" />
      <RouteWithLayout component={ThermalAnalysisView} exact layout={MainLayout} path="/measurement/thermalanalysis" />



      {/* 分解 degradation 望月 */}
      <RouteWithLayout component={DegradationMainView} exact layout={MainLayout} path="/degradation/" />
      <RouteWithLayout component={ChemicalDegradationView} exact layout={MainLayout} path="/degradation/degradation" />
      <RouteWithLayout component={BiodegradationView} exact layout={MainLayout} path="/degradation/biodegradation" />
      <RouteWithLayout component={DegradationDataView} exact layout={MainLayout} path="/degradation/biodegradation/degradationdata" />


      <RouteWithLayout component={NotFoundView} exact layout={MinimalLayout} path="/not-found" />
      <Redirect to="/not-found" />
    </Switch>
    </Provider>
  );
};

export default Routes;
