
import { combineReducers } from 'redux'
import {  connect } from "react-redux";

import {
  LOGOUT,
  UPDATE_ADMIN_ID,
  TEMP_SET_USER
} from 'actions/user';


import {
  CATEGORY,
  OPEN_DIALOG,
  OPEN_TREE_DIALOG,
  OPEN_OVERVIEW_MENU,
  OPEN_PROPERYW_MENU,
  OPEN_SYNTHESIS_MENU,
  OPEN_CALCULATION_MENU,
  OPEN_DEGRADATION_MENU,
  OPEN_MEASUREMENT_MENU,

  // LOADING

} from 'actions/settings';



import {

  UPDATE_DOCID,
  UPDATE_OVID,
  UPDATE_SAMPLE,
  UPDATE_SAMPLEID,
  UPDATE_GROUP,
  UPDATE_INST,
  UPDATE_KIND,
  UPDATE_CHST,
  UPDATE_CHST_IMG,
  UPDATE_EXPP,
  UPDATE_RESPP,
  UPDATE_EXPDATE,
  UPDATE_INPP,
  UPDATE_BMEMO,
} from 'actions/overviewArray';


import {
  //熱力学量
  //熱力学量
  UPDATE_PP_TD_DEN,
  UPDATE_PP_TD_SV,
  UPDATE_PP_TD_AR,
  UPDATE_PP_TD_ST,
  UPDATE_PP_TD_TROP,
  UPDATE_PP_TD_ENTHAL,
  UPDATE_PP_TD_CR,
  UPDATE_PP_TD_TE,

  //気体透過性
  UPDATE_PP_TD_GP_WP,
  UPDATE_PP_TD_GP_MP,
  UPDATE_PP_TD_GP_FTD,
  UPDATE_PP_TD_GP_TPD,
  UPDATE_PP_TD_GP_HD,


  //流動特性
  UPDATE_PP_TD_FC_MV_SD,
  UPDATE_PP_TD_FC_MV_MTD,
  UPDATE_PP_TD_FC_MV_PD,
  UPDATE_PP_TD_FC_VC,


  //比熱
  UPDATE_PP_TD_SP_SPC,
  UPDATE_PP_TD_SP_STD,


  //熱伝導率
  UPDATE_PP_TD_TC_TTD,
  UPDATE_PP_TD_TC_DSD,
  UPDATE_PP_TD_TC_CPD,


  //熱拡散係数
  UPDATE_PP_TD_TDIF_CMM,
  UPDATE_PP_TD_TDIF_AMO,


  //各種温度
  UPDATE_PP_TD_TEMP_TG_GPD,
  UPDATE_PP_TD_TEMP_MLP_MPD,
  UPDATE_PP_TD_TEMP_DSC,
  UPDATE_PP_TD_TEMP_TGA,
  UPDATE_PP_TD_TEMP_WR5,
  UPDATE_PP_TD_TEMP_EMB,
  UPDATE_PP_TD_TEMP_HDT,

  //燃焼特性
  UPDATE_PP_TD_BCHAR_FIT, //引火温度
  UPDATE_PP_TD_BCHAR_HDC, //熱分解曲線
  UPDATE_PP_TD_BCHAR_CH, //燃焼熱
  UPDATE_PP_TD_BCHAR_OI, //酸素指数
  UPDATE_PP_TD_BCHAR_HRP, //耐熱性

  UPDATE_FRICTION_COEFFICIENT,//摩擦係数

  UPDATE_ENZYMATIC_DECOMPOSITION_RATE, //酵素分解.分解率
  UPDATE_ENZYMATIC_ENZYMATIC_REACTION, //酵素分解.酵素反応データ

  UPDATE_BIODEGRADABILITY_CURVE, //⽣分解度.⽣分解度グラフ.曲線データ

  UPDATE_BAR_GRAPH, //崩壊度.重量変化グラフ.棒グラフデータ

  UPDATE_STRUCTURE_XRAY_GRAPH, //構造.X線.グラフ
  UPDATE_STRUCTURE_MICROSCOPE_IMAGE, //構造.顕微鏡法.画像
  UPDATE_STRUCTURE_CRYSTALLINITY_DEGREE, //構造.結晶化度

} from 'actions/propertyArray';



import {
  UPDATE_PP_MS_CRT, //クリープ試験
  UPDATE_PP_MS_STRREL, //Stress relaxation
  UPDATE_PP_MS_CRACK, //クラック
  UPDATE_PP_MS_FRAT, //Fracture toughness
  UPDATE_PP_MS_FATP, //Fatigue properties
  UPDATE_PP_MS_HARD, //硬さ

  // 機械強度特性
  //  - 応⼒歪曲線
  UPDATE_PP_MS_SSC_TEN_DEGSTRETCH, //応⼒歪曲線.引張.延伸率
  UPDATE_PP_MS_SSC_TEN_DEGELA, //応⼒歪曲線.引張.弾性率
  UPDATE_PP_MS_SSC_TEN_RUPSTR, //応⼒歪曲線.引張.破断応力
  UPDATE_PP_MS_SSC_TEN_STTD, //応⼒歪曲線.引張.温度依存性
  UPDATE_PP_MS_SSC_TEN_STPD, //応⼒歪曲線.引張.圧⼒依存性
  UPDATE_PP_MS_SSC_TEN_BAIMAGE, //応⼒歪曲線.引張.破断応力


  UPDATE_PP_MS_SSC_BEND_BDSTRETCH, //応⼒歪曲線.曲げ試験.延伸率
  UPDATE_PP_MS_SSC_BEND_BDELA, //応⼒歪曲線.曲げ試験.弾性率
  UPDATE_PP_MS_SSC_BEND_BRSTR, //応⼒歪曲線.曲げ試験.破断応力
  UPDATE_PP_MS_SSC_BEND_BTD, //応⼒歪曲線.曲げ試験.温度依存性
  UPDATE_PP_MS_SSC_BEND_BPD, //応⼒歪曲線.曲げ試験.圧⼒依存性
  UPDATE_PP_MS_SSC_BEND_BBAIMAGE, //応⼒歪曲線.曲げ試験.破断応力


  //機械強度特性
  //粘弾性測定
  UPDATE_PP_MS_VEM_VTD, //粘弾性測定.温度依存性
  UPDATE_PP_MS_VEM_VPD, //粘弾性測定.圧⼒依存性
  UPDATE_PP_MS_VEM_GDASH, //粘弾性測定.G'
  UPDATE_PP_MS_VEM_GDASH2, //粘弾性測定.G''
  UPDATE_PP_MS_VEM_LTAN, //粘弾性測定.損失正接

  UPDATE_PP_MS_SHT_CHAR_CTD, //衝撃試験.Charpy.温度依存性
  UPDATE_PP_MS_SHT_IZOD_ITD, //衝撃試験.Charpy.温度依存性

} from 'actions/mechanicalArray';



import {

  //作り方

  //作り方.購入
  UPDATE_MAKER,
  UPDATE_LOT_NUMBER,

  //作り方.製造プロセス
  UPDATE_BUY_PMAKER,
  UPDATE_BUY_PLOT_NUMBER,
  UPDATE_PROCESS_SYNTHESIS,
  UPDATE_PROCESS_MACHINE,
  UPDATE_PROCESS_THEOTHER,

  UPDATE_HOM_ENZYME,
  UPDATE_HOM_FUNGUS,


  // 作り方.重合名
  UPDATE_HOM_PRIZE_CHAIN_ADD_RAD_LRP,
  UPDATE_HOM_PRIZE_CHAIN_ADD_CATI_LCP,
  UPDATE_HOM_PRIZE_CHAIN_ADD_ANIO_LAP,
  UPDATE_HOM_PRIZE_CHAIN_ADD_CRD,
  UPDATE_HOM_PRIZE_CHAIN_ROP_CROP,
  UPDATE_HOM_PRIZE_CHAIN_ROP_AROP,
  UPDATE_HOM_PRIZE_CHAIN_ROP_RROP,
  UPDATE_HOM_PRIZE_CHAIN_ROP_TMCR_ROMP,
  UPDATE_HOM_PRIZE_CHAIN_CGCP,


  UPDATE_HOM_PRIZE_SGP_PCON_NASP,
  UPDATE_HOM_PRIZE_SGP_PCON_ANSP,
  UPDATE_HOM_PRIZE_SGP_PCON_ARNSP,
  UPDATE_HOM_PRIZE_SGP_PCON_ARESP,
  UPDATE_HOM_PRIZE_SGP_PCON_OCP,
  UPDATE_HOM_PRIZE_SGP_PCON_TMCP,
  UPDATE_HOM_PRIZE_SGP_PCON_EP,
  UPDATE_HOM_PRIZE_SGP_PADD_PTDB,
  UPDATE_HOM_PRIZE_SGP_PADD_ROPA,
  UPDATE_HOM_PRIZE_SGP_PADD_CYPA,
  UPDATE_HOM_PRIZE_SGP_PADD_OTPA,
  UPDATE_HOM_PRIZE_SGP_ADCO,


  UPDATE_RCON_PH,
  UPDATE_RCON_RTEMP,
  UPDATE_RCON_OTRC,


  //結果.反応時間
  UPDATE_RSLT_RT,
  UPDATE_RSLT_MCR,
  UPDATE_RSLT_RRATE,
  UPDATE_RSLT_CY,
  UPDATE_RSLT_PRATE_TUR,
  UPDATE_RSLT_PRATE_OCC,
  UPDATE_RSLT_PRATE_PDATA,

  // バイオモノマー生成
  UPDATE_BMSYN_REACT,
  UPDATE_BMSYN_REACS,
  UPDATE_BMSYN_YIELD,

  // バイオモノマー生成.生産速度
  UPDATE_BMSYN_PROS_FGTB,
  UPDATE_BMSYN_PROS_CSD,
  UPDATE_BMSYN_PROS_SCON,
  UPDATE_BMSYN_PROS_PDT,

} from 'actions/SynthesisArray';


// 20220822 望月
import {
  UPDATE_CHD,
  UPDATE_BIOD_PLS_LOC,
  UPDATE_BIOD_PLS_BDATE,
  UPDATE_BIOD_PLS_BTIME,
  UPDATE_BIOD_PLS_TOC,
  UPDATE_BIOD_PLS_NITA,
  UPDATE_BIOD_PLS_BMASS,
  UPDATE_BIOD_PLS_DD_RC_PH,
  UPDATE_BIOD_PLS_DD_RC_BTEMP,
  UPDATE_BIOD_PLS_DD_RC_BOTH,
  UPDATE_BIOD_PLS_DD_BRLT_BRT,
  UPDATE_BIOD_PLS_DD_BRLT_DR,
  UPDATE_BIOD_PLS_DD_BRLT_DP,
  UPDATE_BIOD_PLS_DD_BRLT_DBOD,

} from 'actions/degradationArray';


import {
  UPDATE_MD_MDP,
  UPDATE_MD_COND_ENS,

  UPDATE_MD_COND_FRC_FTBL,
  UPDATE_MD_COND_ICRD_ICIMAGE,
  UPDATE_MD_COND_ICRD_ICPDB,
  UPDATE_MD_COND_ICRD_ICDCD,
  UPDATE_MD_COND_ICRD_ICPSF,
  UPDATE_MD_COND_OTCOND,
  UPDATE_MD_CRSLT_THDYD_TVST,
  UPDATE_MD_CRSLT_THDYD_PVST,
  UPDATE_MD_CRSLT_THDYD_EVST,
  UPDATE_MD_CRSLT_DYD,
  UPDATE_QC_QCP,
  UPDATE_QC_QCBC,
  UPDATE_QC_QCAP
} from 'actions/calculationArray'


import {
  UPDATE_SPY_INF_FTI,
  UPDATE_SPY_NMR,
  UPDATE_SPY_UVI,
  UPDATE_SPY_FLU,
  UPDATE_SPY_RAM,
  UPDATE_SPY_CD,
  UPDATE_SPY_LS,
  UPDATE_SPY_ELL,
  UPDATE_SPY_NSC,
  UPDATE_SPY_XRA_PHO,
  UPDATE_SPY_XRA_DIF,
  UPDATE_SPY_XRA_XS_SAG,
  UPDATE_SPY_XRA_XS_WID,
  UPDATE_CHR_GAS,
  UPDATE_CHR_HPL_GPC,
  UPDATE_CHR_HPL_SEC,
  UPDATE_MIC_AFM,
  UPDATE_MIC_SEM,
  UPDATE_MIC_TEM,
  UPDATE_MIC_OPT,
  UPDATE_MIC_FLM,
  UPDATE_MIC_CLS,
  UPDATE_MIC_SPR,
  UPDATE_MIC_QCM,
  UPDATE_MIC_POL,
  UPDATE_THA_TGA,
  UPDATE_THA_DTA,
  UPDATE_THA_DSC,
  UPDATE_EA,
  UPDATE_MSM,
  UPDATE_GEP,
  UPDATE_CONA,
  UPDATE_STEN,
  UPDATE_SFOR,
  UPDATE_BOD

} from 'actions/measurementArray'


const initSynthesisArray = {
  //作り方.購入
  buy_maker: {},
  buy_lot_number: {},

  //作り方.製造プロセス
  buy_pmaker: {},
  buy_plot_number: {},
  process_synthesis: {},
  process_machine: {},
  process_theother: {},

  hom_enzyme: {},
  hom_fungus: {},

  // 作り方.重合名
  hom_prize_chain_add_rad_LRP: {},
  hom_prize_chain_add_cati_LCP: {},
  hom_prize_chain_add_anio_LAP: {},
  hom_prize_chain_add_crd: {},
  hom_prize_chain_rop_CROP: {},
  hom_prize_chain_rop_AROP: {},
  hom_prize_chain_rop_RROP: {},
  hom_prize_chain_rop_TMCR_ROMP: {},
  hom_prize_chain_CGCP: {},


  //重合名.逐次重合.重縮合.求核アシル置換重合
  hom_prize_SGP_pcon_NASP: {},

  //重合名.逐次重合.重縮合.脂肪族求核置換重合
  hom_prize_SGP_pcon_ANSP: {},

  //重合名.逐次重合.重縮合.芳香族求核置換重合
  hom_prize_SGP_pcon_ARNSP: {},

  //重合名.逐次重合.重縮合.芳香族求電子置換重合
  hom_prize_SGP_pcon_ARESP: {},

  //作り方.重合名.逐次重合.重縮合.酸化カップリング重合
  hom_prize_SGP_pcon_OCP: {},

  //重合名.逐次重合.重縮合.遷移金属触媒重合
  hom_prize_SGP_pcon_TMCP: {},

  //作り方.重合名.逐次重合.重縮合.電解重合
  hom_prize_SGP_pcon_EP: {},

  //作り方.重合名.逐次重合.重付加.二重結合への重付加
  hom_prize_SGP_padd_PTDB: {},

  //作り方.重合名.逐次重合.重付加.開環重付加
  hom_prize_SGP_padd_ROPA: {},

  //作り方.重合名.逐次重合.重付加.環化重付加
  hom_prize_SGP_padd_CYPA: {},

  //作り方.重合名.逐次重合.重付加.その他
  hom_prize_SGP_padd_OTPA: {},

  //作り方.重合名.逐次重合.付加縮合
  hom_prize_SGP_adco: {},


  //反応条件.pH
  rcon_pH: {},

  //反応条件.温度
  rcon_rtemp: {},

  //反応条件.その他
  rcon_OTRC: {},


  //結果.反応時間
  rslt_RT: {},

  //結果.モノマー消費率
  rslt_MCR: {},

  //結果.反応速度
  rslt_rrate: {},

  //結果.収量
  rslt_CY: {},

  //結果.生産速度.菌体濁度
  rslt_prate_tur: {},

  //結果.生産速度.炭素原濃度
  rslt_prate_occ: {},

  //結果.生産速度.生産データ
  rslt_prate_pdata: {},

  // バイオモノマー生成.反応時間
  bmsyn_react: {},
  // バイオモノマー生成.反応速度
  bmsyn_reacs: {},
  // バイオモノマー生成.収量
  bmsyn_yield: {},

  // バイオモノマー生成.生産速度.菌体濁度
  bmsyn_pros_fgtb: {},
  // バイオモノマー生成.生産速度.炭素源濃度
    bmsyn_pros_csd: {},
  // バイオモノマー生成.生産速度.糖消費
  bmsyn_pros_scon: {},
  // バイオモノマー生成.生産速度.生産データ
  bmsyn_pros_pdt: {},



}


const SynthesisArray = (state = initSynthesisArray, action) => {

  switch (action.type) {
    //作り方.購入
    case UPDATE_MAKER:
      return { ...state, buy_maker: action.payload }

    case UPDATE_LOT_NUMBER:
      return { ...state, buy_lot_number: action.payload }

    case UPDATE_BUY_PMAKER:
      return { ...state, buy_pmaker: action.payload }

    case UPDATE_BUY_PLOT_NUMBER:
      return { ...state, buy_plot_number: action.payload }


    case UPDATE_PROCESS_SYNTHESIS:
      return { ...state, process_synthesis: action.payload }

    case UPDATE_PROCESS_MACHINE:
      return { ...state, process_machine: action.payload }

    case UPDATE_PROCESS_THEOTHER:
      return { ...state, process_theother: action.payload }


    case UPDATE_HOM_ENZYME:
      return { ...state, hom_enzyme: action.payload }

    case UPDATE_HOM_FUNGUS:
      return { ...state, hom_fungus: action.payload }


    //重合名.連鎖重合.付加重合.ラジカル重合.リビングラジカル重合
    case UPDATE_HOM_PRIZE_CHAIN_ADD_RAD_LRP:
      return { ...state, hom_prize_chain_add_rad_LRP: action.payload }

    //重合名.連鎖重合.付加重合.カチオン重合.リビングカチオン重合
    case UPDATE_HOM_PRIZE_CHAIN_ADD_CATI_LCP:
      return { ...state, hom_prize_chain_add_cati_LCP: action.payload }

    //重合名.連鎖重合.付加重合.アニオン重合.リビングアニオン重合
    case UPDATE_HOM_PRIZE_CHAIN_ADD_ANIO_LAP:
      return { ...state, hom_prize_chain_add_anio_LAP: action.payload }

    //重合名.連鎖重合.付加重合.配位重合
    case UPDATE_HOM_PRIZE_CHAIN_ADD_CRD:
      return { ...state, hom_prize_chain_add_crd: action.payload }

    //重合名.連鎖重合.開環重合.カチオン開環重合
    case UPDATE_HOM_PRIZE_CHAIN_ROP_CROP:
      return { ...state, hom_prize_chain_rop_CROP: action.payload }

    //重合名.連鎖重合.開環重合.アニオン開環重合
    case UPDATE_HOM_PRIZE_CHAIN_ROP_AROP:
      return { ...state, hom_prize_chain_rop_AROP: action.payload }

    //重合名.連鎖重合.開環重合.ラジカル開環重合
    case UPDATE_HOM_PRIZE_CHAIN_ROP_RROP:
      return { ...state, hom_prize_chain_rop_RROP: action.payload }

    //重合名.連鎖重合.開環重合.遷移金属触媒を用いた開環重合.開環メタセシス重合
    case UPDATE_HOM_PRIZE_CHAIN_ROP_TMCR_ROMP:
      return { ...state, hom_prize_chain_rop_TMCR_ROMP: action.payload }

    //重合名.連鎖重合.連鎖縮合重合
    case UPDATE_HOM_PRIZE_CHAIN_CGCP:
      return { ...state, hom_prize_chain_CGCP: action.payload }



    case UPDATE_HOM_PRIZE_SGP_PCON_NASP:
      return { ...state, hom_prize_SGP_pcon_NASP: action.payload }



    case UPDATE_HOM_PRIZE_SGP_PCON_ANSP:
      return { ...state, hom_prize_SGP_pcon_ANSP: action.payload }


    case UPDATE_HOM_PRIZE_SGP_PCON_ARNSP:
      return { ...state, hom_prize_SGP_pcon_ARNSP: action.payload }

    case UPDATE_HOM_PRIZE_SGP_PCON_ARESP:
      return { ...state, hom_prize_SGP_pcon_ARESP: action.payload }

    case UPDATE_HOM_PRIZE_SGP_PCON_OCP:
      return { ...state, hom_prize_SGP_pcon_OCP: action.payload }

    case UPDATE_HOM_PRIZE_SGP_PCON_TMCP:
      return { ...state, hom_prize_SGP_pcon_TMCP: action.payload }

    case UPDATE_HOM_PRIZE_SGP_PCON_EP:
      return { ...state, hom_prize_SGP_pcon_EP: action.payload }

    case UPDATE_HOM_PRIZE_SGP_PADD_PTDB:
      return { ...state, hom_prize_SGP_padd_PTDB: action.payload }

    case UPDATE_HOM_PRIZE_SGP_PADD_ROPA:
      return { ...state, hom_prize_SGP_padd_ROPA: action.payload }

    case UPDATE_HOM_PRIZE_SGP_PADD_CYPA:
      return { ...state, hom_prize_SGP_padd_CYPA: action.payload }

    case UPDATE_HOM_PRIZE_SGP_PADD_OTPA:
      return { ...state, hom_prize_SGP_padd_OTPA: action.payload }

    case UPDATE_HOM_PRIZE_SGP_ADCO:
      return { ...state, hom_prize_SGP_adco: action.payload }



    case UPDATE_RCON_PH:
      return { ...state, rcon_pH: action.payload }

    case UPDATE_RCON_RTEMP:
      return { ...state, rcon_rtemp: action.payload }

    case UPDATE_RCON_OTRC:
      return { ...state, rcon_OTRC: action.payload }


    //結果.反応時間
    case UPDATE_RSLT_RT:
      return { ...state, rslt_RT: action.payload }

    case UPDATE_RSLT_MCR:
      return { ...state, rslt_MCR: action.payload }

    case UPDATE_RSLT_RRATE:
      return { ...state, rslt_rrate: action.payload }

    case UPDATE_RSLT_CY:
      return { ...state, rslt_CY: action.payload }

    case UPDATE_RSLT_PRATE_TUR:
      return { ...state, rslt_prate_tur: action.payload }


    case UPDATE_RSLT_PRATE_OCC:
      return { ...state, rslt_prate_occ: action.payload }

    case UPDATE_RSLT_PRATE_PDATA:
      return { ...state, rslt_prate_pdata: action.payload }


    // バイオモノマー生成
    case UPDATE_BMSYN_REACT:
      return { ...state, bmsyn_react: action.payload }

    case UPDATE_BMSYN_REACS:
      return { ...state, bmsyn_reacs: action.payload }

    case UPDATE_BMSYN_YIELD:
      return { ...state, bmsyn_yield: action.payload }

    // バイオモノマー生成.生産速度
    case UPDATE_BMSYN_PROS_FGTB:
      return { ...state, bmsyn_pros_fgtb: action.payload }

    case UPDATE_BMSYN_PROS_CSD:
      return { ...state, bmsyn_pros_csd: action.payload }

    case UPDATE_BMSYN_PROS_SCON:
      return { ...state, bmsyn_pros_scon: action.payload }

    case UPDATE_BMSYN_PROS_PDT:
      return { ...state, bmsyn_pros_pdt: action.payload }


    default:
      return state;

  }
}



const initMechanicalArray = {
  //固体物性.機械強度特性
  pp_ms_crt: {}, //クリープ試験
  pp_ms_strrel: {}, //stress relaxation
  pp_ms_crack: {}, //クラック
  pp_ms_frat: {}, //fracture toughness
  pp_ms_fatp: {}, //Fatigue properties
  pp_ms_hard: {}, //硬さ


  //機械強度特性
  //
  //  - 応⼒歪曲線
  //    . 引張
  //        延伸率
  //        弾性率
  //        破断応⼒
  //        温度依存性
  //        圧⼒依存性
  //        試験前後の画像
  pp_ms_ssc_ten_degstretch: {}, //応⼒歪曲線.引張.延伸率
  pp_ms_ssc_ten_degela: {}, //応⼒歪曲線.引張.弾性率
  pp_ms_ssc_ten_rupstr: {}, //応⼒歪曲線.引張.破断応力
  pp_ms_ssc_ten_sttd: {}, //応⼒歪曲線.引張.温度依存性
  pp_ms_ssc_ten_stpd: {}, //応⼒歪曲線.引張.圧⼒依存性
  pp_ms_ssc_ten_baimage: {}, //応⼒歪曲線.引張.試験前後の画像


  pp_ms_ssc_bend_bdstretch: {}, //応⼒歪曲線.曲げ試験.延伸率
  pp_ms_ssc_bend_bdela: {}, //応⼒歪曲線.曲げ試験.弾性率
  pp_ms_ssc_bend_brstr: {}, //応⼒歪曲線.曲げ試験.破断応力
  pp_ms_ssc_bend_btd: {}, //応⼒歪曲線.曲げ試験.温度依存性
  pp_ms_ssc_bend_bpd: {}, //応⼒歪曲線.曲げ試験.圧⼒依存性
  pp_ms_ssc_bend_bbaimage: {},  //応⼒歪曲線.曲げ試験.試験前後の画像


  pp_ms_vem_vtd: {}, //粘弾性測定.温度依存性
  pp_ms_vem_vpd: {}, //粘弾性測定.圧⼒依存性
  pp_ms_vem_gdash: {},  //粘弾性測定.G'
  pp_ms_vem_gdash2: {}, //粘弾性測定.G''
  pp_ms_vem_ltan: {}, //粘弾性測定.損失正接


  //衝撃試験
  pp_ms_sht_Char_ctd: {}, //衝撃試験.Charpy.温度依存性
  pp_ms_sht_Izod_itd: {}, //衝撃試験.Izod.温度依存性

}



const mechanicalArray = (state = initMechanicalArray, action) => {
  switch (action.type) {
    case UPDATE_PP_MS_CRT: //クリープ試験
      return { ...state, pp_ms_crt: action.payload }

    case UPDATE_PP_MS_STRREL: //Stress relaxation
      return { ...state, pp_ms_strrel: action.payload }

    case UPDATE_PP_MS_CRACK: //クラック
      return { ...state, pp_ms_crack: action.payload }

    case UPDATE_PP_MS_FRAT: //Fracture toughness
      return { ...state, pp_ms_frat: action.payload }

    case UPDATE_PP_MS_FATP: //Fatigue properties
      return { ...state, pp_ms_fatp: action.payload }

    case UPDATE_PP_MS_HARD: //硬さ
      return { ...state, pp_ms_hard: action.payload }


    //機械強度特性.応⼒歪曲線.引張
    case UPDATE_PP_MS_SSC_TEN_DEGSTRETCH:
      return { ...state, pp_ms_ssc_ten_degstretch: action.payload }

    case UPDATE_PP_MS_SSC_TEN_DEGELA:
      return { ...state, pp_ms_ssc_ten_degela: action.payload }

    case UPDATE_PP_MS_SSC_TEN_RUPSTR:
      return { ...state, pp_ms_ssc_ten_rupstr: action.payload }

    case UPDATE_PP_MS_SSC_TEN_STTD:
      return { ...state, pp_ms_ssc_ten_sttd: action.payload }

    case UPDATE_PP_MS_SSC_TEN_STPD:
      return { ...state, pp_ms_ssc_ten_stpd: action.payload }

    case UPDATE_PP_MS_SSC_TEN_BAIMAGE:
      return { ...state, pp_ms_ssc_ten_baimage: action.payload }


    //機械強度特性.応⼒歪曲線.曲げ試験
    case UPDATE_PP_MS_SSC_BEND_BDSTRETCH:
      return { ...state, pp_ms_ssc_bend_bdstretch: action.payload }

    case UPDATE_PP_MS_SSC_BEND_BDELA:
      return { ...state, pp_ms_ssc_bend_bdela: action.payload }

    case UPDATE_PP_MS_SSC_BEND_BRSTR:
      return { ...state, pp_ms_ssc_bend_brstr: action.payload }

    case UPDATE_PP_MS_SSC_BEND_BTD:
      return { ...state, pp_ms_ssc_bend_btd: action.payload }

    case UPDATE_PP_MS_SSC_BEND_BPD:
      return { ...state, pp_ms_ssc_bend_bpd: action.payload }

    case UPDATE_PP_MS_SSC_BEND_BBAIMAGE:
      return { ...state, pp_ms_ssc_bend_bbaimage: action.payload }


    case UPDATE_PP_MS_VEM_VTD: //粘弾性測定.温度依存性
      return { ...state, pp_ms_vem_vtd: action.payload }

    case UPDATE_PP_MS_VEM_VPD: //粘弾性測定.圧⼒依存性
      return { ...state, pp_ms_vem_vpd: action.payload }

    case UPDATE_PP_MS_VEM_GDASH: //粘弾性測定.G'
      return { ...state, pp_ms_vem_gdash: action.payload }

    case UPDATE_PP_MS_VEM_GDASH2: //粘弾性測定.G''
      return { ...state, pp_ms_vem_gdash2: action.payload }

    case UPDATE_PP_MS_VEM_LTAN: //粘弾性測定.損失正接
      return { ...state, pp_ms_vem_ltan: action.payload }


    //衝撃試験
    case UPDATE_PP_MS_SHT_CHAR_CTD: //衝撃試験.Charpy.温度依存性
      return { ...state, pp_ms_sht_Char_ctd: action.payload }

    case UPDATE_PP_MS_SHT_IZOD_ITD: //衝撃試験.Charpy.温度依存性
      return { ...state, pp_ms_sht_Izod_itd: action.payload }

    default:
      return state;
  }
}

const initPropertyArray = {

  //熱力学量
  pp_td_den: {},
  pp_td_sv: {},
  pp_td_ar: {},
  pp_td_st: {},
  pp_td_trop: {},
  pp_td_enthal: {},
  pp_td_cr: {},
  pp_td_te: {},

  //気体透過性
  pp_td_gp_wp: {},
  pp_td_gp_mp: {},
  pp_td_gp_ftd: {},
  pp_td_gp_tpd: {},
  pp_td_gp_hd: {},


  //流動特性
  pp_td_fc_mv_sd: {},
  pp_td_fc_mv_mtd: {},
  pp_td_fc_mv_pd: {},
  pp_td_fc_vc: {},


  //比熱
  pp_td_sp_spc: {},
  pp_td_sp_std: {},


  //熱伝導率
  pp_td_tc_ttd: {},
  pp_td_tc_dsd: {},
  pp_td_tc_cpd: {},

  //熱拡散係数
  pp_td_tdif_cmm: {},
  pp_td_tdif_amo: {},


  //各種温度
  pp_td_temp_Tg_gpd: {},
  pp_td_temp_mlp_mpd: {},
  pp_td_temp_DSC: {},
  pp_td_temp_TGA: {},
  pp_td_temp_wr5: {},
  pp_td_temp_emb: {},
  pp_td_temp_HDT: {},


  //燃焼特性
  pp_td_bchar_FIT: {}, //引火温度
  pp_td_bchar_hdc: {}, //熱分解曲線
  pp_td_bchar_ch: {}, //燃焼熱
  pp_td_bchar_oi: {}, //酸素指数
  pp_td_bchar_hrp: {}, //耐熱性


  //固体物性.摩擦係数
  friction_coefficient: {},

  //固体物性.酵素分解
  enzymatic_decomposition_rate: {}, //酵素分解.分解率
  enzymatic_enzymatic_reaction: {}, //酵素分解.酵素反応データ

  //固体物性.生分解度
  biodegradability_curve: {}, //⽣分解度.⽣分解度グラフ.曲線データ

  //固体物性.崩壊度
  bar_graph: {}, //崩壊度.重量変化グラフ.棒グラフデータ

  //固体物性.構造
  structure_xray_graph: {}, //構造.X線.グラフ
  structure_microscope_image: {}, //構造.顕微鏡法.画像
  structure_crystallinity_degree: {}, //構造.結晶化度

}


const propertyArray = (state = initPropertyArray, action) => {

  switch (action.type) {
    case UPDATE_PP_TD_DEN:
      return { ...state, pp_td_den: action.payload }

    case UPDATE_PP_TD_SV:
      return { ...state, pp_td_sv: action.payload }

    case UPDATE_PP_TD_AR:
      return { ...state, pp_td_ar: action.payload }

    case UPDATE_PP_TD_ST:
      return { ...state, pp_td_st: action.payload }

    case UPDATE_PP_TD_TROP:
      return { ...state, pp_td_trop: action.payload }

    case UPDATE_PP_TD_ENTHAL:
      return { ...state, pp_td_enthal: action.payload }

    case UPDATE_PP_TD_CR:
      return { ...state, pp_td_cr: action.payload }

    case UPDATE_PP_TD_TE:
      return { ...state, pp_td_te: action.payload }



    case UPDATE_PP_TD_GP_WP:
      return { ...state, pp_td_gp_wp: action.payload }

    case UPDATE_PP_TD_GP_MP:
      return { ...state, pp_td_gp_mp: action.payload }

    case UPDATE_PP_TD_GP_FTD:
      return { ...state, pp_td_gp_ftd: action.payload }

    case UPDATE_PP_TD_GP_TPD:
      return { ...state, pp_td_gp_tpd: action.payload }

    case UPDATE_PP_TD_GP_HD:
      return { ...state, pp_td_gp_hd: action.payload }



    //流動特性
    case UPDATE_PP_TD_FC_MV_SD:
      return { ...state, pp_td_fc_mv_sd: action.payload }

    case UPDATE_PP_TD_FC_MV_MTD:
      return { ...state, pp_td_fc_mv_mtd: action.payload }

    case UPDATE_PP_TD_FC_MV_PD:
      return { ...state, pp_td_fc_mv_pd: action.payload }

    case UPDATE_PP_TD_FC_VC:
      return { ...state, pp_td_fc_vc: action.payload }


    //比熱
    case UPDATE_PP_TD_SP_SPC:
      return { ...state, pp_td_sp_spc: action.payload }

    case UPDATE_PP_TD_SP_STD:
      return { ...state, pp_td_sp_std: action.payload }


    //熱伝導率
    case UPDATE_PP_TD_TC_TTD:
      return { ...state, pp_td_tc_ttd: action.payload }

    case UPDATE_PP_TD_TC_DSD:
      return { ...state, pp_td_tc_dsd: action.payload }

    case UPDATE_PP_TD_TC_CPD:
      return { ...state, pp_td_tc_cpd: action.payload }


    //熱拡散係数
    case UPDATE_PP_TD_TDIF_CMM:
      return { ...state, pp_td_tdif_cmm: action.payload }

    case UPDATE_PP_TD_TDIF_AMO:
      return { ...state, pp_td_tdif_amo: action.payload }



    //各種温度
    case UPDATE_PP_TD_TEMP_TG_GPD:
      return { ...state, pp_td_temp_Tg_gpd: action.payload }

    case UPDATE_PP_TD_TEMP_MLP_MPD:
      return { ...state, pp_td_temp_mlp_mpd: action.payload }

    case UPDATE_PP_TD_TEMP_DSC:
      return { ...state, pp_td_temp_DSC: action.payload }

    case UPDATE_PP_TD_TEMP_TGA:
      return { ...state, pp_td_temp_TGA: action.payload }

    case UPDATE_PP_TD_TEMP_WR5:
      return { ...state, pp_td_temp_wr5: action.payload }

    case UPDATE_PP_TD_TEMP_EMB:
      return { ...state, pp_td_temp_emb: action.payload }

    case UPDATE_PP_TD_TEMP_HDT:
      return { ...state, pp_td_temp_HDT: action.payload }


    //燃焼特性
    case UPDATE_PP_TD_BCHAR_FIT:
      return { ...state, pp_td_bchar_FIT: action.payload }

    case UPDATE_PP_TD_BCHAR_HDC:
      return { ...state, pp_td_bchar_hdc: action.payload }

    case UPDATE_PP_TD_BCHAR_CH:
      return { ...state, pp_td_bchar_ch: action.payload }

    case UPDATE_PP_TD_BCHAR_OI:
      return { ...state, pp_td_bchar_oi: action.payload }

    case UPDATE_PP_TD_BCHAR_HRP:
      return { ...state, pp_td_bchar_hrp: action.payload }




    case UPDATE_FRICTION_COEFFICIENT://摩擦係数
      return { ...state, friction_coefficient: action.payload }


    //酵素分解.分解率
    case UPDATE_ENZYMATIC_DECOMPOSITION_RATE:
      return { ...state, enzymatic_decomposition_rate: action.payload }


    //酵素分解.酵素反応データ
    case UPDATE_ENZYMATIC_ENZYMATIC_REACTION:
      return { ...state, enzymatic_enzymatic_reaction: action.payload }


    //⽣分解度.⽣分解度グラフ.曲線データ
    case UPDATE_BIODEGRADABILITY_CURVE:
      return { ...state, biodegradability_curve: action.payload }


    //崩壊度.重量変化グラフ.棒グラフデータ
    case UPDATE_BAR_GRAPH:
      return { ...state, bar_graph: action.payload }


    case UPDATE_STRUCTURE_XRAY_GRAPH: //構造.X線.グラフ
      return { ...state, structure_xray_graph: action.payload }

    case UPDATE_STRUCTURE_MICROSCOPE_IMAGE: //構造.顕微鏡法.画像
      return { ...state, structure_microscope_image: action.payload }

    case UPDATE_STRUCTURE_CRYSTALLINITY_DEGREE: //構造.結晶化度
      return { ...state, structure_crystallinity_degree: action.payload }


    default:
      return state;
  }

}

const initOverviewArray = {
  docid: '',
  ovid: '',
  sample: '',
  sampleID: '',
  group: '',
  inst: '',
  kind: '',
  chst: {},
  chst_img: '', // url on localhost
  expp: '',
  respp: '',
  expdate: '',
  inpp: '',
  bmemo: '',
}




const overviewArray = (state = initOverviewArray, action) => {

  switch (action.type) {
    case UPDATE_DOCID:
      return { ...state, docid: action.payload }

    case UPDATE_OVID:
      return { ...state, ovid: action.payload }

    case UPDATE_SAMPLE:
      return { ...state, sample: action.payload }

    case UPDATE_SAMPLEID:
      return { ...state, sampleID: action.payload }

    case UPDATE_GROUP:
      return { ...state, group: action.payload }

    case UPDATE_INST:
      return { ...state, inst: action.payload }

    case UPDATE_CHST_IMG:
      return { ...state, chst_img: action.payload }
    
    case UPDATE_KIND:
      return { ...state, kind: action.payload }

    case UPDATE_CHST:
      return { ...state, chst: action.payload }

    case UPDATE_EXPP:
      return { ...state, expp: action.payload }

    case UPDATE_RESPP:
      return { ...state, respp: action.payload }

    case UPDATE_EXPDATE:
      return { ...state, expdate: action.payload }

    case UPDATE_INPP:
      return { ...state, inpp: action.payload }

    case UPDATE_BMEMO:
      return { ...state, bmemo: action.payload }

    default:
      return state;
  }
}



const initialState = {
  status: '',
  admin_id: '',
  // userEmail: '',
  // password: '',
  // username:'',
  // group:'',
}


const user = (state = initialState, action) => {

  switch (action.type) {
    // case UPDATE_USER_EMAIL:
    //   return {...state, userEmail:action.payload}

    // case UPDATE_USER_NAME:
    //   return {...state, username:action.payload}

    // case UPDATE_PASSWORD:
    //   return {...state, password:action.payload}

    case LOGOUT:
      return action.payload;

    case UPDATE_ADMIN_ID:
      return { ...state, password: action.payload }

    // case UPDATE_GROUP:
    //   return {...state, group: action.payload}

    case TEMP_SET_USER:
      return action.payload;

    default:
      return state;
  }
}



const initSettings = {
  category: '',
  openDialog: false,
  openTreeDialog: false,
  openOverviewMenu: true,
  openProperywMenu: true,
  openSynthesisMenu: true,
  openCalculationMenu: true,
  openDegradationMenu: true,
  openMeasurementMenu: true,

  loading: false,

}

const settings = (state = initSettings, action) => {

  switch (action.type) {
    case CATEGORY:
      return { ...state, category: action.payload }

    case OPEN_DIALOG:
      return { ...state, openDialog: action.payload }

    case OPEN_TREE_DIALOG:
      return { ...state, openTreeDialog: action.payload }
  

    case OPEN_OVERVIEW_MENU:
      return { ...state, openOverviewMenu: action.payload }

    case OPEN_PROPERYW_MENU:
      return { ...state, openProperywMenu: action.payload }

    case OPEN_SYNTHESIS_MENU:
      return { ...state, openSynthesisMenu: action.payload }
      
    case OPEN_CALCULATION_MENU:
      return { ...state, openCalculationMenu: action.payload }

    case OPEN_DEGRADATION_MENU:
      return { ...state, openDecompositonMenu: action.payload }
   
    case OPEN_MEASUREMENT_MENU:
      return { ...state, openCalculationMenu: action.payload }
   
    // case LOADING:
    //   return { ...state, loading: action.payload }

    default:
      return state;
  }
}


// 20220822 望月
const initDegradation = {
  chd: {},
  biod_pls_loc: {},
  biod_pls_bdate: {},
  biod_pls_btime: {},
  biod_pls_toc: {},
  biod_pls_nita: {},
  biod_pls_bmass: {},
  biod_pls_dd_rc_ph: {},
  biod_pls_dd_rc_btemp: {},
  biod_pls_dd_rc_both: {},
  biod_pls_dd_brlt_brt: {},
  biod_pls_dd_brlt_dr: {},
  biod_pls_dd_brlt_dp: {},
  biod_pls_dd_brlt_dbod: {},

}



const degradationArray = (state = initDegradation, action) => {
  switch (action.type) {
    case UPDATE_CHD:
      return { ...state, chd: action.payload }

    case UPDATE_BIOD_PLS_LOC:
      return { ...state, biod_pls_loc: action.payload }

    case UPDATE_BIOD_PLS_BDATE:
      return { ...state, biod_pls_bdate: action.payload }

    case UPDATE_BIOD_PLS_BTIME:
      return { ...state, biod_pls_btime: action.payload }

    case UPDATE_BIOD_PLS_TOC:
      return { ...state, biod_pls_toc: action.payload }

    case UPDATE_BIOD_PLS_NITA:
      return { ...state, biod_pls_nita: action.payload }

    case UPDATE_BIOD_PLS_BMASS:
      return { ...state, biod_pls_bmass: action.payload }

    case UPDATE_BIOD_PLS_DD_RC_PH:
      return { ...state, biod_pls_dd_rc_ph: action.payload }

    case UPDATE_BIOD_PLS_DD_RC_BTEMP:
      return { ...state, biod_pls_dd_rc_btemp: action.payload }

    case UPDATE_BIOD_PLS_DD_RC_BOTH:
      return { ...state, biod_pls_dd_rc_both: action.payload }

    case UPDATE_BIOD_PLS_DD_BRLT_BRT:
      return { ...state, biod_pls_dd_brlt_brt: action.payload }

    case UPDATE_BIOD_PLS_DD_BRLT_DR:
      return { ...state, biod_pls_dd_brlt_dr: action.payload }

    case UPDATE_BIOD_PLS_DD_BRLT_DP:
      return { ...state, biod_pls_dd_brlt_dp: action.payload }

    case UPDATE_BIOD_PLS_DD_BRLT_DBOD:
      return { ...state, biod_pls_dd_brlt_dbod: action.payload }

    default:
      return state;
  }
}


// 計算 望月
const initCalculation = {
  MD_MDp: {},
  MD_cond_ens: {},
  MD_cond_frc_ftbl: {},
  MD_cond_icrd_icimage: {},
  MD_cond_icrd_icPDB: {},
  MD_cond_icrd_icDCD: {},
  MD_cond_icrd_icPSF: {},
  MD_cond_otcond: {},
  MD_crslt_Thdyd_TvsT: {},
  MD_crslt_Thdyd_PvsT: {},
  MD_crslt_Thdyd_EvsT: {},
  MD_crslt_Dyd: {},
  QC_QCp: {},
  QC_QCbc: {},
  QC_QCap: {},

}




const calculationArray = (state = initCalculation, action) => {
  switch (action.type) {
    case UPDATE_MD_MDP:
      return { ...state, MD_MDp: action.payload }

    case UPDATE_MD_COND_ENS:
      return { ...state, MD_cond_ens: action.payload }
    case UPDATE_MD_COND_FRC_FTBL:
      return { ...state, MD_cond_frc_ftbl: action.payload }
    case UPDATE_MD_COND_ICRD_ICIMAGE:
      return { ...state, MD_cond_icrd_icimage: action.payload }
    case UPDATE_MD_COND_ICRD_ICPDB:
      return { ...state, MD_cond_icrd_icPDB: action.payload }
    case UPDATE_MD_COND_ICRD_ICDCD:
      return { ...state, MD_cond_icrd_icDCD: action.payload }
    
    case UPDATE_MD_COND_ICRD_ICPSF:
      return { ...state, MD_cond_icrd_icPSF: action.payload }

    case UPDATE_MD_COND_OTCOND:
      return { ...state, MD_cond_otcond: action.payload }
    
    case UPDATE_MD_CRSLT_THDYD_TVST:
      return { ...state, MD_crslt_Thdyd_TvsT: action.payload }
    
    case UPDATE_MD_CRSLT_THDYD_PVST:
      return { ...state, MD_crslt_Thdyd_PvsT: action.payload }
    
    case UPDATE_MD_CRSLT_THDYD_EVST:
      return { ...state, MD_crslt_Thdyd_EvsT: action.payload }
    
    case UPDATE_MD_CRSLT_DYD:
      return { ...state, MD_crslt_Dyd: action.payload }
    
    case UPDATE_QC_QCP:
      return { ...state, QC_QCp: action.payload }
    
    case UPDATE_QC_QCBC:
      return { ...state, QC_QCbc: action.payload }
    
    case UPDATE_QC_QCAP:
      return { ...state, QC_QCap: action.payload }
    
    default:
      return state;
    
    }
}




// 測定法　望月

const initMeasurement = {
  spy_inf_fti: {},
  spy_nmr: {},
  spy_uvi: {},
  spy_flu: {},
  spy_ram: {},
  spy_cd: {},
  spy_ls: {},
  spy_ell: {},
  spy_nsc: {},
  spy_xra_pho: {},
  spy_xra_dif: {},
  spy_xra_xs_sag: {},
  spy_xra_xs_wid: {},
  chr_gas: {},
  chr_hpl_gpc: {},
  chr_hpl_sec: {},
  mic_afm: {},
  mic_sem: {},
  mic_tem: {},
  mic_opt: {},
  mic_flm: {},
  mic_cls: {},
  mic_spr: {},
  mic_qcm: {},
  mic_pol: {},
  tha_tga: {},
  tha_dta: {},
  tha_dsc: {},
  ea: {},
  msm: {},
  gep: {},
  cona: {},
  sten: {},
  sfor: {},
  bod: {},


}


const measurementArray = (state = initMeasurement, action) => {
  switch (action.type) {
    case UPDATE_SPY_INF_FTI:
      return { ...state, spy_inf_fti: action.payload }

    case UPDATE_SPY_NMR:
      return { ...state, spy_nmr: action.payload }
    
    case UPDATE_SPY_UVI:
      return { ...state, spy_uvi: action.payload }
    
    case UPDATE_SPY_FLU:
      return { ...state, spy_flu: action.payload }
    
    case UPDATE_SPY_RAM:
      return { ...state, spy_ram: action.payload }
    
    case UPDATE_SPY_CD:
      return { ...state, spy_cd: action.payload }
    
    case UPDATE_SPY_LS:
      return { ...state, spy_ls: action.payload }
    
    case UPDATE_SPY_ELL:
      return { ...state, spy_ell: action.payload }
    
    case UPDATE_SPY_NSC:
      return { ...state, spy_nsc: action.payload }
    
    case UPDATE_SPY_XRA_PHO:
      return { ...state, spy_xra_pho: action.payload }
    
    case UPDATE_SPY_XRA_DIF:
      return { ...state, spy_xra_dif: action.payload }
    
    case UPDATE_SPY_XRA_XS_SAG:
      return { ...state, spy_xra_xs_sag: action.payload }
    
    case UPDATE_SPY_XRA_XS_WID:
      return { ...state, spy_xra_xs_wid: action.payload }
    
    case UPDATE_CHR_GAS:
      return { ...state, chr_gas: action.payload }
    
    case UPDATE_CHR_HPL_GPC:
      return { ...state, chr_hpl_gpc: action.payload }
    
    case UPDATE_CHR_HPL_SEC:
      return { ...state, chr_hpl_sec: action.payload }
    
    case UPDATE_MIC_AFM:
      return { ...state, mic_afm: action.payload }
    
    case UPDATE_MIC_SEM:
      return { ...state, mic_sem: action.payload }
    
    case UPDATE_MIC_TEM:
      return { ...state, mic_tem: action.payload }
    
    case UPDATE_MIC_OPT:
      return { ...state, mic_opt: action.payload }
    
    case UPDATE_MIC_FLM:
      return { ...state, mic_flm: action.payload }
    
    case UPDATE_MIC_CLS:
      return { ...state, mic_cls: action.payload }
    
    case UPDATE_MIC_SPR:
      return { ...state, mic_spr: action.payload }
    
    case UPDATE_MIC_QCM:
      return { ...state, mic_qcm: action.payload }
    
    case UPDATE_MIC_POL:
      return { ...state, mic_pol: action.payload }
    
    case UPDATE_THA_TGA:
      return { ...state, tha_tga: action.payload }
    
    case UPDATE_THA_DTA:
      return { ...state, tha_dta: action.payload }
    
    case UPDATE_THA_DSC:
      return { ...state, tha_dsc: action.payload }
    
    case UPDATE_EA:
      return { ...state, ea: action.payload }
    
    case UPDATE_MSM:
      return { ...state, msm: action.payload }
    
    case UPDATE_GEP:
      return { ...state, gep: action.payload }
    
    case UPDATE_CONA:
      return { ...state, cona: action.payload }
    
    case UPDATE_STEN:
      return { ...state, sten: action.payload }
    
    case UPDATE_SFOR:
      return { ...state, sfor: action.payload }
    
    case UPDATE_BOD:
      return { ...state, bod: action.payload }
    
    default:
      return state;
    
    }
}




const rootReducer = combineReducers({
  user,
  settings,
  overviewArray,
  propertyArray,
  mechanicalArray,
  SynthesisArray,

  degradationArray, //20220822 望月
  calculationArray, //20220825 望月
  measurementArray, //20220830 望月

});

export default rootReducer;

