import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updateBMSYN_REACT,
  updateBMSYN_REACS,
  updateBMSYN_YIELD,
} from 'actions/SynthesisArray';


import { makeLabelVal } from "common/util";

import {
  updateOpenDialog,
} from "actions/settings";

import { InputDialog } from "views/InputDialog"

import {
  BMSYN_REACT,
  BMSYN_REACS,
  BMSYN_YIELD
} from "constants.js";


/**
 * 高分子生成.バイオモノマー生成
 */
const BiomonomerSynthesisDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');

  const [bmsyn_reactValue, setBmsyn_reactValue] = useState('');
  const [bmsyn_reacsValue, setBmsyn_reacsValue] = useState('');
  const [bmsyn_yieldValue, setBmsyn_yieldValue] = useState('');


  const selector_bmsyn_react = useSelector((state) => state.SynthesisArray.bmsyn_react);
  const selector_bmsyn_reacs = useSelector((state) => state.SynthesisArray.bmsyn_reacs);
  const selector_bmsyn_yield = useSelector((state) => state.SynthesisArray.bmsyn_yield);

  const selector_openDialog = useSelector((state) => state.settings.openDialog);

  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {
      case BMSYN_REACT:
        setBmsyn_reactValue(makeLabelVal(new_val));
        props.updateBMSYN_REACT(new_val);
        break;

      case BMSYN_REACS:
        setBmsyn_reacsValue(makeLabelVal(new_val));
        props.updateBMSYN_REACS(new_val);
        break;

      case BMSYN_YIELD:
        setBmsyn_yieldValue(makeLabelVal(new_val));
        props.updateBMSYN_YIELD(new_val);
        break;
    }
  }


  const showChargerSearchDialog = (selected) => {

    setSelectedNode(selected);

    switch (selected) {
      case BMSYN_REACT:
        setDialogLabel('反応時間');
        break;

      case BMSYN_REACS:
        setDialogLabel('反応速度');
        break;
      
      case BMSYN_YIELD:
        setDialogLabel('収量');
        break;
    }

    props.updateOpenDialog(true);
  }


  useEffect(() => {
    setBmsyn_reactValue(makeLabelVal(selector_bmsyn_react));
    setBmsyn_reacsValue(makeLabelVal(selector_bmsyn_reacs));
    setBmsyn_yieldValue(makeLabelVal(selector_bmsyn_yield));
  }, []);



  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'バイオモノマー生成'} saveInput={saveInput} selectedNode={selectedNode} />

        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">バイオモノマー生成</Typography>} style={{ backgroundColor: 'lightgray' }} />

          <Divider />

          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6} xs={6}>
                <TextField
                  label="反応時間"
                  name={BMSYN_REACT}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(BMSYN_REACT) }}
                  onBeforeInput={() => { showChargerSearchDialog(BMSYN_REACT) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(BMSYN_REACT) } }}
                  type="text"
                  value={bmsyn_reactValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="反応速度"
                  name={BMSYN_REACS}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(BMSYN_REACS) }}
                  onBeforeInput={() => { showChargerSearchDialog(BMSYN_REACS) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(BMSYN_REACS) } }}
                  type="text"
                  value={bmsyn_reacsValue || ''}
                  variant="outlined"
                />
              </Grid>

              
              <Grid item md={6} xs={6}>
                <TextField
                  label="収量"
                  name={BMSYN_YIELD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(BMSYN_YIELD) }}
                  onBeforeInput={() => { showChargerSearchDialog(BMSYN_YIELD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(BMSYN_YIELD) } }}
                  type="text"
                  value={bmsyn_yieldValue || ''}
                  variant="outlined"
                />
              </Grid>

            </Grid>
          </CardContent>

        </Card>
      </div>
    </div>
  );
};

BiomonomerSynthesisDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({
   updateBMSYN_REACT,
   updateBMSYN_REACS,
   updateBMSYN_YIELD,

    updateOpenDialog
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    SynthesisArray: state.SynthesisArray,
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BiomonomerSynthesisDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
