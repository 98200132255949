import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link as RouterLink, withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { makeLabelVal } from "common/util";


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updatePP_MS_CRT,
  updatePP_MS_STRREL,
  updatePP_MS_CRACK,
  updatePP_MS_FRAT,
  updatePP_MS_FATP,
  updatePP_MS_HARD,

} from 'actions/mechanicalArray';



import {
  PP_MS_CRT,
  PP_MS_STRREL,
  PP_MS_CRACK,
  PP_MS_FRAT,
  PP_MS_FATP,
  PP_MS_HARD
} from "constants.js";

import {
  updateOpenDialog,
} from "actions/settings";

import { InputDialog } from "views/InputDialog"



const MechanicalDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');

  const [pp_ms_crtValue, setPp_ms_crtValue] = useState('');
  const [pp_ms_strrelValue, setPp_ms_strrelValue] = useState('');
  const [pp_ms_crackValue, setPp_ms_crackValue] = useState('');
  const [pp_ms_fratValue, setPp_ms_fratValue] = useState('');
  const [pp_ms_fatpValue, setPp_ms_fatpValue] = useState('');
  const [pp_ms_hardValue, setPp_ms_hardValue] = useState('');


  const selector_pp_ms_crt = useSelector((state) => state.mechanicalArray.pp_ms_crt);
  const selector_pp_ms_strrel = useSelector((state) => state.mechanicalArray.pp_ms_strrel);
  const selector_pp_ms_crack = useSelector((state) => state.mechanicalArray.pp_ms_crack);
  const selector_pp_ms_frat = useSelector((state) => state.mechanicalArray.pp_ms_frat);
  const selector_pp_ms_fatp = useSelector((state) => state.mechanicalArray.pp_ms_fatp);
  const selector_pp_ms_hard = useSelector((state) => state.mechanicalArray.pp_ms_hard);

  const selector_openDialog = useSelector((state) => state.settings.openDialog);


  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
      
      
    };

    switch (selectedNode) {
      case PP_MS_CRT:
        setPp_ms_crtValue(makeLabelVal(new_val));
        props.updatePP_MS_CRT(new_val);
        break;

      case PP_MS_STRREL:
        setPp_ms_strrelValue(makeLabelVal(new_val));
        props.updatePP_MS_STRREL(new_val);
        break;

      case PP_MS_CRACK:
        setPp_ms_crackValue(makeLabelVal(new_val));
        props.updatePP_MS_CRACK(new_val);
        break;

      case PP_MS_FRAT:
        setPp_ms_fratValue(makeLabelVal(new_val));
        props.updatePP_MS_FRAT(new_val);
        break;

      case PP_MS_FATP:
        setPp_ms_fatpValue(makeLabelVal(new_val));
        props.updatePP_MS_FATP(new_val);
        break;

      case PP_MS_HARD:
        setPp_ms_hardValue(makeLabelVal(new_val));
        props.updatePP_MS_HARD(new_val);
        break;
    }
  }


  const showChargerSearchDialog = (selected) => {

    setSelectedNode(selected);

    switch(selected){
      case PP_MS_CRT:
        setDialogLabel('クリープ試験');
        break;

      case PP_MS_STRREL:
        setDialogLabel('stress relaxation');
        break;

      case PP_MS_CRACK:
        setDialogLabel('クラック');
        break;

      case PP_MS_FRAT:
        setDialogLabel('fracture toughness');
        break;

      case PP_MS_FATP:
        setDialogLabel('fatigue properties');
        break;

      case PP_MS_HARD:
        setDialogLabel('硬さ');
        break;
    }
    props.updateOpenDialog(true);
  }


  useEffect(() => {
    setPp_ms_crtValue(makeLabelVal(selector_pp_ms_crt));
    setPp_ms_strrelValue(makeLabelVal(selector_pp_ms_strrel));
    setPp_ms_crackValue(makeLabelVal(selector_pp_ms_crack));
    setPp_ms_fratValue(makeLabelVal(selector_pp_ms_frat));
    setPp_ms_fatpValue(makeLabelVal(selector_pp_ms_fatp));
    setPp_ms_hardValue(makeLabelVal(selector_pp_ms_hard));

  }, []);



  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'機械強度特性'} saveInput={saveInput} selectedNode={selectedNode} />

        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">機械強度特性</Typography>} style={{ backgroundColor: 'lightgray' }}
          />
          <Divider />

          <CardContent>
            <Grid container spacing={2}>

              <Grid item md={6} xs={6}>
                <TextField
                  label="クリープ試験"
                  name={PP_MS_CRT}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_CRT) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_CRT) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_CRT) } }}
                  type="text"
                  value={pp_ms_crtValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="stress relaxation"
                  name={PP_MS_STRREL}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_STRREL) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_STRREL) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_STRREL) } }}
                  type="text"
                  value={pp_ms_strrelValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="クラック"
                  name={PP_MS_CRACK}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_CRACK) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_CRACK) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_CRACK) } }}
                  type="text"
                  value={pp_ms_crackValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="fracture toughness"
                  name={PP_MS_FRAT}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_FRAT) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_FRAT) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_FRAT) } }}
                  type="text"
                  value={pp_ms_fratValue || ''}
                  variant="outlined"
                />
              </Grid>


              <Grid item md={6} xs={6}>
                <TextField
                  label="fatigue properties"
                  name={PP_MS_FATP}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_FATP) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_FATP) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_FATP) } }}
                  type="text"
                  value={pp_ms_fatpValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="硬さ"
                  name={PP_MS_HARD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_HARD) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_HARD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_HARD) } }}
                  type="text"
                  value={pp_ms_hardValue || ''}
                  variant="outlined"
                />
              </Grid>

            </Grid>
          </CardContent>

        </Card>
      </div>
    </div>
  );
};

MechanicalDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updatePP_MS_CRT,
    updatePP_MS_STRREL,
    updatePP_MS_CRACK,
    updatePP_MS_FRAT,
    updatePP_MS_FATP,
    updatePP_MS_HARD,

    updateOpenDialog,
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    mechanicalArray: state.mechanicalArray,
    settings: state.settings
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MechanicalDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
