import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,

  Typography,
} from '@material-ui/core';


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeLabelVal } from "common/util";


import {
  updatePP_TD_TDIF_CMM,
  updatePP_TD_TDIF_AMO,
} from 'actions/propertyArray';


import {
  updateOpenDialog,
} from "actions/settings";

import {
  PP_TD_TDIF_CMM,
  PP_TD_TDIF_AMO,
} from "constants";

import { InputDialog } from "views/InputDialog"



/**
 * 固体物性.熱力学量.熱拡散係数
 * @param {*} props 
 * @returns 
 */
const ThermalDiffusionDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');


  const [selectedNode, setSelectedNode] = useState('');

  //結晶性高分子
  const [pp_td_tdif_cmmValue, setPp_td_tdif_cmmValue] = useState('');
  //非晶性高分子
  const [pp_td_tdif_amoValue, setPp_td_tdif_amoValue] = useState('');


  const selector_pp_td_tdif_cmm = useSelector((state) => state.propertyArray.pp_td_tdif_cmm);
  const selector_pp_td_tdif_amo = useSelector((state) => state.propertyArray.pp_td_tdif_amo);

  const selector_openDialog = useSelector((state) => state.settings.openDialog);


  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {
      case PP_TD_TDIF_CMM:
        setPp_td_tdif_cmmValue(makeLabelVal(new_val));
        props.updatePP_TD_TDIF_CMM(new_val);
        break;

      case PP_TD_TDIF_AMO:
        setPp_td_tdif_amoValue(makeLabelVal(new_val));
        props.updatePP_TD_TDIF_AMO(new_val);
        break;
    }
  }


  const showChargerSearchDialog = (selected) => {

    setSelectedNode(selected);

    switch (selected) {
      case PP_TD_TDIF_CMM:
        setDialogLabel('結晶性高分子');
        break;

      case PP_TD_TDIF_AMO:
        setDialogLabel('非晶性高分子');
        break;
    }


    props.updateOpenDialog(true);
  }


  useEffect(() => {
    setPp_td_tdif_cmmValue(makeLabelVal(selector_pp_td_tdif_cmm));
    setPp_td_tdif_amoValue(makeLabelVal(selector_pp_td_tdif_amo));
  }, []);




  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'熱力学量'} saveInput={saveInput} selectedNode={selectedNode} />

        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">熱拡散係数</Typography>} style={{ backgroundColor: 'lightgray' }}
            subheader={<Typography variant="h5">- 熱力学量 -</Typography>}
          />

          <Divider />

          <CardContent>

            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="結晶性高分子"
                  name={PP_TD_TDIF_CMM}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_TDIF_CMM) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_TDIF_CMM) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_TDIF_CMM) } }}
                  type="text"
                  value={pp_td_tdif_cmmValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="非晶性高分子"
                  name={PP_TD_TDIF_AMO}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_TDIF_AMO) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_TDIF_AMO) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_TDIF_AMO) } }}
                  type="text"
                  value={pp_td_tdif_amoValue || ''}
                  variant="outlined"
                />
              </Grid>
            </Grid>

          </CardContent>
        </Card>
      </div>
    </div>
  );
};

ThermalDiffusionDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updatePP_TD_TDIF_CMM,
    updatePP_TD_TDIF_AMO,

    updateOpenDialog
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    propertyArray: state.propertyArray,
    settings: state.settings
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ThermalDiffusionDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
