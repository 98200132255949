import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updateHOM_PRIZE_SGP_PCON_NASP,
  updateHOM_PRIZE_SGP_PCON_ANSP,
  updateHOM_PRIZE_SGP_PCON_ARNSP,
  updateHOM_PRIZE_SGP_PCON_ARESP,
  updateHOM_PRIZE_SGP_PCON_OCP,
  updateHOM_PRIZE_SGP_PCON_TMCP,
  updateHOM_PRIZE_SGP_PCON_EP,
  updateHOM_PRIZE_SGP_PADD_PTDB,
  updateHOM_PRIZE_SGP_PADD_ROPA,
  updateHOM_PRIZE_SGP_PADD_CYPA,
  updateHOM_PRIZE_SGP_PADD_OTPA,
  updateHOM_PRIZE_SGP_ADCO
} from 'actions/SynthesisArray';


import { makeLabelVal } from "common/util";



import {
  HOM_PRIZE_SGP_PCON_NASP,
  HOM_PRIZE_SGP_PCON_ANSP,
  HOM_PRIZE_SGP_PCON_ARNSP,
  HOM_PRIZE_SGP_PCON_ARESP,
  HOM_PRIZE_SGP_PCON_OCP,
  HOM_PRIZE_SGP_PCON_TMCP,
  HOM_PRIZE_SGP_PCON_EP,
  HOM_PRIZE_SGP_PADD_PTDB,
  HOM_PRIZE_SGP_PADD_ROPA,
  HOM_PRIZE_SGP_PADD_CYPA,
  HOM_PRIZE_SGP_PADD_OTPA,
  HOM_PRIZE_SGP_ADCO

} from "constants.js";

import {
  updateOpenDialog,
} from "actions/settings";

import { InputDialog } from "views/InputDialog"

/**
 * 作り方.重合名.逐次重合
 */
const PolymerizationStepDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');


  const [selectedNode, setSelectedNode] = useState('');

  const [pconNASPValue, setPconNASPValue] = useState('');
  const [pconANSPValue, setPconANSPValue] = useState('');
  const [pconARNSPValue, setPconARNSPValue] = useState('');
  const [pconARESPValue, setPconARESPValue] = useState('');
  const [pconOCPValue, setPconOCPValue] = useState('');
  const [pconTMCPValue, setPconTMCPValue] = useState('');
  const [pconEPValue, setPconEPValue] = useState('');
  const [paddPTDBValue, setPaddPTDBValue] = useState('');
  const [paddROPAValue, setPaddROPAValue] = useState('');
  const [paddCYPAValue, setPaddCYPAValue] = useState('');
  const [paddOTPAValue, setPaddOTPAValue] = useState('');
  const [SGPadcoValue, setSGPadcoValue] = useState('');


  const selector_hom_prize_SGP_pcon_NASP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_NASP);
  const selector_hom_prize_SGP_pcon_ANSP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_ANSP);
  const selector_hom_prize_SGP_pcon_ARNSP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_ARNSP);
  const selector_hom_prize_SGP_pcon_ARESP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_ARESP);
  const selector_hom_prize_SGP_pcon_OCP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_OCP);
  const selector_hom_prize_SGP_pcon_TMCP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_TMCP);
  const selector_hom_prize_SGP_pcon_EP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_EP);
  const selector_hom_prize_SGP_padd_PTDB = useSelector((state) => state.SynthesisArray.hom_prize_SGP_padd_PTDB);
  const selector_hom_prize_SGP_padd_ROPA = useSelector((state) => state.SynthesisArray.hom_prize_SGP_padd_ROPA);
  const selector_hom_prize_SGP_padd_CYPA = useSelector((state) => state.SynthesisArray.hom_prize_SGP_padd_CYPA);
  const selector_hom_prize_SGP_padd_OTPA = useSelector((state) => state.SynthesisArray.hom_prize_SGP_padd_OTPA);
  const selector_hom_prize_SGP_adco = useSelector((state) => state.SynthesisArray.hom_prize_SGP_adco);



  const selector_openDialog = useSelector((state) => state.settings.openDialog);


  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };


    switch (selectedNode) {
      case HOM_PRIZE_SGP_PCON_NASP:
        setPconNASPValue(makeLabelVal(new_val));
        props.updateHOM_PRIZE_SGP_PCON_NASP(new_val);
        break;


      case HOM_PRIZE_SGP_PCON_ANSP:
        setPconANSPValue(makeLabelVal(new_val));
        props.updateHOM_PRIZE_SGP_PCON_ANSP(new_val);
        break;


      case HOM_PRIZE_SGP_PCON_ARNSP:
        setPconARNSPValue(makeLabelVal(new_val));
        props.updateHOM_PRIZE_SGP_PCON_ARNSP(new_val);
        break;

      case HOM_PRIZE_SGP_PCON_ARESP:
        setPconARESPValue(makeLabelVal(new_val));
        props.updateHOM_PRIZE_SGP_PCON_ARESP(new_val);
        break;

      case HOM_PRIZE_SGP_PCON_OCP:
        setPconOCPValue(makeLabelVal(new_val));
        props.updateHOM_PRIZE_SGP_PCON_OCP(new_val);
        break;

      case HOM_PRIZE_SGP_PCON_TMCP:
        setPconTMCPValue(makeLabelVal(new_val));
        props.updateHOM_PRIZE_SGP_PCON_TMCP(new_val);
        break;

      case HOM_PRIZE_SGP_PCON_EP:
        setPconEPValue(makeLabelVal(new_val));
        props.updateHOM_PRIZE_SGP_PCON_EP(new_val);
        break;

      case HOM_PRIZE_SGP_PADD_PTDB:
        setPaddPTDBValue(makeLabelVal(new_val));
        props.updateHOM_PRIZE_SGP_PADD_PTDB(new_val);
        break;

      case HOM_PRIZE_SGP_PADD_ROPA:
        setPaddROPAValue(makeLabelVal(new_val));
        props.updateHOM_PRIZE_SGP_PADD_ROPA(new_val);
        break;

      case HOM_PRIZE_SGP_PADD_CYPA:
        setPaddCYPAValue(makeLabelVal(new_val));
        props.updateHOM_PRIZE_SGP_PADD_CYPA(new_val);
        break;

      case HOM_PRIZE_SGP_PADD_OTPA:
        setPaddOTPAValue(makeLabelVal(new_val));
        props.updateHOM_PRIZE_SGP_PADD_OTPA(new_val);
        break;

      case HOM_PRIZE_SGP_ADCO:
        setSGPadcoValue(makeLabelVal(new_val));
        props.updateHOM_PRIZE_SGP_ADCO(new_val);
        break;
    }

  }


  const showChargerSearchDialog = (selected) => {

    setSelectedNode(selected);

    switch (selected) {
      case HOM_PRIZE_SGP_PCON_NASP:
        setDialogLabel('求核アシル置換重合');
        break;

      case HOM_PRIZE_SGP_PCON_ANSP:
        setDialogLabel('脂肪族求核置換重合');
        break;

      case HOM_PRIZE_SGP_PCON_ARNSP:
        setDialogLabel('芳⾹族求核置換重合');
        break;

      case HOM_PRIZE_SGP_PCON_ARESP:
        setDialogLabel('芳⾹族求電⼦置換重合');
        break;

      case HOM_PRIZE_SGP_PCON_OCP:
        setDialogLabel('酸化カップリング重合');
        break;

      case HOM_PRIZE_SGP_PCON_TMCP:
        setDialogLabel('遷移⾦属触媒重合');
        break;

      case HOM_PRIZE_SGP_PCON_EP:
        setDialogLabel('電解重合');
        break;

      case HOM_PRIZE_SGP_PADD_PTDB:
        setDialogLabel('⼆重結合への重付加');
        break;

      case HOM_PRIZE_SGP_PADD_ROPA:
        setDialogLabel('開環重付加');
        break;

      case HOM_PRIZE_SGP_PADD_CYPA:
        setDialogLabel('環化重付加');
        break;

      case HOM_PRIZE_SGP_PADD_OTPA:
        setDialogLabel('その他');
        break;

      case HOM_PRIZE_SGP_ADCO:
        setDialogLabel('付加縮合');
        break;
    }

    props.updateOpenDialog(true);
  }


  useEffect(() => {
    setPconNASPValue(makeLabelVal(selector_hom_prize_SGP_pcon_NASP));
    setPconANSPValue(makeLabelVal(selector_hom_prize_SGP_pcon_ANSP));
    setPconARNSPValue(makeLabelVal(selector_hom_prize_SGP_pcon_ARNSP));
    setPconARESPValue(makeLabelVal(selector_hom_prize_SGP_pcon_ARESP));
    setPconOCPValue(makeLabelVal(selector_hom_prize_SGP_pcon_OCP));
    setPconTMCPValue(makeLabelVal(selector_hom_prize_SGP_pcon_TMCP));
    setPconEPValue(makeLabelVal(selector_hom_prize_SGP_pcon_EP));
    setPaddPTDBValue(makeLabelVal(selector_hom_prize_SGP_padd_PTDB));
    setPaddROPAValue(makeLabelVal(selector_hom_prize_SGP_padd_ROPA));
    setPaddCYPAValue(makeLabelVal(selector_hom_prize_SGP_padd_CYPA));
    setPaddOTPAValue(makeLabelVal(selector_hom_prize_SGP_padd_OTPA));
    setSGPadcoValue(makeLabelVal(selector_hom_prize_SGP_adco));
  }, []);


  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'重合名'} saveInput={saveInput} selectedNode={selectedNode} />


        <Card className={clsx(classes.root, className)}>
        {/* <Card> */}

          <CardHeader align='center' title={<Typography variant="h4">重合名.逐次重合</Typography>} style={{ backgroundColor: 'lightgray' }}
            subheader={<Typography variant="h5">- 作り方 -</Typography>}
          />

          <Divider />

          <CardContent>

            <Grid item md={12} spacing={0}>
              <Typography variant="h4" >重縮合</Typography>
            </Grid>

            <Divider variant="inset" />

            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>
              <Grid item md={12} spacing={0}>
              </Grid>

            </Grid>

            <Divider variant="inset" />

            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="求核アシル置換重合"
                  name={HOM_PRIZE_SGP_PCON_NASP}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(HOM_PRIZE_SGP_PCON_NASP) }}
                  onBeforeInput={() => { showChargerSearchDialog(HOM_PRIZE_SGP_PCON_NASP) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(HOM_PRIZE_SGP_PCON_NASP) } }}
                  type="text"
                  value={pconNASPValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="脂肪族求核置換重合"
                  name={HOM_PRIZE_SGP_PCON_ANSP}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(HOM_PRIZE_SGP_PCON_ANSP) }}
                  onBeforeInput={() => { showChargerSearchDialog(HOM_PRIZE_SGP_PCON_ANSP) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(HOM_PRIZE_SGP_PCON_ANSP) } }}
                  type="text"
                  value={pconANSPValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="芳⾹族求核置換重合"
                  name={HOM_PRIZE_SGP_PCON_ARNSP}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(HOM_PRIZE_SGP_PCON_ARNSP) }}
                  onBeforeInput={() => { showChargerSearchDialog(HOM_PRIZE_SGP_PCON_ARNSP) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(HOM_PRIZE_SGP_PCON_ARNSP) } }}
                  type="text"
                  value={pconARNSPValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="芳⾹族求電⼦置換重合"
                  name={HOM_PRIZE_SGP_PCON_ARESP}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(HOM_PRIZE_SGP_PCON_ARESP) }}
                  onBeforeInput={() => { showChargerSearchDialog(HOM_PRIZE_SGP_PCON_ARESP) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(HOM_PRIZE_SGP_PCON_ARESP) } }}
                  type="text"
                  value={pconARESPValue || ''}
                  variant="outlined"
                />
              </Grid>


              <Grid item md={6} xs={6}>
                <TextField
                  label="酸化カップリング重合"
                  name={HOM_PRIZE_SGP_PCON_OCP}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(HOM_PRIZE_SGP_PCON_OCP) }}
                  onBeforeInput={() => { showChargerSearchDialog(HOM_PRIZE_SGP_PCON_OCP) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(HOM_PRIZE_SGP_PCON_OCP) } }}
                  type="text"
                  value={pconOCPValue || ''}
                  variant="outlined"
                />
              </Grid>


              <Grid item md={6} xs={6}>
                <TextField
                  label="遷移⾦属触媒重合"
                  name={HOM_PRIZE_SGP_PCON_TMCP}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(HOM_PRIZE_SGP_PCON_TMCP) }}
                  onBeforeInput={() => { showChargerSearchDialog(HOM_PRIZE_SGP_PCON_TMCP) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(HOM_PRIZE_SGP_PCON_TMCP) } }}
                  type="text"
                  value={pconTMCPValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="電解重合"
                  name={HOM_PRIZE_SGP_PCON_EP}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(HOM_PRIZE_SGP_PCON_EP) }}
                  onBeforeInput={() => { showChargerSearchDialog(HOM_PRIZE_SGP_PCON_EP) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(HOM_PRIZE_SGP_PCON_EP) } }}
                  type="text"
                  value={pconEPValue || ''}
                  variant="outlined"
                />
              </Grid>
            </Grid>


            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>
              <Grid item md={12} spacing={0}>
                <Typography variant="h4">重付加</Typography>
              </Grid>

            </Grid>

            <Divider variant="inset" />

            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="⼆重結合への重付加"
                  name={HOM_PRIZE_SGP_PADD_PTDB}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(HOM_PRIZE_SGP_PADD_PTDB, '') }}
                  onBeforeInput={() => { showChargerSearchDialog(HOM_PRIZE_SGP_PADD_PTDB) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(HOM_PRIZE_SGP_PADD_PTDB) } }}
                  type="text"
                  value={paddPTDBValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="開環重付加"
                  name={HOM_PRIZE_SGP_PADD_ROPA}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(HOM_PRIZE_SGP_PADD_ROPA, '') }}
                  onBeforeInput={() => { showChargerSearchDialog(HOM_PRIZE_SGP_PADD_ROPA) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(HOM_PRIZE_SGP_PADD_ROPA) } }}
                  type="text"
                  value={paddROPAValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="環化重付加"
                  name={HOM_PRIZE_SGP_PADD_CYPA}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(HOM_PRIZE_SGP_PADD_CYPA, '') }}
                  onBeforeInput={() => { showChargerSearchDialog(HOM_PRIZE_SGP_PADD_CYPA) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(HOM_PRIZE_SGP_PADD_CYPA) } }}
                  type="text"
                  value={paddCYPAValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="その他"
                  name={HOM_PRIZE_SGP_PADD_OTPA}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(HOM_PRIZE_SGP_PADD_OTPA, '') }}
                  onBeforeInput={() => { showChargerSearchDialog(HOM_PRIZE_SGP_PADD_OTPA) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(HOM_PRIZE_SGP_PADD_OTPA) } }}
                  type="text"
                  value={paddOTPAValue || ''}
                  variant="outlined"
                />
              </Grid>

            </Grid>



            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>
              <Grid item md={12} spacing={0}>
              </Grid>

            </Grid>

            <Divider variant="inset" />

            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="付加縮合"
                  name={HOM_PRIZE_SGP_ADCO}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(HOM_PRIZE_SGP_ADCO, '') }}
                  onBeforeInput={() => { showChargerSearchDialog(HOM_PRIZE_SGP_ADCO) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(HOM_PRIZE_SGP_ADCO) } }}
                  type="text"
                  value={SGPadcoValue || ''}
                  variant="outlined"
                />
              </Grid>

            </Grid>

          </CardContent>

        </Card>
      </div>
    </div>
  );
};

PolymerizationStepDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateHOM_PRIZE_SGP_PCON_NASP,
    updateHOM_PRIZE_SGP_PCON_ANSP,
    updateHOM_PRIZE_SGP_PCON_ARNSP,
    updateHOM_PRIZE_SGP_PCON_ARESP,
    updateHOM_PRIZE_SGP_PCON_OCP,
    updateHOM_PRIZE_SGP_PCON_TMCP,
    updateHOM_PRIZE_SGP_PCON_EP,
    updateHOM_PRIZE_SGP_PADD_PTDB,
    updateHOM_PRIZE_SGP_PADD_ROPA,
    updateHOM_PRIZE_SGP_PADD_CYPA,
    updateHOM_PRIZE_SGP_PADD_OTPA,
    updateHOM_PRIZE_SGP_ADCO,

    updateOpenDialog
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    SynthesisArray: state.SynthesisArray,
    settings: state.settings
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PolymerizationStepDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0)
  },

}));
