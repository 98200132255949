import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';


import moment from 'moment';

import {
  List,
  Box,
  ListItem,
  ListItemText,
  Typography,
  colors,

  Card,
  Divider,

  Button,
  Collapse
} from '@material-ui/core';

import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { makeLabelVal } from "common/util";
import { useHistory } from "react-router-dom";


import {
  updateOverViewMenu,
  updatePropertyViewMenu,
  updateSynthesisMenu,
  updateDegradationMenu,
  updateCalculationMenu,
  updateMeasurementMenu,

  updateCategory
} from 'actions/settings';


import { CATEGORY_LABEL } from 'constants';




const theme = createTheme({
  palette: {
    background: {
      paper: '#fff',
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
      link: colors.blue[600]
    },
    // action: {
    //   active: '#001E3C',
    // },
    // success: {
    //   dark: '#009688',
    // },
    success: {
      contrastText: '#FFFFFF',
      dark: '#009688',
      main: colors.green[600],
      light: colors.green[400]
    },
  },

  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            // borderLeft: `5px solid ${colors.primary}`
            borderLeft: `5px solid #11a8df`
          }
        }
      }
    }
  },



});



const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.white,
    // // backgroundColor: "#1a1a1a",
    // display: 'flex',
    // flexDirection: 'column',
    // height: '100%',
    // padding: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),

    // margin:  theme.spacing(1)
  },
  content: {
    // padding: theme.spacing(1)
    paddingTop: theme.spacing(1),
    // paddingLeft: theme.spacing(1)
  },

  subHeader: {
    // color: 'gray',
    color: 'text.primary',
    fontSize: 15,
    fontWeight: 'bold',
    justifyContent:'flex-start',
    alignItems: 'flex-start',
    display:'flex',
    width:'90%'
    // padding: theme.spacing(0)
  },

  inputLabel: {
    // color: 'text.primary',
    color: 'gray',
    fontSize: 11,
    fontWeight: 'medium',
    // fontWeight: 'medium',
    // padding: theme.spacing(0)
  },
  inputValue: {
    // backgroundColor: theme.palette.white,
    color: '#009688',
    display: 'inline',
    // fontWeight: 'bold',
    // fontWeight: 'light',
    // fontWeight: 'regular',
    fontWeight: 500,
    // mx: 1,
    // pl: 10,
    padding: theme.spacing(1),
    // padding: theme.spacing(2),
    // pl: theme.spacing(10),
    fontSize: 11,
  },


  active: {
    color: theme.palette.primary.main,
    // color: "#000000",
    // backgroundColor: "#b7b7b7",
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
      // color: "#000000"
    }
  },

  button: {
    // color: colors.blueGrey[800],
    color: "#2a3f54",
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },

}));

const InputtedList = props => {
  const { className } = props;
  const history = useHistory();

  const classes = useStyles();


  const selector_openOverviewMenu = useSelector((state) => state.settings.openOverviewMenu);
  const selector_openProperywMenu = useSelector((state) => state.settings.openProperywMenu);
  const selector_openSynthesisMenu = useSelector((state) => state.settings.openSynthesisMenu);
  const selector_openCalculationMenu = useSelector((state) => state.settings.openCalculationMenu);
  const selector_openMeasurementMenu = useSelector((state) => state.settings.openMeasurementMenu);
  const selector_openDegradationMenu = useSelector((state) => state.settings.openDegradationMenu);


  const [overviewOpen, setOverviewOpen] = React.useState(selector_openOverviewMenu);
  const [propertyOpen, setPropertyOpen] = React.useState(selector_openProperywMenu);
  const [synthesisOpen, setSynthesisOpen] = React.useState(selector_openSynthesisMenu);
  const [calculationOpen, setCalculationOpen] = React.useState(selector_openCalculationMenu);
  const [measurementOpen, setMeasurementOpen] = React.useState(selector_openMeasurementMenu);
  const [degradationOpen, setDegradationOpen] = React.useState(selector_openDegradationMenu);


  const listClick = (newurl) => {
    history.push(newurl);
  }


  const handleOpenOverview = () => {
    setOverviewOpen(!overviewOpen);
    props.updateOverViewMenu(!overviewOpen);
  }

  const handleOpenProperty = () => {
    setPropertyOpen(!propertyOpen);
    props.updatePropertyViewMenu(!propertyOpen);
  }

  const handleOpenSynthesis = () => {
    setSynthesisOpen(!synthesisOpen);
    props.updateSynthesisMenu(!synthesisOpen);
  }

  const handleOpenCalculation = () => {
      setCalculationOpen(!calculationOpen);
      props.updateCalculationMenu(!calculationOpen);
    }

  const handleOpenDegradation = () => {
    setDegradationOpen(!degradationOpen);
    props.updateDegradationMenu(!degradationOpen);
  }

  const handleOpenMeasurement = () => {
    setMeasurementOpen(!measurementOpen);
    props.updateMeasurementMenu(!measurementOpen);
  }


  const makePropertyLabel = (selector, label, url, categroy) => {

    if (typeof selector.inputValue !== 'undefined' ) {

      return (
        <React.Fragment>
          <ListItem button>
            <ListItemText
              primary={<Box className={classes.inputLabel}>{label}: <Typography className={classes.inputValue}>{makeLabelVal(selector)}</Typography></Box>}
              onClick={(e) => { listClick(url); props.updateCategory(categroy); }}
            />
          </ListItem>
          <Divider variant='inset' component="li" />
        </React.Fragment>
      );

    } else {
      return;
    }

  }



  const makeOverviewLabel = (selector, label, url) => {

    // var d1 = '2022-08-10T00:16:00.000Z';
    // console.log(d1);
    // console.log(new Date(d1));
    // console.log(moment( new Date(d1) ).format('YYYY-MM-DD'));

    if (selector !== '') {

      if (Object.prototype.toString.call(selector) === '[object Object]' && Object.keys(selector).length === 0) {
        // 化学構造図が{}のとき一覧に表示しない
        return;
      } else if  (selector === null) {
        // 実験日が削除されたとき一覧に表示しない
        return;
      } else if (Object.prototype.toString.call(selector) === '[object Date]' && Number.isNaN(selector.getTime())) {
        // 実験日が　invalid dateのとき一覧に表示しない
        return;
      }

      let display_value = selector;

      // 20221015 テスト中
      if (label === '化学構造図' ) {
        display_value = display_value.inputValue
      }

      if (label === '実験日') {
        display_value = moment(new Date(display_value)).format('YYYY-MM-DD');
      }


      return (
        <React.Fragment>
          <ListItem button>
            <ListItemText
              primary={<Box className={classes.inputLabel}>{label}: <Typography className={classes.inputValue}>{display_value}</Typography></Box>}
              onClick={(e) => { listClick(url); props.updateCategory(CATEGORY_LABEL.OVERVIEW); }}
            />
          </ListItem>
          <Divider variant='inset' component="li" />
        </React.Fragment>
      );

    } else {
      return;
    }
    
  }




  const selector_docid = useSelector((state) => state.overviewArray.docid);
  const selector_ovid = useSelector((state) => state.overviewArray.ovid);
  const selector_sample = useSelector((state) => state.overviewArray.sample);
  const selector_sampleID = useSelector((state) => state.overviewArray.sampleID);
  const selector_group = useSelector((state) => state.overviewArray.group);
  const selector_inst = useSelector((state) => state.overviewArray.inst);
  const selector_kind = useSelector((state) => state.overviewArray.kind);
  const selector_chst = useSelector((state) => state.overviewArray.chst);
  const selector_expp = useSelector((state) => state.overviewArray.expp);
  const selector_respp = useSelector((state) => state.overviewArray.respp);
  const selector_expdate = useSelector((state) => state.overviewArray.expdate);
  const selector_inpp = useSelector((state) => state.overviewArray.inpp);
  const selector_bmemo = useSelector((state) => state.overviewArray.bmemo);


  //固体物性.熱力学量
  const selector_pp_td_den = useSelector((state) => state.propertyArray.pp_td_den);
  const selector_pp_td_sv = useSelector((state) => state.propertyArray.pp_td_sv);
  const selector_pp_td_ar = useSelector((state) => state.propertyArray.pp_td_ar);
  const selector_pp_td_st = useSelector((state) => state.propertyArray.pp_td_st);
  const selector_pp_td_trop = useSelector((state) => state.propertyArray.pp_td_trop);
  const selector_pp_td_enthal = useSelector((state) => state.propertyArray.pp_td_enthal);
  const selector_pp_td_cr = useSelector((state) => state.propertyArray.pp_td_cr);
  const selector_pp_td_te = useSelector((state) => state.propertyArray.pp_td_te);


  //固体物性.熱力学量.気体透過性
  const selector_pp_td_gp_wp = useSelector((state) => state.propertyArray.pp_td_gp_wp);
  const selector_pp_td_gp_mp = useSelector((state) => state.propertyArray.pp_td_gp_mp);
  const selector_pp_td_gp_ftd = useSelector((state) => state.propertyArray.pp_td_gp_ftd);
  const selector_pp_td_gp_tpd = useSelector((state) => state.propertyArray.pp_td_gp_tpd);
  const selector_pp_td_gp_hd = useSelector((state) => state.propertyArray.pp_td_gp_hd);


  //固体物性.熱力学量.流動特性
  const selector_pp_td_fc_mv_sd = useSelector((state) => state.propertyArray.pp_td_fc_mv_sd);
  const selector_pp_td_fc_mv_mtd = useSelector((state) => state.propertyArray.pp_td_fc_mv_mtd);
  const selector_pp_td_fc_mv_pd = useSelector((state) => state.propertyArray.pp_td_fc_mv_pd);
  const selector_pp_td_fc_vc = useSelector((state) => state.propertyArray.pp_td_fc_vc);


  //固体物性.熱力学量.比熱
  const selector_pp_td_sp_spc = useSelector((state) => state.propertyArray.pp_td_sp_spc);
  const selector_pp_td_sp_std = useSelector((state) => state.propertyArray.pp_td_sp_std);

  //固体物性.熱力学量.熱伝導率
  const selector_pp_td_tc_ttd = useSelector((state) => state.propertyArray.pp_td_tc_ttd);
  const selector_pp_td_tc_dsd = useSelector((state) => state.propertyArray.pp_td_tc_dsd);
  const selector_pp_td_tc_cpd = useSelector((state) => state.propertyArray.pp_td_tc_cpd);

  //固体物性.熱力学量.熱拡散係数
  const selector_pp_td_tdif_cmm = useSelector((state) => state.propertyArray.pp_td_tdif_cmm);
  const selector_pp_td_tdif_amo = useSelector((state) => state.propertyArray.pp_td_tdif_amo);

  // 固体物性.熱力学量.各種温度
  const selector_pp_td_temp_Tg_gpd = useSelector((state) => state.propertyArray.pp_td_temp_Tg_gpd);
  const selector_pp_td_temp_mlp_mpd = useSelector((state) => state.propertyArray.pp_td_temp_mlp_mpd);
  const selector_pp_td_temp_DSC = useSelector((state) => state.propertyArray.pp_td_temp_DSC);
  const selector_pp_td_temp_TGA = useSelector((state) => state.propertyArray.pp_td_temp_TGA);
  const selector_pp_td_temp_wr5 = useSelector((state) => state.propertyArray.pp_td_temp_wr5);
  const selector_pp_td_temp_emb = useSelector((state) => state.propertyArray.pp_td_temp_emb);
  const selector_pp_td_temp_HDT = useSelector((state) => state.propertyArray.pp_td_temp_HDT);


  //固体物性.熱力学量.燃焼特性
  const selector_pp_td_bchar_FIT = useSelector((state) => state.propertyArray.pp_td_bchar_FIT);
  const selector_pp_td_bchar_hdc = useSelector((state) => state.propertyArray.pp_td_bchar_hdc);
  const selector_pp_td_bchar_ch = useSelector((state) => state.propertyArray.pp_td_bchar_ch);
  const selector_pp_td_bchar_oi = useSelector((state) => state.propertyArray.pp_td_bchar_oi);
  const selector_pp_td_bchar_hrp = useSelector((state) => state.propertyArray.pp_td_bchar_hrp);


  //固体物性.機械強度特性
  const selector_pp_ms_crt = useSelector((state) => state.mechanicalArray.pp_ms_crt);
  const selector_pp_ms_strrel = useSelector((state) => state.mechanicalArray.pp_ms_strrel);
  const selector_pp_ms_crack = useSelector((state) => state.mechanicalArray.pp_ms_crack);
  const selector_pp_ms_frat = useSelector((state) => state.mechanicalArray.pp_ms_frat);
  const selector_pp_ms_fatp = useSelector((state) => state.mechanicalArray.pp_ms_fatp);
  const selector_pp_ms_hard = useSelector((state) => state.mechanicalArray.pp_ms_hard);

  //固体物性.機械強度特性.応力歪曲線.引張
  const selector_pp_ms_ssc_ten_degstretch = useSelector((state) => state.mechanicalArray.pp_ms_ssc_ten_degstretch);
  const selector_pp_ms_ssc_ten_degela = useSelector((state) => state.mechanicalArray.pp_ms_ssc_ten_degela);
  const selector_pp_ms_ssc_ten_rupstr = useSelector((state) => state.mechanicalArray.pp_ms_ssc_ten_rupstr);
  const selector_pp_ms_ssc_ten_sttd = useSelector((state) => state.mechanicalArray.pp_ms_ssc_ten_sttd);
  const selector_pp_ms_ssc_ten_stpd = useSelector((state) => state.mechanicalArray.pp_ms_ssc_ten_stpd);
  const selector_pp_ms_ssc_ten_baimage = useSelector((state) => state.mechanicalArray.pp_ms_ssc_ten_baimage);

  //固体物性.機械強度特性.応力歪曲線.曲げ試験
  const selector_pp_ms_ssc_bend_bdstretch = useSelector((state) => state.mechanicalArray.pp_ms_ssc_bend_bdstretch);
  const selector_pp_ms_ssc_bend_bdela = useSelector((state) => state.mechanicalArray.pp_ms_ssc_bend_bdela);
  const selector_pp_ms_ssc_bend_brstr = useSelector((state) => state.mechanicalArray.pp_ms_ssc_bend_brstr);
  const selector_pp_ms_ssc_bend_btd = useSelector((state) => state.mechanicalArray.pp_ms_ssc_bend_btd);
  const selector_pp_ms_ssc_bend_bpd = useSelector((state) => state.mechanicalArray.pp_ms_ssc_bend_bpd);
  const selector_pp_ms_ssc_bend_bbaimage = useSelector((state) => state.mechanicalArray.pp_ms_ssc_bend_bbaimage);


  //固体物性.機械強度特性.粘弾性測定
  const selector_pp_ms_vem_vtd = useSelector((state) => state.mechanicalArray.pp_ms_vem_vtd);
  const selector_pp_ms_vem_vpd = useSelector((state) => state.mechanicalArray.pp_ms_vem_vpd);
  const selector_pp_ms_vem_gdash = useSelector((state) => state.mechanicalArray.pp_ms_vem_gdash);
  const selector_pp_ms_vem_gdash2 = useSelector((state) => state.mechanicalArray.pp_ms_vem_gdash2);
  const selector_pp_ms_vem_ltan = useSelector((state) => state.mechanicalArray.pp_ms_vem_ltan);

  //固体物性.機械強度特性.衝撃試験
  const selector_pp_ms_sht_Char_ctd = useSelector((state) => state.mechanicalArray.pp_ms_sht_Char_ctd);
  const selector_pp_ms_sht_Izod_itd = useSelector((state) => state.mechanicalArray.pp_ms_sht_Izod_itd);


//固体物性.摩擦係数
  const selector_friction_coefficient = useSelector((state) => state.propertyArray.friction_coefficient);

  //固体物性.酵素分解
  const selector_enzymatic_decomposition_rate = useSelector((state) => state.propertyArray.enzymatic_decomposition_rate);
  const selector_enzymatic_enzymatic_reaction = useSelector((state) => state.propertyArray.enzymatic_enzymatic_reaction);

   //固体物性.生分解度
  const selector_biodegradability_curve = useSelector((state) => state.propertyArray.biodegradability_curve);

   //固体物性.崩壊度
  const selector_bar_graph = useSelector((state) => state.propertyArray.bar_graph);

    //固体物性.構造
  const selector_structure_xray_graph = useSelector((state) => state.propertyArray.structure_xray_graph);
  const selector_structure_microscope_image = useSelector((state) => state.propertyArray.structure_microscope_image);
  const selector_structure_crystallinity_degree = useSelector((state) => state.propertyArray.structure_crystallinity_degree);


//高分子生成.作り方
  const selector_hom_enzyme = useSelector((state) => state.SynthesisArray.hom_enzyme);
  const selector_hom_fungus = useSelector((state) => state.SynthesisArray.hom_fungus);

  //高分子生成.作り方.購入
  const selector_buy_maker = useSelector((state) => state.SynthesisArray.buy_maker);
  const selector_buy_lot_number = useSelector((state) => state.SynthesisArray.buy_lot_number);

  //高分子生成.作り方.製造プロセス
  const selector_buy_pmaker = useSelector((state) => state.SynthesisArray.buy_pmaker);
  const selector_buy_plot_number = useSelector((state) => state.SynthesisArray.buy_plot_number);
  const selector_process_synthesis = useSelector((state) => state.SynthesisArray.process_synthesis);
  const selector_process_machine = useSelector((state) => state.SynthesisArray.process_machine);
  const selector_process_theother = useSelector((state) => state.SynthesisArray.process_theother);

  //高分子生成.作り方.重合名.連鎖重合
  const selector_hom_prize_chain_add_rad_LRP = useSelector((state) => state.SynthesisArray.hom_prize_chain_add_rad_LRP);
  const selector_hom_prize_chain_add_cati_LCP = useSelector((state) => state.SynthesisArray.hom_prize_chain_add_cati_LCP);
  const selector_hom_prize_chain_add_anio_LAP = useSelector((state) => state.SynthesisArray.hom_prize_chain_add_anio_LAP);
  const selector_hom_prize_chain_add_crd = useSelector((state) => state.SynthesisArray.hom_prize_chain_add_crd);
  const selector_hom_prize_chain_rop_CROP = useSelector((state) => state.SynthesisArray.hom_prize_chain_rop_CROP);
  const selector_hom_prize_chain_rop_AROP = useSelector((state) => state.SynthesisArray.hom_prize_chain_rop_AROP);
  const selector_hom_prize_chain_rop_RROP = useSelector((state) => state.SynthesisArray.hom_prize_chain_rop_RROP);
  const selector_hom_prize_chain_rop_TMCR_ROMP = useSelector((state) => state.SynthesisArray.hom_prize_chain_rop_TMCR_ROMP);
  const selector_hom_prize_chain_CGCP = useSelector((state) => state.SynthesisArray.hom_prize_chain_CGCP);

//高分子生成.作り方.重合名.逐次重合
  const selector_hom_prize_SGP_pcon_NASP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_NASP);
  const selector_hom_prize_SGP_pcon_ANSP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_ANSP);
  const selector_hom_prize_SGP_pcon_ARNSP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_ARNSP);
  const selector_hom_prize_SGP_pcon_ARESP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_ARESP);
  const selector_hom_prize_SGP_pcon_OCP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_OCP);
  const selector_hom_prize_SGP_pcon_TMCP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_TMCP);
  const selector_hom_prize_SGP_pcon_EP = useSelector((state) => state.SynthesisArray.hom_prize_SGP_pcon_EP);
  const selector_hom_prize_SGP_padd_PTDB = useSelector((state) => state.SynthesisArray.hom_prize_SGP_padd_PTDB);
  const selector_hom_prize_SGP_padd_ROPA = useSelector((state) => state.SynthesisArray.hom_prize_SGP_padd_ROPA);
  const selector_hom_prize_SGP_padd_CYPA = useSelector((state) => state.SynthesisArray.hom_prize_SGP_padd_CYPA);
  const selector_hom_prize_SGP_padd_OTPA = useSelector((state) => state.SynthesisArray.hom_prize_SGP_padd_OTPA);
  const selector_hom_prize_SGP_adco = useSelector((state) => state.SynthesisArray.hom_prize_SGP_adco);

  //高分子生成.反応条件
  const selector_rcon_pH = useSelector((state) => state.SynthesisArray.rcon_pH);
  const selector_rcon_rtemp = useSelector((state) => state.SynthesisArray.rcon_rtemp);
  const selector_rcon_OTRC = useSelector((state) => state.SynthesisArray.rcon_OTRC);

    //高分子生成.結果
  const selector_rslt_RT = useSelector((state) => state.SynthesisArray.rslt_RT);
  const selector_rslt_MCR = useSelector((state) => state.SynthesisArray.rslt_MCR);
  const selector_rslt_rrate = useSelector((state) => state.SynthesisArray.rslt_rrate);
  const selector_rslt_CY = useSelector((state) => state.SynthesisArray.rslt_CY);
  const selector_rslt_prate_tur = useSelector((state) => state.SynthesisArray.rslt_prate_tur);
  const selector_rslt_prate_occ = useSelector((state) => state.SynthesisArray.rslt_prate_occ);
  const selector_rslt_prate_pdata = useSelector((state) => state.SynthesisArray.rslt_prate_pdata);

  // 高分子生成.バイオモノマー生成
  const selector_bmsyn_react = useSelector((state) => state.SynthesisArray.bmsyn_react);
  const selector_bmsyn_reacs = useSelector((state) => state.SynthesisArray.bmsyn_reacs);
  const selector_bmsyn_yield = useSelector((state) => state.SynthesisArray.bmsyn_yield);

    // 高分子生成.バイオモノマー生成..生産速度
  const selector_bmsyn_pros_fgtb = useSelector((state) => state.SynthesisArray.bmsyn_pros_fgtb);
  const selector_bmsyn_pros_csd = useSelector((state) => state.SynthesisArray.bmsyn_pros_csd);
  const selector_bmsyn_pros_scon = useSelector((state) => state.SynthesisArray.bmsyn_pros_scon);
  const selector_bmsyn_pros_pdt = useSelector((state) => state.SynthesisArray.bmsyn_pros_pdt);


  // 20220829 望月
  const selector_MD_MDp = useSelector((state) => state.calculationArray.MD_MDp);
  const selector_MD_cond_ens = useSelector((state) => state.calculationArray.MD_cond_ens);
  const selector_MD_cond_frc_ftbl = useSelector((state) => state.calculationArray.MD_cond_frc_ftbl);
  const selector_MD_cond_icrd_icimage = useSelector((state) => state.calculationArray.MD_cond_icrd_icimage);
  const selector_MD_cond_icrd_icPDB = useSelector((state) => state.calculationArray.MD_cond_icrd_icPDB);
  const selector_MD_cond_icrd_icDCD = useSelector((state) => state.calculationArray.MD_cond_icrd_icDCD);
  const selector_MD_cond_icrd_icPSF = useSelector((state) => state.calculationArray.MD_cond_icrd_icPSF);
  const selector_MD_cond_otcond = useSelector((state) => state.calculationArray.MD_cond_otcond);
  const selector_MD_crslt_Thdyd_TvsT = useSelector((state) => state.calculationArray.MD_crslt_Thdyd_TvsT);
  const selector_MD_crslt_Thdyd_PvsT = useSelector((state) => state.calculationArray.MD_crslt_Thdyd_PvsT);
  const selector_MD_crslt_Thdyd_EvsT = useSelector((state) => state.calculationArray.MD_crslt_Thdyd_EvsT);
  const selector_MD_crslt_Dyd = useSelector((state) => state.calculationArray.MD_crslt_Dyd);
  const selector_QC_QCp = useSelector((state) => state.calculationArray.QC_QCp);
  const selector_QC_QCbc = useSelector((state) => state.calculationArray.QC_QCbc);
  const selector_QC_QCap = useSelector((state) => state.calculationArray.QC_QCap);

  // 20220831 測定法 望月
  const selector_spy_inf_fti = useSelector((state) => state.measurementArray.spy_inf_fti);
  const selector_spy_nmr = useSelector((state) => state.measurementArray.spy_nmr);
  const selector_spy_uvi = useSelector((state) => state.measurementArray.spy_uvi);
  const selector_spy_flu = useSelector((state) => state.measurementArray.spy_flu);
  const selector_spy_ram = useSelector((state) => state.measurementArray.spy_ram);
  const selector_spy_cd = useSelector((state) => state.measurementArray.spy_cd);
  const selector_spy_ls = useSelector((state) => state.measurementArray.spy_ls);
  const selector_spy_ell = useSelector((state) => state.measurementArray.spy_ell);
  const selector_spy_nsc = useSelector((state) => state.measurementArray.spy_nsc);
  const selector_spy_xra_pho = useSelector((state) => state.measurementArray.spy_xra_pho);
  const selector_spy_xra_dif = useSelector((state) => state.measurementArray.spy_xra_dif);
  const selector_spy_xra_xs_sag = useSelector((state) => state.measurementArray.spy_xra_xs_sag);
  const selector_spy_xra_xs_wid = useSelector((state) => state.measurementArray.spy_xra_xs_wid);
  const selector_chr_gas = useSelector((state) => state.measurementArray.chr_gas);
  const selector_chr_hpl_gpc = useSelector((state) => state.measurementArray.chr_hpl_gpc);
  const selector_chr_hpl_sec = useSelector((state) => state.measurementArray.chr_hpl_sec);
  const selector_mic_afm = useSelector((state) => state.measurementArray.mic_afm);
  const selector_mic_sem = useSelector((state) => state.measurementArray.mic_sem);
  const selector_mic_tem = useSelector((state) => state.measurementArray.mic_tem);
  const selector_mic_opt = useSelector((state) => state.measurementArray.mic_opt);
  const selector_mic_flm = useSelector((state) => state.measurementArray.mic_flm);
  const selector_mic_cls = useSelector((state) => state.measurementArray.mic_cls);
  const selector_mic_spr = useSelector((state) => state.measurementArray.mic_spr);
  const selector_mic_qcm = useSelector((state) => state.measurementArray.mic_qcm);
  const selector_mic_pol = useSelector((state) => state.measurementArray.mic_pol);
  const selector_tha_tga = useSelector((state) => state.measurementArray.tha_tga);
  const selector_tha_dta = useSelector((state) => state.measurementArray.tha_dta);
  const selector_tha_dsc = useSelector((state) => state.measurementArray.tha_dsc);
  const selector_ea = useSelector((state) => state.measurementArray.ea);
  const selector_msm = useSelector((state) => state.measurementArray.msm);
  const selector_gep = useSelector((state) => state.measurementArray.gep);
  const selector_cona = useSelector((state) => state.measurementArray.cona);
  const selector_sten = useSelector((state) => state.measurementArray.sten);
  const selector_sfor = useSelector((state) => state.measurementArray.sfor);
  const selector_bod = useSelector((state) => state.measurementArray.bod);

  //20220824 分解
  const selector_chd = useSelector((state) => state.degradationArray.chd);
  const selector_biod_pls_loc = useSelector((state) => state.degradationArray.biod_pls_loc);
  const selector_biod_pls_bdate = useSelector((state) => state.degradationArray.biod_pls_bdate);
  const selector_biod_pls_btime = useSelector((state) => state.degradationArray.biod_pls_btime);
  const selector_biod_pls_toc = useSelector((state) => state.degradationArray.biod_pls_toc);
  const selector_biod_pls_nita = useSelector((state) => state.degradationArray.biod_pls_nita);
  const selector_biod_pls_bmass = useSelector((state) => state.degradationArray.biod_pls_bmass);
  const selector_biod_pls_dd_rc_ph = useSelector((state) => state.degradationArray.biod_pls_dd_rc_ph);
  const selector_biod_pls_dd_rc_btemp = useSelector((state) => state.degradationArray.biod_pls_dd_rc_btemp);
  const selector_biod_pls_dd_rc_both = useSelector((state) => state.degradationArray.biod_pls_dd_rc_both);
  const selector_biod_pls_dd_brlt_brt = useSelector((state) => state.degradationArray.biod_pls_dd_brlt_brt);
  const selector_biod_pls_dd_brlt_dr = useSelector((state) => state.degradationArray.biod_pls_dd_brlt_dr);
  const selector_biod_pls_dd_brlt_dp = useSelector((state) => state.degradationArray.biod_pls_dd_brlt_dp);
  const selector_biod_pls_dd_brlt_dbod = useSelector((state) => state.degradationArray.biod_pls_dd_brlt_dbod);









  return (
    <ThemeProvider theme={theme}>

      <div className={classes.root}>
        <div className={classes.content}>

          <Card >
          {/* <Card className={clsx(classes.root, className)}> */}

            <List dense={true} disablePadding={true}>

              <ListItem className={classes.item} disableGutters key={CATEGORY_LABEL.OVERVIEW}>
                <Button activeClassName={classes.active} className={classes.button} onClick={handleOpenOverview} >
                  <div className={classes.subHeader}>{CATEGORY_LABEL.OVERVIEW}</div>
                  <div width={'10%'}>{overviewOpen ? <ExpandLess /> : <ExpandMore />}</div>
                </Button>
              </ListItem>
              <Collapse in={overviewOpen} timeout="auto" unmountOnExit >

                <List component="div" dense disablePadding  style={{ overflowY: 'scroll' ,maxHeight: '50vh'}}>

                  {makeOverviewLabel(selector_docid, 'ドキュメントID', '/overview')}
                  {/* {makeOverviewLabel(selector_ovid, '概要ID', '/overview')} */}
                  {makeOverviewLabel(selector_sample, '試料総称名', '/overview')}
                  {makeOverviewLabel(selector_sampleID, '試料識別名', '/overview')}
                  {makeOverviewLabel(selector_group, '試料作成グループ名', '/overview')}
                  {makeOverviewLabel(selector_inst, '機関名', '/overview')}
                  {makeOverviewLabel(selector_kind, '種類', '/overview')}
                  {makeOverviewLabel(selector_chst, '化学構造図', '/overview')}
                  {makeOverviewLabel(selector_expp, '実験者', '/overview')}
                  {makeOverviewLabel(selector_respp, '責任者名', '/overview')}
                  {makeOverviewLabel(selector_expdate, '実験日', '/overview')}
                  {makeOverviewLabel(selector_inpp, '入力者のみ', '/overview')}
                  {makeOverviewLabel(selector_bmemo, '概要', '/overview')}
                </List>

              </Collapse>

              <ListItem className={classes.item} disableGutters key={CATEGORY_LABEL.PROPERTY}>
                <Button activeClassName={classes.active} className={classes.button} onClick={handleOpenProperty} >
                  <div className={classes.subHeader}>{CATEGORY_LABEL.PROPERTY}</div>
                  <div width={'10%'}>{propertyOpen ? <ExpandLess /> : <ExpandMore />}</div>
                </Button>
              </ListItem>
              <Collapse in={propertyOpen} timeout="auto" unmountOnExit>

                <List component="div" dense disablePadding style={{ overflowY: 'scroll' ,maxHeight: '50vh'}}>


                {makePropertyLabel(selector_pp_td_den, '密度', '/property/thermal', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_sv, '比容積', '/property/thermal', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_ar, '水吸収率', '/property/thermal', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_st, '表面張力', '/property/thermal', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_trop, '屈性率', '/property/thermal', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_enthal, 'エンタルピー', '/property/thermal', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_cr, '圧縮率', '/property/thermal', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_te, '熱膨張係数', '/property/thermal', CATEGORY_LABEL.PROPERTY)}

                  {makePropertyLabel(selector_pp_td_gp_wp, '気体透過性_水透過性', '/property/thermal/gas', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_gp_mp, '気体透過性_透湿率', '/property/thermal/gas', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_gp_ftd, '気体透過性_フィルム厚み依存性', '/property/thermal/gas', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_gp_tpd, '気体透過性_温度依存性', '/property/thermal/gas', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_gp_hd, '気体透過性_湿度依存性', '/property/thermal/gas', CATEGORY_LABEL.PROPERTY)}

                  {makePropertyLabel(selector_pp_td_fc_mv_sd, '流動特性_剪断依存性', '/property/thermal/flowcharacteristics', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_fc_mv_mtd, '流動特性_温度依存性', '/property/thermal/flowcharacteristics', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_fc_mv_pd, '流動特性_圧⼒依存性', '/property/thermal/flowcharacteristics', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_fc_vc, '流動特性_粘度曲線', '/property/thermal/flowcharacteristics', CATEGORY_LABEL.PROPERTY)}

                  {makePropertyLabel(selector_pp_td_sp_spc, '比熱_比熱曲線', '/property/thermal/specificheat', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_sp_std, '比熱_温度依存性', '/property/thermal/specificheat', CATEGORY_LABEL.PROPERTY)}

                  {makePropertyLabel(selector_pp_td_tc_ttd, '熱伝導率_温度依存性', '/property/thermal/thermalconductibity', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_tc_dsd, '熱伝導率_延伸率依存性', '/property/thermal/thermalconductibity', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_tc_cpd, '熱伝導率_圧力依存性', '/property/thermal/thermalconductibity', CATEGORY_LABEL.PROPERTY)}

                  {makePropertyLabel(selector_pp_td_tdif_cmm, '熱拡散係数_結晶性高分子', '/property/thermal/thermaldiffusion', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_tdif_amo, '熱拡散係数_非晶性高分子', '/property/thermal/thermaldiffusion', CATEGORY_LABEL.PROPERTY)}

                  {makePropertyLabel(selector_pp_td_temp_Tg_gpd, '各種温度_ガラス転移温度.圧⼒依存性', '/property/thermal/tempratures', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_temp_mlp_mpd, '各種温度_融点.圧⼒依存性', '/property/thermal/tempratures', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_temp_DSC, '各種温度_示差走査熱量計', '/property/thermal/tempratures', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_temp_TGA, '各種温度_熱重量分析', '/property/thermal/tempratures', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_temp_wr5, '各種温度_5% 重量減少温度', '/property/thermal/tempratures', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_temp_emb, '各種温度_脆化温度', '/property/thermal/tempratures', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_temp_HDT, '各種温度_熱変形温度', '/property/thermal/tempratures', CATEGORY_LABEL.PROPERTY)}


                  {makePropertyLabel(selector_pp_td_bchar_FIT, '燃焼特性_引火温度', '/property/thermal/burningcharacteristics', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_bchar_hdc, '燃焼特性_熱分解曲線', '/property/thermal/burningcharacteristics', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_bchar_ch, '燃焼特性_燃焼熱', '/property/thermal/burningcharacteristics', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_bchar_oi, '燃焼特性_酸素指数', '/property/thermal/burningcharacteristics', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_td_bchar_hrp, '燃焼特性_耐熱性', '/property/thermal/burningcharacteristics', CATEGORY_LABEL.PROPERTY)}


                  {makePropertyLabel(selector_pp_ms_crt, 'クリープ試験', '/property/mechanical', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_ms_strrel, 'stress relaxation', '/property/mechanical', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_ms_crack, 'クラック', '/property/mechanical', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_ms_frat, 'fracture toughness', '/property/mechanical', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_ms_fatp, 'fatigue properties', '/property/mechanical', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_ms_hard, '硬さ', '/property/mechanical', CATEGORY_LABEL.PROPERTY)}


                  {makePropertyLabel(selector_pp_ms_ssc_ten_degstretch, '応力歪曲線_引張_延伸率', '/property/mechanical/stressstraincurve', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_ms_ssc_ten_degela, '応力歪曲線_引張_弾性率', '/property/mechanical/stressstraincurve', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_ms_ssc_ten_rupstr, '応力歪曲線_引張_破断応⼒', '/property/mechanical/stressstraincurve', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_ms_ssc_ten_sttd, '応力歪曲線_引張_温度依存性', '/property/mechanical/stressstraincurve', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_ms_ssc_ten_stpd, '応力歪曲線_引張_圧⼒依存性', '/property/mechanical/stressstraincurve', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_ms_ssc_ten_baimage, '応力歪曲線_引張_試験前後の画像', '/property/mechanical/stressstraincurve', CATEGORY_LABEL.PROPERTY)}


                  {makePropertyLabel(selector_pp_ms_ssc_bend_bdstretch, '応力歪曲線_曲げ試験_延伸率', '/property/mechanical/stressstraincurve', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_ms_ssc_bend_bdela, '応力歪曲線_曲げ試験_弾性率', '/property/mechanical/stressstraincurve', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_ms_ssc_bend_brstr, '応力歪曲線_曲げ試験_破断応⼒', '/property/mechanical/stressstraincurve', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_ms_ssc_bend_btd, '応力歪曲線_曲げ試験_温度依存性', '/property/mechanical/stressstraincurve', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_ms_ssc_bend_bpd, '応力歪曲線_曲げ試験_圧⼒依存性', '/property/mechanical/stressstraincurve', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_ms_ssc_bend_bbaimage, '応力歪曲線_曲げ試験_試験前後の画像', '/property/mechanical/stressstraincurve', CATEGORY_LABEL.PROPERTY)}

                  {makePropertyLabel(selector_pp_ms_vem_vtd, '粘弾性測定_温度依存性', '/property/mechanical/viscoelastic', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_ms_vem_vpd, '粘弾性測定_圧⼒依存性', '/property/mechanical/viscoelastic', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_ms_vem_gdash, "粘弾性測定_G'", '/property/mechanical/viscoelastic', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_ms_vem_gdash2, "粘弾性測定_G''", '/property/mechanical/viscoelastic', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_ms_vem_ltan, '粘弾性測定_損失正接', '/property/mechanical/viscoelastic', CATEGORY_LABEL.PROPERTY)}

                  {makePropertyLabel(selector_pp_ms_sht_Char_ctd, 'Charpy.温度依存性', '/property/mechanical/shocktest', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_pp_ms_sht_Izod_itd, 'Izod.温度依存性', '/property/mechanical/shocktest', CATEGORY_LABEL.PROPERTY)}

                  {makePropertyLabel(selector_friction_coefficient, '摩擦係数', '/property/frictioncoefficient', CATEGORY_LABEL.PROPERTY)}

                  {makePropertyLabel(selector_enzymatic_decomposition_rate, '分解率', '/property/enzymaticdecomposition', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_enzymatic_enzymatic_reaction, '酵素反応データ', '/property/enzymaticdecomposition', CATEGORY_LABEL.PROPERTY)}

                  {makePropertyLabel(selector_biodegradability_curve, '⽣分解度.曲線データ', '/property/biodegradability', CATEGORY_LABEL.PROPERTY)}

                  {makePropertyLabel(selector_bar_graph, '棒グラフデータ', '/property/degradation', CATEGORY_LABEL.PROPERTY)}

                  {makePropertyLabel(selector_structure_xray_graph, 'X線.グラフ', '/property/structure', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_structure_microscope_image, '顕微鏡法.画像', '/property/structure', CATEGORY_LABEL.PROPERTY)}
                  {makePropertyLabel(selector_structure_crystallinity_degree, '結晶化度', '/property/structure', CATEGORY_LABEL.PROPERTY)}

                </List>

              </Collapse>


              <ListItem className={classes.item} disableGutters key={CATEGORY_LABEL.SYNTHESIS}>
                <Button activeClassName={classes.active} className={classes.button} onClick={handleOpenSynthesis} >
                  <div className={classes.subHeader}>{CATEGORY_LABEL.SYNTHESIS}</div>
                  <div width={'10%'}>{synthesisOpen ? <ExpandLess /> : <ExpandMore />}</div>
                </Button>
              </ListItem>
              <Collapse in={synthesisOpen} timeout="auto" unmountOnExit>

                <List component="div" dense disablePadding style={{ overflowY: 'scroll' ,maxHeight: '50vh'}}>

                  {makePropertyLabel(selector_hom_enzyme, '酵素名', '/synthesis/howtomake', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_hom_fungus, '菌体名', '/synthesis/howtomake', CATEGORY_LABEL.SYNTHESIS)}


                  {makePropertyLabel(selector_buy_maker, '購入_メーカー', '/synthesis/howtomake/buy', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_buy_lot_number, '購入_ロット番号', '/synthesis/howtomake/buy', CATEGORY_LABEL.SYNTHESIS)}

                  {makePropertyLabel(selector_buy_pmaker, '元の⾼分⼦.メーカー', '/synthesis/howtomake/process', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_buy_plot_number, '元の⾼分⼦.ロット番号', '/synthesis/howtomake/process', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_process_synthesis, '合成', '/synthesis/howtomake/process', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_process_machine, '機械名', '/synthesis/howtomake/process', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_process_theother, 'その他', '/synthesis/howtomake/process', CATEGORY_LABEL.SYNTHESIS)}


                  {makePropertyLabel(selector_hom_prize_chain_add_rad_LRP, 'ラジカル重合.リビングラジカル重合', '/synthesis/howtomake/polymerization/chain', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_hom_prize_chain_add_cati_LCP, 'カチオン重合.リビングカチオン重合', '/synthesis/howtomake/polymerization/chain', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_hom_prize_chain_add_anio_LAP, 'アニオン重合.リビングアニオン重合', '/synthesis/howtomake/polymerization/chain', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_hom_prize_chain_add_crd, '配位重合', '/synthesis/howtomake/polymerization/chain', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_hom_prize_chain_rop_CROP, 'カチオン開環重合', '/synthesis/howtomake/polymerization/chain', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_hom_prize_chain_rop_AROP, 'アニオン開環重合', '/synthesis/howtomake/polymerization/chain', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_hom_prize_chain_rop_RROP, 'ラジカル開環重合', '/synthesis/howtomake/polymerization/chain', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_hom_prize_chain_rop_TMCR_ROMP, '開環メタセシス重合', '/synthesis/howtomake/polymerization/chain', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_hom_prize_chain_CGCP, '連鎖縮合重合', '/synthesis/howtomake/polymerization/chain', CATEGORY_LABEL.SYNTHESIS)}


                  {makePropertyLabel(selector_hom_prize_SGP_pcon_NASP, '求核アシル置換重合', '/synthesis/howtomake/polymerization/step', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_hom_prize_SGP_pcon_ANSP, '脂肪族求核置換重合', '/synthesis/howtomake/polymerization/step', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_hom_prize_SGP_pcon_ARNSP, '芳⾹族求核置換重合', '/synthesis/howtomake/polymerization/step', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_hom_prize_SGP_pcon_ARESP, '芳⾹族求電⼦置換重合', '/synthesis/howtomake/polymerization/step', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_hom_prize_SGP_pcon_OCP, '酸化カップリング重合', '/synthesis/howtomake/polymerization/step', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_hom_prize_SGP_pcon_TMCP, '遷移⾦属触媒重合', '/synthesis/howtomake/polymerization/step', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_hom_prize_SGP_pcon_EP, '電解重合', '/synthesis/howtomake/polymerization/step', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_hom_prize_SGP_padd_PTDB, '⼆重結合への重付加', '/synthesis/howtomake/polymerization/step', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_hom_prize_SGP_padd_ROPA, '開環重付加', '/synthesis/howtomake/polymerization/step', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_hom_prize_SGP_padd_CYPA, '環化重付加', '/synthesis/howtomake/polymerization/step', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_hom_prize_SGP_padd_OTPA, '重付加.その他', '/synthesis/howtomake/polymerization/step', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_hom_prize_SGP_adco, '付加縮合', '/synthesis/howtomake/polymerization/step', CATEGORY_LABEL.SYNTHESIS)}

                  {makePropertyLabel(selector_rcon_pH, '反応条件.pH', '/synthesis/reactioncondition', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_rcon_rtemp, '反応条件.温度', '/synthesis/reactioncondition', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_rcon_OTRC, '反応条件.その他', '/synthesis/reactioncondition', CATEGORY_LABEL.SYNTHESIS)}

                  {makePropertyLabel(selector_rslt_RT, '結果.反応時間', '/synthesis/result', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_rslt_MCR, 'モノマー消費率', '/synthesis/result', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_rslt_rrate, '結果.反応速度', '/synthesis/result', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_rslt_CY, '結果.収量', '/synthesis/result', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_rslt_prate_tur, '結果.生産速度.菌体濁度', '/synthesis/result', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_rslt_prate_occ, '結果.生産速度.炭素原濃度', '/synthesis/result', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_rslt_prate_pdata, '結果.生産速度.生産データ', '/synthesis/result', CATEGORY_LABEL.SYNTHESIS)}
                  
                  {makePropertyLabel(selector_bmsyn_react, 'バイオモノマー生成.反応時間', '/synthesis/biomonomersynthesis', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_bmsyn_reacs, 'バイオモノマー生成.反応速度', '/synthesis/biomonomersynthesis', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_bmsyn_yield, 'バイオモノマー生成.収量', '/synthesis/biomonomersynthesis', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_bmsyn_pros_fgtb, 'バイオモノマー生成.生産速度.菌体濁度', '/synthesis/biomonomersynthesis/productionspeed', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_bmsyn_pros_csd, 'バイオモノマー生成.生産速度.炭素源濃度', '/synthesis/biomonomersynthesis/productionspeed', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_bmsyn_pros_scon, 'バイオモノマー生成.生産速度.糖消費', '/synthesis/biomonomersynthesis/productionspeed', CATEGORY_LABEL.SYNTHESIS)}
                  {makePropertyLabel(selector_bmsyn_pros_pdt, 'バイオモノマー生成.生産速度.生産データ', '/synthesis/biomonomersynthesis/productionspeed', CATEGORY_LABEL.SYNTHESIS)}


                </List>

              </Collapse>


              {/* 20220829 望月 */}

              <ListItem className={classes.item} disableGutters key={CATEGORY_LABEL.CALCULATION}>
                <Button activeClassName={classes.active} className={classes.button} onClick={handleOpenCalculation} >
                  <div className={classes.subHeader}>{CATEGORY_LABEL.CALCULATION}</div>
                  <div width={'10%'}>{calculationOpen ? <ExpandLess /> : <ExpandMore />}</div>
                </Button>
              </ListItem>
              <Collapse in={calculationOpen} timeout="auto" unmountOnExit>

                <List component="div" dense disablePadding style={{ overflowY: 'scroll' ,maxHeight: '50vh'}}>

                  {makePropertyLabel(selector_MD_MDp, '分子動力学計算.プログラム', '/calculation/moleculardynamics', CATEGORY_LABEL.CALCULATION)}
                  {makePropertyLabel(selector_MD_cond_ens, 'アンサンブル', '/calculation/moleculardynamics/calculationconditions', CATEGORY_LABEL.CALCULATION)}
                  {makePropertyLabel(selector_MD_cond_frc_ftbl, '表', '/calculation/moleculardynamics/calculationconditions', CATEGORY_LABEL.CALCULATION)}
                  {makePropertyLabel(selector_MD_cond_icrd_icimage, '画像', '/calculation/moleculardynamics/calculationconditions', CATEGORY_LABEL.CALCULATION)}
                  {makePropertyLabel(selector_MD_cond_icrd_icPDB, 'PDB', '/calculation/moleculardynamics/calculationconditions', CATEGORY_LABEL.CALCULATION)}
                  {makePropertyLabel(selector_MD_cond_icrd_icDCD, 'DCD', '/calculation/moleculardynamics/calculationconditions', CATEGORY_LABEL.CALCULATION)}
                  {makePropertyLabel(selector_MD_cond_icrd_icPSF, 'PSF', '/calculation/moleculardynamics/calculationconditions', CATEGORY_LABEL.CALCULATION)}
                  {makePropertyLabel(selector_MD_cond_otcond, 'その他計算条件', '/calculation/moleculardynamics/calculationconditions', CATEGORY_LABEL.CALCULATION)}
                  {makePropertyLabel(selector_MD_crslt_Thdyd_TvsT, '温度vs時間', '/calculation/moleculardynamics/calculationresult', CATEGORY_LABEL.CALCULATION)}
                  {makePropertyLabel(selector_MD_crslt_Thdyd_PvsT, '圧力vs時間', '/calculation/moleculardynamics/calculationresult', CATEGORY_LABEL.CALCULATION)}
                  {makePropertyLabel(selector_MD_crslt_Thdyd_EvsT, 'エネルギーvs時間', '/calculation/moleculardynamics/calculationresult', CATEGORY_LABEL.CALCULATION)}
                  {makePropertyLabel(selector_MD_crslt_Dyd, '力学データ', '/calculation/moleculardynamics/calculationresult', CATEGORY_LABEL.CALCULATION)}
                  {makePropertyLabel(selector_QC_QCp, '量子化学計算.プログラム', '/calculation/quantumchemistrycalculation', CATEGORY_LABEL.CALCULATION)}
                  {makePropertyLabel(selector_QC_QCbc, '基底関数', '/calculation/quantumchemistrycalculation', CATEGORY_LABEL.CALCULATION)}
                  {makePropertyLabel(selector_QC_QCap, '近似法', '/calculation/quantumchemistrycalculation', CATEGORY_LABEL.CALCULATION)}
                </List>

              </Collapse>

              
              {/* 20220831 望月 測定法 */}

              <ListItem className={classes.item} disableGutters key={CATEGORY_LABEL.MEASUREMENT}>
                <Button activeClassName={classes.active} className={classes.button} onClick={handleOpenMeasurement} >
                  <div className={classes.subHeader}>{CATEGORY_LABEL.MEASUREMENT}</div>
                  <div width={'10%'}>{measurementOpen ? <ExpandLess /> : <ExpandMore />}</div>
                </Button>
              </ListItem>
              <Collapse in={measurementOpen} timeout="auto" unmountOnExit>

                <List component="div" dense disablePadding style={{ overflowY: 'scroll' ,maxHeight: '50vh'}}>

                  {makePropertyLabel(selector_spy_inf_fti, 'フーリエ変換赤外分光法', '/measurement/spectroscopy/infraredspectroscopy', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_spy_nmr, '核磁気共鳴分光法', '/measurement/spectroscopy', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_spy_uvi, '紫外・可視分光法', '/measurement/spectroscopy', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_spy_flu, '蛍光分光法', '/measurement/spectroscopy', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_spy_ram, 'ラマン分光法', '/measurement/spectroscopy', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_spy_cd, '円二色性', '/measurement/spectroscopy', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_spy_ls, '光散乱法', '/measurement/spectroscopy', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_spy_ell, '分光エリプソメトリー', '/measurement/spectroscopy', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_spy_nsc, '中性子散乱法', '/measurement/spectroscopy', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_spy_xra_pho, 'X線光電子分光法', '/measurement/spectroscopy/xray', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_spy_xra_dif, 'X線回折法', '/measurement/spectroscopy/xray', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_spy_xra_xs_sag, '小角', '/measurement/spectroscopy/xray', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_spy_xra_xs_wid, '広角', '/measurement/spectroscopy/xray', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_chr_gas, 'ガスクロマトグラフィー', '/measurement/chromatography', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_chr_hpl_gpc, 'ゲル濾過クロマトグラフィー', '/measurement/chromatography', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_chr_hpl_sec, 'サイズ排除クロマトグラフィー', '/measurement/chromatography', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_mic_afm, '原子間力顕微鏡法', '/measurement/microscopy', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_mic_sem, '走査型電子顕微鏡法', '/measurement/microscopy', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_mic_tem, '透過型電子顕微鏡法', '/measurement/microscopy', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_mic_opt, '光学顕微鏡法', '/measurement/microscopy', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_mic_flm, '蛍光顕微鏡法', '/measurement/microscopy', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_mic_cls, '共焦点レーザー顕微鏡法', '/measurement/microscopy', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_mic_spr, '表面プラズモン共鳴法', '/measurement/microscopy', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_mic_qcm, '水晶振動子マイクロバランス法', '/measurement/microscopy', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_mic_pol, '偏光顕微鏡', '/measurement/microscopy', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_tha_tga, '熱重量分析', '/measurement/thermalanalysis', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_tha_dta, '示差熱分析', '/measurement/thermalanalysis', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_tha_dsc, '示差走査熱量測定', '/measurement/thermalanalysis', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_ea, '元素分析', '/measurement/measurementmethod', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_msm, '質量分析法', '/measurement/measurementmethod', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_gep, 'ゲル電気泳動', '/measurement/measurementmethod', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_cona, '接触角測定', '/measurement/measurementmethod', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_sten, '表面張力測定', '/measurement/measurementmethod', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_sfor, '表面力測定', '/measurement/measurementmethod', CATEGORY_LABEL.MEASUREMENT)}
                  {makePropertyLabel(selector_bod, '生物化学的酸素要求量測定', '/measurement/measurementmethod', CATEGORY_LABEL.MEASUREMENT)}
                </List>

              </Collapse>
              
              {/* 20220824 望月　分解 */}

              <ListItem className={classes.item} disableGutters key={CATEGORY_LABEL.DEGRADATION}>
                <Button activeClassName={classes.active} className={classes.button} onClick={handleOpenDegradation} >
                  <div className={classes.subHeader}>{CATEGORY_LABEL.DEGRADATION}</div>
                  <div width={'10%'}>{degradationOpen ? <ExpandLess /> : <ExpandMore />}</div>
                </Button>
              </ListItem>
              <Collapse in={degradationOpen} timeout="auto" unmountOnExit>

                <List component="div" dense disablePadding style={{ overflowY: 'scroll' ,maxHeight: '50vh'}}>

                  {makePropertyLabel(selector_chd, '化学分解', '/degradation/degradation', CATEGORY_LABEL.DEGRADATION)}
                  {makePropertyLabel(selector_biod_pls_loc, '場所', '/degradation/biodegradation', CATEGORY_LABEL.DEGRADATION)}
                  {makePropertyLabel(selector_biod_pls_bdate, '日時', '/degradation/biodegradation', CATEGORY_LABEL.DEGRADATION)}
                  {makePropertyLabel(selector_biod_pls_btime, '時刻', '/degradation/biodegradation', CATEGORY_LABEL.DEGRADATION)}
                  {makePropertyLabel(selector_biod_pls_toc, '全有機炭素(TOC)', '/degradation/biodegradation', CATEGORY_LABEL.DEGRADATION)}
                  {makePropertyLabel(selector_biod_pls_nita, '窒素量', '/degradation/biodegradation', CATEGORY_LABEL.DEGRADATION)}
                  {makePropertyLabel(selector_biod_pls_bmass, '微生物量', '/degradation/biodegradation', CATEGORY_LABEL.DEGRADATION)}
                  {makePropertyLabel(selector_biod_pls_dd_rc_ph, 'pH', '/degradation/biodegradation/degradationdata', CATEGORY_LABEL.DEGRADATION)}
                  {makePropertyLabel(selector_biod_pls_dd_rc_btemp, '温度', '/degradation/biodegradation/degradationdata', CATEGORY_LABEL.DEGRADATION)}
                  {makePropertyLabel(selector_biod_pls_dd_rc_both, '反応条件.その他', '/degradation/biodegradation/degradationdata', CATEGORY_LABEL.DEGRADATION)}
                  {makePropertyLabel(selector_biod_pls_dd_brlt_brt, '反応時間', '/degradation/biodegradation/degradationdata', CATEGORY_LABEL.DEGRADATION)}
                  {makePropertyLabel(selector_biod_pls_dd_brlt_dr, '分解速度', '/degradation/biodegradation/degradationdata', CATEGORY_LABEL.DEGRADATION)}
                  {makePropertyLabel(selector_biod_pls_dd_brlt_dp, '分解産物', '/degradation/biodegradation/degradationdata', CATEGORY_LABEL.DEGRADATION)}
                  {makePropertyLabel(selector_biod_pls_dd_brlt_dbod, '生物化学的酸素要求量（BOD）測定', '/degradation/biodegradation/degradationdata', CATEGORY_LABEL.DEGRADATION)}
                </List>
              </Collapse>

            </List>
          </Card>

        </div>
      </div>
    </ThemeProvider>
  );
};


const mapDispatchToProps = dispatch => {
  return bindActionCreators({

    updateOverViewMenu,
    updatePropertyViewMenu,
    updateSynthesisMenu,
    updateDegradationMenu,
    updateCalculationMenu,
    updateMeasurementMenu,
    

    updateCategory
  }, dispatch);
};


let mapStateToProps = (state) => {
  return {
    overviewArray: state.overviewArray,
    propertyArray: state.propertyArray,
    mechanicalArray: state.mechanicalArray,
    SynthesisArray: state.SynthesisArray,
    settings: state.settings,

    calcurationArray: state.calculationArray,
    measurementArray: state.measurementArray,
    measurementArray: state.measurementArray,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputtedList);


