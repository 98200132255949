// 計算　望月
// 分子動力学計算.プログラム
export const UPDATE_MD_MDP = 'MD_MDp'
// 分子動力学計算.計算条件.アンサンブル
export const UPDATE_MD_COND_ENS = 'MD_cond_ens'
// 分子動力学計算.計算条件.力場.表
export const UPDATE_MD_COND_FRC_FTBL = 'MD_cond_frc_ftbl'
// 分子動力学計算.計算条件.初期座標.画像
export const UPDATE_MD_COND_ICRD_ICIMAGE = 'MD_cond_icrd_icimage'
// 分子動力学計算.計算条件.初期座標.PDB
export const UPDATE_MD_COND_ICRD_ICPDB = 'MD_cond_icrd_icPDB'
// 分子動力学計算.計算条件.初期座標.DCD
export const UPDATE_MD_COND_ICRD_ICDCD= 'MD_cond_icrd_icDCD'
// 分子動力学計算.計算条件.初期座標.PSF
export const UPDATE_MD_COND_ICRD_ICPSF = 'MD_cond_icrd_icPSF'
// 分子動力学計算.計算条件.その他計算条件
export const UPDATE_MD_COND_OTCOND = 'MD_cond_otcond'
// 分子動力学計算.計算結果.熱力学データ.温度vs時間
export const UPDATE_MD_CRSLT_THDYD_TVST = 'MD_crslt_Thdyd_TvsT'
// 分子動力学計算.計算結果.熱力学データ.圧力vs時間
export const UPDATE_MD_CRSLT_THDYD_PVST = 'MD_crslt_Thdyd_PvsT'
// 分子動力学計算.計算結果.熱力学データ.エネルギーvs時間
export const UPDATE_MD_CRSLT_THDYD_EVST = 'MD_crslt_Thdyd_EvsT'
// 分子動力学計算.計算結果.力学データ
export const UPDATE_MD_CRSLT_DYD = 'MD_crslt_Dyd'
// 量子化学計算.プログラム
export const UPDATE_QC_QCP = 'QC_QCp'
// 量子化学計算.基底関数
export const UPDATE_QC_QCBC = 'QC_QCbc'
// 量子化学計算.近似法
export const UPDATE_QC_QCAP = 'QC_QCap'


export const updateMD_MDP = param1 => {
    return {
      type: UPDATE_MD_MDP,
      payload: param1
    }
}

export const updateMD_COND_ENS = param1 => {
    return {
      type: UPDATE_MD_COND_ENS,
      payload: param1
    }
}

export const updateMD_COND_FRC_FTBL = param1 => {
    return {
      type: UPDATE_MD_COND_FRC_FTBL,
      payload: param1
    }
}

export const updateMD_COND_ICRD_ICIMAGE = param1 => {
    return {
      type: UPDATE_MD_COND_ICRD_ICIMAGE,
      payload: param1
    }
}

export const updateMD_COND_ICRD_ICPDB = param1 => {
    return {
      type: UPDATE_MD_COND_ICRD_ICPDB,
      payload: param1
    }
}

export const updateMD_COND_ICRD_ICDCD = param1 => {
    return {
      type: UPDATE_MD_COND_ICRD_ICDCD,
      payload: param1
    }
}

export const updateMD_COND_ICRD_ICPSF = param1 => {
    return {
      type: UPDATE_MD_COND_ICRD_ICPSF,
      payload: param1
    }
}

export const updateMD_COND_OTCOND = param1 => {
    return {
      type: UPDATE_MD_COND_OTCOND,
      payload: param1
    }
}

export const updateMD_CRSLT_THDYD_TVST = param1 => {
    return {
      type: UPDATE_MD_CRSLT_THDYD_TVST,
      payload: param1
    }
}

export const updateMD_CRSLT_THDYD_PVST = param1 => {
    return {
      type: UPDATE_MD_CRSLT_THDYD_PVST,
      payload: param1
    }
}

export const updateMD_CRSLT_THDYD_EVST = param1 => {
    return {
      type: UPDATE_MD_CRSLT_THDYD_EVST,
      payload: param1
    }
}

export const updateMD_CRSLT_DYD = param1 => {
    return {
      type: UPDATE_MD_CRSLT_DYD,
      payload: param1
    }
}

export const updateQC_QCP = param1 => {
    return {
      type: UPDATE_QC_QCP,
      payload: param1
    }
}

export const updateQC_QCBC= param1 => {
    return {
      type: UPDATE_QC_QCBC,
      payload: param1
    }
}

export const updateQC_QCAP= param1 => {
    return {
      type: UPDATE_QC_QCAP,
      payload: param1
    }
}

