import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import HowtomakeSecondMenu from './components/howtomakeSecondMenu';
import { HowtomakeDetail } from './components/HowtomakeDetail';
import InputtedList from 'views/InputtedList/InputtedList';

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(1)
    paddingTop: theme.spacing(1)
  }
}));


const Howtomake = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>

        <Grid item xl={2} lg={2} md={3} sm={3}>
            <HowtomakeSecondMenu />
        </Grid>


        <Grid item xl={8} lg={8} md={7} sm={7} xs={7} >
          <HowtomakeDetail />
        </Grid>

        <Grid item xl={2} lg={2} md={2} sm={2} xs={2} >
          <InputtedList />
        </Grid>
      </Grid>
    </div>
  );
};

export default Howtomake;
