import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import {

  updateMD_MDP,

} from 'actions/calculationArray';


import { makeLabelVal } from "common/util";

import {
  updateOpenDialog,
} from "actions/settings";

import { InputDialog } from "views/InputDialog"

import {

  MD_MDP,


} from "constants.js";



const MolecularDynamicsCalculationsDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');

  const [mdMdpValue, setMdMdpValue] = useState('');


  const selector_MD_MDp = useSelector((state) => state.calculationArray.MD_MDp);

  const selector_openDialog = useSelector((state) => state.settings.openDialog);


  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {
      case MD_MDP:
        setMdMdpValue(makeLabelVal(new_val));
        props.updateMD_MDP(new_val);
        break;

    }
  }


  const showChargerSearchDialog = (selected) => {

    setSelectedNode(selected);

    switch (selected) {
      case MD_MDP:
        setDialogLabel('プログラム');
        break;
    }

    props.updateOpenDialog(true);
  }




  useEffect(() => {

    setMdMdpValue(makeLabelVal(selector_MD_MDp));


  }, []);



  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'分子動力学計算'} saveInput={saveInput} selectedNode={selectedNode} />

        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">分子動力学計算</Typography>} style={{ backgroundColor: 'lightgray' }} />

          <Divider />

          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6} xs={6}>
                <TextField
                  label="プログラム"
                  name={MD_MDP}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(MD_MDP) }}
                  onBeforeInput={() => { showChargerSearchDialog(MD_MDP) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(MD_MDP) } }}
                  type="text"
                  value={mdMdpValue || ''}
                  variant="outlined"
                  autoFocus={true}

                />
              </Grid>

            </Grid>
          </CardContent>

        </Card>
      </div>
    </div>
  );
};

MolecularDynamicsCalculationsDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateMD_MDP,
    
    updateOpenDialog
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    calculationArray: state.calculationArray,
    settings: state.settings
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MolecularDynamicsCalculationsDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
