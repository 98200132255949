
export const CATEGORY = 'CATEGORY';
export const OPEN_DIALOG = 'OPEN_DIALOG';

//検索ツリーDialog
export const OPEN_TREE_DIALOG = 'OPEN_TREE_DIALOG';


export const OPEN_OVERVIEW_MENU = 'openOverviewMenu';
export const OPEN_PROPERYW_MENU = 'openProperywMenu';
export const OPEN_SYNTHESIS_MENU = 'openSynthesisMenu';
export const OPEN_CALCULATION_MENU = 'openCalculationMenu';
export const OPEN_MEASUREMENT_MENU = 'openMeasurementMenu';
export const OPEN_DEGRADATION_MENU = 'openDegradationMenu';

export const LOADING = 'loading';


export const updateCategory = category => {
  return {
    type: CATEGORY,
    payload: category
  }
}

export const updateOpenDialog = isOpen => {
  return{
    type: OPEN_DIALOG,
    payload: isOpen
  }
}

export const updateOpenTreeDialog = isOpen => {
  return {
    type: OPEN_TREE_DIALOG,
    payload: isOpen
  }
}


export const updateOverViewMenu = isOpen => {
  return {
    type: OPEN_OVERVIEW_MENU,
    payload: isOpen
  }
}

export const updatePropertyViewMenu = isOpen => {
  return {
    type: OPEN_PROPERYW_MENU,
    payload: isOpen
  }
}

export const updateSynthesisMenu = isOpen => {
  return {
    type: OPEN_SYNTHESIS_MENU,
    payload: isOpen
  }
}

export const updateCalculationMenu = isOpen => {
  return {
    type: OPEN_CALCULATION_MENU,
    payload: isOpen
  }
}

export const updateDegradationMenu = isOpen => {
  return {
    type: OPEN_DEGRADATION_MENU,
    payload: isOpen
  }
}

export const updateMeasurementMenu = isOpen => {
  return {
    type: OPEN_MEASUREMENT_MENU,
    payload: isOpen
  }
}

export const uploadLoading = loading => {
  return {
    type: LOADING,
    payload: loading
  }
}