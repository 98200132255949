//作り方


//作り方.購入
export const UPDATE_MAKER = 'UPDATE_maker';
export const UPDATE_LOT_NUMBER = 'UPDATE_lot_number';


//作り方.製造プロセス
export const UPDATE_BUY_PMAKER = 'UPDATE_buy_pmaker';
export const UPDATE_BUY_PLOT_NUMBER = 'UPDATE_buy_plot_number';
export const UPDATE_PROCESS_SYNTHESIS = 'UPDATE_PROCESS_SYNTHESIS';
export const UPDATE_PROCESS_MACHINE = 'UPDATE_PROCESS_MACHINE';
export const UPDATE_PROCESS_THEOTHER = 'UPDATE_PROCESS_THEOTHER';


//作り方.酵素名
export const UPDATE_HOM_ENZYME = 'UPDATE_hom_enzyme';

//作り方.菌体名
export const UPDATE_HOM_FUNGUS = 'UPDATE_hom_fungus'


// 作り方.重合名
//重合名.連鎖重合.付加重合.ラジカル重合.リビングラジカル重合
export const UPDATE_HOM_PRIZE_CHAIN_ADD_RAD_LRP = 'UPDATE_hom_prize_chain_add_rad_LRP';

//重合名.連鎖重合.付加重合.カチオン重合.リビングカチオン重合
export const UPDATE_HOM_PRIZE_CHAIN_ADD_CATI_LCP = 'UPDATE_hom_prize_chain_add_cati_LCP';

//重合名.連鎖重合.付加重合.アニオン重合.リビングアニオン重合
export const UPDATE_HOM_PRIZE_CHAIN_ADD_ANIO_LAP = 'UPDATE_hom_prize_chain_add_anio_LAP';

//重合名.連鎖重合.付加重合.配位重合
export const UPDATE_HOM_PRIZE_CHAIN_ADD_CRD = 'UPDATE_hom_prize_chain_add_crd';


//重合名.連鎖重合.開環重合.カチオン開環重合
export const UPDATE_HOM_PRIZE_CHAIN_ROP_CROP = 'UPDATE_hom_prize_chain_rop_CROP';


//重合名.連鎖重合.開環重合.アニオン開環重合
export const UPDATE_HOM_PRIZE_CHAIN_ROP_AROP = 'UPDATE_hom_prize_chain_rop_AROP';

//重合名.連鎖重合.開環重合.ラジカル開環重合
export const UPDATE_HOM_PRIZE_CHAIN_ROP_RROP = 'UPDATE_hom_prize_chain_rop_RROP';

//重合名.連鎖重合.開環重合.遷移金属触媒を用いた開環重合.開環メタセシス重合
export const UPDATE_HOM_PRIZE_CHAIN_ROP_TMCR_ROMP = 'UPDATE_hom_prize_chain_rop_TMCR_ROMP';

//重合名.連鎖重合.連鎖縮合重合
export const UPDATE_HOM_PRIZE_CHAIN_CGCP = 'UPDATE_hom_prize_chain_CGCP';





//重合名.逐次重合.重縮合.求核アシル置換重合
export const UPDATE_HOM_PRIZE_SGP_PCON_NASP = 'UPDATE_hom_prize_SGP_pcon_NASP';

//重合名.逐次重合.重縮合.脂肪族求核置換重合
export const UPDATE_HOM_PRIZE_SGP_PCON_ANSP = 'UPDATE_hom_prize_SGP_pcon_ANSP';

//重合名.逐次重合.重縮合.芳香族求核置換重合
export const UPDATE_HOM_PRIZE_SGP_PCON_ARNSP = 'UPDATE_hom_prize_SGP_pcon_ARNSP';

//重合名.逐次重合.重縮合.芳香族求電子置換重合
export const UPDATE_HOM_PRIZE_SGP_PCON_ARESP = 'UPDATE_hom_prize_SGP_pcon_ARESP';

//作り方.重合名.逐次重合.重縮合.酸化カップリング重合
export const UPDATE_HOM_PRIZE_SGP_PCON_OCP = 'UPDATE_hom_prize_SGP_pcon_OCP';

//重合名.逐次重合.重縮合.遷移金属触媒重合
export const UPDATE_HOM_PRIZE_SGP_PCON_TMCP = 'UPDATE_hom_prize_SGP_pcon_TMCP';

//作り方.重合名.逐次重合.重縮合.電解重合
export const UPDATE_HOM_PRIZE_SGP_PCON_EP = 'UPDATE_hom_prize_SGP_pcon_EP';

//作り方.重合名.逐次重合.重付加.二重結合への重付加
export const UPDATE_HOM_PRIZE_SGP_PADD_PTDB = 'UPDATE_hom_prize_SGP_padd_PTDB';

//作り方.重合名.逐次重合.重付加.開環重付加
export const UPDATE_HOM_PRIZE_SGP_PADD_ROPA = 'UPDATE_hom_prize_SGP_padd_ROPA';

//作り方.重合名.逐次重合.重付加.環化重付加
export const UPDATE_HOM_PRIZE_SGP_PADD_CYPA = 'UPDATE_hom_prize_SGP_padd_CYPA';

//作り方.重合名.逐次重合.重付加.その他
export const UPDATE_HOM_PRIZE_SGP_PADD_OTPA = 'UPDATE_hom_prize_SGP_padd_OTPA';

//作り方.重合名.逐次重合.付加縮合
export const UPDATE_HOM_PRIZE_SGP_ADCO = 'UPDATE_hom_prize_SGP_adco';



//反応条件.pH
export const UPDATE_RCON_PH = 'UPDATE_rcon_pH';

//反応条件.温度
export const UPDATE_RCON_RTEMP = 'UPDATE_rcon_rtemp';

//反応条件.その他
export const UPDATE_RCON_OTRC = 'UPDATE_rcon_OTRC';


//結果.反応時間
export const UPDATE_RSLT_RT = 'UPDATE_rslt_RT';

//結果.モノマー消費率
export const UPDATE_RSLT_MCR = 'UPDATE_rslt_MCR';

//結果.反応速度
export const UPDATE_RSLT_RRATE = 'UPDATE_rslt_rrate';

//結果.収量
export const UPDATE_RSLT_CY = 'UPDATE_rslt_CY';

//結果.生産速度.菌体濁度
export const UPDATE_RSLT_PRATE_TUR = 'UPDATE_rslt_prate_tur';

//結果.生産速度.炭素原濃度
export const UPDATE_RSLT_PRATE_OCC = 'UPDATE_rslt_prate_occ';

//結果.生産速度.生産データ
export const UPDATE_RSLT_PRATE_PDATA = 'UPDATE_rslt_prate_pdata';


// バイオモノマー生成.反応時間
export const UPDATE_BMSYN_REACT = 'UPDATE_bmsyn_react';

// バイオモノマー生成.反応速度
export const UPDATE_BMSYN_REACS = 'UPDATE_bmsyn_reacs';

// バイオモノマー生成.収量
export const UPDATE_BMSYN_YIELD = 'UPDATE_bmsyn_yield';

// バイオモノマー生成.生産速度.菌体濁度
export const UPDATE_BMSYN_PROS_FGTB = 'bmsyn_pros_fgtb';
// バイオモノマー生成.生産速度.炭素源濃度
export const UPDATE_BMSYN_PROS_CSD = 'bmsyn_pros_csd';
// バイオモノマー生成.生産速度.糖消費
export const UPDATE_BMSYN_PROS_SCON = 'bmsyn_pros_scon';
// バイオモノマー生成.生産速度.生産データ
export const UPDATE_BMSYN_PROS_PDT = 'bmsyn_pros_pdt';


export const updateRSLT_RT = param1 => {
  return {
    type: UPDATE_RSLT_RT,
    payload: param1
  }
}

export const updateRSLT_MCR = param1 => {
  return {
    type: UPDATE_RSLT_MCR,
    payload: param1
  }
}

export const updateRSLT_RRATE = param1 => {
  return {
    type: UPDATE_RSLT_RRATE,
    payload: param1
  }
}

export const updateRSLT_CY = param1 => {
  return {
    type: UPDATE_RSLT_CY,
    payload: param1
  }
}

export const updateRSLT_PRATE_TUR = param1 => {
  return {
    type: UPDATE_RSLT_PRATE_TUR,
    payload: param1
  }
}

export const updateRSLT_PRATE_OCC = param1 => {
  return {
    type: UPDATE_RSLT_PRATE_OCC,
    payload: param1
  }
}

export const updateRSLT_PRATE_PDATA = param1 => {
  return {
    type: UPDATE_RSLT_PRATE_PDATA,
    payload: param1
  }
}



export const updateRCON_PH = param1 => {
  return {
    type: UPDATE_RCON_PH,
    payload: param1
  }
}


export const updateRCON_RTEMP = param1 => {
  return {
    type: UPDATE_RCON_RTEMP,
    payload: param1
  }
}


export const updateRCON_OTRC = param1 => {
  return {
    type: UPDATE_RCON_OTRC,
    payload: param1
  }
}


export const updateHOM_PRIZE_SGP_PCON_NASP = param1 => {
  return {
    type: UPDATE_HOM_PRIZE_SGP_PCON_NASP,
    payload: param1
  }
}


export const updateHOM_PRIZE_SGP_PCON_ANSP = param1 => {
  return {
    type: UPDATE_HOM_PRIZE_SGP_PCON_ANSP,
    payload: param1
  }
}



export const updateHOM_PRIZE_SGP_PCON_ARNSP = param1 => {
  return {
    type: UPDATE_HOM_PRIZE_SGP_PCON_ARNSP,
    payload: param1
  }
}

export const updateHOM_PRIZE_SGP_PCON_ARESP = param1 => {
  return {
    type: UPDATE_HOM_PRIZE_SGP_PCON_ARESP,
    payload: param1
  }
}

export const updateHOM_PRIZE_SGP_PCON_OCP = param1 => {
  return {
    type: UPDATE_HOM_PRIZE_SGP_PCON_OCP,
    payload: param1
  }
}

export const updateHOM_PRIZE_SGP_PCON_TMCP = param1 => {
  return {
    type: UPDATE_HOM_PRIZE_SGP_PCON_TMCP,
    payload: param1
  }
}

export const updateHOM_PRIZE_SGP_PCON_EP = param1 => {
  return {
    type: UPDATE_HOM_PRIZE_SGP_PCON_EP,
    payload: param1
  }
}

export const updateHOM_PRIZE_SGP_PADD_PTDB = param1 => {
  return {
    type: UPDATE_HOM_PRIZE_SGP_PADD_PTDB,
    payload: param1
  }
}

export const updateHOM_PRIZE_SGP_PADD_ROPA = param1 => {
  return {
    type: UPDATE_HOM_PRIZE_SGP_PADD_ROPA,
    payload: param1
  }
}

export const updateHOM_PRIZE_SGP_PADD_CYPA = param1 => {
  return {
    type: UPDATE_HOM_PRIZE_SGP_PADD_CYPA,
    payload: param1
  }
}

export const updateHOM_PRIZE_SGP_PADD_OTPA = param1 => {
  return {
    type: UPDATE_HOM_PRIZE_SGP_PADD_OTPA,
    payload: param1
  }
}

export const updateHOM_PRIZE_SGP_ADCO = param1 => {
  return {
    type: UPDATE_HOM_PRIZE_SGP_ADCO,
    payload: param1
  }
}


//作り方


//作り方.購入
export const updateBUY_MAKER = param1 => {
  return {
    type: UPDATE_MAKER,
    payload: param1
  }
}

export const updateBUY_LOT_NUMBER = param1 => {
  return {
    type: UPDATE_LOT_NUMBER,
    payload: param1
  }
}


//作り方.製造プロセス
export const updateBUY_PMAKER = param1 => {
  return {
    type: UPDATE_BUY_PMAKER,
    payload: param1
  }
}

export const updateBUY_PLOT_NUMBER = param1 => {
  return {
    type: UPDATE_BUY_PLOT_NUMBER,
    payload: param1
  }
}

export const updatePROCESS_SYNTHESIS = param1 => {
  return {
    type: UPDATE_PROCESS_SYNTHESIS,
    payload: param1
  }
}

export const updatePROCESS_MACHINE = param1 => {
  return {
    type: UPDATE_PROCESS_MACHINE,
    payload: param1
  }
}

export const updatePROCESS_THEOTHER = param1 => {
  return {
    type: UPDATE_PROCESS_THEOTHER,
    payload: param1
  }
}


//作り方.酵素名
export const updateHOM_ENZYME = param1 => {
  return{
    type: UPDATE_HOM_ENZYME,
    payload: param1
  }
}

//作り方.菌体名
export const updateHOM_FUNGUS = param1 => {
  return{
    type: UPDATE_HOM_FUNGUS,
    payload: param1
  }
}



// 作り方.重合名

//重合名.連鎖重合.付加重合.ラジカル重合.リビングラジカル重合
export const updateHOM_PRIZE_CHAIN_ADD_RAD_LRP = param1 => {

  return {
    type: UPDATE_HOM_PRIZE_CHAIN_ADD_RAD_LRP,
    payload: param1
  }
}


//重合名.連鎖重合.付加重合.カチオン重合.リビングカチオン重合
export const updateHOM_PRIZE_CHAIN_ADD_CATI_LCP = param1 => {
  return{
    type: UPDATE_HOM_PRIZE_CHAIN_ADD_CATI_LCP,
    payload: param1
  }
}


//重合名.連鎖重合.付加重合.アニオン重合.リビングアニオン重合
export const updateHOM_PRIZE_CHAIN_ADD_ANIO_LAP = param1 => {
  return {
    type: UPDATE_HOM_PRIZE_CHAIN_ADD_ANIO_LAP,
    payload: param1
  }
}


//重合名.連鎖重合.付加重合.配位重合
export const updateHOM_PRIZE_CHAIN_ADD_CRD = param1 => {
  return {
    type: UPDATE_HOM_PRIZE_CHAIN_ADD_CRD,
    payload: param1
  }
}


//重合名.連鎖重合.開環重合.カチオン開環重合
export const updateHOM_PRIZE_CHAIN_ROP_CROP = param1 => {
  return {
    type: UPDATE_HOM_PRIZE_CHAIN_ROP_CROP,
    payload: param1
  }
}


//重合名.連鎖重合.開環重合.アニオン開環重合
export const updateHOM_PRIZE_CHAIN_ROP_AROP = param1 => {
  return {
    type: UPDATE_HOM_PRIZE_CHAIN_ROP_AROP,
    payload: param1
  }
}


//重合名.連鎖重合.開環重合.ラジカル開環重合
export const updateHOM_PRIZE_CHAIN_ROP_RROP = param1 => {
  return {
    type: UPDATE_HOM_PRIZE_CHAIN_ROP_RROP,
    payload: param1
  }
}

//重合名.連鎖重合.開環重合.遷移金属触媒を用いた開環重合.開環メタセシス重合
export const updateHOM_PRIZE_CHAIN_ROP_TMCR_ROMP = param1 => {
  return {
    type: UPDATE_HOM_PRIZE_CHAIN_ROP_TMCR_ROMP,
    payload: param1
  }
}


//重合名.連鎖重合.連鎖縮合重合
export const updateHOM_PRIZE_CHAIN_CGCP= param1 => {
  return {
    type: UPDATE_HOM_PRIZE_CHAIN_CGCP,
    payload: param1
  }
}

// バイオモノマー生成.反応時間
export const updateBMSYN_REACT= param1 => {
  return {
    type: UPDATE_BMSYN_REACT,
    payload: param1
  }
}

// バイオモノマー生成.反応速度
export const updateBMSYN_REACS= param1 => {
  return {
    type: UPDATE_BMSYN_REACS,
    payload: param1
  }
}

// バイオモノマー生成.収量
export const updateBMSYN_YIELD= param1 => {
  return {
    type: UPDATE_BMSYN_YIELD,
    payload: param1
  }
}

// バイオモノマー生成.生産速度.菌体濁度
export const updateBMSYN_PROS_FGTB= param1 => {
  return {
    type: UPDATE_BMSYN_PROS_FGTB,
    payload: param1
  }
}

// バイオモノマー生成.生産速度.炭素源濃度
export const updateBMSYN_PROS_CSD= param1 => {
  return {
    type: UPDATE_BMSYN_PROS_CSD,
    payload: param1
  }
}

// バイオモノマー生成.生産速度.糖消費
export const updateBMSYN_PROS_SCON= param1 => {
  return {
    type: UPDATE_BMSYN_PROS_SCON,
    payload: param1
  }
}

// バイオモノマー生成.生産速度.生産データ
export const updateBMSYN_PROS_PDT= param1 => {
  return {
    type: UPDATE_BMSYN_PROS_PDT,
    payload: param1
  }
}
