import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link as RouterLink, withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { makeLabelVal } from "common/util";


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { InputDialog } from "views/InputDialog"

import {
  updatePP_TD_DEN,
  updatePP_TD_SV,
  updatePP_TD_AR,
  updatePP_TD_ST,
  updatePP_TD_TROP,
  updatePP_TD_ENTHAL,
  updatePP_TD_CR,
  updatePP_TD_TE,
} from 'actions/propertyArray';

import {
  updateOpenDialog,
} from "actions/settings";


import {
  PP_TD_DEN,
  PP_TD_SV,
  PP_TD_AR,
  PP_TD_ST,
  PP_TD_TROP,
  PP_TD_ENTHAL,
  PP_TD_CR,
  PP_TD_TE,
} from "constants.js";


const ThermalDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');

  const [pp_td_denValue, setPP_TD_DENValue] = useState('');
  // console.log(pp_td_denValue)
  const [pp_td_svValue, setPP_TD_SVValue] = useState('');
  const [pp_td_arValue, setPP_TD_ARValue] = useState('');
  const [pp_td_stValue, setPP_TD_STValue] = useState('');
  const [pp_td_tropValue, setPP_TD_TROPValue] = useState('');
  const [pp_td_enthalValue, setPP_TD_ENTHALValue] = useState('');
  const [pp_td_crValue, setPP_TD_CRValue] = useState('');
  const [pp_td_teValue, setPP_TD_TEValue] = useState('');

  //テスト中
  const [num, setNum] = useState(0)
  const selector_pp_td_den = useSelector((state) => state.propertyArray.pp_td_den);
  // console.log(selector_pp_td_den)
  const selector_pp_td_sv = useSelector((state) => state.propertyArray.pp_td_sv);
  const selector_pp_td_ar = useSelector((state) => state.propertyArray.pp_td_ar);
  const selector_pp_td_st = useSelector((state) => state.propertyArray.pp_td_st);
  const selector_pp_td_trop = useSelector((state) => state.propertyArray.pp_td_trop);
  const selector_pp_td_enthal = useSelector((state) => state.propertyArray.pp_td_enthal);
  const selector_pp_td_cr = useSelector((state) => state.propertyArray.pp_td_cr);
  const selector_pp_td_te = useSelector((state) => state.propertyArray.pp_td_te);

  const selector_openDialog = useSelector((state) => state.settings.openDialog);

  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {
      case PP_TD_DEN:
        // console.log(makeLabelVal(new_val))
        setPP_TD_DENValue(makeLabelVal(new_val));
        props.updatePP_TD_DEN(new_val);
        break;

      case PP_TD_SV:
        setPP_TD_SVValue(makeLabelVal(new_val));
        props.updatePP_TD_SV(new_val);
        break;

      case PP_TD_AR:
        setPP_TD_ARValue(makeLabelVal(new_val));
        props.updatePP_TD_AR(new_val);
        break;

      case PP_TD_ST:
        setPP_TD_STValue(makeLabelVal(new_val));
        props.updatePP_TD_ST(new_val);
        break;

      case PP_TD_TROP:
        setPP_TD_TROPValue(makeLabelVal(new_val));
        props.updatePP_TD_TROP(new_val);
        break;

      case PP_TD_ENTHAL:
        setPP_TD_ENTHALValue(makeLabelVal(new_val));
        props.updatePP_TD_ENTHAL(new_val);
        break;

      case PP_TD_CR:
        setPP_TD_CRValue(makeLabelVal(new_val));
        props.updatePP_TD_CR(new_val);
        break;

      case PP_TD_TE:
        setPP_TD_TEValue(makeLabelVal(new_val));
        props.updatePP_TD_TE(new_val);
        break;
    }
  }


  const showChargerSearchDialog = (selected) => {

    setSelectedNode(selected);

    switch (selected) {
      case PP_TD_DEN:
        setDialogLabel('密度');
        break;

      case PP_TD_SV:
        setDialogLabel('比容積');
        break;

      case PP_TD_AR:
        setDialogLabel('水吸収率');
        break;

      case PP_TD_ST:
        setDialogLabel('表面張力');
        break;

      case PP_TD_TROP:
        setDialogLabel('屈性率');
        break;

      case PP_TD_ENTHAL:
        setDialogLabel('エンタルピー');
        break;

      case PP_TD_CR:
        setDialogLabel('圧縮率');
        break;

      case PP_TD_TE:
        setDialogLabel('熱膨張係数');
        break;
    }

    props.updateOpenDialog(true);
  }


  useEffect(() => {
    

    setPP_TD_DENValue(makeLabelVal(selector_pp_td_den));
    setPP_TD_SVValue(makeLabelVal(selector_pp_td_sv));
    setPP_TD_ARValue(makeLabelVal(selector_pp_td_ar));
    setPP_TD_STValue(makeLabelVal(selector_pp_td_st));
    setPP_TD_TROPValue(makeLabelVal(selector_pp_td_trop));
    setPP_TD_ENTHALValue(makeLabelVal(selector_pp_td_enthal));
    setPP_TD_CRValue(makeLabelVal(selector_pp_td_cr));
    setPP_TD_TEValue(makeLabelVal(selector_pp_td_te));
  }, []);




  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'熱力学量'} saveInput={saveInput} selectedNode={selectedNode}/>

        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader align='center' title={<Typography variant="h4">熱力学量</Typography>} style={{ backgroundColor: 'lightgray' }}/>
          <Divider />

          <CardContent>
            <Grid container spacing={2}>

              <Grid item md={6} xs={6}>
                <TextField
                  label="密度"
                  name={PP_TD_DEN}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_DEN) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_DEN) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_DEN) } }}
                  type="text"
                  value={pp_td_denValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="比容積"
                  name={PP_TD_SV}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_SV) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_SV) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_SV) } }}
                  type="text"
                  value={pp_td_svValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="水吸収率"
                  name={PP_TD_AR}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_AR) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_AR) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_AR) } }}
                  type="text"
                  value={pp_td_arValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="表面張力"
                  name={PP_TD_ST}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_ST) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_ST) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_ST) } }}
                  type="text"
                  value={pp_td_stValue || ''}
                  variant="outlined"
                />
              </Grid>


              <Grid item md={6} xs={6}>
                <TextField
                  label="屈性率"
                  name={PP_TD_TROP}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_TROP) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_TROP) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_TROP) } }}
                  type="text"
                  value={pp_td_tropValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="エンタルピー"
                  name={PP_TD_ENTHAL}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_ENTHAL) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_ENTHAL) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_ENTHAL) } }}
                  type="text"
                  value={pp_td_enthalValue || ''}
                  variant="outlined"
                />
              </Grid>


              <Grid item md={6} xs={6}>
                <TextField
                  label="圧縮率"
                  name={PP_TD_CR}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_CR) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_CR) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_CR) } }}
                  type="text"
                  value={pp_td_crValue || ''}
                  variant="outlined"
                />
              </Grid>


              <Grid item md={6} xs={6}>
                <TextField
                  label="熱膨張係数"
                  name={PP_TD_TE}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_TE) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_TE) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_TE) } }}
                  type="text"
                  value={pp_td_teValue || ''}
                  variant="outlined"
                />
              </Grid>

            </Grid>
          </CardContent>

        </Card>
      </div>
    </div>
  );
};

ThermalDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updatePP_TD_DEN,
    updatePP_TD_SV,
    updatePP_TD_AR,
    updatePP_TD_ST,
    updatePP_TD_TROP,
    updatePP_TD_ENTHAL,
    updatePP_TD_CR,
    updatePP_TD_TE,

    updateOpenDialog,
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    propertyArray: state.propertyArray,
    settings: state.settings
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ThermalDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
