import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updateBMSYN_PROS_FGTB,
  updateBMSYN_PROS_CSD,
  updateBMSYN_PROS_SCON,
  updateBMSYN_PROS_PDT
} from 'actions/SynthesisArray';


import { makeLabelVal } from "common/util";

import {
  BMSYN_PROS_FGTB,
  BMSYN_PROS_CSD,
  BMSYN_PROS_SCON,
  BMSYN_PROS_PDT,
} from "constants.js";

import {
  updateOpenDialog,
} from "actions/settings";

import { InputDialog } from "views/InputDialog"


/**
 * バイオモノマー生成.生産速度
 */
const ProductionSpeedDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');

  const [bmsyn_pros_fgtbValue, setBmsyn_pros_fgtbValue] = useState('');
  const [bmsyn_pros_csdValue, setBmsyn_pros_csdValue] = useState('');
  const [bmsyn_pros_sconValue, setBmsyn_pros_sconValue] = useState('');
  const [bmsyn_pros_pdtValue, setBmsyn_pros_pdtValue] = useState('');

  const selector_bmsyn_pros_fgtb = useSelector((state) => state.SynthesisArray.bmsyn_pros_fgtb);
  const selector_bmsyn_pros_csd = useSelector((state) => state.SynthesisArray.bmsyn_pros_csd);
  const selector_bmsyn_pros_scon = useSelector((state) => state.SynthesisArray.bmsyn_pros_scon);
  const selector_bmsyn_pros_pdt = useSelector((state) => state.SynthesisArray.bmsyn_pros_pdt);

  const selector_openDialog = useSelector((state) => state.settings.openDialog);


  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {
      case BMSYN_PROS_FGTB:
        setBmsyn_pros_fgtbValue(makeLabelVal(new_val));
        props.updateBMSYN_PROS_FGTB(new_val);
        break;

      case BMSYN_PROS_CSD:
        setBmsyn_pros_csdValue(makeLabelVal(new_val));
        props.updateBMSYN_PROS_CSD(new_val);
        break;
      
      case BMSYN_PROS_SCON:
        setBmsyn_pros_sconValue(makeLabelVal(new_val));
        props.updateBMSYN_PROS_SCON(new_val);
        break;

      case BMSYN_PROS_PDT:
        setBmsyn_pros_pdtValue(makeLabelVal(new_val));
        props.updateBMSYN_PROS_PDT(new_val);
        break;
    }

  }


  const showChargerSearchDialog = (selected) => {

    setSelectedNode(selected);

    switch (selected) {
      case BMSYN_PROS_FGTB:
        setDialogLabel('菌体濁度');
        break;

      case BMSYN_PROS_CSD:
        setDialogLabel('炭素源濃度');
        break;
      
      case BMSYN_PROS_SCON:
        setDialogLabel('糖消費');
        break;
          
      case BMSYN_PROS_PDT:
        setDialogLabel('生産データ');
        break;
    }


    props.updateOpenDialog(true);
  }


  useEffect(() => {
    setBmsyn_pros_fgtbValue(makeLabelVal(selector_bmsyn_pros_fgtb));
    setBmsyn_pros_csdValue(makeLabelVal(selector_bmsyn_pros_csd));
    setBmsyn_pros_sconValue(makeLabelVal(selector_bmsyn_pros_scon));
    setBmsyn_pros_pdtValue(makeLabelVal(selector_bmsyn_pros_pdt));
  }, []);



  return (
    <div className={classes.root}>
      <div className={classes.content}>

      <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'生産速度'} saveInput={saveInput} selectedNode={selectedNode}/>


        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">生産速度</Typography>} style={{ backgroundColor: 'lightgray' }}
            subheader={<Typography variant="h5">- バイオモノマー生成 -</Typography>}
          />

          <Divider />

          <CardContent>
            <Grid container spacing={2}>

              <Grid item md={6} xs={6}>
                <TextField
                  label="菌体濁度"
                  name={BMSYN_PROS_FGTB}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(BMSYN_PROS_FGTB) }}
                  onBeforeInput={() => { showChargerSearchDialog(BMSYN_PROS_FGTB) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(BMSYN_PROS_FGTB) } }}
                  type="text"
                  value={bmsyn_pros_fgtbValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="炭素源濃度"
                  name={BMSYN_PROS_CSD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(BMSYN_PROS_CSD) }}
                  onBeforeInput={() => { showChargerSearchDialog(BMSYN_PROS_CSD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(BMSYN_PROS_CSD) } }}
                  type="text"
                  value={bmsyn_pros_csdValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="糖消費"
                  name={BMSYN_PROS_SCON}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(BMSYN_PROS_SCON) }}
                  onBeforeInput={() => { showChargerSearchDialog(BMSYN_PROS_SCON) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(BMSYN_PROS_SCON) } }}
                  type="text"
                  value={bmsyn_pros_sconValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="生産データ"
                  name={BMSYN_PROS_PDT}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(BMSYN_PROS_PDT) }}
                  onBeforeInput={() => { showChargerSearchDialog(BMSYN_PROS_PDT) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(BMSYN_PROS_PDT) } }}
                  type="text"
                  value={bmsyn_pros_pdtValue || ''}
                  variant="outlined"
                />
              </Grid>

            </Grid>
          </CardContent>

        </Card>
      </div>
    </div>
  );
};

ProductionSpeedDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateBMSYN_PROS_FGTB,
    updateBMSYN_PROS_CSD,
    updateBMSYN_PROS_SCON,
    updateBMSYN_PROS_PDT,

    updateOpenDialog,
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    SynthesisArray: state.SynthesisArray,
    settings: state.settings
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProductionSpeedDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
