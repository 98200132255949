/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  List, ListItem, Button
} from '@material-ui/core';


const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));


export const SidebarNav = props => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <Card className={clsx(classes.root, className)} {...rest}>

          <List
            // {...rest}
            // className={clsx(classes.root, className)} 
            >

            {pages.map(page => (
              <ListItem className={classes.item} disableGutters key={page.title} dense={true}>

                <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={page.href} >
                  {page.title}
                </Button>

              </ListItem>
            ))}
          </List>
        </Card>
      </div>
    </div>
  );
};


SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;


const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    // minWidth: 146,
    minWidth: 120,
    // backgroundColor: 'gray',
  },
  content: {
    // marginTop: theme.spacing(1),
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft:0,
    paddingBottom: 0,
    // paddingLeft: 10,
  },
  button: {
    // color: colors.blueGrey[800],
    color: "#2a3f54",
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 16,
  },
  icon: {
    color: theme.palette.icon,
    // color: "#2a3f54",
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    // color: "#000000",
    // backgroundColor: "#b7b7b7",
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
      // color: "#000000"
    }
  }
}));