import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link as RouterLink, withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { makeLabelVal } from "common/util";
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updateENZYMATIC_DECOMPOSITION_RATE,
  updateENZYMATIC_ENZYMATIC_REACTION,
} from 'actions/propertyArray';

import {
  updateOpenDialog,
} from "actions/settings";

import { InputDialog } from "views/InputDialog"

import {
  ENZYMATIC_DECOMPOSITION_RATE,
  ENZYMATIC_ENZYMATIC_REACTION,
} from "constants";


/**
 * 固体物性.酵素分解
 */
const EnzymaticDecompositionDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');

  const [enzymatic_decomposition_rateValue, setEnzymatic_decomposition_rateValue] = useState('');
  const [enzymatic_enzymatic_reactionValue, setEnzymatic_enzymatic_reactionValue] = useState('');

  const selector_enzymatic_decomposition_rate = useSelector((state) => state.propertyArray.enzymatic_decomposition_rate);
  const selector_enzymatic_enzymatic_reaction = useSelector((state) => state.propertyArray.enzymatic_enzymatic_reaction);


  const selector_openDialog = useSelector((state) => state.settings.openDialog);


  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
      
    };

    switch (selectedNode) {
      case ENZYMATIC_DECOMPOSITION_RATE:
        setEnzymatic_decomposition_rateValue(makeLabelVal(new_val));
        props.updateENZYMATIC_DECOMPOSITION_RATE(new_val);
        break;

      case ENZYMATIC_ENZYMATIC_REACTION:
        setEnzymatic_enzymatic_reactionValue(makeLabelVal(new_val));
        props.updateENZYMATIC_ENZYMATIC_REACTION(new_val);
        break;
    }
  }

  const showChargerSearchDialog = (selected) => {

    // setDialogLabel(label);
    setSelectedNode(selected);
    switch(selected) {
      case ENZYMATIC_DECOMPOSITION_RATE:
        setDialogLabel('分解率');
        break;

      case ENZYMATIC_ENZYMATIC_REACTION:
        setDialogLabel('酵素反応データ');
        break;
    }
    props.updateOpenDialog(true);

  }


  useEffect(() => {
    setEnzymatic_decomposition_rateValue(makeLabelVal(selector_enzymatic_decomposition_rate));
    setEnzymatic_enzymatic_reactionValue(makeLabelVal(selector_enzymatic_enzymatic_reaction));
  }, []);



  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'酵素分解'} saveInput={saveInput} selectedNode={selectedNode} />

        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">酵素分解</Typography>} style={{ backgroundColor: 'lightgray' }} />
          <Divider />

          <CardContent>
            <Grid container spacing={2}>

              <Grid item md={6} xs={6}>
                <TextField
                  label="分解率"
                  name={ENZYMATIC_DECOMPOSITION_RATE}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(ENZYMATIC_DECOMPOSITION_RATE) }}
                  onBeforeInput={() => { showChargerSearchDialog(ENZYMATIC_DECOMPOSITION_RATE) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(ENZYMATIC_DECOMPOSITION_RATE) } }}
                  type="text"
                  value={enzymatic_decomposition_rateValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>

              <Grid container item md={12} spacing={0}></Grid>
            </Grid>


            <Grid item md={12} spacing={0}>
              <Typography variant="h4">活性測定</Typography>
            </Grid>

            <Divider variant="inset" />

            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="酵素反応データ"
                  name={ENZYMATIC_ENZYMATIC_REACTION}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(ENZYMATIC_ENZYMATIC_REACTION) }}
                  onBeforeInput={() => { showChargerSearchDialog(ENZYMATIC_ENZYMATIC_REACTION) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(ENZYMATIC_ENZYMATIC_REACTION) } }}
                  type="text"
                  value={enzymatic_enzymatic_reactionValue || ''}
                  variant="outlined"
                />
              </Grid>

            </Grid>

          </CardContent>

        </Card>
      </div>
    </div>
  );
};

EnzymaticDecompositionDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateENZYMATIC_DECOMPOSITION_RATE,
    updateENZYMATIC_ENZYMATIC_REACTION,

    updateOpenDialog,
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    propertyArray: state.propertyArray,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EnzymaticDecompositionDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
