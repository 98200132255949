import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updateBIOD_PLS_DD_RC_PH,
  updateBIOD_PLS_DD_RC_BTEMP, 
  updateBIOD_PLS_DD_RC_BOTH,
  updateBIOD_PLS_DD_BRLT_BRT,
  updateBIOD_PLS_DD_BRLT_DR,
  updateBIOD_PLS_DD_BRLT_DP,
  updateBIOD_PLS_DD_BRLT_DBOD,

} from 'actions/degradationArray';


import { makeLabelVal } from "common/util";

import {
  BIOD_PLS_DD_RC_PH,
  BIOD_PLS_DD_RC_BTEMP, 
  BIOD_PLS_DD_RC_BOTH,
  BIOD_PLS_DD_BRLT_BRT,
  BIOD_PLS_DD_BRLT_DR,
  BIOD_PLS_DD_BRLT_DP,
  BIOD_PLS_DD_BRLT_DBOD,

} from "constants.js";

import {
  updateOpenDialog,
} from "actions/settings";

import { InputDialog } from "views/InputDialog"



const DegradationDataDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');

  // 2つ目のノードまで省略
  const [ddRcPhValue, setDdRcPhValue] = useState('');
  const [ddRcBtempValue, setDdRcBtempValue] = useState('');
  const [ddRcBothValue, setDdRcBothValue] = useState('');
  const [ddBrltBrtValue, setDdBrltBrtValue] = useState('');
  const [ddBrltDrValue, setDdBrltDrValue] = useState('');
  const [ddBrltDpValue, setDdBrltDpValue] = useState('');
  const [ddBrltDbodValue, setDdBrltDbodValue] = useState('');

  const selector_biod_pls_dd_rc_ph = useSelector((state) => state.degradationArray.biod_pls_dd_rc_ph);
  const selector_biod_pls_dd_rc_btemp = useSelector((state) => state.degradationArray.biod_pls_dd_rc_btemp);
  const selector_biod_pls_dd_rc_both = useSelector((state) => state.degradationArray.biod_pls_dd_rc_both);
  const selector_biod_pls_dd_brlt_brt = useSelector((state) => state.degradationArray.biod_pls_dd_brlt_brt);
  const selector_biod_pls_dd_brlt_dr = useSelector((state) => state.degradationArray.biod_pls_dd_brlt_dr);
  const selector_biod_pls_dd_brlt_dp = useSelector((state) => state.degradationArray.biod_pls_dd_brlt_dp);
  const selector_biod_pls_dd_brlt_dbod = useSelector((state) => state.degradationArray.biod_pls_dd_brlt_dbod);

  const selector_openDialog = useSelector((state) => state.settings.openDialog);


  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {
      case BIOD_PLS_DD_RC_PH:
        setDdRcPhValue(makeLabelVal(new_val));
        props.updateBIOD_PLS_DD_RC_PH(new_val);
        break;

      case BIOD_PLS_DD_RC_BTEMP:
        setDdRcBtempValue(makeLabelVal(new_val));
        props.updateBIOD_PLS_DD_RC_BTEMP(new_val);
        break;

      case BIOD_PLS_DD_RC_BOTH:
        setDdRcBothValue(makeLabelVal(new_val));
        props.updateBIOD_PLS_DD_RC_BOTH(new_val);
        break;

      case BIOD_PLS_DD_BRLT_BRT:
        setDdBrltBrtValue(makeLabelVal(new_val));
        props.updateBIOD_PLS_DD_BRLT_BRT(new_val);
        break;

      case BIOD_PLS_DD_BRLT_DR:
        setDdBrltDrValue(makeLabelVal(new_val));
        props.updateBIOD_PLS_DD_BRLT_DR(new_val);
        break;

      case BIOD_PLS_DD_BRLT_DP:
        setDdBrltDpValue(makeLabelVal(new_val));
        props.updateBIOD_PLS_DD_BRLT_DP(new_val);
        break;

      case BIOD_PLS_DD_BRLT_DBOD:
        setDdBrltDbodValue(makeLabelVal(new_val));
        props.updateBIOD_PLS_DD_BRLT_DBOD(new_val);
        break;
    }

  }


  const showChargerSearchDialog = (selected) => {

 
    setSelectedNode(selected);
    switch (selected) {
      case BIOD_PLS_DD_RC_PH:
        setDialogLabel('pH');
        break;

      case BIOD_PLS_DD_RC_BTEMP:
        setDialogLabel('温度');
        break;

      case BIOD_PLS_DD_RC_BOTH:
        setDialogLabel('その他');
        break;

      case BIOD_PLS_DD_BRLT_BRT:
        setDialogLabel('反応時間');
        break;

      case BIOD_PLS_DD_BRLT_DR:
        setDialogLabel('分解速度');
        break;

      case BIOD_PLS_DD_BRLT_DP:
        setDialogLabel('分解産物');
        break;

      case BIOD_PLS_DD_BRLT_DBOD:
        setDialogLabel('生物化学的酸素要求量（BOD）測定');
        break;
    }
    props.updateOpenDialog(true);
  }


  useEffect(() => {
    setDdRcPhValue(makeLabelVal(selector_biod_pls_dd_rc_ph));
    setDdRcBtempValue(makeLabelVal(selector_biod_pls_dd_rc_btemp));
    setDdRcBothValue(makeLabelVal(selector_biod_pls_dd_rc_both));
    setDdBrltBrtValue(makeLabelVal(selector_biod_pls_dd_brlt_brt));
    setDdBrltDrValue(makeLabelVal(selector_biod_pls_dd_brlt_dr));
    setDdBrltDpValue(makeLabelVal(selector_biod_pls_dd_brlt_dp));
    setDdBrltDbodValue(makeLabelVal(selector_biod_pls_dd_brlt_dbod));


  }, []);




  return (
    <div className={classes.root}>
      <div className={classes.content}>

      <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'分解データ'} saveInput={saveInput} selectedNode={selectedNode}/>


        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">分解データ</Typography>} style={{ backgroundColor: 'lightgray' }}
            subheader={<Typography variant="h5">-生分解.植種情報-</Typography>}
          />

          <Divider />

          <CardContent>
            <Grid item md={12} spacing={0}>
                <Typography variant="h4">反応条件</Typography>
              </Grid>

              <Divider variant="inset" />



            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="pH"
                  name={BIOD_PLS_DD_RC_PH}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(BIOD_PLS_DD_RC_PH) }}
                  onBeforeInput={() => { showChargerSearchDialog(BIOD_PLS_DD_RC_PH) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(BIOD_PLS_DD_RC_PH) } }}
                  type="text"
                  value={ddRcPhValue || ''}
                  variant="outlined"
                  autoFocus={true}

                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="温度"
                  name={BIOD_PLS_DD_RC_BTEMP}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(BIOD_PLS_DD_RC_BTEMP) }}
                  onBeforeInput={() => { showChargerSearchDialog(BIOD_PLS_DD_RC_BTEMP) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(BIOD_PLS_DD_RC_BTEMP) } }}
                  type="text"
                  value={ddRcBtempValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="その他"
                  name={BIOD_PLS_DD_RC_BOTH}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(BIOD_PLS_DD_RC_BOTH) }}
                  onBeforeInput={() => { showChargerSearchDialog(BIOD_PLS_DD_RC_BOTH) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(BIOD_PLS_DD_RC_BOTH) } }}
                  type="text"
                  value={ddRcBothValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid container item md={12}></Grid>
              <Grid container item md={12}></Grid>

            </Grid>

            <Grid item md={12} spacing={0}>
              <Typography variant="h4">結果</Typography>
            </Grid>

            <Divider variant="inset" />

            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="反応時間"
                  name={BIOD_PLS_DD_BRLT_BRT}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(BIOD_PLS_DD_BRLT_BRT ) }}
                  onBeforeInput={() => { showChargerSearchDialog(BIOD_PLS_DD_BRLT_BRT) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(BIOD_PLS_DD_BRLT_BRT) } }}
                  type="text"
                  value={ddBrltBrtValue || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <TextField
                  label=" 分解速度"
                  name={BIOD_PLS_DD_BRLT_DR}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(BIOD_PLS_DD_BRLT_DR) }}
                  onBeforeInput={() => { showChargerSearchDialog(BIOD_PLS_DD_BRLT_DR) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(BIOD_PLS_DD_BRLT_DR) } }}
                  type="text"
                  value={ddBrltDrValue || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <TextField
                  label=" 分解産物"
                  name={BIOD_PLS_DD_BRLT_DP}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(BIOD_PLS_DD_BRLT_DP) }}
                  onBeforeInput={() => { showChargerSearchDialog(BIOD_PLS_DD_BRLT_DP) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(BIOD_PLS_DD_BRLT_DP) } }}
                  type="text"
                  value={ddBrltDpValue || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <TextField
                  label=" 生物化学的酸素要求量（BOD）測定"
                  name={BIOD_PLS_DD_BRLT_DBOD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(BIOD_PLS_DD_BRLT_DBOD) }}
                  onBeforeInput={() => { showChargerSearchDialog(BIOD_PLS_DD_BRLT_DBOD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(BIOD_PLS_DD_BRLT_DBOD) } }}
                  type="text"
                  value={ddBrltDbodValue || ''}
                  variant="outlined"
                />
              </Grid>

            </Grid>
          </CardContent>

        </Card>
      </div>
    </div>
  );
};

DegradationDataDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateBIOD_PLS_DD_RC_PH,
    updateBIOD_PLS_DD_RC_BTEMP, 
    updateBIOD_PLS_DD_RC_BOTH,
    updateBIOD_PLS_DD_BRLT_BRT,
    updateBIOD_PLS_DD_BRLT_DR,
    updateBIOD_PLS_DD_BRLT_DP,
    updateBIOD_PLS_DD_BRLT_DBOD,

    updateOpenDialog,
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    degradationArray: state.degradationArray,
    settings: state.settings
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DegradationDataDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
