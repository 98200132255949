import React, {useState, useEffect} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';

import { connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { tempSetUser} from 'actions/user.js';



const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));


const Profile = props => {
  const { className, tempSetUser, ...rest } = props;

  const classes = useStyles();

  const user = {
    avatar: '/images/avatars/avatar_default2.png',
  };

  const [userState, setUserState] = useState({
    name: props.user.admin_id || 'ログアウト状態',
    avatar: '/images/avatars/avatar_default2.png',
  });


  const loadUser = () => {
    const userjson = localStorage.getItem('userinfo');

    if(!userjson) return;

    let user = JSON.parse(userjson);

    tempSetUser(user);

    setUserState( userState => ({
      name: user.admin_id,
    }));
  }


  useEffect(() => {
    loadUser();
  },[]);


  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        src={user.avatar}
        to="/account"
        variant='square'
      />
      <Typography
        className={classes.name}
        variant="h4"
      >
        {userState.name}
      </Typography>
    </div>
  );
};


Profile.propTypes = {
  className: PropTypes.string
};


const mapDispatchToProps = dispatch => {
  return bindActionCreators({tempSetUser}, dispatch)
}

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
