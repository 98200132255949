/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import DashboardIcon from '@material-ui/icons/Dashboard';
import ScheduleIcon from '@material-ui/icons/Schedule';
import TimelineIcon from '@material-ui/icons/Timeline'


import { SidebarNav } from 'SidebarNav';


const SynthesisFirstMenu = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  var pages = [

    {
      title: '作り⽅',
      href: '/synthesis/howtomake',
      icon: <DashboardIcon />
    },

    {
      title: '反応条件',
      href: '/synthesis/reactioncondition',
      disable: false,
      icon: <ScheduleIcon />
    },

    {
      title: '結果',
      href: '/synthesis/result',
      disable: false,
      icon: <TimelineIcon />
    },

    {
      title: 'バイオモノマー生成',
      href: '/synthesis/biomonomersynthesis',
      disable: false,
      icon: <TimelineIcon />
    },

  ];


  return (

    <div className={classes.root}>
      <div className={classes.content}>
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </div>
  );
};

SynthesisFirstMenu.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};



export default SynthesisFirstMenu;


const useStyles = makeStyles(theme => ({
  root: {
    // paddingLeft: theme.spacing(1),
    // paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    // minWidth: 140,
  },
  content: {
    marginTop: theme.spacing(1),
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
  },
  sub_item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 26,
  },
  button: {
    // color: colors.blueGrey[800],
    color: "#2a3f54",
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    // color: "#2a3f54",
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    // color: "#000000",
    // backgroundColor: "#b7b7b7",
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
      // color: "#000000"
    }
  },
  dividerInset: {
    margin: `5px 0 0 ${theme.spacing(9)}px`,
  },
}));