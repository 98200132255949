import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Button,
  TextField,

  Typography,
} from '@material-ui/core';


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';


import {
  updatePP_TD_TC_TTD,
  updatePP_TD_TC_DSD,
  updatePP_TD_TC_CPD,
} from 'actions/propertyArray';

import { makeLabelVal } from "common/util";



import {
  updateOpenDialog,
} from "actions/settings";


import {
  PP_TD_TC_TTD,
  PP_TD_TC_DSD,
  PP_TD_TC_CPD,
} from "constants";

import { InputDialog } from "views/InputDialog"


/**
 * 固体物性.熱力学量.熱伝導率
 * @param {*} props 
 * @returns 
 */
const ThermalConductibityDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');

  //温度依存性
  const [pp_td_tc_ttdValue, setPp_td_tc_ttdValue] = useState('');
  //延伸率依存性
  const [pp_td_tc_dsdValue, setPp_td_tc_dsdValue] = useState('');
  //圧力依存性
  const [pp_td_tc_cpdValue, setPp_td_tc_cpdValue] = useState('');


  const selector_pp_td_tc_ttd = useSelector((state) => state.propertyArray.pp_td_tc_ttd);
  const selector_pp_td_tc_dsd = useSelector((state) => state.propertyArray.pp_td_tc_dsd);
  const selector_pp_td_tc_cpd = useSelector((state) => state.propertyArray.pp_td_tc_cpd);

  const selector_openDialog = useSelector((state) => state.settings.openDialog);


  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

      let new_val = {
        inputType: inputType || '',
        inputValue: inputValue || '',
        inputUnit: inputUnit || '',
        inputFactor: inputFactor || '',
        inputComment: inputComment || '',
        inputImage: inputImage || '',
        inputCSVFile: inputCSVFile || '',
        inputPreview: inputPreview || ''
        
      };
  
      switch (selectedNode) {
        case PP_TD_TC_TTD:
          setPp_td_tc_ttdValue(makeLabelVal(new_val));
          props.updatePP_TD_TC_TTD(new_val);
          break;
  
  
        case PP_TD_TC_DSD:
          setPp_td_tc_dsdValue(makeLabelVal(new_val));
          props.updatePP_TD_TC_DSD(new_val);
          break;
  
  
        case PP_TD_TC_CPD:
          setPp_td_tc_cpdValue(makeLabelVal(new_val));
          props.updatePP_TD_TC_CPD(new_val);
          break;
      

    }


  }

  // const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile) => {
 
  //   if (inputType == undefined && inputValue == undefined && inputUnit == undefined && 
  //       inputFactor == undefined && inputComment == undefined) {

  //   } else if (inputType == '' && inputValue == '' && inputUnit == '' && 
  //     inputFactor == '' && inputComment == ''  ){
         
  //     switch (selectedNode) {
  //       case PP_TD_TC_TTD:
  //         setPp_td_tc_ttdValue('');
  //         props.updatePP_TD_TC_TTD('');
  //         break;
  
  
  //       case PP_TD_TC_DSD:
  //         setPp_td_tc_dsdValue(makeLabelVal(new_val));
  //         props.updatePP_TD_TC_DSD(new_val);
  //         break;
  
  
  //       case PP_TD_TC_CPD:
  //         setPp_td_tc_cpdValue(makeLabelVal(new_val));
  //         props.updatePP_TD_TC_CPD(new_val);
  //         break;
  //     }

  //  } else {
  //     let new_val = {
  //       inputType: inputType || '',
  //       inputValue: inputValue || '',
  //       inputUnit: inputUnit || '',
  //       inputFactor: inputFactor || '',
  //       inputComment: inputComment || '',
  //       inputImage: inputImage || '',
  //       inputCSVFile: inputCSVFile || ''
  //     };
  
  //     switch (selectedNode) {
  //       case PP_TD_TC_TTD:
  //         setPp_td_tc_ttdValue(makeLabelVal(new_val));
  //         props.updatePP_TD_TC_TTD(new_val);
  //         break;
  
  
  //       case PP_TD_TC_DSD:
  //         setPp_td_tc_dsdValue(makeLabelVal(new_val));
  //         props.updatePP_TD_TC_DSD(new_val);
  //         break;
  
  
  //       case PP_TD_TC_CPD:
  //         setPp_td_tc_cpdValue(makeLabelVal(new_val));
  //         props.updatePP_TD_TC_CPD(new_val);
  //         break;
  //     }

  //   }


  // }


  const showChargerSearchDialog = (selected, label) => {

    setSelectedNode(selected);

    switch (selected) {
      case PP_TD_TC_TTD:
        setDialogLabel('温度依存性');
        break;

      case PP_TD_TC_DSD:
        setDialogLabel('延伸率依存性');
        break;

      case PP_TD_TC_CPD:
        setDialogLabel('圧力依存性');
        break;
    }


    props.updateOpenDialog(true);
  }


  useEffect(() => {
    setPp_td_tc_ttdValue(makeLabelVal(selector_pp_td_tc_ttd));
    setPp_td_tc_dsdValue(makeLabelVal(selector_pp_td_tc_dsd));
    setPp_td_tc_cpdValue(makeLabelVal(selector_pp_td_tc_cpd));
  }, []);




  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'熱力学量'} saveInput={saveInput} selectedNode={selectedNode} />

        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">熱伝導率</Typography>} style={{ backgroundColor: 'lightgray' }}
            subheader={<Typography variant="h5">- 熱力学量 -</Typography>}
          />
          <Divider />

          <CardContent>

            <Grid container spacing={2}>

              <Grid item md={6} xs={6}>
                <TextField
                  label="温度依存性"
                  name={PP_TD_TC_TTD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_TC_TTD) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_TC_TTD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_TC_TTD) } }}
                  type="text"
                  value={pp_td_tc_ttdValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="延伸率依存性"
                  name={PP_TD_TC_DSD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_TC_DSD) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_TC_DSD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_TC_DSD) } }}
                  type="text"
                  value={pp_td_tc_dsdValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="圧力依存性"
                  name={PP_TD_TC_CPD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_TC_CPD) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_TC_CPD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_TC_CPD) }}}
                  type="text"
                  value={pp_td_tc_cpdValue || ''}
                  variant="outlined"
                />
              </Grid>

            </Grid>


          </CardContent>

        </Card>
      </div>
    </div>
  );
};

ThermalConductibityDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updatePP_TD_TC_TTD,
    updatePP_TD_TC_DSD,
    updatePP_TD_TC_CPD,

    updateOpenDialog
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    propertyArray: state.propertyArray,
    settings: state.settings
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ThermalConductibityDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
