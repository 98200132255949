import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link as RouterLink, withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { makeLabelVal } from "common/util";


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { InputDialog } from "views/InputDialog"

import {
  updateCHD,
} from 'actions/degradationArray';

import {
  updateOpenDialog,
} from "actions/settings";


import {  
  CHD
} from "constants.js";


const ChemicalDegradationDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');

  const [chdValue, setChdValue] = useState('');

  const selector_chd = useSelector((state) => state.degradationArray.chd);

  const selector_openDialog = useSelector((state) => state.settings.openDialog);

  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {
      case CHD:
        setChdValue(makeLabelVal(new_val));
        props.updateCHD(new_val);
        break;
    }

  }

  const showChargerSearchDialog = (selected) => {

    setSelectedNode(selected);

    switch (selected) {
      case CHD:
        setDialogLabel('化学分解');
        break;
    }

    props.updateOpenDialog(true);
  }


  useEffect(() => {
    setChdValue(makeLabelVal(selector_chd));
  }, []);


  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'分解'} saveInput={saveInput} selectedNode={selectedNode}/>

        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader align='center' title={<Typography variant="h4">分解</Typography>} style={{ backgroundColor: 'lightgray' }}/>
          <Divider />

          <CardContent>
            <Grid container spacing={2}>

              <Grid item md={6} xs={6}>
                <TextField
                  label="化学分解"
                  name={CHD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(CHD) }}
                  onBeforeInput={() => { showChargerSearchDialog(CHD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(CHD) } }}
                  type="text"
                  value={chdValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>

            </Grid>
          </CardContent>

        </Card>
      </div>
    </div>
  );
};

ChemicalDegradationDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateCHD,
    updateOpenDialog,
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    degradationArray: state.degradationArray,
    settings: state.settings
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ChemicalDegradationDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
