/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import DashboardIcon from '@material-ui/icons/Dashboard';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import ScheduleIcon from '@material-ui/icons/Schedule';
import TimelineIcon from '@material-ui/icons/Timeline'

import { Grid } from '@material-ui/core';
import { SidebarNav } from 'SidebarNav';


const HowtomakeSecondMenu = props => {
  const { className, ...rest } = props;
  const classes = useStyles();


  var pages_first = [

    {
      title: '作り⽅',
      href: '/synthesis/howtomake',
      icon: <DashboardIcon />
    },

    {
      title: '反応条件',
      href: '/synthesis/reactioncondition',
      disable: false,
      icon: <ScheduleIcon />
    },

    {
      title: '結果',
      href: '/synthesis/result',
      disable: false,
      icon: <TimelineIcon />
    },
    
    {
      title: 'バイオモノマー生成',
      href: '/synthesis/biomonomersynthesis',
      disable: false,
      icon: <TimelineIcon />
    },

  ];


  var pages = [
    {
      title: '購⼊',
      href: '/synthesis/howtomake/buy',
      disable: false,
      icon: <TimelapseIcon />
    },

    {
      title: '製造プロセス',
      href: '/synthesis/howtomake/process',
      disable: false,
      icon: <TimelapseIcon />
    },

    {
      title: '重合名(連鎖)',
      href: '/synthesis/howtomake/polymerization/chain',
      disable: false,
      icon: <TimelapseIcon />
    },
    {
      title: '重合名(逐次)',
      href: '/synthesis/howtomake/polymerization/step',
      disable: false,
      icon: <TimelapseIcon />
    },
  ];



  return (

    <div className={classes.root}>
      <Grid container item spacing={0}
        direction="row" justifyContent="flex-start" alignItems="flex-start"
      >
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <SidebarNav
            className={classes.nav}
            pages={pages_first}
          />
        </Grid>


        <Grid item lg={6} md={6} sm={6} xs={6}>

          <SidebarNav
            className={classes.nav}
            pages={pages}
          />
        </Grid>
      </Grid>
    </div>
  );
};

HowtomakeSecondMenu.propTypes = {
  className: PropTypes.string,
};



export default HowtomakeSecondMenu;


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
  },
}));