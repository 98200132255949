import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import {

  updateSPY_NMR,
  updateSPY_UVI,
  updateSPY_FLU,
  updateSPY_RAM,
  updateSPY_CD,
  updateSPY_LS,
  updateSPY_ELL,
  updateSPY_NSC,


} from 'actions/measurementArray';


import { makeLabelVal } from "common/util";

import {
  updateOpenDialog,
} from "actions/settings";

import { InputDialog } from "views/InputDialog"

import {
  
  SPY_NMR,
  SPY_UVI,
  SPY_FLU,
  SPY_RAM,
  SPY_CD,
  SPY_LS,
  SPY_ELL,
  SPY_NSC,

} from "constants.js";



const MolecularDynamicsCalculationsDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');

  const [spyNmrValue, setSpyNmrValue] = useState('');
  const [spyUviValue, setSpyUviValue] = useState('');
  const [spyFluValue, setSpyFluValue] = useState('');
  const [spyRamValue, setSpyRamValue] = useState('');
  const [spyCdValue, setSpyCdValue] = useState('');
  const [spyLsValue, setSpyLsValue] = useState('');
  const [spyEllValue, setSpyEllValue] = useState('');
  const [spyNscValue, setSpyNscValue] = useState('');

  const selector_spy_nmr = useSelector((state) => state.measurementArray.spy_nmr);
  const selector_spy_uvi = useSelector((state) => state.measurementArray.spy_uvi);
  const selector_spy_flu = useSelector((state) => state.measurementArray.spy_flu);
  const selector_spy_ram = useSelector((state) => state.measurementArray.spy_ram);
  const selector_spy_cd = useSelector((state) => state.measurementArray.spy_cd);
  const selector_spy_ls = useSelector((state) => state.measurementArray.spy_ls);
  const selector_spy_ell = useSelector((state) => state.measurementArray.spy_ell);
  const selector_spy_nsc = useSelector((state) => state.measurementArray.spy_nsc);

  const selector_openDialog = useSelector((state) => state.settings.openDialog);

  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {
      case SPY_NMR:
        setSpyNmrValue(makeLabelVal(new_val));
        props.updateSPY_NMR(new_val);
        break;

      case SPY_UVI:
        setSpyUviValue(makeLabelVal(new_val));
        props.updateSPY_UVI(new_val);
        break;

      case SPY_FLU:
        setSpyFluValue(makeLabelVal(new_val));
        props.updateSPY_FLU(new_val);
        break;

      case SPY_RAM:
        setSpyRamValue(makeLabelVal(new_val));
        props.updateSPY_RAM(new_val);
        break;

      case SPY_CD:
        setSpyCdValue(makeLabelVal(new_val));
        props.updateSPY_CD(new_val);
        break;

      case SPY_LS:
        setSpyLsValue(makeLabelVal(new_val));
        props.updateSPY_LS(new_val);
        break;

      case SPY_ELL:
        setSpyEllValue(makeLabelVal(new_val));
        props.updateSPY_ELL(new_val);
        break;

      case SPY_NSC:
        setSpyNscValue(makeLabelVal(new_val));
        props.updateSPY_NSC(new_val);
        break;
    }
  }


  const showChargerSearchDialog = (selected) => {

    setSelectedNode(selected);

    switch (selected) {
      case SPY_NMR:
        setDialogLabel('核磁気共鳴分光法');
        break;

      case SPY_UVI:
        setDialogLabel('紫外・可視分光法');
        break;

      case SPY_FLU:
        setDialogLabel('蛍光分光法');
        break;

      case SPY_RAM:
        setDialogLabel('ラマン分光法');
        break;

      case SPY_CD:
        setDialogLabel('円二色性');
        break;

      case SPY_LS:
        setDialogLabel('光散乱法');
        break;

      case SPY_ELL:
        setDialogLabel('分光エリプソメトリー');
        break;

      case SPY_NSC:
        setDialogLabel('中性子散乱法');
        break;
    }
    props.updateOpenDialog(true);
  }




  useEffect(() => {

 
    setSpyNmrValue(makeLabelVal(selector_spy_nmr));
    setSpyUviValue(makeLabelVal(selector_spy_uvi));
    setSpyFluValue(makeLabelVal(selector_spy_flu));
    setSpyRamValue(makeLabelVal(selector_spy_ram));
    setSpyCdValue(makeLabelVal(selector_spy_cd));
    setSpyLsValue(makeLabelVal(selector_spy_ls));
    setSpyEllValue(makeLabelVal(selector_spy_ell));
    setSpyNscValue(makeLabelVal(selector_spy_nsc));

  }, []);



  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'分光'} saveInput={saveInput} selectedNode={selectedNode} />

        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">分光</Typography>} style={{ backgroundColor: 'lightgray' }} />

          <Divider />

          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6} xs={6}>
                <TextField
                  label="核磁気共鳴分光法"
                  name={SPY_NMR}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(SPY_NMR) }}
                  onBeforeInput={() => { showChargerSearchDialog(SPY_NMR) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(SPY_NMR) } }}
                  type="text"
                  value={spyNmrValue || ''}
                  variant="outlined"
                  autoFocus={true}

                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="紫外・可視分光法"
                  name={SPY_UVI}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(SPY_UVI) }}
                  onBeforeInput={() => { showChargerSearchDialog(SPY_UVI) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(SPY_UVI) } }}
                  type="text"
                  value={spyUviValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="蛍光分光法"
                  name={SPY_FLU}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(SPY_FLU) }}
                  onBeforeInput={() => { showChargerSearchDialog(SPY_FLU) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(SPY_FLU) } }}
                  type="text"
                  value={spyFluValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="ラマン分光法"
                  name={SPY_RAM}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(SPY_RAM) }}
                  onBeforeInput={() => { showChargerSearchDialog(SPY_RAM) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(SPY_RAM) } }}
                  type="text"
                  value={spyRamValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="円二色性"
                  name={SPY_CD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(SPY_CD) }}
                  onBeforeInput={() => { showChargerSearchDialog(SPY_CD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(SPY_CD) } }}
                  type="text"
                  value={spyCdValue|| ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="光散乱法"
                  name={SPY_LS}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(SPY_LS) }}
                  onBeforeInput={() => { showChargerSearchDialog(SPY_LS) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(SPY_LS) } }}

                  type="text"
                  value={spyLsValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="分光エリプソメトリー"
                  name={SPY_ELL}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(SPY_ELL) }}
                  onBeforeInput={() => { showChargerSearchDialog(SPY_ELL) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(SPY_ELL) } }}
                  type="text"
                  value={spyEllValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="中性子散乱法"
                  name={SPY_NSC}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(SPY_NSC) }}
                  onBeforeInput={() => { showChargerSearchDialog(SPY_NSC) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(SPY_NSC) } }}
                  type="text"
                  value={spyNscValue || ''}
                  variant="outlined"
                />
              </Grid>

            </Grid>
          </CardContent>

        </Card>
      </div>
    </div>
  );
};

MolecularDynamicsCalculationsDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateSPY_NMR,
    updateSPY_UVI,
    updateSPY_FLU,
    updateSPY_RAM,
    updateSPY_CD,
    updateSPY_LS,
    updateSPY_ELL,
    updateSPY_NSC,
    
    updateOpenDialog
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    calculationArray: state.calculationArray,
    settings: state.settings
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MolecularDynamicsCalculationsDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
