import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';


import {
  updatePP_TD_SP_SPC,
  updatePP_TD_SP_STD,
} from 'actions/propertyArray';

import { makeLabelVal } from "common/util";


import {
  updateOpenDialog,
} from "actions/settings";

import { InputDialog } from "views/InputDialog"

import {
  PP_TD_SP_SPC,
  PP_TD_SP_STD,
} from "constants";


/**
 * 固体物性.熱力学量.比熱
 * @param {*} props 
 * @returns 
 */
const SpecificHeatDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');


  const [selectedNode, setSelectedNode] = useState('');

  const [pp_td_sp_spcValue, setPp_td_sp_spcValue] = useState('');
  const [pp_td_sp_stdValue, setPp_td_sp_stdValue] = useState('');

  const selector_pp_td_sp_spc = useSelector((state) => state.propertyArray.pp_td_sp_spc);
  const selector_pp_td_sp_std = useSelector((state) => state.propertyArray.pp_td_sp_std);

  const selector_openDialog = useSelector((state) => state.settings.openDialog);

  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {
      case PP_TD_SP_SPC:
        setPp_td_sp_spcValue(makeLabelVal(new_val));
        props.updatePP_TD_SP_SPC(new_val);
        break;

      case PP_TD_SP_STD:
        setPp_td_sp_stdValue(makeLabelVal(new_val));
        props.updatePP_TD_SP_STD(new_val);
        break;
    }

  }

  const showChargerSearchDialog = (selected) => {

    setSelectedNode(selected);

    switch (selected) {
      case PP_TD_SP_SPC:
        setDialogLabel('比熱曲線');
        break;

      case PP_TD_SP_STD:
        setDialogLabel('温度依存性');
        break;
    }

    props.updateOpenDialog(true);
  }


  useEffect(() => {
    setPp_td_sp_spcValue(makeLabelVal(selector_pp_td_sp_spc));
    setPp_td_sp_stdValue(makeLabelVal(selector_pp_td_sp_std));
  }, []);



  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'熱力学量'} saveInput={saveInput} selectedNode={selectedNode} />


        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader align='center' title={<Typography variant="h4">比熱</Typography>} style={{ backgroundColor: 'lightgray' }}
            subheader={<Typography variant="h5">- 熱力学量 -</Typography>}
          />

          <Divider />

          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6} xs={6}>
                <TextField
                  label="比熱曲線"
                  name={PP_TD_SP_SPC}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_SP_SPC) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_SP_SPC) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_SP_SPC) } }}
                  type="text"
                  value={pp_td_sp_spcValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="温度依存性"
                  name={PP_TD_SP_STD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_SP_STD) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_SP_STD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_SP_STD) }}}
                  type="text"
                  value={pp_td_sp_stdValue || ''}
                  variant="outlined"
                />
              </Grid>
            </Grid>

          </CardContent>
        </Card>
      </div>
    </div>
  );
};

SpecificHeatDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};


const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updatePP_TD_SP_SPC,
    updatePP_TD_SP_STD,

    updateOpenDialog,
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    propertyArray: state.propertyArray,
    settings: state.settings
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SpecificHeatDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
