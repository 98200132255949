import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import {


  updateMD_COND_ENS,
  updateMD_COND_FRC_FTBL,
  updateMD_COND_ICRD_ICIMAGE,
  updateMD_COND_ICRD_ICPDB,
  updateMD_COND_ICRD_ICDCD,
  updateMD_COND_ICRD_ICPSF,
  updateMD_COND_OTCOND,


} from 'actions/calculationArray';


import { makeLabelVal } from "common/util";

import {
  updateOpenDialog,
} from "actions/settings";

import { InputDialog } from "views/InputDialog"

import {

  MD_COND_ENS,
  MD_COND_FRC_FTBL,
  MD_COND_ICRD_ICIMAGE, 
  MD_COND_ICRD_ICPDB, 
  MD_COND_ICRD_ICDCD,
  MD_COND_ICRD_ICPSF,
  MD_COND_OTCOND,


} from "constants.js";



const CalculationConditionsDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');

  const [condEnsValue, setCondEnsValue] = useState('');
  const [frcFtblValue, setfrcFtblValue] = useState('');
  const [icrdIcimageValue, setIcrdIcimageValue] = useState('');
  const [icrdIcpdbValue, setIcrdIcpdbValue] = useState('');
  const [icrdIcdcdValue, setIcrdIcdcdValue] = useState('');
  const [icrdIcpsfValue, setIcrdIcpsfValue] = useState('');
  const [condOtcondValue, setCondOtcondValue] = useState('');


  const selector_MD_cond_ens = useSelector((state) => state.calculationArray.MD_cond_ens);
  const selector_MD_cond_frc_ftbl = useSelector((state) => state.calculationArray.MD_cond_frc_ftbl);
  const selector_MD_cond_icrd_icimage = useSelector((state) => state.calculationArray.MD_cond_icrd_icimage);
  const selector_MD_cond_icrd_icPDB = useSelector((state) => state.calculationArray.MD_cond_icrd_icPDB);
  const selector_MD_cond_icrd_icDCD = useSelector((state) => state.calculationArray.MD_cond_icrd_icDCD);
  const selector_MD_cond_icrd_icPSF = useSelector((state) => state.calculationArray.MD_cond_icrd_icPSF);
  const selector_MD_cond_otcond = useSelector((state) => state.calculationArray.MD_cond_otcond);


  const selector_openDialog = useSelector((state) => state.settings.openDialog);


  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {

      case MD_COND_ENS:
        setCondEnsValue(makeLabelVal(new_val));
        props.updateMD_COND_ENS(new_val);
        break;
      
      case MD_COND_FRC_FTBL:
        setfrcFtblValue(makeLabelVal(new_val));
        props.updateMD_COND_FRC_FTBL(new_val);
        break;

      case MD_COND_ICRD_ICIMAGE:
        setIcrdIcimageValue(makeLabelVal(new_val));
        props.updateMD_COND_ICRD_ICIMAGE(new_val);
        break;
      
      case MD_COND_ICRD_ICPDB:
        setIcrdIcpdbValue(makeLabelVal(new_val));
        props.updateMD_COND_ICRD_ICPDB(new_val);
        break;

      case MD_COND_ICRD_ICDCD:
        setIcrdIcdcdValue(makeLabelVal(new_val));
        props.updateMD_COND_ICRD_ICDCD(new_val);
        break;

      case MD_COND_ICRD_ICPSF:
        setIcrdIcpsfValue(makeLabelVal(new_val));
        props.updateMD_COND_ICRD_ICPSF(new_val);
        break;

      case MD_COND_OTCOND:
        setCondOtcondValue(makeLabelVal(new_val));
        props.updateMD_COND_OTCOND(new_val);
        break;

    }
  }


  const showChargerSearchDialog = (selected) => {


    setSelectedNode(selected);

    switch (selected) {
      case MD_COND_ENS:
        setDialogLabel('アンサンブル');
        break;
      
      case MD_COND_FRC_FTBL:
        setDialogLabel('表');
        break;

      case MD_COND_ICRD_ICIMAGE:
        setDialogLabel('画像');
        break;
      
      case MD_COND_ICRD_ICPDB:
        setDialogLabel('PDB');
        break;

      case MD_COND_ICRD_ICDCD:
        setDialogLabel('DCD');
        break;

      case MD_COND_ICRD_ICPSF:
        setDialogLabel('PSF');
        break;

      case MD_COND_OTCOND:
        setDialogLabel('その他計算条件');
        break;

  
    }

    props.updateOpenDialog(true);
  }




  useEffect(() => {

    setCondEnsValue(makeLabelVal(selector_MD_cond_ens));
    setfrcFtblValue(makeLabelVal(selector_MD_cond_frc_ftbl));
    setIcrdIcimageValue(makeLabelVal(selector_MD_cond_icrd_icimage));
    setIcrdIcpdbValue(makeLabelVal(selector_MD_cond_icrd_icPDB));
    setIcrdIcdcdValue(makeLabelVal(selector_MD_cond_icrd_icDCD));
    setIcrdIcpsfValue(makeLabelVal(selector_MD_cond_icrd_icPSF));
    setCondOtcondValue(makeLabelVal(selector_MD_cond_otcond));



  }, []);



  

  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'計算条件'} saveInput={saveInput} selectedNode={selectedNode} />

        <Card {...rest} className={clsx(classes.root, className)}>

        <CardHeader align='center' title={<Typography variant="h4">計算条件</Typography>} style={{ backgroundColor: 'lightgray' }}
            subheader={<Typography variant="h5">- 分子動力学計算 -</Typography>}
          />
          <Divider />

          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6} xs={6}>
                <TextField
                  label="アンサンブル"
                  name={MD_COND_ENS}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(MD_COND_ENS) }}
                  onBeforeInput={() => { showChargerSearchDialog(MD_COND_ENS) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(MD_COND_ENS) } }}
                  type="text"
                  value={condEnsValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>
              <Grid container item md={12} spacing={0}></Grid>
            </Grid>

              
            <Grid item md={12} spacing={0}>
              <Typography variant="h4">力場</Typography>
            </Grid>

            <Divider variant="inset" />

            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="表"
                  name={MD_COND_FRC_FTBL}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(MD_COND_FRC_FTBL) }}
                  onBeforeInput={() => { showChargerSearchDialog(MD_COND_FRC_FTBL) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(MD_COND_FRC_FTBL) } }}

                  type="text"
                  value={frcFtblValue || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid container item md={12} spacing={0}></Grid>
            </Grid>

            <Grid item md={12} spacing={0}>
              <Typography variant="h4">初期座標</Typography>
            </Grid>

            <Divider variant="inset" />

            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>


              <Grid item md={6} xs={6}>
                <TextField
                  label="画像"
                  name={MD_COND_ICRD_ICIMAGE}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(MD_COND_ICRD_ICIMAGE) }}
                  onBeforeInput={() => { showChargerSearchDialog(MD_COND_ICRD_ICIMAGE) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(MD_COND_ICRD_ICIMAGE) } }}

                  type="text"
                  value={icrdIcimageValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="PDB"
                  name={MD_COND_ICRD_ICPDB}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(MD_COND_ICRD_ICPDB) }}
                  onBeforeInput={() => { showChargerSearchDialog(MD_COND_ICRD_ICPDB) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(MD_COND_ICRD_ICPDB) } }}
                  type="text"
                  value={icrdIcpdbValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="DCD"
                  name={MD_COND_ICRD_ICDCD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(MD_COND_ICRD_ICDCD) }}
                  onBeforeInput={() => { showChargerSearchDialog(MD_COND_ICRD_ICDCD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(MD_COND_ICRD_ICDCD) } }}
                  type="text"
                  value={icrdIcdcdValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="PSF"
                  name={MD_COND_ICRD_ICPSF}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(MD_COND_ICRD_ICPSF) }}
                  onBeforeInput={() => { showChargerSearchDialog(MD_COND_ICRD_ICPSF) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(MD_COND_ICRD_ICPSF) } }}
                  type="text"
                  value={icrdIcpsfValue || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid container item md={12} spacing={0}></Grid>
              <Grid container item md={12} spacing={0}></Grid>
            </Grid>


            <Divider variant="inset" />

              <Grid container spacing={2}>
                <Grid container item md={12} spacing={0}></Grid>

                <Grid item md={6} xs={6}>
                <TextField
                  label="その他計算条件"
                  name={MD_COND_OTCOND}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(MD_COND_OTCOND) }}
                  onBeforeInput={() => { showChargerSearchDialog(MD_COND_OTCOND) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(MD_COND_OTCOND) } }}
                  type="text"
                  value={condOtcondValue || ''}
                  variant="outlined"
                />
              </Grid>           

            </Grid>
          </CardContent>

        </Card>
      </div>
    </div>
  );
};

CalculationConditionsDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateMD_COND_ENS,
    updateMD_COND_FRC_FTBL,
    updateMD_COND_ICRD_ICIMAGE,
    updateMD_COND_ICRD_ICPDB,
    updateMD_COND_ICRD_ICDCD,
    updateMD_COND_ICRD_ICPSF,
    updateMD_COND_OTCOND,

    updateOpenDialog
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    calculationArray: state.calculationArray,
    settings: state.settings
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CalculationConditionsDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
