
//熱力学量
export const UPDATE_PP_TD_DEN = 'pp_td_den';
export const UPDATE_PP_TD_SV = 'pp_td_sv';
export const UPDATE_PP_TD_AR = 'pp_td_ar';
export const UPDATE_PP_TD_ST = 'pp_td_st';
export const UPDATE_PP_TD_TROP = 'pp_td_trop';
export const UPDATE_PP_TD_ENTHAL = 'pp_td_enthal';
export const UPDATE_PP_TD_CR = 'pp_td_cr';
export const UPDATE_PP_TD_TE = 'pp_td_te';

//気体透過性
export const UPDATE_PP_TD_GP_WP = 'pp_td_gp_wp';
export const UPDATE_PP_TD_GP_MP = 'pp_td_gp_mp';
export const UPDATE_PP_TD_GP_FTD = 'pp_td_gp_ftd';
export const UPDATE_PP_TD_GP_TPD = 'pp_td_gp_tpd';
export const UPDATE_PP_TD_GP_HD = 'pp_td_gp_hd';


//流動特性
export const UPDATE_PP_TD_FC_MV_SD = 'pp_td_fc_mv_sd';
export const UPDATE_PP_TD_FC_MV_MTD = 'pp_td_fc_mv_mtd';
export const UPDATE_PP_TD_FC_MV_PD = 'pp_td_fc_mv_pd';
export const UPDATE_PP_TD_FC_VC = 'pp_td_fc_vc';


//比熱
export const UPDATE_PP_TD_SP_SPC = 'pp_td_sp_spc';
export const UPDATE_PP_TD_SP_STD = 'pp_td_sp_std';

//熱伝導率
export const UPDATE_PP_TD_TC_TTD = 'pp_td_tc_ttd';
export const UPDATE_PP_TD_TC_DSD = 'pp_td_tc_dsd';
export const UPDATE_PP_TD_TC_CPD = 'pp_td_tc_cpd';


//熱拡散係数
export const UPDATE_PP_TD_TDIF_CMM = 'pp_td_tdif_cmm';
export const UPDATE_PP_TD_TDIF_AMO = 'pp_td_tdif_amo';


//各種温度
export const UPDATE_PP_TD_TEMP_TG_GPD = 'PP_TD_TEMP_TG_GPD';
export const UPDATE_PP_TD_TEMP_MLP_MPD = 'PP_TD_TEMP_MLP_MPD';
export const UPDATE_PP_TD_TEMP_DSC = 'PP_TD_TEMP_DSC';
export const UPDATE_PP_TD_TEMP_TGA = 'PP_TD_TEMP_TGA';
export const UPDATE_PP_TD_TEMP_WR5 = 'PP_TD_TEMP_WR5';
export const UPDATE_PP_TD_TEMP_EMB = 'PP_TD_TEMP_EMB';
export const UPDATE_PP_TD_TEMP_HDT = 'PP_TD_TEMP_HDT';


//燃焼特性
export const UPDATE_PP_TD_BCHAR_FIT = 'UPDATE_PP_TD_BCHAR_FIT'; //引火温度
export const UPDATE_PP_TD_BCHAR_HDC = 'UPDATE_PP_TD_BCHAR_HDC'; //熱分解曲線
export const UPDATE_PP_TD_BCHAR_CH = 'UPDATE_PP_TD_BCHAR_CH'; //燃焼熱
export const UPDATE_PP_TD_BCHAR_OI = 'UPDATE_PP_TD_BCHAR_OI'; //酸素指数
export const UPDATE_PP_TD_BCHAR_HRP = 'UPDATE_PP_TD_BCHAR_HRP'; //耐熱性



//摩擦係数
export const UPDATE_FRICTION_COEFFICIENT = 'FRICTION_COEFFICIENT';



//酵素分解
//  分解率
//  - 活性測定
//    酵素反応データ
export const UPDATE_ENZYMATIC_DECOMPOSITION_RATE = 'ENZYMATIC_DECOMPOSITION_RATE'; //酵素分解.分解率
export const UPDATE_ENZYMATIC_ENZYMATIC_REACTION = 'ENZYMATIC_ENZYMATIC_REACTION'; //酵素分解.酵素反応データ



//⽣分解度
//  - ⽣分解度グラフ
//    曲線データ
export const UPDATE_BIODEGRADABILITY_CURVE = 'BIODEGRADABILITY_CURVE';



//崩壊度
//  - 重量変化グラフ
//    棒グラフデータ
export const UPDATE_BAR_GRAPH = 'BAR_GRAPH';

//構造
//  - X線
//    グラフ
//  - 顕微鏡法
//    画像
//  結晶化度
export const UPDATE_STRUCTURE_XRAY_GRAPH = 'STRUCTURE_XRAY_GRAPH';
export const UPDATE_STRUCTURE_MICROSCOPE_IMAGE = 'STRUCTURE_MICROSCOPE_IMAGE';
export const UPDATE_STRUCTURE_CRYSTALLINITY_DEGREE = 'STRUCTURE_CRYSTALLINITY_DEGREE';


export const updatePP_TD_DEN = param1 => {

  return {
    type: UPDATE_PP_TD_DEN,
    payload: param1
  }
}


export const updatePP_TD_SV = param1 => {

  return {
    type: UPDATE_PP_TD_SV,
    payload: param1
  }
}

export const updatePP_TD_AR = param1 => {

  return {
    type: UPDATE_PP_TD_AR,
    payload: param1
  }
}

export const updatePP_TD_ST = param1 => {

  return {
    type: UPDATE_PP_TD_ST,
    payload: param1
  }
}


export const updatePP_TD_TROP = param1 => {

  return {
    type: UPDATE_PP_TD_TROP,
    payload: param1
  }
}


export const updatePP_TD_ENTHAL = param1 => {

  return {
    type: UPDATE_PP_TD_ENTHAL,
    payload: param1
  }
}

export const updatePP_TD_CR = param1 => {

  return {
    type: UPDATE_PP_TD_CR,
    payload: param1
  }
}
export const updatePP_TD_TE = param1 => {

  return {
    type: UPDATE_PP_TD_TE,
    payload: param1
  }
}


//気体透過性
export const updatePP_TD_GP_WP = param1 => {
  return {
    type: UPDATE_PP_TD_GP_WP,
    payload: param1
  }
}

export const updatePP_TD_GP_MP = param1 => {
  return {
    type: UPDATE_PP_TD_GP_MP,
    payload: param1
  }
}

export const updatePP_TD_GP_FTD = param1 => {
  return {
    type: UPDATE_PP_TD_GP_FTD,
    payload: param1
  }
}

export const updatePP_TD_GP_TPD = param1 => {
  return {
    type: UPDATE_PP_TD_GP_TPD,
    payload: param1
  }
}

export const updatePP_TD_GP_HD = param1 => {
  return {
    type: UPDATE_PP_TD_GP_HD,
    payload: param1
  }
}



// 流動特性
export const updatePP_TD_FC_MV_SD = param1 => {
  return {
    type: UPDATE_PP_TD_FC_MV_SD,
    payload: param1
  }
}


export const updatePP_TD_FC_MV_MTD = param1 => {
  return {
    type: UPDATE_PP_TD_FC_MV_MTD,
    payload: param1
  }
}

export const updatePP_TD_FC_MV_PD = param1 => {
  return {
    type: UPDATE_PP_TD_FC_MV_PD,
    payload: param1
  }
}

export const updatePP_TD_FC_VC = param1 => {
  return {
    type: UPDATE_PP_TD_FC_VC,
    payload: param1
  }
}



//比熱
export const updatePP_TD_SP_SPC = param1 => {
  return {
    type: UPDATE_PP_TD_SP_SPC,
    payload: param1
  }
}

export const updatePP_TD_SP_STD = param1 => {
  return {
    type: UPDATE_PP_TD_SP_STD,
    payload: param1
  }
}


//熱伝導率
export const updatePP_TD_TC_TTD = param1 => {
  return {
    type: UPDATE_PP_TD_TC_TTD,
    payload: param1
  }
}

export const updatePP_TD_TC_DSD = param1 => {
  return {
    type: UPDATE_PP_TD_TC_DSD,
    payload: param1
  }
}

export const updatePP_TD_TC_CPD = param1 => {
  return {
    type: UPDATE_PP_TD_TC_CPD,
    payload: param1
  }
}

//熱拡散係数
export const updatePP_TD_TDIF_CMM = param1 => {
  return {
    type: UPDATE_PP_TD_TDIF_CMM,
    payload: param1
  }
}

export const updatePP_TD_TDIF_AMO = param1 => {
  return {
    type: UPDATE_PP_TD_TDIF_AMO,
    payload: param1
  }
}


//各種温度
export const updatePP_TD_TEMP_TG_GPD = param1 => {
  return {
    type: UPDATE_PP_TD_TEMP_TG_GPD,
    payload: param1
  }
}

export const updatePP_TD_TEMP_MLP_MPD = param1 => {
  return {
    type: UPDATE_PP_TD_TEMP_MLP_MPD,
    payload: param1
  }
}

export const updatePP_TD_TEMP_DSC = param1 => {
  return {
    type: UPDATE_PP_TD_TEMP_DSC,
    payload: param1
  }
}

export const updatePP_TD_TEMP_TGA = param1 => {
  return {
    type: UPDATE_PP_TD_TEMP_TGA,
    payload: param1
  }
}

export const updatePP_TD_TEMP_WR5 = param1 => {
  return {
    type: UPDATE_PP_TD_TEMP_WR5,
    payload: param1
  }
}

export const updatePP_TD_TEMP_EMB = param1 => {
  return {
    type: UPDATE_PP_TD_TEMP_EMB,
    payload: param1
  }
}

export const updatePP_TD_TEMP_HDT = param1 => {
  return {
    type: UPDATE_PP_TD_TEMP_HDT,
    payload: param1
  }
}



//燃焼特性
export const updatePP_TD_BCHAR_FIT = param1 => {
  return {
    type: UPDATE_PP_TD_BCHAR_FIT,
    payload: param1
  }
}

export const updatePP_TD_BCHAR_HDC = param1 => {
  return {
    type: UPDATE_PP_TD_BCHAR_HDC,
    payload: param1
  }
}

export const updatePP_TD_BCHAR_CH = param1 => {
  return {
    type: UPDATE_PP_TD_BCHAR_CH,
    payload: param1
  }
}

export const updatePP_TD_BCHAR_OI = param1 => {
  return {
    type: UPDATE_PP_TD_BCHAR_OI,
    payload: param1
  }
}

export const updatePP_TD_BCHAR_HRP = param1 => {
  return {
    type: UPDATE_PP_TD_BCHAR_HRP,
    payload: param1
  }
}


//摩擦係数
export const updateFRICTION_COEFFICIENT = param1 => {
  return {
    type: UPDATE_FRICTION_COEFFICIENT,
    payload: param1
  }
}


//酵素分解.分解率
export const updateENZYMATIC_DECOMPOSITION_RATE = param1 => {
  return {
    type: UPDATE_ENZYMATIC_DECOMPOSITION_RATE,
    payload: param1
  }
}

//酵素分解.酵素反応データ
export const updateENZYMATIC_ENZYMATIC_REACTION = param1 => {
  return{
    type: UPDATE_ENZYMATIC_ENZYMATIC_REACTION,
    payload: param1
  }
}

//⽣分解度.⽣分解度グラフ.曲線データ
export const updateBIODEGRADABILITY_CURVE = param1 => {
  return {
    type: UPDATE_BIODEGRADABILITY_CURVE,
    payload: param1
  }
}


//崩壊度
//  - 重量変化グラフ
//    棒グラフデータ
export const updateBAR_GRAPH = param1 => {
  return {
    type: UPDATE_BAR_GRAPH,
    payload: param1
  }
}


//構造.X線.グラフ
export const updateSTRUCTURE_XRAY_GRAPH = param1 => {
  return {
    type: UPDATE_STRUCTURE_XRAY_GRAPH,
    payload: param1
  }
}

//構造.顕微鏡法.画像
export const updateSTRUCTURE_MICROSCOPE_IMAGE = param1 => {
  return {
    type: UPDATE_STRUCTURE_MICROSCOPE_IMAGE,
    payload: param1
  }
}

//構造.結晶化度
export const updateSTRUCTURE_CRYSTALLINITY_DEGREE = param1 => {
  return {
    type: UPDATE_STRUCTURE_CRYSTALLINITY_DEGREE,
    payload: param1
  }
}

