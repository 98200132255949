import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';

import { Sidebar, Topbar  } from './components';

import { useEffect  } from 'react';


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updateDOCID,
  updateOVID,
  updateSAMPLE,
  updateSAMPLEID,
  updateGROUP,
  updateINST,
  updateKIND,
  updateCHST,
  updateCHST_IMG,
  updateEXPP,
  updateRESPP,
  updateEXPDATE,
  updateINPP,
  updateBMEMO
} from 'actions/overviewArray';

import {
  
  updatePP_TD_DEN,
  updatePP_TD_SV,
  updatePP_TD_AR,
  updatePP_TD_ST,
  updatePP_TD_TROP,
  updatePP_TD_ENTHAL,
  updatePP_TD_CR,
  updatePP_TD_TE,
  updatePP_TD_GP_WP,
  updatePP_TD_GP_MP,
  updatePP_TD_GP_FTD,
  updatePP_TD_GP_TPD,
  updatePP_TD_GP_HD,
  updatePP_TD_FC_MV_SD,
  updatePP_TD_FC_MV_MTD,
  updatePP_TD_FC_MV_PD,
  updatePP_TD_FC_VC,
  updatePP_TD_SP_SPC,
  updatePP_TD_SP_STD,
  updatePP_TD_TC_TTD,
  updatePP_TD_TC_DSD,
  updatePP_TD_TC_CPD,
  updatePP_TD_TDIF_CMM,
  updatePP_TD_TDIF_AMO,
  updatePP_TD_TEMP_TG_GPD,
  updatePP_TD_TEMP_MLP_MPD,
  updatePP_TD_TEMP_DSC,
  updatePP_TD_TEMP_TGA,
  updatePP_TD_TEMP_WR5,
  updatePP_TD_TEMP_EMB,
  updatePP_TD_TEMP_HDT,
  updatePP_TD_BCHAR_FIT,
  updatePP_TD_BCHAR_HDC,
  updatePP_TD_BCHAR_CH,
  updatePP_TD_BCHAR_OI,
  updatePP_TD_BCHAR_HRP,
  updateFRICTION_COEFFICIENT,
  updateENZYMATIC_DECOMPOSITION_RATE,
  updateENZYMATIC_ENZYMATIC_REACTION,
  updateBIODEGRADABILITY_CURVE,
  updateBAR_GRAPH,
  updateSTRUCTURE_XRAY_GRAPH,
  updateSTRUCTURE_MICROSCOPE_IMAGE,
  updateSTRUCTURE_CRYSTALLINITY_DEGREE,

} from 'actions/propertyArray'


import {
  
  updatePP_MS_CRT,
  updatePP_MS_STRREL,
  updatePP_MS_CRACK,
  updatePP_MS_FRAT,
  updatePP_MS_FATP,
  updatePP_MS_HARD,
  updatePP_MS_SSC_TEN_DEGSTRETCH,
  updatePP_MS_SSC_TEN_DEGELA,
  updatePP_MS_SSC_TEN_RUPSTR,
  updatePP_MS_SSC_TEN_STTD,
  updatePP_MS_SSC_TEN_STPD,
  updatePP_MS_SSC_TEN_BAIMAGE,
  updatePP_MS_SSC_BEND_BDSTRETCH,
  updatePP_MS_SSC_BEND_BDELA,
  updatePP_MS_SSC_BEND_BRSTR,
  updatePP_MS_SSC_BEND_BTD,
  updatePP_MS_SSC_BEND_BPD,
  updatePP_MS_SSC_BEND_BBAIMAGE,
  updatePP_MS_VEM_VTD,
  updatePP_MS_VEM_VPD,
  updatePP_MS_VEM_GDASH,
  updatePP_MS_VEM_GDASH2,
  updatePP_MS_VEM_LTAN,
  updatePP_MS_SHT_CHAR_CTD,
  updatePP_MS_SHT_IZOD_ITD,
} from 'actions/mechanicalArray'

import {
  updateRSLT_RT,
  updateRSLT_MCR,
  updateRSLT_RRATE,
  updateRSLT_CY,
  updateRSLT_PRATE_TUR,
  updateRSLT_PRATE_OCC,
  updateRSLT_PRATE_PDATA,
  updateRCON_PH,
  updateRCON_RTEMP,
  updateRCON_OTRC,
  updateHOM_PRIZE_SGP_PCON_NASP,
  updateHOM_PRIZE_SGP_PCON_ANSP,
  updateHOM_PRIZE_SGP_PCON_ARNSP,
  updateHOM_PRIZE_SGP_PCON_ARESP,
  updateHOM_PRIZE_SGP_PCON_OCP,
  updateHOM_PRIZE_SGP_PCON_TMCP,
  updateHOM_PRIZE_SGP_PCON_EP,
  updateHOM_PRIZE_SGP_PADD_PTDB,
  updateHOM_PRIZE_SGP_PADD_ROPA,
  updateHOM_PRIZE_SGP_PADD_CYPA,
  updateHOM_PRIZE_SGP_PADD_OTPA,
  updateHOM_PRIZE_SGP_ADCO,
  updateBUY_MAKER,
  updateBUY_LOT_NUMBER,
  updateBUY_PMAKER,
  updateBUY_PLOT_NUMBER,
  updatePROCESS_SYNTHESIS,
  updatePROCESS_MACHINE,
  updatePROCESS_THEOTHER,
  updateHOM_ENZYME,
  updateHOM_FUNGUS,
  updateHOM_PRIZE_CHAIN_ADD_RAD_LRP,
  updateHOM_PRIZE_CHAIN_ADD_CATI_LCP,
  updateHOM_PRIZE_CHAIN_ADD_ANIO_LAP,
  updateHOM_PRIZE_CHAIN_ADD_CRD,
  updateHOM_PRIZE_CHAIN_ROP_CROP,
  updateHOM_PRIZE_CHAIN_ROP_AROP,
  updateHOM_PRIZE_CHAIN_ROP_RROP,
  updateHOM_PRIZE_CHAIN_ROP_TMCR_ROMP,
  updateHOM_PRIZE_CHAIN_CGCP,
  updateBMSYN_REACT,
  updateBMSYN_REACS,
  updateBMSYN_YIELD,
  updateBMSYN_PROS_FGTB,
  updateBMSYN_PROS_CSD,
  updateBMSYN_PROS_SCON,
  updateBMSYN_PROS_PDT,

} from 'actions/SynthesisArray'

import {
  updateMD_MDP,
  updateMD_COND_ENS,
  updateMD_COND_FRC_FTBL,
  updateMD_COND_ICRD_ICIMAGE,
  updateMD_COND_ICRD_ICPDB,
  updateMD_COND_ICRD_ICDCD,
  updateMD_COND_ICRD_ICPSF,
  updateMD_COND_OTCOND,
  updateMD_CRSLT_THDYD_TVST,
  updateMD_CRSLT_THDYD_PVST,
  updateMD_CRSLT_THDYD_EVST,
  updateMD_CRSLT_DYD,
  updateQC_QCP,
  updateQC_QCBC,
  updateQC_QCAP
} from 'actions/calculationArray'

import {
  updateSPY_INF_FTI,
  updateSPY_NMR,
  updateSPY_UVI,
  updateSPY_FLU,
  updateSPY_RAM,
  updateSPY_CD,
  updateSPY_LS,
  updateSPY_ELL,
  updateSPY_NSC,
  updateSPY_XRA_PHO,
  updateSPY_XRA_DIF,
  updateSPY_XRA_XS_SAG,
  updateSPY_XRA_XS_WID,
  updateCHR_GAS,
  updateCHR_HPL_GPC,
  updateCHR_HPL_SEC,
  updateMIC_AFM,
  updateMIC_SEM,
  updateMIC_TEM,
  updateMIC_OPT,
  updateMIC_FLM,
  updateMIC_CLS,
  updateMIC_SPR,
  updateMIC_QCM,
  updateMIC_POL,
  updateTHA_TGA,
  updateTHA_DTA,
  updateTHA_DSC,
  updateEA,
  updateMSM,
  updateGEP,
  updateCONA,
  updateSTEN,
  updateSFOR,
  updateBOD,

} from 'actions/measurementArray'


import {

  updateCHD,
  updateBIOD_PLS_LOC,
  updateBIOD_PLS_BDATE,
  updateBIOD_PLS_BTIME,
  updateBIOD_PLS_TOC,
  updateBIOD_PLS_NITA,
  updateBIOD_PLS_BMASS,
  updateBIOD_PLS_DD_RC_PH,
  updateBIOD_PLS_DD_RC_BTEMP,
  updateBIOD_PLS_DD_RC_BOTH,
  updateBIOD_PLS_DD_BRLT_BRT,
  updateBIOD_PLS_DD_BRLT_DR,
  updateBIOD_PLS_DD_BRLT_DP,
  updateBIOD_PLS_DD_BRLT_DBOD,

} from 'actions/degradationArray'



import { makeLabelVal } from "common/util";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    // paddingLeft: 240
  },
  content: {
    height: '100%'
  }
}));

const Main = props => {
  const { children } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const selector_sample = useSelector((state) => state.overviewArray.sample);
  const [sample, setSample] = useState('');


  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  // input/updateのGET用
  const doGetData = (request_query) => {
  
    // fetch(`http://20.89.138.172/input/new/`,
    //const request_query = 'UFLWO'
    return fetch(`http://localhost:8000/input/update/` + request_query,
    {
      // mode : 'no-cors',
      method: 'GET',
      headers: {
        "X-CSRFToken":"{{csrftoken}}",
        // "Cookie":"csrftoken=KjmpWfhtCJ9ojurL75D3wwj86c4bFkmbCCQTHidxHGZFIKTHSlEnzEKJeexrVvFo",
        // "Cookie":"csrftoken=zpHzwaPXKCz68Lje9WEmWHZX2Md93nDEvoGq20UHKGZleXxsOgLLSYvq49OgCF51",
        "Cookie":"csrftoken=bxRfm5WsJajv0EYUPREdU0LgsRgnnKu4OKKtGIoX3WgJqMOTh2HjjJZoooNMRw45",
        "Authorization": 'Basic dGVzdDAxQGV4YW1wbGUuY29tOnQyMjIyMg=='},
      redirect: 'follow',
//      body: form_data,
    })
    .then( response => {
      if(response.ok) {
        // console.log("response:", response);
        
        // console.log("response.text:", response.text());
        return response.json();
      }

      // console.log("response:" + response);

      throw new Error(`Network response was not ok.`);
    }).then(responseData => {
      // response 確認できる
      // console.log(responseData)
      // console.log("responseData:" + JSON.stringify(responseData));
      // handleSampleChange(responseData.beforedata.ov.sample)
      

      // getしたデータをaction createrに渡す
      const getData  = (type, value, unit, cf, comment, files) =>{
        let newGetData = {}
        const file = files.find(fi => fi.name === value)

        if (file && file.field === 'chst') {
          // console.log(files)
          const saveInput = (inputValue, inputPreview) => {

            let new_val = {
              inputValue: inputValue || '',
              inputPreview: inputPreview || ''
            };
            return new_val
          }

          // console.log(value)
          // console.log(file)
          newGetData = saveInput(value,file.path)

        } else {
          const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

            let new_val = {
              inputType: inputType || '',
              inputValue: inputValue || '',
              inputUnit: inputUnit || '',
              inputFactor: inputFactor || '',
              inputComment: inputComment || '',
              inputImage: inputImage || '',
              inputCSVFile: inputCSVFile || '',
              inputPreview: inputPreview || ''
            };
            return new_val
          }
  
          if (value) {
            if (type === '画像'){
              newGetData = saveInput(type, value, unit, cf, comment,'','',file.path)
            } else if (type === 'CSV') {
              newGetData = saveInput(type, value, unit, cf, comment,'',file.path)
  
            } else {
              newGetData =  saveInput(type, value, unit, cf, comment)
            }
          }

        }
        return newGetData
      }

      const ovFileData = responseData.beforedata.files.ov;
      const ovData = responseData.beforedata.ov

      if (ovData.docid) props.updateDOCID(ovData.docid);
      if (ovData.ovid) props.updateOVID(ovData.ovid);
      if (ovData.sample) props.updateSAMPLE(ovData.sample);
      if (ovData.sampleID) props.updateSAMPLEID(ovData.sampleID);
      if (ovData.group) props.updateGROUP(ovData.group);
      if (ovData.inst) props.updateINST(ovData.inst);
      if (ovData.kind) props.updateKIND(ovData.kind);
      if (ovData.chst) props.updateCHST(getData('',ovData.chst,'','','', ovFileData));
      if (ovData.chst_img) props.updateCHST_IMG(ovData.chst_img);
      if (ovData.expp) props.updateEXPP(ovData.expp);
      if (ovData.respp) props.updateRESPP(ovData.respp);
      if (ovData.expdate) props.updateEXPDATE(ovData.expdate);
      if (ovData.inpp) props.updateINPP(ovData.inpp);
      if (ovData.bmemo) props.updateBMEMO(ovData.bmemo);

    
      const ppFileData = responseData.beforedata.files.phpr;
      const ppData = responseData.beforedata.phpr

      if (ppData.pp_td_den) 
          props.updatePP_TD_DEN(getData(
            ppData.pp_td_den_type, ppData.pp_td_den, ppData.pp_td_den_unit, 
            ppData.pp_td_den_cf, ppData.pp_td_den_cmt, ppFileData
          ))

      if (ppData.pp_td_sv)
          props.updatePP_TD_SV(getData(
            ppData.pp_td_sv_type, ppData.pp_td_sv, ppData.pp_td_sv_unit,
            ppData.pp_td_sv_cf,ppData.pp_td_sv_cmt, ppFileData
          ));

      if (ppData.pp_td_ar) 
          props.updatePP_TD_AR(getData(
            ppData.pp_td_ar_type, ppData.pp_td_ar, ppData.pp_td_ar_unit,
            ppData.pp_td_ar_cf,ppData.pp_td_ar_cmt, ppFileData
          ));

      if (ppData.pp_td_st) 
          props.updatePP_TD_ST(getData(
            ppData.pp_td_st_type, ppData.pp_td_st, ppData.pp_td_st_unit,
            ppData.pp_td_st_cf,ppData.pp_td_st_cmt, ppFileData
          ));

      if (ppData.pp_td_trop) 
          props.updatePP_TD_TROP(getData(
            ppData.pp_td_trop_type, ppData.pp_td_trop, ppData.pp_td_trop_unit,
            ppData.pp_td_trop_cf,ppData.pp_td_trop_cmt, ppFileData
          ));

      if (ppData.pp_td_enthal) 
          props.updatePP_TD_ENTHAL(getData(
            ppData.pp_td_enthal_type, ppData.pp_td_enthal, ppData.pp_td_enthal_unit,
            ppData.pp_td_enthal_cf,ppData.pp_td_enthal_cmt, ppFileData
          ));

      if (ppData.pp_td_cr) 
          props.updatePP_TD_CR(getData(
            ppData.pp_td_cr_type, ppData.pp_td_cr, ppData.pp_td_cr_unit,
            ppData.pp_td_cr_cf,ppData.pp_td_cr_cmt, ppFileData
          ));

      if (ppData.pp_td_te) 
          props.updatePP_TD_TE(getData(
            ppData.pp_td_te_type, ppData.pp_td_te, ppData.pp_td_te_unit,
            ppData.pp_td_te_cf,ppData.pp_td_te_cmt, ppFileData
          ));
 
      
      // //気体透過性
      if (ppData.pp_td_gp_wp) 
          props.updatePP_TD_GP_WP(getData(
            ppData.pp_td_gp_wp_type, ppData.pp_td_gp_wp, ppData.pp_td_gp_wp_unit,
            ppData.pp_td_gp_wp_cf,ppData.pp_td_gp_wp_cmt, ppFileData
          ));

      if (ppData.pp_td_gp_mp) 
          props.updatePP_TD_GP_MP(getData(
            ppData.pp_td_gp_mp_type, ppData.pp_td_gp_mp, ppData.pp_td_gp_mp_unit,
            ppData.pp_td_gp_mp_cf,ppData.pp_td_gp_mp_cmt, ppFileData
          ));

      if (ppData.pp_td_gp_ftd) 
          props.updatePP_TD_GP_FTD(getData(
            ppData.pp_td_gp_ftd_type, ppData.pp_td_gp_ftd, ppData.pp_td_gp_ftd_unit,
            ppData.pp_td_gp_ftd_cf,ppData.pp_td_gp_ftd_cmt, ppFileData
          ));

      if (ppData.pp_td_gp_tpd) 
          props.updatePP_TD_GP_TPD(getData(
            ppData.pp_td_gp_tpd_type, ppData.pp_td_gp_tpd, ppData.pp_td_gp_tpd_unit,
            ppData.pp_td_gp_tpd_cf,ppData.pp_td_gp_tpd_cmt, ppFileData
          ));

      if (ppData.pp_td_gp_hd) 
          props.updatePP_TD_GP_HD(getData(
            ppData.pp_td_gp_hd_type, ppData.pp_td_gp_hd, ppData.pp_td_gp_hd_unit,
            ppData.pp_td_gp_hd_cf,ppData.pp_td_gp_hd_cmt, ppFileData
          ));

      
      // // 流動特性
      if (ppData.pp_td_fc_mv_sd) 
          props.updatePP_TD_FC_MV_SD(getData(
            ppData.pp_td_fc_mv_sd_type, ppData.pp_td_fc_mv_sd, ppData.pp_td_fc_mv_sd_unit,
            ppData.pp_td_fc_mv_sd_cf,ppData.pp_td_fc_mv_sd_cmt, ppFileData
          ));

      if (ppData.pp_td_fc_mv_mtd) 
          props.updatePP_TD_FC_MV_MTD(getData(
            ppData.pp_td_fc_mv_mtd_type, ppData.pp_td_fc_mv_mtd, ppData.pp_td_fc_mv_mtd_unit,
            ppData.pp_td_fc_mv_mtd_cf,ppData.pp_td_fc_mv_mtd_cmt, ppFileData
          ));

      if (ppData.pp_td_fc_mv_pd) 
          props.updatePP_TD_FC_MV_PD(getData(
            ppData.pp_td_fc_mv_pd_type, ppData.pp_td_fc_mv_pd, ppData.pp_td_fc_mv_pd_unit,
            ppData.pp_td_fc_mv_pd_cf,ppData.pp_td_fc_mv_pd_cmt, ppFileData
          ));

      
      if (ppData.pp_td_fc_vc) 
          props.updatePP_TD_FC_VC(getData(
            ppData.pp_td_fc_vc_type, ppData.pp_td_fc_vc,ppData. pp_td_fc_vc_unit,
            ppData.pp_td_fc_vc_cf,ppData.pp_td_fc_vc_cmt, ppFileData
          ));

      
      
      // //比熱
      if (ppData.pp_td_sp_spc) 
          props.updatePP_TD_SP_SPC(getData(
            ppData.pp_td_sp_spc_type, ppData.pp_td_sp_spc, ppData.pp_td_sp_spc_unit,
            ppData.pp_td_sp_spc_cf,ppData.pp_td_sp_spc_cmt, ppFileData
          ));

      
      if (ppData.pp_td_sp_std) 
          props.updatePP_TD_SP_STD(getData(
            ppData.pp_td_sp_std_type, ppData.pp_td_sp_std, ppData.pp_td_sp_std_unit,
            ppData.pp_td_sp_std_cf,ppData.pp_td_sp_std_cmt, ppFileData
          ));

      // //熱伝導率
      if (ppData.pp_td_tc_ttd) 
          props.updatePP_TD_TC_TTD(getData(
            ppData.pp_td_tc_ttd_type, ppData.pp_td_tc_ttd, ppData.pp_td_tc_ttd_unit,
            ppData.pp_td_tc_ttd_cf,ppData.pp_td_tc_ttd_cmt, ppFileData
          ));

      if (ppData.pp_td_tc_dsd) 
          props.updatePP_TD_TC_DSD(getData(
            ppData.pp_td_tc_dsd_type, ppData.pp_td_tc_dsd, ppData.pp_td_tc_dsd_unit,
            ppData.pp_td_tc_dsd_cf,ppData.pp_td_tc_dsd_cmt, ppFileData
          ));

      if (ppData.pp_td_tc_cpd) 
          props.updatePP_TD_TC_CPD(getData(
            ppData.pp_td_tc_cpd_type, ppData.pp_td_tc_cpd, ppData.pp_td_tc_cpd_unit,
            ppData.pp_td_tc_cpd_cf,ppData.pp_td_tc_cpd_cmt, ppFileData
          ));

      // //熱拡散係数
      if (ppData.pp_td_tdif_cmm) 
          props.updatePP_TD_TDIF_CMM(getData(
            ppData.pp_td_tdif_cmm_type, ppData.pp_td_tdif_cmm, ppData.pp_td_tdif_cmm_unit,
            ppData.pp_td_tdif_cmm_cf,ppData.pp_td_tdif_cmm_cmt, ppFileData
          ));

      if (ppData.pp_td_tdif_amo) 
          props.updatePP_TD_TDIF_AMO(getData(
            ppData.pp_td_tdif_amo_type, ppData.pp_td_tdif_amo, ppData.pp_td_tdif_amo_unit,
            ppData.pp_td_tdif_amo_cf,ppData.pp_td_tdif_amo_cmt, ppFileData
          ));

      // //各種温度
      
      if (ppData.pp_td_temp_Tg_gpd) 
          props.updatePP_TD_TEMP_TG_GPD(getData(
            ppData.pp_td_temp_Tg_gpd_type, ppData.pp_td_temp_Tg_gpd, ppData.pp_td_temp_Tg_gpd_unit,
            ppData.pp_td_temp_Tg_gpd_cf,ppData.pp_td_temp_Tg_gpd_cmt, ppFileData
          ));


      if (ppData.pp_td_temp_mlp_mpd)
          props.updatePP_TD_TEMP_MLP_MPD(getData(
            ppData.pp_td_temp_mlp_mpd_type, ppData.pp_td_temp_mlp_mpd, ppData.pp_td_temp_mlp_mpd_unit,
            ppData.pp_td_temp_mlp_mpd_cf,ppData.pp_td_temp_mlp_mpd_cmt, ppFileData
          ));

      if (ppData.pp_td_temp_DSC) 
          props.updatePP_TD_TEMP_DSC(getData(
            ppData.pp_td_temp_DSC_type, ppData.pp_td_temp_DSC, ppData.pp_td_temp_DSC_unit,
            ppData.pp_td_temp_DSC_cf,ppData.pp_td_temp_DSC_cmt, ppFileData
          ));

      if (ppData.pp_td_temp_TGA)
          props.updatePP_TD_TEMP_TGA(getData(
            ppData.pp_td_temp_TGA_type, ppData.pp_td_temp_TGA, ppData.pp_td_temp_TGA_unit,
            ppData.pp_td_temp_TGA_cf,ppData.pp_td_temp_TGA_cmt, ppFileData
          ));

      if (ppData.pp_td_temp_wr5) 
          props.updatePP_TD_TEMP_WR5(getData(
            ppData.pp_td_temp_wr5_type, ppData.pp_td_temp_wr5, ppData.pp_td_temp_wr5_unit,
            ppData.pp_td_temp_wr5_cf,ppData.pp_td_temp_wr5_cmt, ppFileData
          ));

      if (ppData.pp_td_temp_emb) 
          props.updatePP_TD_TEMP_EMB(getData(
            ppData.pp_td_temp_emb_type, ppData.pp_td_temp_emb,ppData.pp_td_temp_emb_unit,
            ppData.pp_td_temp_emb_cf,ppData.pp_td_temp_emb_cmt, ppFileData
          ));

      if (ppData.pp_td_temp_HDT) 
          props.updatePP_TD_TEMP_HDT(getData(
            ppData.pp_td_temp_HDT_type, ppData.pp_td_temp_HDT,ppData.pp_td_temp_HDT_unit,
            ppData.pp_td_temp_HDT_cf,ppData.pp_td_temp_HDT_cmt, ppFileData
          ));

      // //燃焼特性
      if (ppData.pp_td_bchar_FIT)
          props.updatePP_TD_BCHAR_FIT(getData(
            ppData.pp_td_bchar_FIT_type, ppData.pp_td_bchar_FIT,ppData.pp_td_bchar_FIT_unit,
            ppData.pp_td_bchar_FIT_cf,ppData.pp_td_bchar_FIT_cmt, ppFileData
          ));

      if (ppData.pp_td_bchar_hdc)
          props.updatePP_TD_BCHAR_HDC(getData(
            ppData.pp_td_bchar_hdc_type, ppData.pp_td_bchar_hdc,ppData.pp_td_bchar_hdc_unit,
            ppData.pp_td_bchar_hdc_cf,ppData.pp_td_bchar_hdc_cmt, ppFileData
          ));

      if (ppData.pp_td_bchar_ch) 
          props.updatePP_TD_BCHAR_CH(getData(
            ppData.pp_td_bchar_ch_type, ppData.pp_td_bchar_ch,ppData.pp_td_bchar_ch_unit,
            ppData.pp_td_bchar_ch_cf,ppData.pp_td_bchar_ch_cmt, ppFileData
          ));

      if (ppData.pp_td_bchar_oi)
          props.updatePP_TD_BCHAR_OI(getData(
            ppData.pp_td_bchar_oi_type, ppData.pp_td_bchar_oi,ppData.pp_td_bchar_oi_unit,
            ppData.pp_td_bchar_oi_cf,ppData.pp_td_bchar_oi_cmt, ppFileData
          ));

      if (ppData.pp_td_bchar_hrp)
          props.updatePP_TD_BCHAR_HRP(getData(
            ppData.pp_td_bchar_hrp_type, ppData.pp_td_bchar_hrp,ppData.pp_td_bchar_hrp_unit,
            ppData.pp_td_bchar_hrp_cf,ppData.pp_td_bchar_hrp_cmt, ppFileData
          ));

      // //摩擦係数
      if (ppData.pp_fric) 
          props.updateFRICTION_COEFFICIENT(getData(
            ppData.pp_fric_type, ppData.pp_fric,ppData.pp_fric_unit,
            ppData.pp_fric_cf,ppData.pp_fric_cmt, ppFileData
          ));

      // //酵素分解.分解率
      if (ppData.pp_enzy_decr) 
          props.updateENZYMATIC_DECOMPOSITION_RATE(getData(
            ppData.pp_enzy_decr_type, ppData.pp_enzy_decr,ppData.pp_enzy_decr_unit,
            ppData.pp_enzy_decr_cf,ppData.pp_enzy_decr_cmt, ppFileData
          ));

      // //酵素分解.酵素反応データ
      if (ppData.pp_enzy_activ_ereac) 
          props.updateENZYMATIC_ENZYMATIC_REACTION(getData(
            ppData.pp_enzy_activ_ereac_type, ppData.pp_enzy_activ_ereac,ppData.pp_enzy_activ_ereac_unit,
            ppData.pp_enzy_activ_ereac_cf,ppData.pp_enzy_activ_ereac_cmt, ppFileData
          ));

      
      // //⽣分解度.⽣分解度グラフ.曲線データ
      if (ppData.pp_biodegra_bdgraph_bdcurve) 
          props.updateBIODEGRADABILITY_CURVE(getData(
            ppData.pp_biodegra_bdgraph_bdcurve_type, ppData.pp_biodegra_bdgraph_bdcurve, ppData.pp_biodegra_bdgraph_bdcurve_unit,
            ppData.pp_biodegra_bdgraph_bdcurve_cf,ppData.pp_biodegra_bdgraph_bdcurve_cmt, ppFileData
          ));

      // //崩壊度
      // //  - 重量変化グラフ
      // //    棒グラフデータ
      if (ppData.pp_degdegra_wtchg_barg) 
          props.updateBAR_GRAPH(getData(
            ppData.pp_degdegra_wtchg_barg_type, ppData.pp_degdegra_wtchg_barg,ppData.pp_degdegra_wtchg_barg_unit,
            ppData.pp_degdegra_wtchg_barg_cf,ppData.pp_degdegra_wtchg_barg_cmt, ppFileData
          ));

      
      // //構造.X線.グラフ
      if (ppData.pp_struct_xray_graph)
          props.updateSTRUCTURE_XRAY_GRAPH(getData(
            ppData.pp_struct_xray_graph_type, ppData.pp_struct_xray_graph,ppData.pp_struct_xray_graph_unit,
            ppData.pp_struct_xray_graph_cf,ppData.pp_struct_xray_graph_cmt, ppFileData
          ));

      
      // //構造.顕微鏡法.画像
      if (ppData.pp_struct_micro_mimg)
          props.updateSTRUCTURE_MICROSCOPE_IMAGE(getData(
            ppData.pp_struct_micro_mimg_type, ppData.pp_struct_micro_mimg,ppData.pp_struct_micro_mimg_unit,
            ppData.pp_struct_micro_mimg_cf,ppData.pp_struct_micro_mimg_cmt, ppFileData
          ));
 
      
      // //構造.結晶化度
      if (ppData.pp_struct_dcrysta)
          props.updateSTRUCTURE_CRYSTALLINITY_DEGREE(getData(
            ppData.pp_struct_dcrysta_type, ppData.pp_struct_dcrysta,ppData.pp_struct_dcrysta_unit,
            ppData.pp_struct_dcrysta_cf,ppData.pp_struct_dcrysta_cmt, ppFileData
          ));

      
                

        //機械強度特性.クリープ試験
        if (ppData.pp_ms_crt)
          props.updatePP_MS_CRT(getData(
            ppData.pp_ms_crt_type, ppData.pp_ms_crt,ppData.pp_ms_crt_unit,
            ppData.pp_ms_crt_cf,ppData.pp_ms_crt_cmt, ppFileData
          ));


        //機械強度特性.Stress relaxation
        if (ppData.pp_ms_strrel)
            props.updatePP_MS_STRREL(getData(
              ppData.pp_ms_strrel_type, ppData.pp_ms_strrel,ppData.pp_ms_strrel_unit,
              ppData.pp_ms_strrel_cf,ppData.pp_ms_strrel_cmt, ppFileData
            ));


        //機械強度特性.クラック
        if (ppData.pp_ms_crack)
          props.updatePP_MS_CRACK(getData(
            ppData.pp_ms_crack_type, ppData.pp_ms_crack,ppData.pp_ms_crack_unit,
            ppData.pp_ms_crack_cf,ppData.pp_ms_crack_cmt, ppFileData
          ));

        //機械強度特性.Fracture toughness
        if (ppData.pp_ms_frat)
          props.updatePP_MS_FRAT(getData(
            ppData.pp_ms_frat_type, ppData.pp_ms_frat,ppData.pp_ms_frat_unit,
            ppData.pp_ms_frat_cf,ppData.pp_ms_frat_cmt, ppFileData
          ));

        //機械強度特性.Fatigue properties
        if (ppData.pp_ms_fatp)
          props.updatePP_MS_FATP(getData(
            ppData.pp_ms_fatp_type, ppData.pp_ms_fatp,ppData.pp_ms_fatp_unit,
            ppData.pp_ms_fatp_cf,ppData.pp_ms_fatp_cmt, ppFileData
          ));


        //機械強度特性.硬さ
        if (ppData.pp_ms_hard)
          props.updatePP_MS_HARD(getData(
            ppData.pp_ms_hard_type, ppData.pp_ms_hard,ppData.pp_ms_hard_unit,
            ppData.pp_ms_hard_cf,ppData.pp_ms_hard_cmt, ppFileData
          ));

        //機械強度特性.応⼒歪曲線.引張
        if (ppData.pp_ms_ssc_ten_degstretch)
          props.updatePP_MS_SSC_TEN_DEGSTRETCH(getData(
            ppData.pp_ms_ssc_ten_degstretch_type, ppData.pp_ms_ssc_ten_degstretch,ppData.pp_ms_ssc_ten_degstretch_unit,
            ppData.pp_ms_ssc_ten_degstretch_cf,ppData.pp_ms_ssc_ten_degstretch_cmt, ppFileData
          ));


        if (ppData.pp_ms_ssc_ten_degela)
          props.updatePP_MS_SSC_TEN_DEGELA(getData(
            ppData.pp_ms_ssc_ten_degela_type, ppData.pp_ms_ssc_ten_degela,ppData.pp_ms_ssc_ten_degela_unit,
            ppData.pp_ms_ssc_ten_degela_cf,ppData.pp_ms_ssc_ten_degela_cmt, ppFileData
          ));

        if (ppData.pp_ms_ssc_ten_rupstr)
          props.updatePP_MS_SSC_TEN_RUPSTR(getData(
            ppData.pp_ms_ssc_ten_rupstr_type, ppData.pp_ms_ssc_ten_rupstr,ppData.pp_ms_ssc_ten_rupstr_unit,
            ppData.pp_ms_ssc_ten_rupstr_cf,ppData.pp_ms_ssc_ten_rupstr_cmt, ppFileData
          ));



        if (ppData.pp_ms_ssc_ten_sttd) 
          props.updatePP_MS_SSC_TEN_STTD(getData(
            ppData.pp_ms_ssc_ten_sttd_type, ppData.pp_ms_ssc_ten_sttd,ppData.pp_ms_ssc_ten_sttd_unit,
            ppData.pp_ms_ssc_ten_sttd_cf,ppData.pp_ms_ssc_ten_sttd_cmt, ppFileData
          ));

        if (ppData.pp_ms_ssc_ten_stpd)
          props.updatePP_MS_SSC_TEN_STPD(getData(
            ppData.pp_ms_ssc_ten_stpd_type, ppData.pp_ms_ssc_ten_stpd,ppData.pp_ms_ssc_ten_stpd_unit,
            ppData.pp_ms_ssc_ten_stpd_cf,ppData.pp_ms_ssc_ten_stpd_cmt, ppFileData
          ));

        if (ppData.pp_ms_ssc_ten_baimage)
        props.updatePP_MS_SSC_TEN_BAIMAGE(getData(
          ppData.pp_ms_ssc_ten_baimage_type, ppData.pp_ms_ssc_ten_baimage, ppData.pp_ms_ssc_ten_baimage_unit,
          ppData.pp_ms_ssc_ten_baimage_cf,ppData.pp_ms_ssc_ten_baimage_cmt, ppFileData
        ));

        //機械強度特性.応⼒歪曲線.曲げ試験
        if (ppData.pp_ms_ssc_bend_bdstretch)
          props.updatePP_MS_SSC_BEND_BDSTRETCH(getData(
            ppData.pp_ms_ssc_bend_bdstretch_type, ppData.pp_ms_ssc_bend_bdstretch,ppData.pp_ms_ssc_bend_bdstretch_unit,
            ppData.pp_ms_ssc_bend_bdstretch_cf,ppData.pp_ms_ssc_bend_bdstretch_cmt, ppFileData
          ));

        if (ppData.pp_ms_ssc_bend_bdela)
          props.updatePP_MS_SSC_BEND_BDELA(getData(
            ppData.pp_ms_ssc_bend_bdela_type, ppData.pp_ms_ssc_bend_bdela,ppData.pp_ms_ssc_bend_bdela_unit,
            ppData.pp_ms_ssc_bend_bdela_cf,ppData.pp_ms_ssc_bend_bdela_cmt, ppFileData
          ));

        if (ppData.pp_ms_ssc_bend_brstr)
          props.updatePP_MS_SSC_BEND_BRSTR(getData(
            ppData.pp_ms_ssc_bend_brstr_type, ppData.pp_ms_ssc_bend_brstr,ppData.pp_ms_ssc_bend_brstr_unit,
            ppData.pp_ms_ssc_bend_brstr_cf,ppData.pp_ms_ssc_bend_brstr_cmt, ppFileData
          ));

        if (ppData.pp_ms_ssc_bend_btd)
        props.updatePP_MS_SSC_BEND_BTD(getData(
          ppData.pp_ms_ssc_bend_btd_type, ppData.pp_ms_ssc_bend_btd,ppData.pp_ms_ssc_bend_btd_unit,
          ppData.pp_ms_ssc_bend_btd_cf,ppData.pp_ms_ssc_bend_btd_cmt, ppFileData
        ));

        if (ppData.pp_ms_ssc_bend_bpd)
          props.updatePP_MS_SSC_BEND_BPD(getData(
            ppData.pp_ms_ssc_bend_bpd_type, ppData.pp_ms_ssc_bend_bpd,ppData.pp_ms_ssc_bend_bpd_unit,
            ppData.pp_ms_ssc_bend_bpd_cf,ppData.pp_ms_ssc_bend_bpd_cmt, ppFileData
          ));

        if (ppData.pp_ms_ssc_bend_bbaimage)
          props.updatePP_MS_SSC_BEND_BBAIMAGE(getData(
            ppData.pp_ms_ssc_bend_bbaimage_type, ppData.pp_ms_ssc_bend_bbaimage, ppData.pp_ms_ssc_bend_bbaimage_unit,
            ppData.pp_ms_ssc_bend_bbaimage_cf,ppData.pp_ms_ssc_bend_bbaimage_cmt, ppFileData
          ));
 
        if (ppData.pp_ms_vem_vtd)
          props.updatePP_MS_VEM_VTD(getData(
            ppData.pp_ms_vem_vtd_type, ppData.pp_ms_vem_vtd,ppData.pp_ms_vem_vtd_unit,
            ppData.pp_ms_vem_vtd_cf,ppData.pp_ms_vem_vtd_cmt, ppFileData
          ));

        if (ppData.pp_ms_vem_vpd)
         props.updatePP_MS_VEM_VPD(getData(
          ppData.pp_ms_vem_vpd_type, ppData.pp_ms_vem_vpd,ppData.pp_ms_vem_vpd_unit,
          ppData.pp_ms_vem_vpd_cf,ppData.pp_ms_vem_vpd_cmt, ppFileData
        ));

        if (ppData.pp_ms_vem_gdash)
          props.updatePP_MS_VEM_GDASH(getData(
            ppData.pp_ms_vem_gdash_type, ppData.pp_ms_vem_gdash,ppData.pp_ms_vem_gdash_unit,
            ppData.pp_ms_vem_gdash_cf,ppData.pp_ms_vem_gdash_cmt, ppFileData
          ));

        if (ppData.pp_ms_vem_gdash2)
          props.updatePP_MS_VEM_GDASH2(getData(
            ppData.pp_ms_vem_gdash2_type, ppData.pp_ms_vem_gdash2,ppData.pp_ms_vem_gdash2_unit,
            ppData.pp_ms_vem_gdash2_cf,ppData.pp_ms_vem_gdash2_cmt, ppFileData
          ));

        if (ppData.pp_ms_vem_ltan)
          props.updatePP_MS_VEM_LTAN(getData(
            ppData.pp_ms_vem_ltan_type, ppData.pp_ms_vem_ltan,ppData.pp_ms_vem_ltan_unit,
            ppData.pp_ms_vem_ltan_cf,ppData.pp_ms_vem_ltan_cmt, ppFileData
          ));
 
        //衝撃試験.Charpy.温度依存性
        if (ppData.pp_ms_sht_Char_ctd)
          props.updatePP_MS_SHT_CHAR_CTD(getData(
            ppData.pp_ms_sht_Char_ctd_type, ppData.pp_ms_sht_Char_ctd,ppData.pp_ms_sht_Char_ctd_unit,
            ppData.pp_ms_sht_Char_ctd_cf,ppData.pp_ms_sht_Char_ctd_cmt, ppFileData
          ));


        //衝撃試験.Izod.温度依存性
        if (ppData.pp_ms_sht_Izod_itd)
          props.updatePP_MS_SHT_IZOD_ITD(getData(
            ppData.pp_ms_sht_Izod_itd_type, ppData.pp_ms_sht_Izod_itd,ppData.pp_ms_sht_Izod_itd_unit,
            ppData.pp_ms_sht_Izod_itd_cf,ppData.pp_ms_sht_Izod_itd_cmt, ppFileData
          ));


        // 高分子生成
        const synFileData = responseData.beforedata.files.syn;
        const synData = responseData.beforedata.syn;

        if (synData.rslt_RT)                     
          props.updateRSLT_RT(getData(
            synData.rslt_RT_type, synData.rslt_RT,synData.rslt_RT_unit,
            synData.rslt_RT_cf,synData.rslt_RT_cmt, synFileData
          ));

        if (synData.rslt_MCR)   
          props.updateRSLT_MCR(getData(
            synData.rslt_MCR_type, synData.rslt_MCR,synData.rslt_MCR_unit,
            synData.rslt_MCR_cf,synData.rslt_MCR_cmt, synFileData
          ));

        if (synData.rslt_rrate)   
          props.updateRSLT_RRATE(getData(
            synData.rslt_rrate_type, synData.rslt_rrate,synData.rslt_rrate_unit,
            synData.rslt_rrate_cf,synData.rslt_rrate_cmt, synFileData
          ));

        if (synData.rslt_CY)   
          props.updateRSLT_CY(getData(
            synData.rslt_CY_type, synData.rslt_CY,synData.rslt_CY_unit,
            synData.rslt_CY_cf,synData.rslt_CY_cmt, synFileData
          ));

        if (synData.rslt_prate_tur)   
          props.updateRSLT_PRATE_TUR(getData(
            synData.rslt_prate_tur_type, synData.rslt_prate_tur,synData.rslt_prate_tur_unit,
            synData.rslt_prate_tur_cf,synData.rslt_prate_tur_cmt, synFileData
          ));
            
        if (synData.rslt_prate_occ)   
          props.updateRSLT_PRATE_OCC(getData(
            synData.rslt_prate_occ_type, synData.rslt_prate_occ,synData.rslt_prate_occ_unit,
            synData.rslt_prate_occ_cf,synData.rslt_prate_occ_cmt, synFileData
          ));
        
        if (synData.rslt_prate_pdata)   
          props.updateRSLT_PRATE_PDATA(getData(
            synData.rslt_prate_pdata_type, synData.rslt_prate_pdata,synData.rslt_prate_pdata_unit,
            synData.rslt_prate_pdata_cf,synData.rslt_prate_pdata_cmt, synFileData
          ));
        
        if (synData.rcon_pH)   
          props.updateRCON_PH(getData(
            synData.rcon_pH_type, synData.rcon_pH,synData.rcon_pH_unit,
            synData.rcon_pH_cf,synData.rcon_pH_cmt, synFileData
          ));


        if (synData.rcon_rtemp)   
          props.updateRCON_RTEMP(getData(
            synData.rcon_rtemp_type, synData.rcon_rtemp,
            synData.rcon_rtemp_unit, synData.rcon_rtemp_cf,
            synData.rcon_rtemp_cmt, synFileData
          ));

        if (synData.rcon_OTRC)   
          props.updateRCON_OTRC(getData(
            synData.rcon_OTRC_type, synData.rcon_OTRC,synData.rcon_OTRC_unit,
            synData.rcon_OTRC_cf,synData.rcon_OTRC_cmt, synFileData
          ));


        if (synData.hom_prize_SGP_pcon_NASP)   
          props.updateHOM_PRIZE_SGP_PCON_NASP(getData(
            synData.hom_prize_SGP_pcon_NASP_type, synData.hom_prize_SGP_pcon_NASP,
            synData.hom_prize_SGP_pcon_NASP_unit, synData.hom_prize_SGP_pcon_NASP_cf,
            synData.hom_prize_SGP_pcon_NASP_cmt, synFileData
          ));

        if (synData.hom_prize_SGP_pcon_ANSP)   
          props.updateHOM_PRIZE_SGP_PCON_ANSP(getData(
            synData.hom_prize_SGP_pcon_ANSP_type, synData.hom_prize_SGP_pcon_ANSP,
            synData.hom_prize_SGP_pcon_ANSP_unit, synData.hom_prize_SGP_pcon_ANSP_cf,
            synData.hom_prize_SGP_pcon_ANSP_cmt, synFileData
          ));

        if (synData.hom_prize_SGP_pcon_ARNSP)   
          props.updateHOM_PRIZE_SGP_PCON_ARNSP(getData(
            synData.hom_prize_SGP_pcon_ARNSP_type, synData.hom_prize_SGP_pcon_ARNSP,
            synData.hom_prize_SGP_pcon_ARNSP_unit, synData.hom_prize_SGP_pcon_ARNSP_cf,
            synData.hom_prize_SGP_pcon_ARNSP_cmt, synFileData
          ));

        if (synData.hom_prize_SGP_pcon_ARESP)   
          props.updateHOM_PRIZE_SGP_PCON_ARESP(getData(
            synData.hom_prize_SGP_pcon_ARESP_type, synData.hom_prize_SGP_pcon_ARESP,
            synData.hom_prize_SGP_pcon_ARESP_unit, synData.hom_prize_SGP_pcon_ARESP_cf,
            synData.hom_prize_SGP_pcon_ARESP_cmt, synFileData
          ));

        if (synData.hom_prize_SGP_pcon_OCP)   
          props.updateHOM_PRIZE_SGP_PCON_OCP(getData(
            synData.hom_prize_SGP_pcon_OCP_type, synData.hom_prize_SGP_pcon_OCP,
            synData.hom_prize_SGP_pcon_OCP_unit, synData.hom_prize_SGP_pcon_OCP_cf,
            synData.hom_prize_SGP_pcon_OCP_cmt, synFileData
          ));

        if (synData.hom_prize_SGP_pcon_TMCP)   
          props.updateHOM_PRIZE_SGP_PCON_TMCP(getData(
            synData.hom_prize_SGP_pcon_TMCP_type, synData.hom_prize_SGP_pcon_TMCP,
            synData.hom_prize_SGP_pcon_TMCP_unit, synData.hom_prize_SGP_pcon_TMCP_cf,
            synData.hom_prize_SGP_pcon_TMCP_cmt, synFileData
          ));

        if (synData.hom_prize_SGP_pcon_EP)   
          props.updateHOM_PRIZE_SGP_PCON_EP(getData(
            synData.hom_prize_SGP_pcon_EP_type, synData.hom_prize_SGP_pcon_EP,
            synData.hom_prize_SGP_pcon_EP_unit,synData.hom_prize_SGP_pcon_EP_cf,
            synData.hom_prize_SGP_pcon_EP_cmt, synFileData
          ));

        if (synData.hom_prize_SGP_padd_PTDB)   
          props.updateHOM_PRIZE_SGP_PADD_PTDB(getData(
            synData.hom_prize_SGP_padd_PTDB_type, synData.hom_prize_SGP_padd_PTDB,
            synData.hom_prize_SGP_padd_PTDB_unit, synData.hom_prize_SGP_padd_PTDB_cf,
            synData.hom_prize_SGP_padd_PTDB_cmt, synFileData
          ));

        if (synData.hom_prize_SGP_padd_ROPA)   
          props.updateHOM_PRIZE_SGP_PADD_ROPA(getData(
            synData.hom_prize_SGP_padd_ROPA_type, synData.hom_prize_SGP_padd_ROPA,
            synData.hom_prize_SGP_padd_ROPA_unit, synData.hom_prize_SGP_padd_ROPA_cf,
            synData.hom_prize_SGP_padd_ROPA_cmt, synFileData
          ));

        if (synData.hom_prize_SGP_padd_CYPA)   
          props.updateHOM_PRIZE_SGP_PADD_CYPA(getData(
            synData.hom_prize_SGP_padd_CYPA_type, synData.hom_prize_SGP_padd_CYPA,
            synData.hom_prize_SGP_padd_CYPA_unit,synData.hom_prize_SGP_padd_CYPA_cf,
            synData.hom_prize_SGP_padd_CYPA_cmt, synFileData
          ));
            
        if (synData.hom_prize_SGP_padd_OTPA)   
          props.updateHOM_PRIZE_SGP_PADD_OTPA(getData(
            synData.hom_prize_SGP_padd_OTPA_type, synData.hom_prize_SGP_padd_OTPA,
            synData.hom_prize_SGP_padd_OTPA_unit, synData.hom_prize_SGP_padd_OTPA_cf,
            synData.hom_prize_SGP_padd_OTPA_cmt, synFileData
          ));

        if (synData.hom_prize_SGP_adco)   
          props.updateHOM_PRIZE_SGP_ADCO(getData(
            synData.hom_prize_SGP_adco_type, synData.hom_prize_SGP_adco,
            synData.hom_prize_SGP_adco_unit, synData.hom_prize_SGP_adco_cf,
            synData.hom_prize_SGP_adco_cmt, synFileData
          ));

          //作り方

          //作り方.購入
        if (synData.hom_buy_maker)   
          props.updateBUY_MAKER(getData(
            synData.hom_buy_maker_type, synData.hom_buy_maker,
            synData.hom_buy_maker_unit, synData.hom_buy_maker_cf,
            synData.hom_buy_maker_cmt, synFileData
          ));

        if (synData.hom_buy_lot)   
          props.updateBUY_LOT_NUMBER(getData(
            synData.hom_buy_lot_type, synData.hom_buy_lot,
            synData.hom_buy_lot_unit, synData.hom_buy_lot_cf,
            synData.hom_buy_lot_cmt, synFileData
          ));



          //作り方.製造プロセス
        if (synData.hom_proc_mat_pch_pmaker)   
          props.updateBUY_PMAKER(getData(
            synData.hom_proc_mat_pch_pmaker_type, synData.hom_proc_mat_pch_pmaker,
            synData.hom_proc_mat_pch_pmaker_unit, synData.hom_proc_mat_pch_pmaker_cf,
            synData.hom_proc_mat_pch_pmaker_cmt, synFileData
          ));

        if (synData.hom_proc_mat_pch_plot)   
          props.updateBUY_PLOT_NUMBER(getData(
            synData.hom_proc_mat_pch_plot_type, synData.hom_proc_mat_pch_plot,
            synData.hom_proc_mat_pch_plot_unit, synData.hom_proc_mat_pch_plot_cf,
            synData.hom_proc_mat_pch_plot_cmt, synFileData
          ));

        if (synData.hom_proc_mat_synth)   
          props.updatePROCESS_SYNTHESIS(getData(
            synData.hom_proc_mat_synth_type, synData.hom_proc_mat_synth,synData.hom_proc_mat_synth_unit,
            synData.hom_proc_mat_synth_cf,synData.hom_proc_mat_synth_cmt, synFileData
          ));

        if (synData.hom_proc_mach)   
          props.updatePROCESS_MACHINE(getData(
            synData.hom_proc_mach_type, synData.hom_proc_mach,synData.hom_proc_mach_unit,
            synData.hom_proc_mach_cf,synData.hom_proc_mach_cmt, synFileData
          ));

        if (synData.hom_proc_other)   
          props.updatePROCESS_THEOTHER(getData(
            synData.hom_proc_other_type, synData.hom_proc_other,synData.hom_proc_other_unit,
            synData.hom_proc_other_cf,synData.hom_proc_other_cmt, synFileData
          ));



          //作り方.酵素名
        if (synData.hom_enzyme)   
          props.updateHOM_ENZYME(getData(
            synData.hom_enzyme_type, synData.hom_enzyme, synData.hom_enzyme_unit,
            synData.hom_enzyme_cf,synData.hom_enzyme_cmt, synFileData
          ));

          //作り方.菌体名
        if (synData.hom_fungus)   
          props.updateHOM_FUNGUS(getData(
            synData.hom_fungus_type, synData.hom_fungus,synData.hom_fungus_unit,
            synData.hom_fungus_cf,synData.hom_fungus_cmt, synFileData
          ));




          // 作り方.重合名

          //重合名.連鎖重合.付加重合.ラジカル重合.リビングラジカル重合
        if (synData.hom_prize_chain_add_rad_LRP)   
          props.updateHOM_PRIZE_CHAIN_ADD_RAD_LRP(getData(
            synData.hom_prize_chain_add_rad_LRP_type, synData.hom_prize_chain_add_rad_LRP,
            synData.hom_prize_chain_add_rad_LRP_unit, synData.hom_prize_chain_add_rad_LRP_cf,
            synData.hom_prize_chain_add_rad_LRP_cmt, synFileData
          ));


          //重合名.連鎖重合.付加重合.カチオン重合.リビングカチオン重合
        if (synData.hom_prize_chain_add_cati_LCP)   
          props.updateHOM_PRIZE_CHAIN_ADD_CATI_LCP(getData(
            synData.hom_prize_chain_add_cati_LCP_type, synData.hom_prize_chain_add_cati_LCP,
            synData.hom_prize_chain_add_cati_LCP_unit, synData.hom_prize_chain_add_cati_LCP_cf, 
            synData.hom_prize_chain_add_cati_LCP_cmt, synFileData
          ));


          //重合名.連鎖重合.付加重合.アニオン重合.リビングアニオン重合
        if (synData.hom_prize_chain_add_anio_LAP)   
          props.updateHOM_PRIZE_CHAIN_ADD_ANIO_LAP(getData(
            synData.hom_prize_chain_add_anio_LAP_type, synData.hom_prize_chain_add_anio_LAP,
            synData.hom_prize_chain_add_anio_LAP_unit, synData.hom_prize_chain_add_anio_LAP_cf, 
            synData.hom_prize_chain_add_anio_LAP_cmt, synFileData
          ));


          //重合名.連鎖重合.付加重合.配位重合
        if (synData.hom_prize_chain_add_crd)   
          props.updateHOM_PRIZE_CHAIN_ADD_CRD(getData(
            synData.hom_prize_chain_add_crd_type, synData.hom_prize_chain_add_crd, 
            synData.hom_prize_chain_add_crd_unit, synData.hom_prize_chain_add_crd_cf, 
            synData.hom_prize_chain_add_crd_cmt, synFileData
          ));

          //重合名.連鎖重合.開環重合.カチオン開環重合
        if (synData.hom_prize_chain_rop_CROP)   
          props.updateHOM_PRIZE_CHAIN_ROP_CROP(getData(
            synData.hom_prize_chain_rop_CROP_type, synData.hom_prize_chain_rop_CROP, 
            synData.hom_prize_chain_rop_CROP_unit, synData.hom_prize_chain_rop_CROP_cf,
            synData.hom_prize_chain_rop_CROP_cmt, synFileData
          ));

          //重合名.連鎖重合.開環重合.アニオン開環重合
        if (synData.hom_prize_chain_rop_AROP)   
          props.updateHOM_PRIZE_CHAIN_ROP_AROP(getData(
            synData.hom_prize_chain_rop_AROP_type, synData.hom_prize_chain_rop_AROP,
            synData.hom_prize_chain_rop_AROP_unit, synData.hom_prize_chain_rop_AROP_cf, 
            synData.hom_prize_chain_rop_AROP_cmt, synFileData
          ));


          //重合名.連鎖重合.開環重合.ラジカル開環重合
        if (synData.hom_prize_chain_rop_RROP)   
          props.updateHOM_PRIZE_CHAIN_ROP_RROP(getData(
            synData.hom_prize_chain_rop_RROP_type, synData.hom_prize_chain_rop_RROP,
            synData.hom_prize_chain_rop_RROP_unit, synData.hom_prize_chain_rop_RROP_cf, 
            synData.hom_prize_chain_rop_RROP_cmt, synFileData
          ));

          //重合名.連鎖重合.開環重合.遷移金属触媒を用いた開環重合.開環メタセシス重合
        if (synData.hom_prize_chain_rop_TMCR_ROMP)   
          props.updateHOM_PRIZE_CHAIN_ROP_TMCR_ROMP(getData(
            synData.hom_prize_chain_rop_TMCR_ROMP_type, synData.hom_prize_chain_rop_TMCR_ROMP,
            synData.hom_prize_chain_rop_TMCR_ROMP_unit, synData.hom_prize_chain_rop_TMCR_ROMP_cf,
            synData.hom_prize_chain_rop_TMCR_ROMP_cmt, synFileData
          ));

          //重合名.連鎖重合.連鎖縮合重合
        if (synData.hom_prize_chain_CGCP)   
          props.updateHOM_PRIZE_CHAIN_CGCP(getData(
            synData.hom_prize_chain_CGCP_type, synData.hom_prize_chain_CGCP,
            synData.hom_prize_chain_CGCP_unit, synData.hom_prize_chain_CGCP_cf,
            synData.hom_prize_chain_CGCP_cmt, synFileData
          ));
        
        // バイオモノマー生成.反応時間
        if (synData.bmsyn_react)   
        props.updateBMSYN_REACT(getData(
          synData.bmsyn_react_type, synData.bmsyn_react,
          synData.bmsyn_react_unit, synData.bmsyn_react_cf,
          synData.bmsyn_react_cmt, synFileData
        ));

        // バイオモノマー生成.反応速度
        if (synData.bmsyn_reacs)   
        props.updateBMSYN_REACS(getData(
          synData.bmsyn_reacs_type, synData.bmsyn_reacs,
          synData.bmsyn_reacs_unit, synData.bmsyn_reacs_cf,
          synData.bmsyn_reacs_cmt, synFileData
        ));

        // バイオモノマー生成.収量
        if (synData.bmsyn_yield)   
        props.updateBMSYN_YIELD(getData(
          synData.bmsyn_yield_type, synData.bmsyn_yield,
          synData.bmsyn_yield_unit, synData.bmsyn_yield_cf,
          synData.bmsyn_yield_cmt, synFileData
        ));

        // バイオモノマー生成.生産速度.菌体濁度
        if (synData.bmsyn_pros_fgtb)   
        props.updateBMSYN_PROS_FGTB(getData(
          synData.bmsyn_pros_fgtb_type, synData.bmsyn_pros_fgtb,
          synData.bmsyn_pros_fgtb_unit, synData.bmsyn_pros_fgtb_cf,
          synData.bmsyn_pros_fgtb_cmt, synFileData
        ));

        // バイオモノマー生成.生産速度.炭素源濃度
        if (synData.bmsyn_pros_csd)   
        props.updateBMSYN_PROS_CSD(getData(
          synData.bmsyn_pros_csd_type, synData.bmsyn_pros_csd,
          synData.bmsyn_pros_csd_unit, synData.bmsyn_pros_csd_cf,
          synData.bmsyn_pros_csd_cmt, synFileData
        ));

        // バイオモノマー生成.生産速度.糖消費
        if (synData.bmsyn_pros_scon)   
        props.updateBMSYN_PROS_SCON(getData(
          synData.bmsyn_pros_scon_type, synData.bmsyn_pros_scon,
          synData.bmsyn_pros_scon_unit, synData.bmsyn_pros_scon_cf,
          synData.bmsyn_pros_scon_cmt, synFileData
        ));

        // バイオモノマー生成.生産速度.生産データ
        if (synData.bmsyn_pros_pdt)   
        props.updateBMSYN_PROS_PDT(getData(
          synData.bmsyn_pros_pdt_type, synData.bmsyn_pros_pdt,
          synData.bmsyn_pros_pdt_unit, synData.bmsyn_pros_pdt_cf,
          synData.bmsyn_pros_pdt_cmt, synFileData
        ));


        //　計算
        const calData = responseData.beforedata.cal;
        const calFileData = responseData.beforedata.files.cal;

        if (calData.MD_MDp)
          props.updateMD_MDP(getData(
            calData.MD_MDp_type, calData.MD_MDp, calData.MD_MDp_unit,
            calData.MD_MDp_cf, calData.MD_MDp_cmt, calFileData
          ));

        if (calData.MD_cond_ens)    
          props.updateMD_COND_ENS(getData(
            calData.MD_cond_ens_type, calData.MD_cond_ens, calData.MD_cond_ens_unit,
            calData.MD_cond_ens_cf, calData.MD_cond_ens_cmt, calFileData
          ));
        
        if (calData.MD_cond_frc_ftbl)
          props.updateMD_COND_FRC_FTBL(getData(
            calData.MD_cond_frc_ftbl_type, calData.MD_cond_frc_ftbl, calData.MD_cond_frc_ftbl_unit,
            calData.MD_cond_frc_ftbl_cf, calData.MD_cond_frc_ftbl_cmt, calFileData
          ));
        
        if (calData.MD_cond_icrd_icimage)
          props.updateMD_COND_ICRD_ICIMAGE(getData(
            calData.MD_cond_icrd_icimage_type, calData.MD_cond_icrd_icimage, calData.MD_cond_icrd_icimage_unit,
            calData.MD_cond_icrd_icimage_cf, calData.MD_cond_icrd_icimage_cmt, calFileData
          ));

        if (calData.MD_cond_icrd_icPDB)
          props.updateMD_COND_ICRD_ICPDB(getData(
            calData.MD_cond_icrd_icPDB_type, calData.MD_cond_icrd_icPDB, calData.MD_cond_icrd_icPDB_unit,
            calData.MD_cond_icrd_icPDB_cf, calData.MD_cond_icrd_icPDB_cmt, calFileData
          ));
        
          if (calData.MD_cond_icrd_icDCD)
          props.updateMD_COND_ICRD_ICDCD(getData(
            calData.MD_cond_icrd_icDCD_type, calData.MD_cond_icrd_icDCD, calData.MD_cond_icrd_icDCD_unit,
            calData.MD_cond_icrd_icDCD_cf, calData.MD_cond_icrd_icDCD_cmt, calFileData
          ));

        if (calData.MD_cond_icrd_icPSF)
          props.updateMD_COND_ICRD_ICPSF(getData(
            calData.MD_cond_icrd_icPSF_type, calData.MD_cond_icrd_icPSF, calData.MD_cond_icrd_icPSF_unit,
            calData.MD_cond_icrd_icPSF_cf, calData.MD_cond_icrd_icPSF_cmt, calFileData
          ));

        if (calData.MD_cond_otcond)
          props.updateMD_COND_OTCOND(getData(
            calData.MD_cond_otcond_type, calData.MD_cond_otcond, calData.MD_cond_otcond_unit,
            calData.MD_cond_otcond_cf, calData.MD_cond_otcond_cmt, calFileData
          ));
        
        if (calData.MD_crslt_Thdyd_TvsT)
          props.updateMD_CRSLT_THDYD_TVST(getData(
            calData.MD_crslt_Thdyd_TvsT_type, calData.MD_crslt_Thdyd_TvsT, calData.MD_crslt_Thdyd_TvsT_unit,
            calData.MD_crslt_Thdyd_TvsT_cf, calData.MD_crslt_Thdyd_TvsT_cmt, calFileData
          ));

        if (calData.MD_crslt_Thdyd_PvsT)
          props.updateMD_CRSLT_THDYD_PVST(getData(
            calData.MD_crslt_Thdyd_PvsT_type, calData.MD_crslt_Thdyd_PvsT, calData.MD_crslt_Thdyd_PvsT_unit,
            calData.MD_crslt_Thdyd_PvsT_cf, calData.MD_crslt_Thdyd_PvsT_cmt, calFileData
          ));

        if (calData.MD_crslt_Thdyd_EvsT)
          props.updateMD_CRSLT_THDYD_EVST(getData(
            calData.MD_crslt_Thdyd_EvsT_type, calData.MD_crslt_Thdyd_EvsT, calData.MD_crslt_Thdyd_EvsT_unit,
            calData.MD_crslt_Thdyd_EvsT_cf, calData.MD_crslt_Thdyd_EvsT_cmt, calFileData
          ));
        
        if (calData.MD_crslt_Dyd)
          props.updateMD_CRSLT_DYD(getData(
            calData.MD_crslt_Dyd_type, calData.MD_crslt_Dyd, calData.MD_crslt_Dyd_unit,
            calData.MD_crslt_Dyd_cf, calData.MD_crslt_Dyd_cmt, calFileData
          ));
        
        if (calData.QC_QCp)
          props.updateQC_QCP(getData(
            calData.QC_QCp_type, calData.QC_QCp, calData.QC_QCp_unit,
            calData.QC_QCp_cf, calData.QC_QCp_cmt, calFileData
          ));

        if (calData.QC_QCbc)
        props.updateQC_QCBC(getData(
          calData.QC_QCbc_type, calData.QC_QCbc, calData.QC_QCbc_unit,
          calData.QC_QCbc_cf, calData.QC_QCbc_cmt, calFileData
        ));

        if (calData.QC_QCap)
        props.updateQC_QCAP(getData(
          calData.QC_QCap_type, calData.QC_QCap, calData.QC_QCap_unit,
          calData.QC_QCap_cf, calData.QC_QCap_cmt, calFileData
        ));

        // 測定法
        
        const meamData = responseData.beforedata.meam;
        const meamFileData = responseData.beforedata.files.meam;

        if (meamData.spy_inf_fti)
          props.updateSPY_INF_FTI(getData(
            meamData.spy_inf_fti_type, meamData.spy_inf_fti, meamData.spy_inf_fti_unit,
            meamData.spy_inf_fti_cf, meamData.spy_inf_fti_cmt, meamFileData
          ));
        if (meamData.spy_nmr)
          props.updateSPY_NMR(getData(
            meamData.spy_nmr_type, meamData.spy_nmr, meamData.spy_nmr_unit,
            meamData.spy_nmr_cf, meamData.spy_nmr_cmt, meamFileData
          ));
        if (meamData.spy_uvi)
          props.updateSPY_UVI(getData(
            meamData.spy_uvi_type, meamData.spy_uvi, meamData.spy_uvi_unit,
            meamData.spy_uvi_cf, meamData.spy_uvi_cmt, meamFileData
          ));

        if (meamData.spy_flu)
          props.updateSPY_FLU(getData(
            meamData.spy_flu_type, meamData.spy_flu, meamData.spy_flu_unit,
            meamData.spy_flu_cf, meamData.spy_flu_cmt, meamFileData
          ));
        if (meamData.spy_ram)
          props.updateSPY_RAM(getData(
            meamData.spy_ram_type, meamData.spy_ram, meamData.spy_ram_unit,
            meamData.spy_ram_cf, meamData.spy_ram_cmt, meamFileData
          ));

        if (meamData.spy_cd)
          props.updateSPY_CD(getData(
            meamData.spy_cd_type, meamData.spy_cd, meamData.spy_cd_unit,
            meamData.spy_cd_cf, meamData.spy_cd_cmt, meamFileData
          ));

        if (meamData.spy_ls)
          props.updateSPY_LS(getData(
            meamData.spy_ls_type, meamData.spy_ls, meamData.spy_ls_unit,
            meamData.spy_ls_cf, meamData.spy_ls_cmt, meamFileData
          ));

        if (meamData.spy_ell)
          props.updateSPY_ELL(getData(
            meamData.spy_ell_type, meamData.spy_ell, meamData.spy_ell_unit,
            meamData.spy_ell_cf, meamData.spy_ell_cmt, meamFileData
          ));
        if (meamData.spy_nsc)

          props.updateSPY_NSC(getData(
            meamData.spy_nsc_type, meamData.spy_nsc, meamData.spy_nsc_unit,
            meamData.spy_nsc_cf, meamData.spy_nsc_cmt, meamFileData
          ));

        if (meamData.spy_xra_pho)
          props.updateSPY_XRA_PHO(getData(
            meamData.spy_xra_pho_type, meamData.spy_xra_pho, meamData.spy_xra_pho_unit,
            meamData.spy_xra_pho_cf, meamData.spy_xra_pho_cmt, meamFileData
          ));
        
        if (meamData.spy_xra_dif)
          props.updateSPY_XRA_DIF(getData(
            meamData.spy_xra_dif_type, meamData.spy_xra_dif, meamData.spy_xra_dif_unit,
            meamData.spy_xra_dif_cf, meamData.spy_xra_dif_cmt, meamFileData
          ));

        if (meamData.spy_xra_xs_sag)
          props.updateSPY_XRA_XS_SAG(getData(
            meamData.spy_xra_xs_sag_type, meamData.spy_xra_xs_sag, meamData.spy_xra_xs_sag_unit,
            meamData.spy_xra_xs_sag_cf, meamData.spy_xra_xs_sag_cmt, meamFileData
          ));
        
        // console.log(meamData.spy_xra_xs_wid)

        if (meamData.spy_xra_xs_wid)
          props.updateSPY_XRA_XS_WID(getData(
            meamData.spy_xra_xs_wid_type, meamData.spy_xra_xs_wid, meamData.spy_xra_xs_wid_unit,
            meamData.spy_xra_xs_wid_cf, meamData.spy_xra_xs_wid_cmt, meamFileData
          ));

        if (meamData.chr_gas)
          props.updateCHR_GAS(getData(
            meamData.chr_gas_type, meamData.chr_gas, meamData.chr_gas_unit,
            meamData.chr_gas_cf, meamData.chr_gas_cmt, meamFileData
          ));

        if (meamData.chr_hpl_gpc)
          props.updateCHR_HPL_GPC(getData(
            meamData.chr_hpl_gpc_type, meamData.chr_hpl_gpc, meamData.chr_hpl_gpc_unit,
            meamData.chr_hpl_gpc_cf, meamData.chr_hpl_gpc_cmt, meamFileData
          ));
        
        if (meamData.chr_hpl_sec)
          props.updateCHR_HPL_SEC(getData(
            meamData.chr_hpl_sec_type, meamData.chr_hpl_sec, meamData.chr_hpl_sec_unit,
            meamData.chr_hpl_sec_cf, meamData.chr_hpl_sec_cmt, meamFileData
          ));

        if (meamData.mic_afm)
          props.updateMIC_AFM(getData(
            meamData.mic_afm_type, meamData.mic_afm, meamData.mic_afm_unit,
            meamData.mic_afm_cf, meamData.mic_afm_cmt, meamFileData
          ));

        if (meamData.mic_sem)
          props.updateMIC_SEM(getData(
            meamData.mic_sem_type, meamData.mic_sem, meamData.mic_sem_unit,
            meamData.mic_sem_cf, meamData.mic_sem_cmt, meamFileData
          ));

        if (meamData.mic_tem)
          props.updateMIC_TEM(getData(
            meamData.mic_tem_type, meamData.mic_tem, meamData.mic_tem_unit,
            meamData.mic_tem_cf, meamData.mic_tem_cmt, meamFileData
          ));
        
        if (meamData.mic_opt)
          props.updateMIC_OPT(getData(
            meamData.mic_opt_type, meamData.mic_opt, meamData.mic_opt_unit,
            meamData.mic_opt_cf, meamData.mic_opt_cmt, meamFileData
          ));
        
        if (meamData.mic_flm)
          props.updateMIC_FLM(getData(
            meamData.mic_flm_type, meamData.mic_flm, meamData.mic_flm_unit,
            meamData.mic_flm_cf, meamData.mic_flm_cmt, meamFileData
          ));

        if (meamData.mic_cls)
          props.updateMIC_CLS(getData(
            meamData.mic_cls_type, meamData.mic_cls, meamData.mic_cls_unit,
            meamData.mic_cls_cf, meamData.mic_cls_cmt, meamFileData
          ));

        if (meamData.mic_spr)
          props.updateMIC_SPR(getData(
            meamData.mic_spr_type, meamData.mic_spr, meamData.mic_spr_unit,
            meamData.mic_spr_cf, meamData.mic_spr_cmt, meamFileData
          ));

        if (meamData.mic_qcm)
          props.updateMIC_QCM(getData(
            meamData.mic_qcm_type, meamData.mic_qcm, meamData.mic_qcm_unit,
            meamData.mic_qcm_cf, meamData.mic_qcm_cmt, meamFileData
          ));

        if (meamData.mic_pol)
          props.updateMIC_POL(getData(
            meamData.mic_pol_type, meamData.mic_pol, meamData.mic_pol_unit,
            meamData.mic_pol_cf, meamData.mic_pol_cmt, meamFileData
          ));

        if (meamData.tha_tga)
          props.updateTHA_TGA(getData(
            meamData.tha_tga_type, meamData.tha_tga, meamData.tha_tga_unit,
            meamData.tha_tga_cf, meamData.tha_tga_cmt, meamFileData
          ));

        if (meamData.tha_dta)
          props.updateTHA_DTA(getData(
            meamData.tha_dta_type, meamData.tha_dta, meamData.tha_dta_unit,
            meamData.tha_dta_cf, meamData.tha_dta_cmt, meamFileData
          ));

        if (meamData.tha_dsc)
          props.updateTHA_DSC(getData(
            meamData.tha_dsc_type, meamData.tha_dsc, meamData.tha_dsc_unit,
            meamData.tha_dsc_cf, meamData.tha_dsc_cmt, meamFileData
          ));

        if (meamData.ea)
          props.updateEA(getData(
            meamData.ea_type, meamData.ea, meamData.ea_unit,
            meamData.ea_cf, meamData.ea_cmt, meamFileData
          ));
        if (meamData.msm)
          props.updateMSM(getData(
            meamData.msm_type, meamData.msm, meamData.msm_unit,
            meamData.msm_cf, meamData.msm_cmt, meamFileData
          ));
        if (meamData.gep)
          props.updateGEP(getData(
            meamData.gep_type, meamData.gep, meamData.gep_unit,
            meamData.gep_cf, meamData.gep_cmt, meamFileData
          ));
        if (meamData.cona)
          props.updateCONA(getData(
            meamData.cona_type, meamData.cona, meamData.cona_unit,
            meamData.cona_cf, meamData.cona_cmt, meamFileData
          ));
        if (meamData.sten)
          props.updateSTEN(getData(
            meamData.sten_type, meamData.sten, meamData.sten_unit,
            meamData.sten_cf, meamData.sten_cmt, meamFileData
          ));
        if (meamData.sfor)
          props.updateSFOR(getData(
            meamData.sfor_type, meamData.sfor, meamData.sfor_unit,
            meamData.sfor_cf, meamData.sfor_cmt, meamFileData
          ));
        if (meamData.bod)
          props.updateBOD(getData(
            meamData.bod_type, meamData.bod, meamData.bod_unit,
            meamData.bod_cf, meamData.bod_cmt, meamFileData
          ));
      
        
        // 分解

        const degrData = responseData.beforedata.degr;
        const degrFileData = responseData.beforedata.files.degr;

        if (degrData.chd)
          props.updateCHD(getData(
            degrData.chd_type, degrData.chd, degrData.chd_unit,
            degrData.chd_cf, degrData.chd_cmt, degrFileData
          ));

        if (degrData.biod_pls_loc)
          props.updateBIOD_PLS_LOC(getData(
            degrData.biod_pls_loc_type, degrData.biod_pls_loc, degrData.biod_pls_loc_unit,
            degrData.biod_pls_loc_cf, degrData.biod_pls_loc_cmt, degrFileData
          ));

        if (degrData.biod_pls_bdate)
          props.updateBIOD_PLS_BDATE(getData(
            degrData.biod_pls_bdate_type, degrData.biod_pls_bdate, degrData.biod_pls_bdate_unit,
            degrData.biod_pls_bdate_cf, degrData.biod_pls_bdate_cmt, degrFileData
          ));

        if (degrData.biod_pls_btime)
          props.updateBIOD_PLS_BTIME(getData(
            degrData.biod_pls_btime_type, degrData.biod_pls_btime, degrData.biod_pls_btime_unit,
            degrData.biod_pls_btime_cf, degrData.biod_pls_btime_cmt, degrFileData
          ));

        if (degrData.biod_pls_toc)
          props.updateBIOD_PLS_TOC(getData(
            degrData.biod_pls_toc_type, degrData.biod_pls_toc, degrData.biod_pls_toc_unit,
            degrData.biod_pls_toc_cf, degrData.biod_pls_toc_cmt, degrFileData
          ));

        if (degrData.biod_pls_nita)
          props.updateBIOD_PLS_NITA(getData(
            degrData.biod_pls_nita_type, degrData.biod_pls_nita, degrData.biod_pls_nita_unit,
            degrData.biod_pls_nita_cf, degrData.biod_pls_nita_cmt, degrFileData
          ));

        if (degrData.biod_pls_bmass)
          props.updateBIOD_PLS_BMASS(getData(
            degrData.biod_pls_bmass_type, degrData.biod_pls_bmass, degrData.biod_pls_bmass_unit,
            degrData.biod_pls_bmass_cf, degrData.biod_pls_bmass_cmt, degrFileData
          ));

        if (degrData.biod_pls_dd_rc_ph)
          props.updateBIOD_PLS_DD_RC_PH(getData(
            degrData.biod_pls_dd_rc_ph_type, degrData.biod_pls_dd_rc_ph, degrData.biod_pls_dd_rc_ph_unit,
            degrData.biod_pls_dd_rc_ph_cf, degrData.biod_pls_dd_rc_ph_cmt, degrFileData
          ));

        if (degrData.biod_pls_dd_rc_btemp)
          props.updateBIOD_PLS_DD_RC_BTEMP(getData(
            degrData.biod_pls_dd_rc_btemp_type, degrData.biod_pls_dd_rc_btemp, degrData.biod_pls_dd_rc_btemp_unit,
            degrData.biod_pls_dd_rc_btemp_cf, degrData.biod_pls_dd_rc_btemp_cmt, degrFileData
          ));

        if (degrData.biod_pls_dd_rc_both)
          props.updateBIOD_PLS_DD_RC_BOTH(getData(
            degrData.biod_pls_dd_rc_both_type, degrData.biod_pls_dd_rc_both, degrData.biod_pls_dd_rc_both_unit,
            degrData.biod_pls_dd_rc_both_cf, degrData.biod_pls_dd_rc_both_cmt, degrFileData
          ));

        if (degrData.biod_pls_dd_brlt_brt)
          props.updateBIOD_PLS_DD_BRLT_BRT(getData(
            degrData.biod_pls_dd_brlt_brt_type, degrData.biod_pls_dd_brlt_brt, degrData.biod_pls_dd_brlt_brt_unit,
            degrData.biod_pls_dd_brlt_brt_cf, degrData.biod_pls_dd_brlt_brt_cmt, degrFileData
          ));

        if (degrData.biod_pls_dd_brlt_dr)
          props.updateBIOD_PLS_DD_BRLT_DR(getData(
            degrData.biod_pls_dd_brlt_dr_type, degrData.biod_pls_dd_brlt_dr, degrData.biod_pls_dd_brlt_dr_unit,
            degrData.biod_pls_dd_brlt_dr_cf, degrData.biod_pls_dd_brlt_dr_cmt, degrFileData
          ));

        if (degrData.biod_pls_dd_brlt_dp)
          props.updateBIOD_PLS_DD_BRLT_DP(getData(
            degrData.biod_pls_dd_brlt_dp_type, degrData.biod_pls_dd_brlt_dp, degrData.biod_pls_dd_brlt_dp_unit,
            degrData.biod_pls_dd_brlt_dp_cf, degrData.biod_pls_dd_brlt_dp_cmt, degrFileData
          ));

        if (degrData.biod_pls_dd_brlt_dbod)
          props.updateBIOD_PLS_DD_BRLT_DBOD(getData(
            degrData.biod_pls_dd_brlt_dbod_type, degrData.biod_pls_dd_brlt_dbod, degrData.biod_pls_dd_brlt_dbod_unit,
            degrData.biod_pls_dd_brlt_dbod_cf, degrData.biod_pls_dd_brlt_dbod_cmt, degrFileData
          ));



      if(responseData.resultCode === '000'){
        //success for login

        // const newuser = {
        //   status: 'on',
        //   admin_id: responseData.admin_id,
        // }

        // localStorage.setItem('userinfo', JSON.stringify(newuser) );

        // setTimeout( () => {
        //   history.push('/');
        //   }, 500);

      } else {
        // alert(`ログインへ失敗しました。\n${responseData.resultMessage}`);
        // return 1;
      }
    })
    .catch( error => {
      // console.error(error);
      // alert('ログインへ失敗しました。');
      // return 2;
    });
  }

  useEffect(() => {
    // 1度だけ読み込む
    // cookie からdocid取得
    let allcookies = document.cookie.split('; ');
    // console.log(allcookies)
    allcookies.forEach(c => {
      let r = c.split('=');
      // console.log(r)
      if(r[0] == 'docid'){
          doGetData(r[1]);
      }
    });

    // doGetData('ARFQE');


 

  },[]);

  useEffect(() => {

    const testes = () => {
      document.cookie = 'docid=; max-age=0';
    }

    window.addEventListener('beforeunload', testes)

    return () => window.removeEventListener('beforeunload', testes)

  }, [])




  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} />
      {/* <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      /> */}
      <main className={classes.content}>
        {children}
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};


const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    // doLogout,
    // updateCategory,
    // updateOpenTreeDialog,

    updateDOCID,
    updateOVID,
    updateSAMPLE,
    updateSAMPLEID,
    updateGROUP,
    updateINST,
    updateKIND,
    updateCHST,
    updateCHST_IMG,
    updateEXPP,
    updateRESPP,
    updateEXPDATE,
    updateINPP,
    updateBMEMO,

     
     
    updatePP_TD_DEN,
    updatePP_TD_SV,
    updatePP_TD_AR,
    updatePP_TD_ST,
    updatePP_TD_TROP,
    updatePP_TD_ENTHAL,
    updatePP_TD_CR,
    updatePP_TD_TE,
    updatePP_TD_GP_WP,
    updatePP_TD_GP_MP,
    updatePP_TD_GP_FTD,
    updatePP_TD_GP_TPD,
    updatePP_TD_GP_HD,
    updatePP_TD_FC_MV_SD,
    updatePP_TD_FC_MV_MTD,
    updatePP_TD_FC_MV_PD,
    updatePP_TD_FC_VC,
    updatePP_TD_SP_SPC,
    updatePP_TD_SP_STD,
    updatePP_TD_TC_TTD,
    updatePP_TD_TC_DSD,
    updatePP_TD_TC_CPD,
    updatePP_TD_TDIF_CMM,
    updatePP_TD_TDIF_AMO,
    updatePP_TD_TEMP_TG_GPD,
    updatePP_TD_TEMP_MLP_MPD,
    updatePP_TD_TEMP_DSC,
    updatePP_TD_TEMP_TGA,
    updatePP_TD_TEMP_WR5,
    updatePP_TD_TEMP_EMB,
    updatePP_TD_TEMP_HDT,
    updatePP_TD_BCHAR_FIT,
    updatePP_TD_BCHAR_HDC,
    updatePP_TD_BCHAR_CH,
    updatePP_TD_BCHAR_OI,
    updatePP_TD_BCHAR_HRP,
    updateFRICTION_COEFFICIENT,
    updateENZYMATIC_DECOMPOSITION_RATE,
    updateENZYMATIC_ENZYMATIC_REACTION,
    updateBIODEGRADABILITY_CURVE,
    updateBAR_GRAPH,
    updateSTRUCTURE_XRAY_GRAPH,
    updateSTRUCTURE_MICROSCOPE_IMAGE,
    updateSTRUCTURE_CRYSTALLINITY_DEGREE,

    updatePP_MS_CRT,
    updatePP_MS_STRREL,
    updatePP_MS_CRACK,
    updatePP_MS_FRAT,
    updatePP_MS_FATP,
    updatePP_MS_HARD,
    updatePP_MS_SSC_TEN_DEGSTRETCH,
    updatePP_MS_SSC_TEN_DEGELA,
    updatePP_MS_SSC_TEN_RUPSTR,
    updatePP_MS_SSC_TEN_STTD,
    updatePP_MS_SSC_TEN_STPD,
    updatePP_MS_SSC_TEN_BAIMAGE,
    updatePP_MS_SSC_BEND_BDSTRETCH,
    updatePP_MS_SSC_BEND_BDELA,
    updatePP_MS_SSC_BEND_BRSTR,
    updatePP_MS_SSC_BEND_BTD,
    updatePP_MS_SSC_BEND_BPD,
    updatePP_MS_SSC_BEND_BBAIMAGE,
    updatePP_MS_VEM_VTD,
    updatePP_MS_VEM_VPD,
    updatePP_MS_VEM_GDASH,
    updatePP_MS_VEM_GDASH2,
    updatePP_MS_VEM_LTAN,
    updatePP_MS_SHT_CHAR_CTD,
    updatePP_MS_SHT_IZOD_ITD,
    
    updateRSLT_RT,
    updateRSLT_MCR,
    updateRSLT_RRATE,
    updateRSLT_CY,
    updateRSLT_PRATE_TUR,
    updateRSLT_PRATE_OCC,
    updateRSLT_PRATE_PDATA,
    updateRCON_PH,
    updateRCON_RTEMP,
    updateRCON_OTRC,
    updateHOM_PRIZE_SGP_PCON_NASP,
    updateHOM_PRIZE_SGP_PCON_ANSP,
    updateHOM_PRIZE_SGP_PCON_ARNSP,
    updateHOM_PRIZE_SGP_PCON_ARESP,
    updateHOM_PRIZE_SGP_PCON_OCP,
    updateHOM_PRIZE_SGP_PCON_TMCP,
    updateHOM_PRIZE_SGP_PCON_EP,
    updateHOM_PRIZE_SGP_PADD_PTDB,
    updateHOM_PRIZE_SGP_PADD_ROPA,
    updateHOM_PRIZE_SGP_PADD_CYPA,
    updateHOM_PRIZE_SGP_PADD_OTPA,
    updateHOM_PRIZE_SGP_ADCO,
    updateBUY_MAKER,
    updateBUY_LOT_NUMBER,
    updateBUY_PMAKER,
    updateBUY_PLOT_NUMBER,
    updatePROCESS_SYNTHESIS,
    updatePROCESS_MACHINE,
    updatePROCESS_THEOTHER,
    updateHOM_ENZYME,
    updateHOM_FUNGUS,
    updateHOM_PRIZE_CHAIN_ADD_RAD_LRP,
    updateHOM_PRIZE_CHAIN_ADD_CATI_LCP,
    updateHOM_PRIZE_CHAIN_ADD_ANIO_LAP,
    updateHOM_PRIZE_CHAIN_ADD_CRD,
    updateHOM_PRIZE_CHAIN_ROP_CROP,
    updateHOM_PRIZE_CHAIN_ROP_AROP,
    updateHOM_PRIZE_CHAIN_ROP_RROP,
    updateHOM_PRIZE_CHAIN_ROP_TMCR_ROMP,
    updateHOM_PRIZE_CHAIN_CGCP,
    updateBMSYN_REACT,
    updateBMSYN_REACS,
    updateBMSYN_YIELD,
    updateBMSYN_PROS_FGTB,
    updateBMSYN_PROS_CSD,
    updateBMSYN_PROS_SCON,
    updateBMSYN_PROS_PDT,

    updateMD_MDP,
    updateMD_COND_ENS,
    updateMD_COND_FRC_FTBL,
    updateMD_COND_ICRD_ICIMAGE,
    updateMD_COND_ICRD_ICPDB,
    updateMD_COND_ICRD_ICDCD,
    updateMD_COND_ICRD_ICPSF,
    updateMD_COND_OTCOND,
    updateMD_CRSLT_THDYD_TVST,
    updateMD_CRSLT_THDYD_PVST,
    updateMD_CRSLT_THDYD_EVST,
    updateMD_CRSLT_DYD,
    updateQC_QCP,
    updateQC_QCBC,
    updateQC_QCAP,

    updateSPY_INF_FTI,
    updateSPY_NMR,
    updateSPY_UVI,
    updateSPY_FLU,
    updateSPY_RAM,
    updateSPY_CD,
    updateSPY_LS,
    updateSPY_ELL,
    updateSPY_NSC,
    updateSPY_XRA_PHO,
    updateSPY_XRA_DIF,
    updateSPY_XRA_XS_SAG,
    updateSPY_XRA_XS_WID,
    updateCHR_GAS,
    updateCHR_HPL_GPC,
    updateCHR_HPL_SEC,
    updateMIC_AFM,
    updateMIC_SEM,
    updateMIC_TEM,
    updateMIC_OPT,
    updateMIC_FLM,
    updateMIC_CLS,
    updateMIC_SPR,
    updateMIC_QCM,
    updateMIC_POL,
    updateTHA_TGA,
    updateTHA_DTA,
    updateTHA_DSC,
    updateEA,
    updateMSM,
    updateGEP,
    updateCONA,
    updateSTEN,
    updateSFOR,
    updateBOD,

    updateCHD,
    updateBIOD_PLS_LOC,
    updateBIOD_PLS_BDATE,
    updateBIOD_PLS_BTIME,
    updateBIOD_PLS_TOC,
    updateBIOD_PLS_NITA,
    updateBIOD_PLS_BMASS,
    updateBIOD_PLS_DD_RC_PH,
    updateBIOD_PLS_DD_RC_BTEMP,
    updateBIOD_PLS_DD_RC_BOTH,
    updateBIOD_PLS_DD_BRLT_BRT,
    updateBIOD_PLS_DD_BRLT_DR,
    updateBIOD_PLS_DD_BRLT_DP,
    updateBIOD_PLS_DD_BRLT_DBOD,


   }, dispatch)
}

let mapStateToProps = (state) => {
  return {
    user: state.user,
    settings: state.settings
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);

// export default Main;
