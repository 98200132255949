// 測定法　望月

// 分光.赤外分光法.フーリエ変換赤外分光法
export const UPDATE_SPY_INF_FTI = "spy_inf_fti"
//分光.核磁気共鳴分光法
export const UPDATE_SPY_NMR = "spy_nmr"
// 分光.紫外・可視分光法
export const UPDATE_SPY_UVI = "spy_uvi"
// 分光.蛍光分光法
export const UPDATE_SPY_FLU = "spy_flu"
// 分光.ラマン分光法
export const UPDATE_SPY_RAM = "spy_ram"
// 分光.円二色性
export const UPDATE_SPY_CD = "spy_cd"
// 分光.光散乱法
export const UPDATE_SPY_LS = "spy_ls"
// 分光.分光エリプソメトリー
export const UPDATE_SPY_ELL = "spy_ell"
// 分光.中性子散乱法
export const UPDATE_SPY_NSC = "spy_nsc"
//===== 分光.X線.X線光電子分光法
export const UPDATE_SPY_XRA_PHO = "spy_xra_pho"
// 分光.X線.X線回折法
export const UPDATE_SPY_XRA_DIF = "spy_xra_dif"
//===== 分光.X線.X線散乱法.小角
export const UPDATE_SPY_XRA_XS_SAG  = "spy_xra_xs_sag"
// 分光.X線.X線散乱法.広角
export const UPDATE_SPY_XRA_XS_WID = "spy_xra_xs_wid"
// クロマトグラフィー.ガスクロマトグラフィー
export const UPDATE_CHR_GAS = "chr_gas"
// クロマトグラフィー.高速液体クロマトグラフィー.ゲル濾過クロマトグラフィー
export const UPDATE_CHR_HPL_GPC = "chr_hpl_gpc"
// クロマトグラフィー.高速液体クロマトグラフィー.サイズ排除クロマトグラフィー
export const UPDATE_CHR_HPL_SEC = "chr_hpl_sec"
// 顕微鏡法.原子間力顕微鏡法
export const UPDATE_MIC_AFM = "mic_afm"
// 顕微鏡法.走査型電子顕微鏡法
export const UPDATE_MIC_SEM = "mic_sem"
// 顕微鏡法.透過型電子顕微鏡法
export const UPDATE_MIC_TEM = "mic_tem"
// 顕微鏡法.光学顕微鏡法
export const UPDATE_MIC_OPT = "mic_opt"
// 顕微鏡法.蛍光顕微鏡法
export const UPDATE_MIC_FLM = "mic_flm"
// 顕微鏡法.共焦点レーザー顕微鏡法
export const UPDATE_MIC_CLS = "mic_cls"
// 顕微鏡法.表面プラズモン共鳴法
export const UPDATE_MIC_SPR = "mic_spr"
// 顕微鏡法.水晶振動子マイクロバランス法
export const UPDATE_MIC_QCM = "mic_qcm"
// 顕微鏡法.偏光顕微鏡
export const UPDATE_MIC_POL = "mic_pol"
// 熱分析.熱重量分析
export const UPDATE_THA_TGA = "tha_tga"
// 熱分析.示差熱分析
export const UPDATE_THA_DTA = "tha_dta"
// 熱分析.示差走査熱量測定
export const UPDATE_THA_DSC = "tha_dsc"
// 元素分析
export const UPDATE_EA = "ea"
// 質量分析法
export const UPDATE_MSM = "msm"
// ゲル電気泳動
export const UPDATE_GEP = "gep"
// 接触角測定
export const UPDATE_CONA = "cona"
// 表面張力測定
export const UPDATE_STEN = "sten"
//表面力測定
export const UPDATE_SFOR = "sfor"
// 生物化学的酸素要求量測定
export const UPDATE_BOD = "bod"


export const updateSPY_INF_FTI = param1 => {
    return {
      type: UPDATE_SPY_INF_FTI,
      payload: param1
    }
}

export const updateSPY_NMR = param1 => {
    return {
      type: UPDATE_SPY_NMR,
      payload: param1
    }
}

export const updateSPY_UVI = param1 => {
    return {
      type: UPDATE_SPY_UVI,
      payload: param1
    }
}

export const updateSPY_FLU = param1 => {
    return {
      type: UPDATE_SPY_FLU,
      payload: param1
    }
}

export const updateSPY_RAM = param1 => {
    return {
      type: UPDATE_SPY_RAM,
      payload: param1
    }
}

export const updateSPY_CD = param1 => {
    return {
      type: UPDATE_SPY_CD,
      payload: param1
    }
}

export const updateSPY_LS = param1 => {
    return {
      type: UPDATE_SPY_LS,
      payload: param1
    }
}

export const updateSPY_ELL = param1 => {
    return {
      type: UPDATE_SPY_ELL,
      payload: param1
    }
}

export const updateSPY_NSC = param1 => {
    return {
      type: UPDATE_SPY_NSC,
      payload: param1
    }
}

export const updateSPY_XRA_PHO = param1 => {
    return {
      type: UPDATE_SPY_XRA_PHO,
      payload: param1
    }
}

export const updateSPY_XRA_DIF = param1 => {
    return {
      type: UPDATE_SPY_XRA_DIF,
      payload: param1
    }
}

export const updateSPY_XRA_XS_SAG = param1 => {
    return {
      type: UPDATE_SPY_XRA_XS_SAG,
      payload: param1
    }
}

export const updateSPY_XRA_XS_WID = param1 => {
    return {
      type: UPDATE_SPY_XRA_XS_WID,
      payload: param1
    }
}

export const updateCHR_GAS = param1 => {
    return {
      type: UPDATE_CHR_GAS,
      payload: param1
    }
}

export const updateCHR_HPL_GPC = param1 => {
    return {
      type: UPDATE_CHR_HPL_GPC,
      payload: param1
    }
}

export const updateCHR_HPL_SEC = param1 => {
    return {
      type: UPDATE_CHR_HPL_SEC,
      payload: param1
    }
}

export const updateMIC_AFM = param1 => {
    return {
      type: UPDATE_MIC_AFM,
      payload: param1
    }
}

export const updateMIC_SEM = param1 => {
    return {
      type: UPDATE_MIC_SEM,
      payload: param1
    }
}

export const updateMIC_TEM = param1 => {
    return {
      type: UPDATE_MIC_TEM,
      payload: param1
    }
}

export const updateMIC_OPT = param1 => {
    return {
      type: UPDATE_MIC_OPT,
      payload: param1
    }
}

export const updateMIC_FLM = param1 => {
    return {
      type: UPDATE_MIC_FLM,
      payload: param1
    }
}

export const updateMIC_CLS = param1 => {
    return {
      type: UPDATE_MIC_CLS,
      payload: param1
    }
}

export const updateMIC_SPR = param1 => {
    return {
      type: UPDATE_MIC_SPR,
      payload: param1
    }
}

export const updateMIC_QCM = param1 => {
    return {
      type: UPDATE_MIC_QCM,
      payload: param1
    }
}

export const updateMIC_POL = param1 => {
    return {
      type: UPDATE_MIC_POL,
      payload: param1
    }
}

export const updateTHA_TGA = param1 => {
    return {
      type: UPDATE_THA_TGA,
      payload: param1
    }
}

export const updateTHA_DTA = param1 => {
    return {
      type: UPDATE_THA_DTA,
      payload: param1
    }
}

export const updateTHA_DSC = param1 => {
    return {
      type: UPDATE_THA_DSC,
      payload: param1
    }
}

export const updateEA = param1 => {
    return {
      type: UPDATE_EA,
      payload: param1
    }
}

export const updateMSM = param1 => {
    return {
      type: UPDATE_MSM,
      payload: param1
    }
}

export const updateGEP = param1 => {
    return {
      type: UPDATE_GEP,
      payload: param1
    }
}

export const updateCONA = param1 => {
    return {
      type: UPDATE_CONA,
      payload: param1
    }
}

export const updateSTEN = param1 => {
    return {
      type: UPDATE_STEN,
      payload: param1
    }
}

export const updateSFOR = param1 => {
    return {
      type: UPDATE_SFOR,
      payload: param1
    }
}

export const updateBOD = param1 => {
    return {
      type: UPDATE_BOD,
      payload: param1
    }
}
