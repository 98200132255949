import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import {

  updateMD_CRSLT_THDYD_TVST,
  updateMD_CRSLT_THDYD_PVST,
  updateMD_CRSLT_THDYD_EVST,
  updateMD_CRSLT_DYD,


} from 'actions/calculationArray';


import { makeLabelVal } from "common/util";

import {
  updateOpenDialog,
} from "actions/settings";

import { InputDialog } from "views/InputDialog"

import {

  MD_CRSLT_THDYD_TVST,
  MD_CRSLT_THDYD_PVST,
  MD_CRSLT_THDYD_EVST,
  MD_CRSLT_DYD,

} from "constants.js";



const CalculationResultDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');


  const [thdydTvstValue, setThdydTvstValue] = useState('');
  const [thdydPvstValue, setThdydPvstValue] = useState('');
  const [thdydEvstValue, setThdydEvstValue] = useState('');
  const [crsltDydValue, setCrsltDydValue] = useState('');

  const selector_MD_crslt_Thdyd_TvsT= useSelector((state) => state.calculationArray.MD_crslt_Thdyd_TvsT);
  const selector_MD_crslt_Thdyd_PvsT= useSelector((state) => state.calculationArray.MD_crslt_Thdyd_PvsT);
  const selector_MD_crslt_Thdyd_EvsT= useSelector((state) => state.calculationArray.MD_crslt_Thdyd_EvsT);
  const selector_MD_crslt_Dyd= useSelector((state) => state.calculationArray.MD_crslt_Dyd);



  const selector_openDialog = useSelector((state) => state.settings.openDialog);

  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {
 

      case MD_CRSLT_THDYD_TVST:
        setThdydTvstValue(makeLabelVal(new_val));
        props.updateMD_CRSLT_THDYD_TVST(new_val);
        break;

      case MD_CRSLT_THDYD_PVST:
        setThdydPvstValue(makeLabelVal(new_val));
        props.updateMD_CRSLT_THDYD_PVST(new_val);
        break;

      case MD_CRSLT_THDYD_EVST:
        setThdydEvstValue(makeLabelVal(new_val));
        props.updateMD_CRSLT_THDYD_EVST(new_val);
        break;
     
      case MD_CRSLT_DYD:
        setCrsltDydValue(makeLabelVal(new_val));
        props.updateMD_CRSLT_DYD(new_val);
        break;
     
    }
  }


  const showChargerSearchDialog = (selected) => {


    setSelectedNode(selected);

    switch (selected) {

      case MD_CRSLT_THDYD_TVST:
        setDialogLabel('温度vs時間');
        break;

      case MD_CRSLT_THDYD_PVST:
        setDialogLabel('圧力vs時間');
        break;

      case MD_CRSLT_THDYD_EVST:
        setDialogLabel('エネルギーvs時間');
        break;
     
      case MD_CRSLT_DYD:
        setDialogLabel('力学データ');
        break;
     
    }

    props.updateOpenDialog(true);
  }




  useEffect(() => {

    setThdydTvstValue(makeLabelVal(selector_MD_crslt_Thdyd_TvsT));
    setThdydPvstValue(makeLabelVal(selector_MD_crslt_Thdyd_PvsT));
    setThdydEvstValue(makeLabelVal(selector_MD_crslt_Thdyd_EvsT));
    setCrsltDydValue(makeLabelVal(selector_MD_crslt_Dyd));

  }, []);



  

  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'計算結果'} saveInput={saveInput} selectedNode={selectedNode} />

        <Card {...rest} className={clsx(classes.root, className)}>

        <CardHeader align='center' title={<Typography variant="h4">計算結果</Typography>} style={{ backgroundColor: 'lightgray' }}
            subheader={<Typography variant="h5">- 分子動力学計算 -</Typography>}
          />
          <Divider />

          <CardContent>
            <Grid item md={12} spacing={0}>
              <Typography variant="h4">熱力学データ</Typography>
            </Grid>

            <Divider variant="inset" />

            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>
              <Grid item md={6} xs={6}>
                <TextField
                  label="温度vs時間"
                  name={MD_CRSLT_THDYD_TVST}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(MD_CRSLT_THDYD_TVST) }}
                  onBeforeInput={() => { showChargerSearchDialog(MD_CRSLT_THDYD_TVST) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(MD_CRSLT_THDYD_TVST) } }}
                  type="text"
                  value={thdydTvstValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="圧力vs時間"
                  name={MD_CRSLT_THDYD_PVST}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(MD_CRSLT_THDYD_PVST) }}
                  onBeforeInput={() => { showChargerSearchDialog(MD_CRSLT_THDYD_PVST) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(MD_CRSLT_THDYD_PVST) } }}
                  type="text"
                  value={thdydPvstValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="エネルギーvs時間"
                  name={MD_CRSLT_THDYD_EVST}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(MD_CRSLT_THDYD_EVST) }}
                  onBeforeInput={() => { showChargerSearchDialog(MD_CRSLT_THDYD_EVST) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(MD_CRSLT_THDYD_EVST) } }}
                  type="text"
                  value={thdydEvstValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid container item md={12} spacing={0}></Grid>
              <Grid container item md={12} spacing={0}></Grid>
            </Grid>


            <Divider variant="inset" />

            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>
              <Grid item md={6} xs={6}>
                <TextField
                  label="力学データ"
                  name={MD_CRSLT_DYD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(MD_CRSLT_DYD) }}
                  onBeforeInput={() => { showChargerSearchDialog(MD_CRSLT_DYD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(MD_CRSLT_DYD) } }}
                  type="text"
                  value={crsltDydValue || ''}
                  variant="outlined"
                />
              </Grid>
            </Grid>

          </CardContent>

        </Card>
      </div>
    </div>
  );
};

CalculationResultDetail .propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({
 
    updateMD_CRSLT_THDYD_TVST,
    updateMD_CRSLT_THDYD_PVST,
    updateMD_CRSLT_THDYD_EVST,
    updateMD_CRSLT_DYD,
 
    
    updateOpenDialog
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    calculationArray: state.calculationArray,
    settings: state.settings
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CalculationResultDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
