import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import {

  updateEA,
  updateMSM,
  updateGEP,
  updateCONA,
  updateSTEN,
  updateSFOR,
  updateBOD,

} from 'actions/measurementArray';


import { makeLabelVal } from "common/util";

import {
  updateOpenDialog,
} from "actions/settings";

import { InputDialog } from "views/InputDialog"

import {

  EA,
  MSM,
  GEP,
  CONA,
  STEN,
  SFOR,
  BOD,


} from "constants.js";



const MeasurementMethodDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');


  const [eaValue, setEaValue] = useState('');
  const [msmValue, setMsmValue] = useState('');
  const [gepValue, setGepValue] = useState('');
  const [conaValue, setConaValue] = useState('');
  const [stenValue, setStenValue] = useState('');
  const [sforValue, setSforValue] = useState('');
  const [bodValue, setBodValue] = useState('');

  const selector_ea = useSelector((state) => state.measurementArray.ea);
  const selector_msm = useSelector((state) => state.measurementArray.msm);
  const selector_gep = useSelector((state) => state.measurementArray.gep);
  const selector_cona = useSelector((state) => state.measurementArray.cona);
  const selector_sten = useSelector((state) => state.measurementArray.sten);
  const selector_sfor = useSelector((state) => state.measurementArray.sfor);
  const selector_bod = useSelector((state) => state.measurementArray.bod);


  const selector_openDialog = useSelector((state) => state.settings.openDialog);

  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {
      case EA:
        setEaValue(makeLabelVal(new_val));
        props.updateEA(new_val);
        break;

      case MSM:
        setMsmValue(makeLabelVal(new_val));
        props.updateMSM(new_val);
        break;

      case GEP:
        setGepValue(makeLabelVal(new_val));
        props.updateGEP(new_val);
        break;

      case CONA:
        setConaValue(makeLabelVal(new_val));
        props.updateCONA(new_val);
        break;

      case STEN:
        setStenValue(makeLabelVal(new_val));
        props.updateSTEN(new_val);
        break;

      case SFOR:
        setSforValue(makeLabelVal(new_val));
        props.updateSFOR(new_val);
        break;

      case BOD:
        setBodValue(makeLabelVal(new_val));
        props.updateBOD(new_val);
        break;     
    }
  }


  const showChargerSearchDialog = (selected) => {


    setSelectedNode(selected);

    switch (selected) {
      case EA:
        setDialogLabel('元素分析');
        break;

      case MSM:
        setDialogLabel('質量分析法');
        break;

      case GEP:
        setDialogLabel('ゲル電気泳動');
        break;

      case CONA:
        setDialogLabel('接触角測定');
        break;

      case STEN:
        setDialogLabel('表面張力測定');
        break;

      case SFOR:
        setDialogLabel('表面力測定');
        break;

      case BOD:
        setDialogLabel('生物化学的酸素要求量測定');
        break;     
    }
    props.updateOpenDialog(true);
  }




  useEffect(() => {

    setEaValue(makeLabelVal(selector_ea));
    setMsmValue(makeLabelVal(selector_msm));
    setGepValue(makeLabelVal(selector_gep));
    setConaValue(makeLabelVal(selector_cona));
    setStenValue(makeLabelVal(selector_sten));
    setSforValue(makeLabelVal(selector_sfor));
    setBodValue(makeLabelVal(selector_bod));



  }, []);



  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'測定法'} saveInput={saveInput} selectedNode={selectedNode} />

        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">測定法</Typography>} style={{ backgroundColor: 'lightgray' }} />

          <Divider />

          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6} xs={6}>
                <TextField
                  label="元素分析"
                  name={EA}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(EA) }}
                  onBeforeInput={() => { showChargerSearchDialog(EA) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(EA) } }}
                  type="text"
                  value={eaValue || ''}
                  variant="outlined"
                  autoFocus={true}

                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="質量分析法"
                  name={MSM}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(MSM) }}
                  onBeforeInput={() => { showChargerSearchDialog(MSM) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(MSM) } }}
                  type="text"
                  value={msmValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="ゲル電気泳動"
                  name={GEP}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(GEP) }}
                  onBeforeInput={() => { showChargerSearchDialog(GEP) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(GEP) } }}
                  type="text"
                  value={gepValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label=" 接触角測定"
                  name={CONA}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(CONA) }}
                  onBeforeInput={() => { showChargerSearchDialog(CONA) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(CONA) } }}
                  type="text"
                  value={conaValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="表面張力測定"
                  name={STEN}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(STEN) }}
                  onBeforeInput={() => { showChargerSearchDialog(STEN) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(STEN) } }}
                  type="text"
                  value={stenValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="表面力測定"
                  name={SFOR}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(SFOR) }}
                  onBeforeInput={() => { showChargerSearchDialog(SFOR) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(SFOR) } }}
                  type="text"
                  value={sforValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="生物化学的酸素要求量測定"
                  name={BOD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(BOD) }}
                  onBeforeInput={() => { showChargerSearchDialog(BOD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(BOD) } }}
                  type="text"
                  value={bodValue || ''}
                  variant="outlined"
                />
              </Grid>

            </Grid>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

MeasurementMethodDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({

    updateEA,
    updateMSM,
    updateGEP,
    updateCONA,
    updateSTEN,
    updateSFOR,
    updateBOD,
    
    updateOpenDialog
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    measurementArray: state.measurementArray,
    settings: state.settings
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MeasurementMethodDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
