


export const makeLabelVal = (val) => {

  let ret = '';

  if (typeof val !== 'undefined') {
    let unitlabel = '';
    if (typeof val.inputUnit !== 'undefined' && val.inputUnit.length > 0) {
      unitlabel = '[' + val.inputUnit + ']';
    }

    let commentlabel = '';
    if (typeof val.inputComment !== 'undefined' && val.inputComment.length > 0) {
      commentlabel = ' (' + val.inputComment + ')';
    }

    if (typeof val.inputValue !== 'undefined' ) {
      ret = val.inputType + ' - ' + val.inputValue + unitlabel + ' , ' + val.inputFactor + commentlabel;
    }
  }

  return ret;
}