import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';


import {
  updatePP_TD_BCHAR_FIT,
  updatePP_TD_BCHAR_HDC,
  updatePP_TD_BCHAR_CH,
  updatePP_TD_BCHAR_OI,
  updatePP_TD_BCHAR_HRP
} from 'actions/propertyArray';

import { makeLabelVal } from "common/util";


import {
  PP_TD_BCHAR_FIT,
  PP_TD_BCHAR_HDC,
  PP_TD_BCHAR_CH,
  PP_TD_BCHAR_OI,
  PP_TD_BCHAR_HRP,
} from "constants";

import { InputDialog } from "views/InputDialog"

import {
  updateOpenDialog,
} from "actions/settings";

/**
 * 固体物性.熱力学量.各種温度
 * @param {*} props 
 * @returns 
 */
const BurningCharacteristicsDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');

  const selector_pp_td_bchar_FIT = useSelector((state) => state.propertyArray.pp_td_bchar_FIT);
  const selector_pp_td_bchar_hdc = useSelector((state) => state.propertyArray.pp_td_bchar_hdc);
  const selector_pp_td_bchar_ch = useSelector((state) => state.propertyArray.pp_td_bchar_ch);
  const selector_pp_td_bchar_oi = useSelector((state) => state.propertyArray.pp_td_bchar_oi);
  const selector_pp_td_bchar_hrp = useSelector((state) => state.propertyArray.pp_td_bchar_hrp);


  //引火温度
  const [pp_td_bchar_FITValue, setPp_td_bchar_FITValue] = useState('');

  //熱分解曲線
  const [pp_td_bchar_hdcValue, setPp_td_bchar_hdcValue] = useState('');

  //燃焼熱
  const [pp_td_bchar_chValue, setPp_td_bchar_chValue] = useState('');

  //酸素指数
  const [pp_td_bchar_oiValue, setPp_td_bchar_oiValue] = useState('');

  //耐熱性
  const [pp_td_bchar_hrpValue, setPp_td_bchar_hrpValue] = useState('');

  const selector_openDialog = useSelector((state) => state.settings.openDialog);



  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {
      case PP_TD_BCHAR_FIT:
        setPp_td_bchar_FITValue(makeLabelVal(new_val));
        props.updatePP_TD_BCHAR_FIT(new_val);
        break;

      case PP_TD_BCHAR_HDC:
        setPp_td_bchar_hdcValue(makeLabelVal(new_val));
        props.updatePP_TD_BCHAR_HDC(new_val);
        break;

      case PP_TD_BCHAR_CH:
        setPp_td_bchar_chValue(makeLabelVal(new_val));
        props.updatePP_TD_BCHAR_CH(new_val);
        break;

      case PP_TD_BCHAR_OI:
        setPp_td_bchar_oiValue(makeLabelVal(new_val));
        props.updatePP_TD_BCHAR_OI(new_val);
        break;

      case PP_TD_BCHAR_HRP:
        setPp_td_bchar_hrpValue(makeLabelVal(new_val));
        props.updatePP_TD_BCHAR_HRP(new_val);
        break;
    }
  }

  const showChargerSearchDialog = (selected) => {

    setSelectedNode(selected);

    switch (selected) {
      case PP_TD_BCHAR_FIT:
        setDialogLabel('引火温度');
        break;

      case PP_TD_BCHAR_HDC:
        setDialogLabel('熱分解曲線');
        break;

      case PP_TD_BCHAR_CH:
        setDialogLabel('燃焼熱');
        break;

      case PP_TD_BCHAR_OI:
        setDialogLabel('酸素指数');
        break;

      case PP_TD_BCHAR_HRP:
        setDialogLabel('耐熱性');
        break;
    }

    props.updateOpenDialog(true);
  }

  useEffect(() => {
    setPp_td_bchar_FITValue(makeLabelVal(selector_pp_td_bchar_FIT));
    setPp_td_bchar_hdcValue(makeLabelVal(selector_pp_td_bchar_hdc));
    setPp_td_bchar_chValue(makeLabelVal(selector_pp_td_bchar_ch));
    setPp_td_bchar_oiValue(makeLabelVal(selector_pp_td_bchar_oi));
    setPp_td_bchar_hrpValue(makeLabelVal(selector_pp_td_bchar_hrp));
  }, []);


  return (
    <div className={classes.root}>
      <div className={classes.content}>


        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'熱力学量'} saveInput={saveInput} selectedNode={selectedNode} />

        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">燃焼特性</Typography>} style={{ backgroundColor: 'lightgray' }}
            subheader={<Typography variant="h5">- 熱力学量 -</Typography>}
          />
          <Divider />

          <CardContent>

            <Grid container spacing={2}>

              <Grid item md={6} xs={6}>
                <TextField
                  label="引火温度"
                  name={PP_TD_BCHAR_FIT}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_BCHAR_FIT) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_BCHAR_FIT) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_BCHAR_FIT) } }}
                  type="text"
                  value={pp_td_bchar_FITValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="熱分解曲線"
                  name={PP_TD_BCHAR_HDC}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_BCHAR_HDC) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_BCHAR_HDC) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_BCHAR_HDC) } }}
                  type="text"
                  value={pp_td_bchar_hdcValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="燃焼熱"
                  name={PP_TD_BCHAR_CH}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_BCHAR_CH) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_BCHAR_CH) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_BCHAR_CH) } }}
                  type="text"
                  value={pp_td_bchar_chValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="酸素指数"
                  name={PP_TD_BCHAR_OI}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_BCHAR_OI) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_BCHAR_OI) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_BCHAR_OI) } }}
                  type="text"
                  value={pp_td_bchar_oiValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="耐熱性"
                  name={PP_TD_BCHAR_HRP}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_BCHAR_HRP) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_BCHAR_HRP) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_BCHAR_HRP) }}}
                  type="text"
                  value={pp_td_bchar_hrpValue || ''}
                  variant="outlined"
                />
              </Grid>

            </Grid>

          </CardContent>
        </Card>
      </div>
    </div>
  );
};

BurningCharacteristicsDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updatePP_TD_BCHAR_FIT,
    updatePP_TD_BCHAR_HDC,
    updatePP_TD_BCHAR_CH,
    updatePP_TD_BCHAR_OI,
    updatePP_TD_BCHAR_HRP,

    updateOpenDialog,
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    propertyArray: state.propertyArray,
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BurningCharacteristicsDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
