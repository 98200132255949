// export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL';
// export const UPDATE_USER_NAME = 'UPDATE_USER_NAME';
// export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const LOGOUT = 'LOGOUT';
export const UPDATE_ADMIN_ID = 'UPDATE_ADMIN_ID';
// export const UPDATE_GROUP = 'UPDATE_GROUP';
export const TEMP_SET_USER = 'TEMP_SET_USER';



export const tempSetUser = user => {
  return {
    type: TEMP_SET_USER,
    payload: user
  }
}

export const updateAdminId = adminId => {
  return {
    type: UPDATE_ADMIN_ID,
    payload: adminId
  }
}
// export const updateUserEmail = userEmail => {
//   return {
//     type: UPDATE_USER_EMAIL,
//     payload: userEmail
//   }
// }

// export const updateUserName = userName => {
//   return {
//     type: UPDATE_USER_NAME,
//     payload: userName
//   }
// }

// export const updatePassword = password => {
//   return {
//     type: UPDATE_PASSWORD,
//     payload: password
//   }
// }

// export const updateGroup = group => {
//   return {
//     type: UPDATE_GROUP,
//     payload: group
//   }
// }

export const doLogout = () => {
  
  const newuser = {
    status: '',
    admin_id: '',
    // userEmail: '',
    // password: '',
    // username: '',
    // group: ''
  }

  localStorage.removeItem('userinfo');

  return {
    type: LOGOUT,
    payload: newuser
  }
}
