/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import DashboardIcon from '@material-ui/icons/Dashboard';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import ScheduleIcon from '@material-ui/icons/Schedule';
import TimelineIcon from '@material-ui/icons/Timeline'


import { Grid } from '@material-ui/core';
import { SidebarNav } from 'SidebarNav';


const MechanicalSecondMenu = props => {
  const { className, onClose, ...rest } = props;
  const classes = useStyles();

  var pages_first = [

    {
      title: '熱力学量',
      href: '/property/thermal',
      icon: <DashboardIcon />
    },

    {
      title: '機械強度特性',
      href: '/property/mechanical',
      disable: false,
      icon: <ScheduleIcon />
    },

    {
      title: '摩擦係数',
      href: '/property/frictioncoefficient',
      disable: false,
      icon: <TimelineIcon />
    },

    {
      title: '酵素分解',
      href: '/property/enzymaticdecomposition',
      disable: false,
      icon: <TimelapseIcon />
    },

    {
      title: '⽣分解度',
      href: '/property/biodegradability',
      disable: false,
      icon: <TimelapseIcon />
    },

    {
      title: '崩壊度',
      href: '/property/degradation',
      disable: false,
      icon: <TimelapseIcon />
    },

    {
      title: '構造',
      href: '/property/structure',
      disable: false,
      icon: <TimelapseIcon />
    },
  ];


  var pages = [

    {
      title: '応⼒歪曲線',
      href: '/property/mechanical/stressstraincurve',
      icon: <DashboardIcon />
    },

    {
      title: '粘弾性測定',
      href: '/property/mechanical/viscoelastic',
      disable: false,
      icon: <ScheduleIcon />
    },

    {
      title: '衝撃試験',
      href: '/property/mechanical/shocktest',
      disable: false,
      icon: <TimelineIcon />
    },

  ];


  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <Grid container item spacing={0}
          direction="row" justifyContent="flex-start" alignItems="flex-start"
        >
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <SidebarNav
              className={classes.nav}
              pages={pages_first}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>

            <SidebarNav
              className={classes.nav}
              pages={pages}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};


MechanicalSecondMenu.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};




export default MechanicalSecondMenu;


const useStyles = makeStyles(theme => ({
  root: {
    // paddingLeft: theme.spacing(1),
    // paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },

}));