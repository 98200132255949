import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import {

  updateMIC_AFM,
  updateMIC_SEM,
  updateMIC_TEM,
  updateMIC_OPT,
  updateMIC_FLM,
  updateMIC_CLS,
  updateMIC_SPR,
  updateMIC_QCM,
  updateMIC_POL,


} from 'actions/measurementArray';


import { makeLabelVal } from "common/util";

import {
  updateOpenDialog,
} from "actions/settings";

import { InputDialog } from "views/InputDialog"

import {

  MIC_AFM,
  MIC_SEM,
  MIC_TEM,
  MIC_OPT,
  MIC_FLM,
  MIC_CLS,
  MIC_SPR,
  MIC_QCM,
  MIC_POL,



} from "constants.js";



const MicroscopyDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');


  const [micAfmValue, setMicAfmValue] = useState('');
  const [micSemValue, setMicSemValue] = useState('');
  const [micTemValue, setMicTemValue] = useState('');
  const [micOptValue, setMicOptValue] = useState('');
  const [micFlmValue, setMicFlmValue] = useState('');
  const [micClsValue, setMicClsValue] = useState('');
  const [micSprValue, setMicSprValue] = useState('');
  const [micQcmValue, seMicQcmValue] = useState('');
  const [micPolValue, setMicPolValue] = useState('');



  const selector_mic_afm = useSelector((state) => state.measurementArray.mic_afm);
  const selector_mic_sem = useSelector((state) => state.measurementArray.mic_sem);
  const selector_mic_tem = useSelector((state) => state.measurementArray.mic_tem);
  const selector_mic_opt = useSelector((state) => state.measurementArray.mic_opt);
  const selector_mic_flm = useSelector((state) => state.measurementArray.mic_flm);
  const selector_mic_cls = useSelector((state) => state.measurementArray.mic_cls);
  const selector_mic_spr = useSelector((state) => state.measurementArray.mic_spr);
  const selector_mic_qcm = useSelector((state) => state.measurementArray.mic_qcm);
  const selector_mic_pol = useSelector((state) => state.measurementArray.mic_pol);



  const selector_openDialog = useSelector((state) => state.settings.openDialog);

  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {
      case MIC_AFM:
        setMicAfmValue(makeLabelVal(new_val));
        props.updateMIC_AFM(new_val);
        break;

      case MIC_SEM:
        setMicSemValue(makeLabelVal(new_val));
        props.updateMIC_SEM(new_val);
        break;

      case MIC_TEM:
        setMicTemValue(makeLabelVal(new_val));
        props.updateMIC_TEM(new_val);
        break;

      case MIC_OPT:
        setMicOptValue(makeLabelVal(new_val));
        props.updateMIC_OPT(new_val);
        break;

      case MIC_FLM:
        setMicFlmValue(makeLabelVal(new_val));
        props.updateMIC_FLM(new_val);
        break;

      case MIC_CLS:
        setMicClsValue(makeLabelVal(new_val));
        props.updateMIC_CLS(new_val);
        break;

      case MIC_SPR:
        setMicSprValue(makeLabelVal(new_val));
        props.updateMIC_SPR(new_val);
        break;

      case MIC_QCM:
        seMicQcmValue(makeLabelVal(new_val));
        props.updateMIC_QCM(new_val);
        break;

      case MIC_POL:
        setMicPolValue(makeLabelVal(new_val));
        props.updateMIC_POL(new_val);
        break;
    }
  }


  const showChargerSearchDialog = (selected) => {

    setSelectedNode(selected);

    switch (selected) {
      case MIC_AFM:
        setDialogLabel('原子間力顕微鏡法');
        break;

      case MIC_SEM:
        setDialogLabel('走査型電子顕微鏡法');
        break;

      case MIC_TEM:
        setDialogLabel('透過型電子顕微鏡法');
        break;

      case MIC_OPT:
        setDialogLabel('光学顕微鏡法');
        break;

      case MIC_FLM:
        setDialogLabel('蛍光顕微鏡法');
        break;

      case MIC_CLS:
        setDialogLabel('共焦点レーザー顕微鏡法');
        break;

      case MIC_SPR:
        setDialogLabel('表面プラズモン共鳴法');
        break;

      case MIC_QCM:
        setDialogLabel('水晶振動子マイクロバランス法');
        break;

      case MIC_POL:
        setDialogLabel('偏光顕微鏡');
        break;


    }
    props.updateOpenDialog(true);
  }




  useEffect(() => {

    setMicAfmValue(makeLabelVal(selector_mic_afm));
    setMicSemValue(makeLabelVal(selector_mic_sem));
    setMicTemValue(makeLabelVal(selector_mic_tem));
    setMicOptValue(makeLabelVal(selector_mic_opt));
    setMicFlmValue(makeLabelVal(selector_mic_flm));
    setMicClsValue(makeLabelVal(selector_mic_cls));
    setMicSprValue(makeLabelVal(selector_mic_spr));
    seMicQcmValue(makeLabelVal(selector_mic_qcm));
    setMicPolValue(makeLabelVal(selector_mic_pol));

  }, []);



  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'顕微鏡法'} saveInput={saveInput} selectedNode={selectedNode} />

        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">顕微鏡法</Typography>} style={{ backgroundColor: 'lightgray' }} />

          <Divider />

          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6} xs={6}>
                <TextField
                  label="原子間力顕微鏡法"
                  name={MIC_AFM}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(MIC_AFM) }}
                  onBeforeInput={() => { showChargerSearchDialog(MIC_AFM) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(MIC_AFM) } }}
                  type="text"
                  value={micAfmValue || ''}
                  variant="outlined"
                  autoFocus={true}

                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="走査型電子顕微鏡法"
                  name={MIC_SEM}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(MIC_SEM) }}
                  onBeforeInput={() => { showChargerSearchDialog(MIC_SEM) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(MIC_SEM) } }}
                  type="text"
                  value={micSemValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="透過型電子顕微鏡法"
                  name={MIC_TEM}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(MIC_TEM) }}
                  onBeforeInput={() => { showChargerSearchDialog(MIC_TEM) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(MIC_TEM) } }}
                  type="text"
                  value={micTemValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label=" 光学顕微鏡法"
                  name={MIC_OPT}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(MIC_OPT) }}
                  onBeforeInput={() => { showChargerSearchDialog(MIC_OPT) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(MIC_OPT) } }}
                  type="text"
                  value={micOptValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="蛍光顕微鏡法"
                  name={MIC_FLM}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(MIC_FLM) }}
                  onBeforeInput={() => { showChargerSearchDialog(MIC_FLM) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(MIC_FLM) } }}
                  type="text"
                  value={micFlmValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="共焦点レーザー顕微鏡法"
                  name={MIC_CLS}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(MIC_CLS) }}
                  onBeforeInput={() => { showChargerSearchDialog(MIC_CLS) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(MIC_CLS) } }}
                  type="text"
                  value={micClsValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="表面プラズモン共鳴法"
                  name={MIC_SPR}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(MIC_SPR) }}
                  onBeforeInput={() => { showChargerSearchDialog(MIC_SPR) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(MIC_SPR) } }}
                  type="text"
                  value={micSprValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="水晶振動子マイクロバランス法"
                  name={MIC_QCM}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(MIC_QCM) }}
                  onBeforeInput={() => { showChargerSearchDialog(MIC_QCM) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(MIC_QCM) } }}
                  type="text"
                  value={micQcmValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="偏光顕微鏡"
                  name={MIC_POL}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(MIC_POL) }}
                  onBeforeInput={() => { showChargerSearchDialog(MIC_POL) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(MIC_POL) } }}
                  type="text"
                  value={micPolValue || ''}
                  variant="outlined"
                />
              </Grid>

            </Grid>
          </CardContent>

        </Card>
      </div>
    </div>
  );
};

MicroscopyDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({

    updateMIC_AFM,
    updateMIC_SEM,
    updateMIC_TEM,
    updateMIC_OPT,
    updateMIC_FLM,
    updateMIC_CLS,
    updateMIC_SPR,
    updateMIC_QCM,
    updateMIC_POL,
    
    updateOpenDialog
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    measurementArray: state.measurementArray,
    settings: state.settings
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MicroscopyDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
