/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import DashboardIcon from '@material-ui/icons/Dashboard';

import { SidebarNav } from 'SidebarNav';


const FirstMenu = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  var pages = [
    {
      title: '概要',
      href: '/overview',
      icon: <DashboardIcon />
    },
  ];


  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </div>
  );
};

FirstMenu.propTypes = {
  className: PropTypes.string,
};

export default FirstMenu;

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
}));