import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link as RouterLink, withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { makeLabelVal } from "common/util";

import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { InputDialog } from "views/InputDialog"

import {
  updateBAR_GRAPH,
} from 'actions/propertyArray';


import {
  updateOpenDialog,
} from "actions/settings";


import {
  BAR_GRAPH
} from "constants.js";


/**
 * 固体物性.崩壊度
 */
const DegradationDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');

  const [bar_graphValue, setBar_graphValue] = useState('');

  const selector_bar_graph = useSelector((state) => state.propertyArray.bar_graph);

  const selector_openDialog = useSelector((state) => state.settings.openDialog);

  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {
      case BAR_GRAPH:
        setBar_graphValue(makeLabelVal(new_val));
        props.updateBAR_GRAPH(new_val);
        break;
    }

  }

  const showChargerSearchDialog = (selected) => {

    setSelectedNode(selected);

    switch(selected){
      case BAR_GRAPH:
        setDialogLabel('棒グラフデータ');
        break;
    }

    props.updateOpenDialog(true);
  }


  useEffect(() => {
    setBar_graphValue(makeLabelVal(selector_bar_graph));
  }, []);


  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'崩壊度'} saveInput={saveInput} selectedNode={selectedNode} />

        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">崩壊度</Typography>} style={{ backgroundColor: 'lightgray' }} />

          <Divider />

          <CardContent>

            <Grid item md={12} spacing={0}>
              <Typography variant="h4">重量変化グラフ</Typography>
            </Grid>

            <Divider variant="inset" />

            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="棒グラフデータ"
                  name={BAR_GRAPH}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(BAR_GRAPH) }}
                  onBeforeInput={() => { showChargerSearchDialog(BAR_GRAPH) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(BAR_GRAPH) } }}
                  type="text"
                  value={bar_graphValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>

              <Grid container item md={12} spacing={0}></Grid>
            </Grid>

          </CardContent>
        </Card>
      </div>
    </div>
  );
};


DegradationDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};


const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateBAR_GRAPH,

    updateOpenDialog,
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    propertyArray: state.propertyArray,
    settings: state.settings
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DegradationDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
