import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import {
  Grid,

  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,

  Button,

  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

import {
  updateOpenTreeDialog,
  updateCategory
} from "actions/settings";

import { CATEGORY_LABEL } from 'constants';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';



const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));


/**
 * ツリー検索
 */
const TreeDialog = (props) => {
  // const {  } = props;
  const classes = useStyles();

  const selector_openTreeDialog = useSelector((state) => state.settings.openTreeDialog);

  useEffect(() => {
  }, [selector_openTreeDialog]);


  const closeDialog = (category) => {
    if (category !== ''){
      props.updateCategory(category);
    }
    props.updateOpenTreeDialog(false);
  }


  var overview_menu = [
    {
      title: '概要',
      href: '/overview',
      isSub: false,
    },
  ];

  var propertyPages = [

    {
      title: '熱力学量',
      href: '/property/thermal',
      isSub: false,
      icon: <ArrowRightIcon />
    },
        {
          title: '気体透過性',
          href: '/property/thermal/gas',
          isSub: true,
        },
        {
          title: '流動特性',
          href: '/property/thermal/flowcharacteristics',
          isSub: true,
        },
        {
          title: '⽐熱',
          href: '/property/thermal/specificheat',
          isSub: true,
        },
        {
          title: '熱伝導率',
          href: '/property/thermal/thermalconductibity',
          isSub: true,
        },
        {
          title: '熱拡散係数',
          href: '/property/thermal/thermaldiffusion',
          isSub: true,
        },
        {
          title: '各種温度',
          href: '/property/thermal/tempratures',
          isSub: true,
        },
        {
          title: '燃焼特性',
          href: '/property/thermal/burningcharacteristics',
          isSub: true,
        },
    {
      title: '機械強度特性',
      href: '/property/mechanical',
      isSub: false,
      icon: <ArrowRightIcon />
    },
        {
          title: '応⼒歪曲線',
          href: '/property/mechanical/stressstraincurve',
          isSub: true,
        },
        {
          title: '粘弾性測定',
          href: '/property/mechanical/viscoelastic',
          isSub: true,
        },

        {
          title: '衝撃試験',
          href: '/property/mechanical/shocktest',
          isSub: true,
        },
    {
      title: '摩擦係数',
      href: '/property/frictioncoefficient',
      isSub: false,
      // icon: <ArrowRightIcon />
    },
    {
      title: '酵素分解',
      href: '/property/enzymaticdecomposition',
      isSub: false,
    },
    {
      title: '⽣分解度',
      href: '/property/biodegradability',
      isSub: false,
    },
    {
      title: '崩壊度',
      href: '/property/degradation',
      isSub: false,
    },

    {
      title: '構造',
      href: '/property/structure',
      isSub: false,
    },
  ];


  var synthesis_menu = [
    {
      title: '作り⽅',
      href: '/synthesis/howtomake',
      isSub: false,
      icon: <ArrowRightIcon />
    },
        {
          title: '購⼊',
          href: '/synthesis/howtomake/buy',
          isSub: true,
        },
        {
          title: '製造プロセス',
          href: '/synthesis/howtomake/process',
          isSub: true,
        },
        {
          title: '重合名(連鎖)',
          href: '/synthesis/howtomake/polymerization/chain',
          isSub: true,
        },
        {
          title: '重合名(逐次)',
          href: '/synthesis/howtomake/polymerization/step',
          isSub: true,
        },
    {
      title: '反応条件',
      href: '/synthesis/reactioncondition',
      isSub: false,
    },

    {
      title: '結果',
      href: '/synthesis/result',
      isSub: false,
    },

  ];

  var calculation_menu = [
    {
      title: '計算',
      href: '/calculation',
      isSub: false,   
      icon: <ArrowRightIcon />
    },
        {
          title: '分子動力学計算',
          href: '/calculation/moleculardynamics',
          isSub: true,
        },
            {
              title: '計算条件',
              href: '/calculation/moleculardynamics/calculationconditions',
              isSub: true,
            },
        
            {
              title: '計算結果',
              href: '/calculation/moleculardynamics/calculationresult',
              isSub: true,
            },
        {
          title: '量子化学計算',
          href: '/calculation/quantumchemistrycalculation',
          isSub: true,
        },
  ]

  var measurement_menu = [
    {
      title: '計算',
      href: '/measurement',
      isSub: false,   
      icon: <ArrowRightIcon />
    },
        {
          title: '分光',
          href: '/measurement/spectroscopy',
          isSub: true,        
        },
            {
              title: '赤外分光法',
              href: '/measurement/spectroscopy/infraredspectroscopy',
              isSub: true,  
            },
            {
              title: 'X線',
              href: '/measurement/spectroscopy/xray',
              isSub: true,  
            },
        {
          title: 'クロマトグラフィー',
          href: '/measurement/chromatography',
          isSub: true,        
        },
        {
          title: '顕微鏡法',
          href: '/measurement/microscopy',
          isSub: true,        
        },
        {
          title: '熱分析',
          href: '/measurement/thermalanalysis',
          isSub: true,        
        },
        {
          title: '測定法',
          href: '/measurement/measurementmethod',
          isSub: true,       
        }
  ]

  var degradation_menu = [
    {
      title: '分解',
      href: '/degradation',
      isSub: false,        
      icon: <ArrowRightIcon />
    },
        {
          title: '化学分解',
          href: '/degradation/chemicaldegradation',
          isSub: true,        
        },
        {
          title: '生分解(植種情報)',
          href: '/degradation/biodegradation',
          isSub: true,        
        },
            {
              title: '分解データ',
              href: '/degradation/biodegradation/degradationdata',
              isSub: true,        
            },
  ]
  return (
    <div className={classes.root}>

      <Dialog open={selector_openTreeDialog} onClose={() => { closeDialog() }} fullWidth={true} maxWidth="md">

        <DialogTitle align="center" title="検索ツリー" />

        <DialogContent>

          <Grid container item lg={12} md={12} sm={12} xs={12} >

            <Grid container lg={4} md={4} sm={4} xs={4} >
              <Grid container item md={12}>
                <Typography variant="h4" style={{width:'100%', backgroundColor:'lightgray', marginRight:'10px'}}>{CATEGORY_LABEL.OVERVIEW}</Typography>
              </Grid>

              <Grid container item md={12}>
                {/* <List>
                  {overview_menu.map(page => (
                    <ListItem className={classes.item} disableGutters key={page.title} dense={true}>
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={page.href}
                        onClick={() => closeDialog()}>
                        {page.isSub === false ? page.title : <span>&nbsp;&nbsp;&nbsp;&nbsp;{page.title}</span>}
                      </Button>

                    </ListItem>
                  ))}
                </List> */}
                <Table className={classes.table}>
                  <TableBody>
                  {overview_menu.map(page => (
                    <TableRow className={classes.tr}>
                      <TableCell className={classes.cell}>

                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={page.href}
                        onClick={() => closeDialog(CATEGORY_LABEL.OVERVIEW)}>
                          <div className={classes.icon}>{page.icon}</div>
                        {page.isSub === false ? page.title : <span>&nbsp;&nbsp;&nbsp;&nbsp;{page.title}</span>}
                      </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
                </Table>
              </Grid>


              <Grid container item md={12}>
                <Typography variant="h4" style={{width:'100%', backgroundColor:'lightgray', marginRight:'10px'}}>{CATEGORY_LABEL.PROPERTY}</Typography>
              </Grid>
              <Grid container item md={12}>
                {/* <List>
                  {pages.map(page => (
                    <ListItem className={classes.item} disableGutters key={page.title} dense={true}>
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={page.href}
                        onClick={() => closeDialog()}>
                        {page.isSub === false ? page.title : <span>&nbsp;&nbsp;&nbsp;&nbsp;{page.title}</span>}
                      </Button>

                    </ListItem>
                  ))}
                </List> */}

                <Table className={classes.table}>
                  <TableBody>
                  {propertyPages.map(page => (
                    <TableRow className={classes.tr}>
                      <TableCell className={classes.cell}>

                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={page.href}
                        onClick={() => closeDialog(CATEGORY_LABEL.PROPERTY)}>
                        {/* {page.icon && <div className={classes.icon}>{page.icon}</div>} */}
                        <div className={classes.icon}>{page.icon}</div>
                        {page.isSub === false ? page.title : <span>&nbsp;&nbsp;&nbsp;&nbsp;{page.title}</span>}
                      </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>



            {/* <Grid container lg={1} md={1} sm={1} xs={1} spacing={0}></Grid> */}

            <Grid container lg={4} md={4} sm={4} xs={4} alignContent='flex-start'>
              <Grid container item md={12}>
                <Typography variant="h4" style={{width:'100%', backgroundColor:'lightgray', marginRight:'10px'}}>{CATEGORY_LABEL.SYNTHESIS}</Typography>
              </Grid>

              <Grid container item md={12}>
                {/* <List>
                  {synthesis_menu.map(page => (
                    <ListItem className={classes.item} disableGutters key={page.title} dense={true}>
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={page.href}
                        onClick={() => closeDialog()}>
                        {page.isSub === false ? page.title : <span>&nbsp;&nbsp;&nbsp;&nbsp;{page.title}</span>}
                      </Button>

                    </ListItem>
                  ))}
                </List> */}

                <Table className={classes.table}>
                  <TableBody>
                  {synthesis_menu.map(page => (
                    <TableRow className={classes.tr}>
                      <TableCell className={classes.cell}>

                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={page.href}
                        onClick={() => closeDialog(CATEGORY_LABEL.SYNTHESIS)}>
                        <div className={classes.icon}>{page.icon}</div>
                        {page.isSub === false ? page.title : <span>&nbsp;&nbsp;&nbsp;&nbsp;{page.title}</span>}
                      </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
                </Table>
              </Grid>
              <Grid container item md={12}>
                <Typography variant="h4" style={{width:'100%', backgroundColor:'lightgray', marginRight:'10px'}}>{CATEGORY_LABEL.CALCULATION}</Typography>
              </Grid>

              <Grid container item md={12}>
                {/* <List>
                  {synthesis_menu.map(page => (
                    <ListItem className={classes.item} disableGutters key={page.title} dense={true}>
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={page.href}
                        onClick={() => closeDialog()}>
                        {page.isSub === false ? page.title : <span>&nbsp;&nbsp;&nbsp;&nbsp;{page.title}</span>}
                      </Button>

                    </ListItem>
                  ))}
                </List> */}

                <Table className={classes.table}>
                  <TableBody>
                  {calculation_menu.map(page => (
                    <TableRow className={classes.tr}>
                      <TableCell className={classes.cell}>

                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={page.href}
                        onClick={() => closeDialog(CATEGORY_LABEL.CALCULATION)}>
                        <div className={classes.icon}>{page.icon}</div>
                        {page.isSub === false ? page.title : <span>&nbsp;&nbsp;&nbsp;&nbsp;{page.title}</span>}
                      </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>

            <Grid container lg={4} md={4} sm={4} xs={4} alignContent='flex-start'>
              <Grid container item md={12}>
                <Typography variant="h4" style={{width:'100%', backgroundColor:'lightgray', marginRight:'10px'}}>{CATEGORY_LABEL.MEASUREMENT}</Typography>
              </Grid>

              <Grid container item md={12}>
                {/* <List>
                  {synthesis_menu.map(page => (
                    <ListItem className={classes.item} disableGutters key={page.title} dense={true}>
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={page.href}
                        onClick={() => closeDialog()}>
                        {page.isSub === false ? page.title : <span>&nbsp;&nbsp;&nbsp;&nbsp;{page.title}</span>}
                      </Button>

                    </ListItem>
                  ))}
                </List> */}

                <Table className={classes.table}>
                  <TableBody>
                  {measurement_menu.map(page => (
                    <TableRow className={classes.tr}>
                      <TableCell className={classes.cell}>

                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={page.href}
                        onClick={() => closeDialog(CATEGORY_LABEL.MEASUREMENT)}>
                        <div className={classes.icon}>{page.icon}</div>
                        {page.isSub === false ? page.title : <span>&nbsp;&nbsp;&nbsp;&nbsp;{page.title}</span>}
                      </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
                </Table>
              </Grid>


              <Grid container item md={12}>
                <Typography variant="h4" style={{width:'100%', backgroundColor:'lightgray', marginRight:'10px'}}>{CATEGORY_LABEL.DEGRADATION}</Typography>
              </Grid>

              <Grid container item md={12}>
                {/* <List>
                  {synthesis_menu.map(page => (
                    <ListItem className={classes.item} disableGutters key={page.title} dense={true}>
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={page.href}
                        onClick={() => closeDialog()}>
                        {page.isSub === false ? page.title : <span>&nbsp;&nbsp;&nbsp;&nbsp;{page.title}</span>}
                      </Button>

                    </ListItem>
                  ))}
                </List> */}

                <Table className={classes.table}>
                  <TableBody>
                  {degradation_menu.map(page => (
                    <TableRow className={classes.tr}>
                      <TableCell className={classes.cell}>

                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={page.href}
                        onClick={() => closeDialog(CATEGORY_LABEL.DEGRADATION)}>
                        <div className={classes.icon}>{page.icon}</div>
                        {page.isSub === false ? page.title : <span>&nbsp;&nbsp;&nbsp;&nbsp;{page.title}</span>}
                      </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>




          </Grid>
        </DialogContent>

        



        <DialogActions style={{ padding: '20px 0px 20px 0px' }}>
          <Grid container item className={classes.action}
            direction="row" alignItems="center" justifyContent="center">

            <Button
              variant="outlined"
              size="large"
              onClick={() => closeDialog()}
              className={classes.action}
            >
              閉じる&nbsp;<Typography variant='caption' style={{ color: "inherit" }}>[ESC]</Typography>
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
};


const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateOpenTreeDialog,
    updateCategory,
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    settings: state.settings,
    mechanicalArray: state.mechanicalArray,
    SynthesisArray: state.SynthesisArray,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TreeDialog);


const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(1),
  },

  table: {
    // width: "600px",
    // minWidth: "400px",
    // maxWidth: "800px",
    borderWidth: "1px",
    // borderStyle: "solid",
    borderColor: "#777",
    borderCollapse: "collapse",
    padding: "0px",
    marginRight:"10px"
  },
  // th: {
  //   borderWidth: "1px",
  //   borderStyle: "solid",
  //   borderColor: "#777",
  //   padding: "4px",
  //   fontSize: 14,
  //   align: "center",
  // },
  tr: {
    borderWidth: "1px",
    // borderStyle: "solid",
    borderColor: "#777",
    // padding: "4px",
    // fontSize: 14,
    // padding: "10",
  },
  cell:{
    padding: '1px 0 1px 2px',
    // padding : 0,
  },

  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    // paddingLeft:0,
    paddingLeft: 10,
  },
  button: {
    color: "#2a3f54",
    // padding: '10px 8px',
    justifyContent: 'flex-start',
    // textTransform: 'none',
    // letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    // fontSize: 15,
  },
  icon: {
    color: theme.palette.icon,
    // color: "#2a3f54",
    width: 20,
    // height: 20,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    // color: "#000000",
    // backgroundColor: "#b7b7b7",
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
      // color: "#000000"
    }
  }
}));