//機械強度特性
//  クリープ試験
//  Stress relaxation
//  クラック
//  Fracture toughness
//  Fatigue properties
//  硬さ

export const UPDATE_PP_MS_CRT = 'PP_MS_CRT';//クリープ試験
export const UPDATE_PP_MS_STRREL = 'PP_MS_STRREL'; //Stress relaxation
export const UPDATE_PP_MS_CRACK = 'PP_MS_CRACK'; //クラック
export const UPDATE_PP_MS_FRAT = 'PP_MS_FRAT'; //Fracture toughness
export const UPDATE_PP_MS_FATP = 'PP_MS_FATP'; //Fatigue properties
export const UPDATE_PP_MS_HARD = 'PP_MS_HARD'; //硬さ


//機械強度特性
//
//  - 応⼒歪曲線
//    . 引張
//        延伸率
//        弾性率
//        破断応⼒
//        温度依存性
//        圧⼒依存性
//        試験前後の画像
export const UPDATE_PP_MS_SSC_TEN_DEGSTRETCH = 'PP_MS_SSC_TEN_DEGSTRETCH'; //応⼒歪曲線.引張.延伸率
export const UPDATE_PP_MS_SSC_TEN_DEGELA = 'PP_MS_SSC_TEN_DEGELA'; //応⼒歪曲線.引張.弾性率
export const UPDATE_PP_MS_SSC_TEN_RUPSTR = 'PP_MS_SSC_TEN_RUPSTR'; //応⼒歪曲線.引張.破断応力
export const UPDATE_PP_MS_SSC_TEN_STTD = 'PP_MS_SSC_TEN_STTD'; //応⼒歪曲線.引張.温度依存性
export const UPDATE_PP_MS_SSC_TEN_STPD = 'PP_MS_SSC_TEN_STPD'; //応⼒歪曲線.引張.圧⼒依存性
export const UPDATE_PP_MS_SSC_TEN_BAIMAGE = 'PP_MS_SSC_TEN_BAIMAGE'; //応⼒歪曲線.引張.試験前後の画像



//    . 曲げ試験
//        延伸率
//        弾性率
//        破断応⼒
//        温度依存性
//        圧⼒依存性
//        試験前後の画像

export const UPDATE_PP_MS_SSC_BEND_BDSTRETCH = 'PP_MS_SSC_BEND_BDSTRETCH'; //応⼒歪曲線.曲げ試験.延伸率
export const UPDATE_PP_MS_SSC_BEND_BDELA = 'PP_MS_SSC_BEND_BDELA'; //応⼒歪曲線.曲げ試験.弾性率
export const UPDATE_PP_MS_SSC_BEND_BRSTR = 'PP_MS_SSC_BEND_BRSTR'; //応⼒歪曲線.曲げ試験.破断応力
export const UPDATE_PP_MS_SSC_BEND_BTD = 'PP_MS_SSC_BEND_BTD'; //応⼒歪曲線.曲げ試験.温度依存性
export const UPDATE_PP_MS_SSC_BEND_BPD = 'PP_MS_SSC_BEND_BPD'; //応⼒歪曲線.曲げ試験.圧⼒依存性
export const UPDATE_PP_MS_SSC_BEND_BBAIMAGE = 'PP_MS_SSC_BEND_BBAIMAGE'; //応⼒歪曲線.曲げ試験.試験前後の画像


//  - 粘弾性測定
//    温度依存性
//    圧⼒依存性
//    G'
//    G''
//    損失正接
// VISCOELASTIC
//   TEMPERATURE_DEPENDENCY
//   PRESSURE_DEPENDENCY
//   G_DASH
//   G_DASH_DASH
//   LOSS_TANGENT

export const UPDATE_PP_MS_VEM_VTD = 'PP_MS_VEM_VTD'; //粘弾性測定.温度依存性
export const UPDATE_PP_MS_VEM_VPD = 'PP_MS_VEM_VPD'; //粘弾性測定.圧⼒依存性
export const UPDATE_PP_MS_VEM_GDASH = 'PP_MS_VEM_GDASH'; //粘弾性測定.G'
export const UPDATE_PP_MS_VEM_GDASH2 = 'PP_MS_VEM_GDASH2'; //粘弾性測定.G''
export const UPDATE_PP_MS_VEM_LTAN = 'PP_MS_VEM_LTAN'; //粘弾性測定.損失正接



//  - 衝撃試験
//    . Charpy
//      温度依存性
//    . Izod
//      温度依存性

export const UPDATE_PP_MS_SHT_CHAR_CTD = 'PP_MS_SHT_CHAR_CTD'; //衝撃試験.Charpy.温度依存性
export const UPDATE_PP_MS_SHT_IZOD_ITD = 'PP_MS_SHT_IZOD_ITD'; //衝撃試験.Charpy.温度依存性





//機械強度特性.クリープ試験
export const updatePP_MS_CRT = param1 => {
  return {
    type: UPDATE_PP_MS_CRT,
    payload: param1
  }
}

//機械強度特性.Stress relaxation
export const updatePP_MS_STRREL = param1 =>{
  return {
    type: UPDATE_PP_MS_STRREL,
    payload: param1
  }
}

//機械強度特性.クラック
export const updatePP_MS_CRACK = param1 => {
  return {
    type: UPDATE_PP_MS_CRACK,
    payload: param1
  }
}

//機械強度特性.Fracture toughness
export const updatePP_MS_FRAT = param1 => {
  return {
    type: UPDATE_PP_MS_FRAT,
    payload: param1
  }
}

//機械強度特性.Fatigue properties
export const updatePP_MS_FATP = param1 => {
  return {
    type: UPDATE_PP_MS_FATP,
    payload: param1
  }
}

//機械強度特性.硬さ
export const updatePP_MS_HARD = param1 => {
  return {
    type: UPDATE_PP_MS_HARD,
    payload: param1
  }
}




//機械強度特性.応⼒歪曲線.引張
export const updatePP_MS_SSC_TEN_DEGSTRETCH = param1 => {
  return {
    type: UPDATE_PP_MS_SSC_TEN_DEGSTRETCH,
    payload: param1
  }
}


export const updatePP_MS_SSC_TEN_DEGELA = param1 => {
  return {
    type: UPDATE_PP_MS_SSC_TEN_DEGELA,
    payload: param1
  }
}

export const updatePP_MS_SSC_TEN_RUPSTR = param1 => {
  return {
    type: UPDATE_PP_MS_SSC_TEN_RUPSTR,
    payload: param1
  }
}


export const updatePP_MS_SSC_TEN_STTD = param1 => {
  return {
    type: UPDATE_PP_MS_SSC_TEN_STTD,
    payload: param1
  }
}


export const updatePP_MS_SSC_TEN_STPD = param1 => {
  return {
    type: UPDATE_PP_MS_SSC_TEN_STPD,
    payload: param1
  }
}

export const updatePP_MS_SSC_TEN_BAIMAGE = param1 => {
  return {
    type: UPDATE_PP_MS_SSC_TEN_BAIMAGE,
    payload: param1
  }
}



//機械強度特性.応⼒歪曲線.曲げ試験
export const updatePP_MS_SSC_BEND_BDSTRETCH = param1 => {
  return {
    type: UPDATE_PP_MS_SSC_BEND_BDSTRETCH,
    payload: param1
  }
}

export const updatePP_MS_SSC_BEND_BDELA = param1 =>{
  return{
    type: UPDATE_PP_MS_SSC_BEND_BDELA,
    payload: param1
  }
}

export const updatePP_MS_SSC_BEND_BRSTR = param1 => {
  return {
    type: UPDATE_PP_MS_SSC_BEND_BRSTR,
    payload: param1
  }
}

export const updatePP_MS_SSC_BEND_BTD = param1 => {
  return {
    type: UPDATE_PP_MS_SSC_BEND_BTD,
    payload: param1
  }
}

export const updatePP_MS_SSC_BEND_BPD = param1 => {
  return {
    type: UPDATE_PP_MS_SSC_BEND_BPD,
    payload: param1
  }
}

export const updatePP_MS_SSC_BEND_BBAIMAGE = param1 => {
  return {
    type: UPDATE_PP_MS_SSC_BEND_BBAIMAGE,
    payload: param1
  }
}




export const updatePP_MS_VEM_VTD = param1 =>{
  return{
    type: UPDATE_PP_MS_VEM_VTD,
    payload: param1
  }
}

export const updatePP_MS_VEM_VPD = param1 =>{
  return {
    type : UPDATE_PP_MS_VEM_VPD,
    payload: param1
  }
}


export const updatePP_MS_VEM_GDASH = param1 => {
  return {
    type: UPDATE_PP_MS_VEM_GDASH,
    payload: param1
  }
}


export const updatePP_MS_VEM_GDASH2 = param1 => {
  return {
    type: UPDATE_PP_MS_VEM_GDASH2,
    payload: param1
  }
}

export const updatePP_MS_VEM_LTAN = param1 => {
  return {
    type: UPDATE_PP_MS_VEM_LTAN,
    payload: param1
  }
}


//衝撃試験.Charpy.温度依存性
export const updatePP_MS_SHT_CHAR_CTD = param1 => {
  return {
    type: UPDATE_PP_MS_SHT_CHAR_CTD,
    payload: param1
  }
}

//衝撃試験.Charpy.温度依存性
export const updatePP_MS_SHT_IZOD_ITD = param1 => {
  return {
    type: UPDATE_PP_MS_SHT_IZOD_ITD,
    payload: param1
  }
}