/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import DashboardIcon from '@material-ui/icons/Dashboard';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import ScheduleIcon from '@material-ui/icons/Schedule';
import TimelineIcon from '@material-ui/icons/Timeline'

import { Grid } from '@material-ui/core';
import { SidebarNav } from 'SidebarNav';


const ThermalSecondMenu = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  var pages_first = [

    {
      title: '熱力学量',
      href: '/property/thermal',
      icon: <DashboardIcon />
    },

    {
      title: '機械強度特性',
      href: '/property/mechanical',
      disable: false,
      icon: <ScheduleIcon />
    },

    {
      title: '摩擦係数',
      href: '/property/frictioncoefficient',
      disable: false,
      icon: <TimelineIcon />
    },

    {
      title: '酵素分解',
      href: '/property/enzymaticdecomposition',
      disable: false,
      icon: <TimelapseIcon />
    },

    {
      title: '⽣分解度',
      href: '/property/biodegradability',
      disable: false,
      icon: <TimelapseIcon />
    },

    {
      title: '崩壊度',
      href: '/property/degradation',
      disable: false,
      icon: <TimelapseIcon />
    },

    {
      title: '構造',
      href: '/property/structure',
      disable: false,
      icon: <TimelapseIcon />
    },
  ];



  var pages = [

    {
      title: '気体透過性',
      href: '/property/thermal/gas',
      disable: false,
      icon: <TimelapseIcon />
    },

    {
      title: '流動特性',
      href: '/property/thermal/flowcharacteristics',
      disable: false,
      icon: <TimelapseIcon />
    },

    {
      title: '⽐熱',
      href: '/property/thermal/specificheat',
      disable: false,
      icon: <TimelapseIcon />
    },
    {
      title: '熱伝導率',
      href: '/property/thermal/thermalconductibity',
      disable: false,
      icon: <TimelapseIcon />
    },
    {
      title: '熱拡散係数',
      href: '/property/thermal/thermaldiffusion',
      disable: false,
      icon: <TimelapseIcon />
    },
    {
      title: '各種温度',
      href: '/property/thermal/tempratures',
      disable: false,
      icon: <TimelapseIcon />
    },
    {
      title: '燃焼特性',
      href: '/property/thermal/burningcharacteristics',
      disable: false,
      icon: <TimelapseIcon />
    },
  ];


  return (
    <div className={classes.root}>
      <Grid container item spacing={0}
        direction="row" justifyContent="flex-start" alignItems="flex-start"
      >

        <Grid item lg={6} md={6} sm={6} xs={6}>
          <SidebarNav
            className={classes.nav}
            pages={pages_first}
          />
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={6}>
          <SidebarNav
            className={classes.nav}
            pages={pages}
          />
        </Grid>
      </Grid>
    </div>
  );
};

ThermalSecondMenu.propTypes = {
  className: PropTypes.string,
};

export default ThermalSecondMenu;

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1)
  },
}));