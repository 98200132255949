import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,

  Typography,
} from '@material-ui/core';


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updatePP_TD_GP_WP,
  updatePP_TD_GP_MP,
  updatePP_TD_GP_FTD,
  updatePP_TD_GP_TPD,
  updatePP_TD_GP_HD,
} from 'actions/propertyArray';


import { makeLabelVal } from "common/util";

import {
  PP_TD_GP_WP,
  PP_TD_GP_MP,
  PP_TD_GP_FTD,
  PP_TD_GP_TPD,
  PP_TD_GP_HD
} from "constants";

import { InputDialog } from "views/InputDialog"

import {
  updateOpenDialog,
} from "actions/settings";


const GasDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');

  const [pp_td_gp_wpValue, setPP_TD_GP_WPValue] = useState('');
  const [pp_td_gp_mp, setPP_TD_GP_MPValue] = useState('');
  const [pp_td_gp_ftdValue, setPP_TD_GP_FTDValue] = useState('');
  const [pp_td_gp_tpdValue, setPP_TD_GP_TPDValue] = useState('');
  const [pp_td_gp_hdValue, setPP_TD_GP_HDValue] = useState('');

  const selector_pp_td_gp_wp = useSelector((state) => state.propertyArray.pp_td_gp_wp);
  const selector_pp_td_gp_mp = useSelector((state) => state.propertyArray.pp_td_gp_mp);
  const selector_pp_td_gp_ftd = useSelector((state) => state.propertyArray.pp_td_gp_ftd);
  const selector_pp_td_gp_tpd = useSelector((state) => state.propertyArray.pp_td_gp_tpd);
  const selector_pp_td_gp_hd = useSelector((state) => state.propertyArray.pp_td_gp_hd);

  const selector_openDialog = useSelector((state) => state.settings.openDialog);


  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {
      case PP_TD_GP_WP:
        setPP_TD_GP_WPValue(makeLabelVal(new_val));
        props.updatePP_TD_GP_WP(new_val);
        break;


      case PP_TD_GP_MP:
        setPP_TD_GP_MPValue(makeLabelVal(new_val));
        props.updatePP_TD_GP_MP(new_val);
        break;


      case PP_TD_GP_FTD:
        setPP_TD_GP_FTDValue(makeLabelVal(new_val));
        props.updatePP_TD_GP_FTD(new_val);
        break;

      case PP_TD_GP_TPD:
        setPP_TD_GP_TPDValue(makeLabelVal(new_val));
        props.updatePP_TD_GP_TPD(new_val);
        break;


      case PP_TD_GP_HD:
        setPP_TD_GP_HDValue(makeLabelVal(new_val));
        props.updatePP_TD_GP_HD(new_val);
        break;
    }

  }


  const showChargerSearchDialog = (selected) => {
    setSelectedNode(selected);

    switch (selected) {
      case PP_TD_GP_WP:
        setDialogLabel('水透過性');
        break;

      case PP_TD_GP_MP:
        setDialogLabel('透湿率');
        break;

      case PP_TD_GP_FTD:
        setDialogLabel('フィルム厚み依存性');
        break;

      case PP_TD_GP_TPD:
        setDialogLabel('温度依存性');
        break;

      case PP_TD_GP_HD:
        setDialogLabel('湿度依存性');
        break;
    }

    props.updateOpenDialog(true);
  }


  useEffect(() => {
    setPP_TD_GP_WPValue(makeLabelVal(selector_pp_td_gp_wp));
    setPP_TD_GP_MPValue(makeLabelVal(selector_pp_td_gp_mp));
    setPP_TD_GP_FTDValue(makeLabelVal(selector_pp_td_gp_ftd));
    setPP_TD_GP_TPDValue(makeLabelVal(selector_pp_td_gp_tpd));
    setPP_TD_GP_HDValue(makeLabelVal(selector_pp_td_gp_hd));
  }, []);



  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'熱力学量'} saveInput={saveInput} selectedNode={selectedNode} />

        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">気体透過性</Typography>} style={{ backgroundColor: 'lightgray' }}
            subheader={<Typography variant="h5">- 熱力学量 -</Typography>}
          />

          <Divider />

          <CardContent>
            <Grid container spacing={2}>

              <Grid item md={6} xs={6}>
                <TextField
                  label="水透過性"
                  name={PP_TD_GP_WP}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_GP_WP) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_GP_WP) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_GP_WP) } }}
                  type="text"
                  value={pp_td_gp_wpValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>


              <Grid item md={6} xs={6}>
                <TextField
                  label="透湿率"
                  name={PP_TD_GP_MP}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_GP_MP) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_GP_MP) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_GP_MP) } }}
                  type="text"
                  value={pp_td_gp_mp || ''}
                  variant="outlined"
                />
              </Grid>


              <Grid item md={6} xs={6}>
                <TextField
                  label="フィルム厚み依存性"
                  name={PP_TD_GP_FTD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_GP_FTD) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_GP_FTD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_GP_FTD) } }}
                  type="text"
                  value={pp_td_gp_ftdValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="温度依存性"
                  name={PP_TD_GP_TPD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_GP_TPD) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_GP_TPD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_GP_TPD) } }}
                  type="text"
                  value={pp_td_gp_tpdValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="湿度依存性"
                  name={PP_TD_GP_HD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_GP_HD) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_GP_HD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_GP_HD) } }}
                  type="text"
                  value={pp_td_gp_hdValue || ''}
                  variant="outlined"
                />
              </Grid>

            </Grid>
          </CardContent>

        </Card>
      </div>
    </div>
  );
};

GasDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updatePP_TD_GP_WP,
    updatePP_TD_GP_MP,
    updatePP_TD_GP_FTD,
    updatePP_TD_GP_TPD,
    updatePP_TD_GP_HD,

    updateOpenDialog,
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    propertyArray: state.propertyArray,
    settings: state.settings
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GasDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
