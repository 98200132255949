import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,

  Typography,

} from '@material-ui/core';


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';


import {
  updatePP_MS_SHT_CHAR_CTD,
  updatePP_MS_SHT_IZOD_ITD,
} from 'actions/mechanicalArray';

import { makeLabelVal } from "common/util";


import {
  PP_MS_SHT_CHAR_CTD,
  PP_MS_SHT_IZOD_ITD,
} from "constants.js";

import {
  updateOpenDialog,
} from "actions/settings";

import { InputDialog } from "views/InputDialog"


/**
 * 固体物性.機械強度特性.衝撃試験
 */
const ShockTestDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');

  const selector_pp_ms_sht_Char_ctd = useSelector((state) => state.mechanicalArray.pp_ms_sht_Char_ctd);
  const selector_pp_ms_sht_Izod_itd = useSelector((state) => state.mechanicalArray.pp_ms_sht_Izod_itd);

  //Charpy.温度依存性
  const [pp_ms_sht_Char_ctdValue, setPp_ms_sht_Char_ctdValue] = useState('');

  //Izod.温度依存性
  const [pp_ms_sht_Izod_itdValue, setPp_ms_sht_Izod_itdValue] = useState('');

  const selector_openDialog = useSelector((state) => state.settings.openDialog);


  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {
    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {
      case PP_MS_SHT_CHAR_CTD:
        setPp_ms_sht_Char_ctdValue(makeLabelVal(new_val));
        props.updatePP_MS_SHT_CHAR_CTD(new_val);
        break;


      case PP_MS_SHT_IZOD_ITD:
        setPp_ms_sht_Izod_itdValue(makeLabelVal(new_val));
        props.updatePP_MS_SHT_IZOD_ITD(new_val);
        break;
    }
  }


  const showChargerSearchDialog = (selected) => {
    setSelectedNode(selected);

    switch (selected) {
      case PP_MS_SHT_CHAR_CTD:
        setDialogLabel('温度依存性');
        break;

      case PP_MS_SHT_IZOD_ITD:
        setDialogLabel('温度依存性');
        break;
    }

    props.updateOpenDialog(true);
  }



  useEffect(() => {
    setPp_ms_sht_Char_ctdValue(makeLabelVal(selector_pp_ms_sht_Char_ctd));
    setPp_ms_sht_Izod_itdValue(makeLabelVal(selector_pp_ms_sht_Izod_itd));
  }, []);


  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'衝撃試験'} saveInput={saveInput} selectedNode={selectedNode} />

        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">衝撃試験</Typography>} style={{ backgroundColor: 'lightgray' }}
            subheader={<Typography variant="h5">- 機械強度特性 -</Typography>}
          />
          <Divider />

          <CardContent>

            <Grid item md={12} spacing={0}>
              <Typography variant="h4">Charpy</Typography>
            </Grid>

            <Divider variant="inset" />


            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="温度依存性"
                  name={PP_MS_SHT_CHAR_CTD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_SHT_CHAR_CTD) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_SHT_CHAR_CTD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_SHT_CHAR_CTD) } }}
                  type="text"
                  value={pp_ms_sht_Char_ctdValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>

              <Grid container item md={12} spacing={0}></Grid>
            </Grid>



            <Grid item md={12} spacing={0}>
              <Typography variant="h4">Izod</Typography>
            </Grid>

            <Divider variant="inset" />


            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>


              <Grid item md={6} xs={6}>
                <TextField
                  label="温度依存性"
                  name={PP_MS_SHT_IZOD_ITD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_SHT_IZOD_ITD) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_SHT_IZOD_ITD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_SHT_IZOD_ITD) } }}
                  type="text"
                  value={pp_ms_sht_Izod_itdValue || ''}
                  variant="outlined"
                />
              </Grid>

            </Grid>

          </CardContent>
        </Card>
      </div>
    </div>
  );
};

ShockTestDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({

    updatePP_MS_SHT_CHAR_CTD,
    updatePP_MS_SHT_IZOD_ITD,

    updateOpenDialog,
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    mechanicalArray: state.mechanicalArray,
    settings: state.settings
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShockTestDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
