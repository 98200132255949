/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import DashboardIcon from '@material-ui/icons/Dashboard';
import ScheduleIcon from '@material-ui/icons/Schedule';
import TimelineIcon from '@material-ui/icons/Timeline'

import { Grid } from '@material-ui/core';
import { SidebarNav } from 'SidebarNav';


const BiodegradationSecondMenu = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  
  var pages_first = [

    {
      title: '分解',
      href: '/degradation/degradation',
      icon: <DashboardIcon />
    },
    {
      title: '生分解(植種情報)',
      href: '/degradation/biodegradation',
      icon: <DashboardIcon />
    },

  ];

  var pages = [

    {
      title: '分解データ',
      href: '/degradation/biodegradation/degradationdata',
      icon: <DashboardIcon />
    },

  ];


  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <Grid container item spacing={0}
          direction="row" justifyContent="flex-start" alignItems="flex-start"
        >
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <SidebarNav
              className={classes.nav}
              pages={pages_first}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>

            <SidebarNav
              className={classes.nav}
              pages={pages}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

BiodegradationSecondMenu.propTypes = {
  className: PropTypes.string,

};



export default BiodegradationSecondMenu;


const useStyles = makeStyles(theme => ({
  root: {
    // paddingLeft: theme.spacing(1),
    // paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    // minWidth: 140,
  },
  content: {
    marginTop: theme.spacing(1),
  },

}));