import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';

import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import NotificationsIcon from '@material-ui/icons/Notifications';


import { connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import { Profile, SidebarNav } from './components';


const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    // backgroundColor: "#1a1a1a",
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2),
    // JustifyContent: 'flex-start',
  }
}));



const Sidebar = props => {
  const { open, variant, onClose, className, router, tempSetUser, ...rest } = props;

  const classes = useStyles();

  // const userjson = localStorage.getItem('userinfo');
  // if(!userjson) {
  //   console.log('user storage なし');

  //   setTimeout( () => {
  //     // history.go('/sign-in');
  //     window.location.href = '/sign-in';
  //   }, 500);
  // }


  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
        />

        {/* <div style={{position:"absolute", paddingRight:"10px", bottom:"10px", fontSize:"12px", fontWeight:"bold" }}>
          Copyright(c) 2021 Castpro Co., Ltd.
          All rights Reserved
        </div> */}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  router: PropTypes.object
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch)
}

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);

