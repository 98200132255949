/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';


import DashboardIcon from '@material-ui/icons/Dashboard';
import TimelapseIcon from '@material-ui/icons/Timelapse';

import { Grid } from '@material-ui/core';
import { SidebarNav } from 'SidebarNav';


const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));



const MolecularSecondMenu = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  var pages_first = [

    {
      title: '分子動力学計算',
      href: '/calculation/moleculardynamics',
      icon: <DashboardIcon />
    },
    {
      title: '量子化学計算',
      href: '/calculation/quantumchemistrycalculation',
      icon: <DashboardIcon />
    },

  ];

  var pages = [

    {
      title: '計算条件',
      href: '/calculation/moleculardynamics/calculationconditions',
      disable: false,
      icon: <TimelapseIcon />
    },

    {
      title: '計算結果',
      href: '/calculation/moleculardynamics/calculationresult',
      disable: false,
      icon: <TimelapseIcon />
    }
  ];


  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <Grid container item spacing={0}
          direction="row" justifyContent="flex-start" alignItems="flex-start"
        >
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <SidebarNav
              className={classes.nav}
              pages={pages_first}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>

            <SidebarNav
              className={classes.nav}
              pages={pages}
            />
          </Grid>
        </Grid>
      </div>
    </div>

  );
};

MolecularSecondMenu.propTypes = {
  className: PropTypes.string,
};



export default MolecularSecondMenu;


const useStyles = makeStyles(theme => ({
  root: {
    // paddingLeft: theme.spacing(0),
    // paddingTop: theme.spacing(1),
    // paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
    // backgroundColor:'white',
  },

}));