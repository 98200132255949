//===== ドキュメントID  "documenti id" 
export const UPDATE_DOCID = 'docid';

//===== 概要ID  "overview id"
export const UPDATE_OVID = 'ovid';

//===== 試料総称名または略称名  "sample"
export const UPDATE_SAMPLE = 'sample';

//===== 試料識別名：（材料の履歴や状態等を示す）  "sample ID"
export const UPDATE_SAMPLEID = 'sampleID';

//===== 試料作成グループ名またはメーカ名  "group"
export const UPDATE_GROUP = 'group';

//===== 機関名  "institution"
export const UPDATE_INST = 'inst';

//===== 種類　"kind"
export const UPDATE_KIND = 'kind';

//===== 化学構造図（主な成分あるいは代表的な構造図、模式図）  "chemical structure"
export const UPDATE_CHST = 'chst';

//画面表示用 - 化学構造図（主な成分あるいは代表的な構造図、模式図）
export const UPDATE_CHST_IMG = 'chst_img';

//===== 実験者・計算者  "experiment person"
export const UPDATE_EXPP = 'expp';

//===== 責任者名  "responsible person"
export const UPDATE_RESPP = 'respp';

//===== 実験日  "experiment date"
export const UPDATE_EXPDATE = 'expdate';

//===== 入力者のみ  "input person"
export const UPDATE_INPP = 'inpp';

//===== 概要  "brief memo"
export const UPDATE_BMEMO = 'bmemo';



export const updateDOCID = param1 => {

  return {
    type: UPDATE_DOCID,
    payload: param1
  }
}


export const updateOVID = param1 => {

  return {
    type: UPDATE_OVID,
    payload: param1
  }
}


export const updateSAMPLE = param1 => {

  return {
    type: UPDATE_SAMPLE,
    payload: param1
  }
}


export const updateSAMPLEID = param1 => {

  return {
    type: UPDATE_SAMPLEID,
    payload: param1
  }
}

export const updateGROUP = param1 => {

  return {
    type: UPDATE_GROUP,
    payload: param1
  }
}


export const updateINST = param1 => {

  return {
    type: UPDATE_INST,
    payload: param1
  }
}


export const updateKIND = param1 => {

  return {
    type: UPDATE_KIND,
    payload: param1
  }
}


export const updateCHST = param1 => {

  return {
    type: UPDATE_CHST,
    payload: param1
  }
}

export const updateCHST_IMG = param1 => {
  return {
    type: UPDATE_CHST_IMG,
    payload: param1
  }
}


export const updateEXPP = param1 => {

  return {
    type: UPDATE_EXPP,
    payload: param1
  }
}


export const updateRESPP = param1 => {

  return {
    type: UPDATE_RESPP,
    payload: param1
  }
}

export const updateEXPDATE = param1 => {

  return {
    type: UPDATE_EXPDATE,
    payload: param1
  }
}

export const updateINPP = param1 => {

  return {
    type: UPDATE_INPP,
    payload: param1
  }
}

export const updateBMEMO = param1 => {

  return {
    type: UPDATE_BMEMO,
    payload: param1
  }
}




// docid
// ovid
// sample
// sampleID
// group
// inst
// kind
// chst
// expp
// respp
// expdate
// inpp
// bmemo
