import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,

  Typography,
} from '@material-ui/core';

import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updatePP_TD_FC_MV_SD,
  updatePP_TD_FC_MV_MTD,
  updatePP_TD_FC_MV_PD,
  updatePP_TD_FC_VC,
} from 'actions/propertyArray';

import {
  updateOpenDialog,
} from "actions/settings";

import { makeLabelVal } from "common/util";


import {
  PP_TD_FC_MV_SD,
  PP_TD_FC_MV_MTD,
  PP_TD_FC_MV_PD,
  PP_TD_FC_VC,
} from "constants";

import { InputDialog } from "views/InputDialog"


const FlowDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const selector_openDialog = useSelector((state) => state.settings.openDialog);

  const [selectedNode, setSelectedNode] = useState('');

  const [pp_td_fc_mv_sdValue, setPp_td_fc_mv_sdValue] = useState('');
  const [pp_td_fc_mv_mtdValue, setPp_td_fc_mv_mtdValue] = useState('');
  const [pp_td_fc_mv_pdValue, setPp_td_fc_mv_pdValue] = useState('');
  const [pp_td_fc_vcValue, setPp_td_fc_vcValue] = useState('');


  const selector_pp_td_fc_mv_sd = useSelector((state) => state.propertyArray.pp_td_fc_mv_sd);
  const selector_pp_td_fc_mv_mtd = useSelector((state) => state.propertyArray.pp_td_fc_mv_mtd);
  const selector_pp_td_fc_mv_pd = useSelector((state) => state.propertyArray.pp_td_fc_mv_pd);
  const selector_pp_td_fc_vc = useSelector((state) => state.propertyArray.pp_td_fc_vc);


  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {
      case PP_TD_FC_MV_SD:
        setPp_td_fc_mv_sdValue(makeLabelVal(new_val));
        props.updatePP_TD_FC_MV_SD(new_val);
        break;

      case PP_TD_FC_MV_MTD:
        setPp_td_fc_mv_mtdValue(makeLabelVal(new_val));
        props.updatePP_TD_FC_MV_MTD(new_val);
        break;

      case PP_TD_FC_MV_PD:
        setPp_td_fc_mv_pdValue(makeLabelVal(new_val));
        props.updatePP_TD_FC_MV_PD(new_val);
        break;

      case PP_TD_FC_VC:
        setPp_td_fc_vcValue(makeLabelVal(new_val));
        props.updatePP_TD_FC_VC(new_val);
        break;
    }
  }


  const showChargerSearchDialog = (selected) => {

    setSelectedNode(selected);

    switch (selected) {
      case PP_TD_FC_MV_SD:
        setDialogLabel('剪断依存性');
        break;

      case PP_TD_FC_MV_MTD:
        setDialogLabel('温度依存性');
        break;

      case PP_TD_FC_MV_PD:
        setDialogLabel('圧⼒依存性');
        break;

      case PP_TD_FC_VC:
        setDialogLabel('粘度曲線');
        break;
    }

    props.updateOpenDialog(true);
  }


  useEffect(() => {
    setPp_td_fc_mv_sdValue(makeLabelVal(selector_pp_td_fc_mv_sd));
    setPp_td_fc_mv_mtdValue(makeLabelVal(selector_pp_td_fc_mv_mtd));
    setPp_td_fc_mv_pdValue(makeLabelVal(selector_pp_td_fc_mv_pd));
    setPp_td_fc_vcValue(makeLabelVal(selector_pp_td_fc_vc));
  }, []);



  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'熱力学量'} saveInput={saveInput} selectedNode={selectedNode}/>

        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">流動特性</Typography>} style={{ backgroundColor: 'lightgray' }}
            subheader={<Typography variant="h5">- 熱力学量 -</Typography>}
          />
          <Divider />

          <CardContent>

            <Grid item md={12} spacing={0}>
              <Typography variant="h4">溶融粘度</Typography>
            </Grid>

            <Divider variant="inset" />


            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="剪断依存性"
                  name={PP_TD_FC_MV_SD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_FC_MV_SD) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_FC_MV_SD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_FC_MV_SD) } }}
                  type="text"
                  value={pp_td_fc_mv_sdValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="温度依存性"
                  name={PP_TD_FC_MV_MTD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_FC_MV_MTD) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_FC_MV_MTD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_FC_MV_MTD) } }}
                  type="text"
                  value={pp_td_fc_mv_mtdValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="圧⼒依存性"
                  name={PP_TD_FC_MV_PD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_FC_MV_PD) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_FC_MV_PD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_FC_MV_PD) } }}
                  type="text"
                  value={pp_td_fc_mv_pdValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid container item md={12}></Grid>
              <Grid container item md={12}></Grid>
            </Grid>


            <Grid item md={12} spacing={0}>
              <Typography variant="h4">その他</Typography>
            </Grid>


            <Divider variant="inset" />


            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="粘度曲線"
                  name={PP_TD_FC_VC}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_TD_FC_VC) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_TD_FC_VC) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_TD_FC_VC) } }}
                  type="text"
                  value={pp_td_fc_vcValue || ''}
                  variant="outlined"
                />
              </Grid>

            </Grid>

          </CardContent>
        </Card>
      </div>
    </div>
  );
};

FlowDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updatePP_TD_FC_MV_SD,
    updatePP_TD_FC_MV_MTD,
    updatePP_TD_FC_MV_PD,
    updatePP_TD_FC_VC,

    updateOpenDialog
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    propertyArray: state.propertyArray,
    settings: state.settings
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FlowDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
