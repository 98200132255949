


// 20220822 望月
// 化学分解
export const UPDATE_CHD = 'chd'
// 生分解.植種情報.場所
export const UPDATE_BIOD_PLS_LOC = 'biod_pls_loc'
// 生分解.植種情報.日時
export const UPDATE_BIOD_PLS_BDATE = 'biod_pls_bdate'
// 生分解.植種情報.時刻
export const UPDATE_BIOD_PLS_BTIME = 'biod_pls_btime'
// 生分解.植種情報.全有機炭素(TOC)
export const UPDATE_BIOD_PLS_TOC = 'biod_pls_toc'
// 生分解.植種情報.窒素量
export const UPDATE_BIOD_PLS_NITA = 'biod_pls_nita'
// 生分解.植種情報.微生物量
export const UPDATE_BIOD_PLS_BMASS = 'biod_pls_bmass'
// 生分解.植種情報.分解データ.反応条件.pH
export const UPDATE_BIOD_PLS_DD_RC_PH = 'biod_pls_dd_rc_ph'
// 生分解.植種情報.分解データ.反応条件.温度
export const UPDATE_BIOD_PLS_DD_RC_BTEMP = 'biod_pls_dd_rc_btemp'
// 生分解.植種情報.分解データ.反応条件.その他
export const UPDATE_BIOD_PLS_DD_RC_BOTH = 'biod_pls_dd_rc_both'
// 生分解.植種情報.分解データ.結果.反応時間
export const UPDATE_BIOD_PLS_DD_BRLT_BRT = 'biod_pls_dd_brlt_brt'
// 生分解.植種情報.分解データ.結果.分解速度
export const UPDATE_BIOD_PLS_DD_BRLT_DR = 'biod_pls_dd_brlt_dr'
// 生分解.植種情報.分解データ.結果.分解産物
export const UPDATE_BIOD_PLS_DD_BRLT_DP = 'biod_pls_dd_brlt_dp'
// 生分解.植種情報.分解データ.結果.生物化学的酸素要求量（BOD）測定
export const UPDATE_BIOD_PLS_DD_BRLT_DBOD = 'biod_pls_dd_brlt_dbod'

export const updateCHD = param1 => {

    return {
      type: UPDATE_CHD,
      payload: param1
    }
  }

export const updateBIOD_PLS_LOC = param1 => {

  return {
    type: UPDATE_BIOD_PLS_LOC,
    payload: param1
  }
}


export const updateBIOD_PLS_BDATE = param1 => {
  return {
    type: UPDATE_BIOD_PLS_BDATE,
    payload: param1
  }
}

export const updateBIOD_PLS_BTIME = param1 => {
  return {
    type: UPDATE_BIOD_PLS_BTIME,
    payload: param1
  }
}

export const updateBIOD_PLS_TOC = param1 => {
  return {
    type: UPDATE_BIOD_PLS_TOC,
    payload: param1
  }
}

export const updateBIOD_PLS_NITA = param1 => {
  return {
    type: UPDATE_BIOD_PLS_NITA,
    payload: param1
  }
}


export const updateBIOD_PLS_BMASS = param1 => {
  return {
    type: UPDATE_BIOD_PLS_BMASS,
    payload: param1
  }
}
export const updateBIOD_PLS_DD_RC_PH = param1 => {
  return {
    type: UPDATE_BIOD_PLS_DD_RC_PH,
    payload: param1
  }
}
export const updateBIOD_PLS_DD_RC_BTEMP = param1 => {
  return {
    type: UPDATE_BIOD_PLS_DD_RC_BTEMP,
    payload: param1
  }
}
export const updateBIOD_PLS_DD_RC_BOTH = param1 => {
  return {
    type: UPDATE_BIOD_PLS_DD_RC_BOTH,
    payload: param1
  }
}

export const updateBIOD_PLS_DD_BRLT_BRT = param1 => {
  return {
    type: UPDATE_BIOD_PLS_DD_BRLT_BRT,
    payload: param1
  }
}

export const updateBIOD_PLS_DD_BRLT_DR = param1 => {
  return {
    type: UPDATE_BIOD_PLS_DD_BRLT_DR,
    payload: param1
  }
}

export const updateBIOD_PLS_DD_BRLT_DP = param1 => {
  return {
    type: UPDATE_BIOD_PLS_DD_BRLT_DP,
    payload: param1
  }
}

export const updateBIOD_PLS_DD_BRLT_DBOD = param1 => {
  return {
    type: UPDATE_BIOD_PLS_DD_BRLT_DBOD,
    payload: param1
  }
}
