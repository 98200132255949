import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import {

  updateTHA_TGA,
  updateTHA_DTA,
  updateTHA_DSC,

} from 'actions/measurementArray';


import { makeLabelVal } from "common/util";

import {
  updateOpenDialog,
} from "actions/settings";

import { InputDialog } from "views/InputDialog"

import {

  THA_TGA,
  THA_DTA,
  THA_DSC,

} from "constants.js";



const ThermalAnalysisDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');


  const [thaTgaValue, setThaTgaValue] = useState('');
  const [thaDtaValue, setThaDtaValue] = useState('');
  const [thaDscValue, setThaDscValue] = useState('');

  const selector_tha_tga = useSelector((state) => state.measurementArray.tha_tga);
  const selector_tha_dta = useSelector((state) => state.measurementArray.tha_dta);
  const selector_tha_dsc = useSelector((state) => state.measurementArray.tha_dsc);



  const selector_openDialog = useSelector((state) => state.settings.openDialog);

  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview )=> {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {
      case THA_TGA:
        setThaTgaValue(makeLabelVal(new_val));
        props.updateTHA_TGA(new_val);
        break;

      case THA_DTA:
        setThaDtaValue(makeLabelVal(new_val));
        props.updateTHA_DTA(new_val);
        break;

      case THA_DSC:
        setThaDscValue(makeLabelVal(new_val));
        props.updateTHA_DSC(new_val);
        break;
    }
  }


  const showChargerSearchDialog = (selected) => {

    setSelectedNode(selected);

    switch (selected) {
      case THA_TGA:
        setDialogLabel('熱重量分析');
        break;

      case THA_DTA:
        setDialogLabel('示差熱分析');
        break;

      case THA_DSC:
        setDialogLabel('示差走査熱量測定');
        break;
    }
    props.updateOpenDialog(true);
  }




  useEffect(() => {

    setThaTgaValue(makeLabelVal(selector_tha_tga));
    setThaDtaValue(makeLabelVal(selector_tha_dta));
    setThaDscValue(makeLabelVal(selector_tha_dsc));

  }, []);



  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'熱分析'} saveInput={saveInput} selectedNode={selectedNode} />

        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">熱分析</Typography>} style={{ backgroundColor: 'lightgray' }} />

          <Divider />

          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6} xs={6}>
                <TextField
                  label="熱重量分析"
                  name={THA_TGA}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(THA_TGA) }}
                  onBeforeInput={() => { showChargerSearchDialog(THA_TGA) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(THA_TGA) } }}
                  type="text"
                  value={thaTgaValue || ''}
                  variant="outlined"
                  autoFocus={true}

                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label=" 示差熱分析"
                  name={THA_DTA}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(THA_DTA) }}
                  onBeforeInput={() => { showChargerSearchDialog(THA_DTA) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(THA_DTA) } }}
                  type="text"
                  value={thaDtaValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="示差走査熱量測定"
                  name={THA_DSC}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(THA_DSC) }}
                  onBeforeInput={() => { showChargerSearchDialog(THA_DSC) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(THA_DSC) } }}
                  type="text"
                  value={thaDscValue || ''}
                  variant="outlined"
                />
              </Grid>

      
            </Grid>
          </CardContent>

        </Card>
      </div>
    </div>
  );
};

ThermalAnalysisDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({

    updateTHA_TGA,
    updateTHA_DTA,
    updateTHA_DSC,
    
    updateOpenDialog
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    measurementArray: state.measurementArray,
    settings: state.settings
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ThermalAnalysisDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
