import { WAS_CONFIG } from 'config.js';


export const doLoginWithPW = (history, admin_id, admin_password) => {

  fetch(`${WAS_CONFIG.HOSTNAME}/admin/signin`,
  {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body:
      JSON.stringify({
        admin_id: admin_id,
        admin_password: admin_password,
      })
  })
  .then( response => {
    return response.json();
  }).then(responseData => {

    console.log(responseData);

    if(responseData.resultCode === '000'){
      //success for login

      const newuser = {
        status: 'on',
        admin_id: responseData.admin_id,
      }

      localStorage.setItem('userinfo', JSON.stringify(newuser) );

      setTimeout( () => {
        history.push('/');
        }, 500);

    } else {
      alert(`ログインへ失敗しました。\n${responseData.resultMessage}`);
      return 1;
    }
  })
  .catch( error => {
    console.error(error);
    alert('ログインへ失敗しました。');
    return 2;
  });

};

