import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';


import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';


import {
  PP_MS_SSC_TEN_DEGSTRETCH,
  PP_MS_SSC_TEN_DEGELA,
  PP_MS_SSC_TEN_RUPSTR,
  PP_MS_SSC_TEN_STTD,
  PP_MS_SSC_TEN_STPD,
  PP_MS_SSC_TEN_BAIMAGE,
  PP_MS_SSC_BEND_BDSTRETCH,
  PP_MS_SSC_BEND_BDELA,
  PP_MS_SSC_BEND_BRSTR,
  PP_MS_SSC_BEND_BTD,
  PP_MS_SSC_BEND_BPD,
  PP_MS_SSC_BEND_BBAIMAGE,
} from "constants.js";


import {
  updatePP_MS_SSC_TEN_DEGSTRETCH,
  updatePP_MS_SSC_TEN_DEGELA,
  updatePP_MS_SSC_TEN_RUPSTR,
  updatePP_MS_SSC_TEN_STTD,
  updatePP_MS_SSC_TEN_STPD,
  updatePP_MS_SSC_TEN_BAIMAGE,

  updatePP_MS_SSC_BEND_BDSTRETCH,
  updatePP_MS_SSC_BEND_BDELA,
  updatePP_MS_SSC_BEND_BRSTR,
  updatePP_MS_SSC_BEND_BTD,
  updatePP_MS_SSC_BEND_BPD,
  updatePP_MS_SSC_BEND_BBAIMAGE,

} from 'actions/mechanicalArray';

import { makeLabelVal } from "common/util";

import {
  updateOpenDialog,
} from "actions/settings";

import { InputDialog } from "views/InputDialog"



/**
 * 固体物性.機械強度特性.応力歪曲線
 * @param {*} props 
 * @returns 
 */
const StressStrainCurveDetail = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [dialogLabel, setDialogLabel] = useState('');

  const [selectedNode, setSelectedNode] = useState('');

  const selector_pp_ms_ssc_ten_degstretch = useSelector((state) => state.mechanicalArray.pp_ms_ssc_ten_degstretch);
  const selector_pp_ms_ssc_ten_degela = useSelector((state) => state.mechanicalArray.pp_ms_ssc_ten_degela);
  const selector_pp_ms_ssc_ten_rupstr = useSelector((state) => state.mechanicalArray.pp_ms_ssc_ten_rupstr);
  const selector_pp_ms_ssc_ten_sttd = useSelector((state) => state.mechanicalArray.pp_ms_ssc_ten_sttd);
  const selector_pp_ms_ssc_ten_stpd = useSelector((state) => state.mechanicalArray.pp_ms_ssc_ten_stpd);
  const selector_pp_ms_ssc_ten_baimage = useSelector((state) => state.mechanicalArray.pp_ms_ssc_ten_baimage);

  const selector_pp_ms_ssc_bend_bdstretch = useSelector((state) => state.mechanicalArray.pp_ms_ssc_bend_bdstretch);
  const selector_pp_ms_ssc_bend_bdela = useSelector((state) => state.mechanicalArray.pp_ms_ssc_bend_bdela);
  const selector_pp_ms_ssc_bend_brstr = useSelector((state) => state.mechanicalArray.pp_ms_ssc_bend_brstr);
  const selector_pp_ms_ssc_bend_btd = useSelector((state) => state.mechanicalArray.pp_ms_ssc_bend_btd);
  const selector_pp_ms_ssc_bend_bpd = useSelector((state) => state.mechanicalArray.pp_ms_ssc_bend_bpd);
  const selector_pp_ms_ssc_bend_bbaimage = useSelector((state) => state.mechanicalArray.pp_ms_ssc_bend_bbaimage);


  //延伸率
  const [pp_ms_ssc_ten_degstretchValue, setPp_ms_ssc_ten_degstretchValue] = useState('');

  //弾性率
  const [pp_ms_ssc_ten_degelaValue, setPp_ms_ssc_ten_degelaValue] = useState('');

  //破断応⼒
  const [pp_ms_ssc_ten_rupstrValue, setPp_ms_ssc_ten_rupstrValue] = useState('');

  //温度依存性
  const [pp_ms_ssc_ten_sttdValue, setPp_ms_ssc_ten_sttdValue] = useState('');

  //圧⼒依存性
  const [pp_ms_ssc_ten_stpdValue, setPp_ms_ssc_ten_stpdValue] = useState('');

  //試験前後の画像
  const [pp_ms_ssc_ten_baimageValue, setPp_ms_ssc_ten_baimageValue] = useState('');


  const [pp_ms_ssc_bend_bdstretchValue, setPp_ms_ssc_bend_bdstretchValue] = useState('');
  const [pp_ms_ssc_bend_bdelaValue, setPp_ms_ssc_bend_bdelaValue] = useState('');
  const [pp_ms_ssc_bend_brstrValue, setPp_ms_ssc_bend_brstrValue] = useState('');
  const [pp_ms_ssc_bend_btdValue, setPp_ms_ssc_bend_btdValue] = useState('');
  const [pp_ms_ssc_bend_bpdValue, setPp_ms_ssc_bend_bpdValue] = useState('');
  const [pp_ms_ssc_bend_bbaimageValue, setPp_ms_ssc_bend_bbaimageValue] = useState('');

  const selector_openDialog = useSelector((state) => state.settings.openDialog);


  const saveInput = (inputType, inputValue, inputUnit, inputFactor, inputComment, inputImage, inputCSVFile, inputPreview) => {

    let new_val = {
      inputType: inputType || '',
      inputValue: inputValue || '',
      inputUnit: inputUnit || '',
      inputFactor: inputFactor || '',
      inputComment: inputComment || '',
      inputImage: inputImage || '',
      inputCSVFile: inputCSVFile || '',
      inputPreview: inputPreview || ''
    };

    switch (selectedNode) {
      case PP_MS_SSC_TEN_DEGSTRETCH:
        setPp_ms_ssc_ten_degstretchValue(makeLabelVal(new_val));
        props.updatePP_MS_SSC_TEN_DEGSTRETCH(new_val);
        break;


      case PP_MS_SSC_TEN_DEGELA:
        setPp_ms_ssc_ten_degelaValue(makeLabelVal(new_val));
        props.updatePP_MS_SSC_TEN_DEGELA(new_val);
        break;


      case PP_MS_SSC_TEN_RUPSTR:
        setPp_ms_ssc_ten_rupstrValue(makeLabelVal(new_val));
        props.updatePP_MS_SSC_TEN_RUPSTR(new_val);
        break;


      case PP_MS_SSC_TEN_STTD:
        setPp_ms_ssc_ten_sttdValue(makeLabelVal(new_val));
        props.updatePP_MS_SSC_TEN_STTD(new_val);
        break;

      case PP_MS_SSC_TEN_STPD:
        setPp_ms_ssc_ten_stpdValue(makeLabelVal(new_val));
        props.updatePP_MS_SSC_TEN_STPD(new_val);
        break;

      case PP_MS_SSC_TEN_BAIMAGE:
        setPp_ms_ssc_ten_baimageValue(makeLabelVal(new_val));
        props.updatePP_MS_SSC_TEN_BAIMAGE(new_val);
        break;


      case PP_MS_SSC_BEND_BDSTRETCH:
        setPp_ms_ssc_bend_bdstretchValue(makeLabelVal(new_val));
        props.updatePP_MS_SSC_BEND_BDSTRETCH(new_val);
        break;


      case PP_MS_SSC_BEND_BDELA:
        setPp_ms_ssc_bend_bdelaValue(makeLabelVal(new_val));
        props.updatePP_MS_SSC_BEND_BDELA(new_val);
        break;

      case PP_MS_SSC_BEND_BRSTR:
        setPp_ms_ssc_bend_brstrValue(makeLabelVal(new_val));
        props.updatePP_MS_SSC_BEND_BRSTR(new_val);
        break;


      case PP_MS_SSC_BEND_BTD:
        setPp_ms_ssc_bend_btdValue(makeLabelVal(new_val));
        props.updatePP_MS_SSC_BEND_BTD(new_val);
        break;


      case PP_MS_SSC_BEND_BPD:
        setPp_ms_ssc_bend_bpdValue(makeLabelVal(new_val));
        props.updatePP_MS_SSC_BEND_BPD(new_val);
        break;


      case PP_MS_SSC_BEND_BBAIMAGE:
        setPp_ms_ssc_bend_bbaimageValue(makeLabelVal(new_val));
        props.updatePP_MS_SSC_BEND_BBAIMAGE(new_val);
        break;
    }
  }


  const showChargerSearchDialog = (selected) => {

    setSelectedNode(selected);

    switch(selected) {
      case PP_MS_SSC_TEN_DEGSTRETCH:
        setDialogLabel('延伸率');
        break;

      case PP_MS_SSC_TEN_DEGELA:
        setDialogLabel('弾性率');
        break;

      case PP_MS_SSC_TEN_RUPSTR:
        setDialogLabel('破断応⼒');
        break;

      case PP_MS_SSC_TEN_STTD:
        setDialogLabel('温度依存性');
        break;

      case PP_MS_SSC_TEN_STPD:
        setDialogLabel('圧⼒依存性');
        break;

      case PP_MS_SSC_TEN_BAIMAGE:
        setDialogLabel('試験前後の画像');
        break;

      case PP_MS_SSC_BEND_BDSTRETCH:
        setDialogLabel('延伸率');
        break;

      case PP_MS_SSC_BEND_BDELA:
        setDialogLabel('弾性率');
        break;

      case PP_MS_SSC_BEND_BRSTR:
        setDialogLabel('破断応⼒');
        break;

      case PP_MS_SSC_BEND_BTD:
        setDialogLabel('温度依存性');
        break;

      case PP_MS_SSC_BEND_BPD:
        setDialogLabel('圧⼒依存性');
        break;

      case PP_MS_SSC_BEND_BBAIMAGE:
        setDialogLabel('試験前後の画像');
        break;
    }

    props.updateOpenDialog(true);
  }



  useEffect(() => {

    setPp_ms_ssc_ten_degstretchValue(makeLabelVal(selector_pp_ms_ssc_ten_degstretch));
    setPp_ms_ssc_ten_degelaValue(makeLabelVal(selector_pp_ms_ssc_ten_degela));
    setPp_ms_ssc_ten_rupstrValue(makeLabelVal(selector_pp_ms_ssc_ten_rupstr));
    setPp_ms_ssc_ten_sttdValue(makeLabelVal(selector_pp_ms_ssc_ten_sttd));
    setPp_ms_ssc_ten_stpdValue(makeLabelVal(selector_pp_ms_ssc_ten_stpd));
    setPp_ms_ssc_ten_baimageValue(makeLabelVal(selector_pp_ms_ssc_ten_baimage));


    setPp_ms_ssc_bend_bdstretchValue(makeLabelVal(selector_pp_ms_ssc_bend_bdstretch));
    setPp_ms_ssc_bend_bdelaValue(makeLabelVal(selector_pp_ms_ssc_bend_bdela));
    setPp_ms_ssc_bend_brstrValue(makeLabelVal(selector_pp_ms_ssc_bend_brstr));
    setPp_ms_ssc_bend_btdValue(makeLabelVal(selector_pp_ms_ssc_bend_btd));
    setPp_ms_ssc_bend_bpdValue(makeLabelVal(selector_pp_ms_ssc_bend_bpd));
    setPp_ms_ssc_bend_bbaimageValue(makeLabelVal(selector_pp_ms_ssc_bend_bbaimage));

  }, []);


  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <InputDialog openDialog={selector_openDialog} dialogLabel={dialogLabel} dialogTitle={'応力歪曲線'} saveInput={saveInput} selectedNode={selectedNode} />


        <Card {...rest} className={clsx(classes.root, className)}>

          <CardHeader align='center' title={<Typography variant="h4">応力歪曲線</Typography>} style={{ backgroundColor: 'lightgray' }}
            subheader={<Typography variant="h5">- 機械強度特性 -</Typography>}
          />
          <Divider />

          <CardContent>


            <Grid item md={12} spacing={0}>
              <Typography variant="h4">引張</Typography>
            </Grid>

            <Divider variant="inset" />


            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>


              <Grid item md={6} xs={6}>
                <TextField
                  label="延伸率"
                  name={PP_MS_SSC_TEN_DEGSTRETCH}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_SSC_TEN_DEGSTRETCH) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_SSC_TEN_DEGSTRETCH) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_SSC_TEN_DEGSTRETCH) } }}
                  type="text"
                  value={pp_ms_ssc_ten_degstretchValue || ''}
                  variant="outlined"
                  autoFocus={true}
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="弾性率"
                  name={PP_MS_SSC_TEN_DEGELA}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_SSC_TEN_DEGELA) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_SSC_TEN_DEGELA) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_SSC_TEN_DEGELA) } }}
                  type="text"
                  value={pp_ms_ssc_ten_degelaValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="破断応⼒"
                  name={PP_MS_SSC_TEN_RUPSTR}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_SSC_TEN_RUPSTR) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_SSC_TEN_RUPSTR) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_SSC_TEN_RUPSTR) } }}
                  type="text"
                  value={pp_ms_ssc_ten_rupstrValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="温度依存性"
                  name={PP_MS_SSC_TEN_STTD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_SSC_TEN_STTD) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_SSC_TEN_STTD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_SSC_TEN_STTD) } }}
                  type="text"
                  value={pp_ms_ssc_ten_sttdValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="圧⼒依存性"
                  name={PP_MS_SSC_TEN_STPD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_SSC_TEN_STPD) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_SSC_TEN_STPD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_SSC_TEN_STPD) } }}
                  type="text"
                  value={pp_ms_ssc_ten_stpdValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="試験前後の画像"
                  name={PP_MS_SSC_TEN_BAIMAGE}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_SSC_TEN_BAIMAGE) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_SSC_TEN_BAIMAGE) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_SSC_TEN_BAIMAGE) } }}
                  type="text"
                  value={pp_ms_ssc_ten_baimageValue || ''}
                  variant="outlined"
                />
              </Grid>


              <Grid container item md={12} spacing={0}></Grid>
              <Grid container item md={12} spacing={0}></Grid>
            </Grid>


            <Grid item md={12} spacing={0}>
              <Typography variant="h4">曲げ試験</Typography>
            </Grid>


            <Divider variant="inset" />


            <Grid container spacing={2}>
              <Grid container item md={12} spacing={0}></Grid>


              <Grid item md={6} xs={6}>
                <TextField
                  label="延伸率"
                  name={PP_MS_SSC_BEND_BDSTRETCH}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_SSC_BEND_BDSTRETCH) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_SSC_BEND_BDSTRETCH) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_SSC_BEND_BDSTRETCH) } }}
                  type="text"
                  value={pp_ms_ssc_bend_bdstretchValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="弾性率"
                  name={PP_MS_SSC_BEND_BDELA}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_SSC_BEND_BDELA) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_SSC_BEND_BDELA) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_SSC_BEND_BDELA) } }}
                  type="text"
                  value={pp_ms_ssc_bend_bdelaValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="破断応⼒"
                  name={PP_MS_SSC_BEND_BRSTR}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_SSC_BEND_BRSTR) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_SSC_BEND_BRSTR) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_SSC_BEND_BRSTR) } }}
                  type="text"
                  value={pp_ms_ssc_bend_brstrValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="温度依存性"
                  name={PP_MS_SSC_BEND_BTD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_SSC_BEND_BTD) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_SSC_BEND_BTD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_SSC_BEND_BTD) } }}
                  type="text"
                  value={pp_ms_ssc_bend_btdValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="圧⼒依存性"
                  name={PP_MS_SSC_BEND_BPD}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_SSC_BEND_BPD) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_SSC_BEND_BPD) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_SSC_BEND_BPD) } }}
                  type="text"
                  value={pp_ms_ssc_bend_bpdValue || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <TextField
                  label="試験前後の画像"
                  name={PP_MS_SSC_BEND_BBAIMAGE}
                  fullWidth
                  onClick={() => { showChargerSearchDialog(PP_MS_SSC_BEND_BBAIMAGE) }}
                  onBeforeInput={() => { showChargerSearchDialog(PP_MS_SSC_BEND_BBAIMAGE) }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') { showChargerSearchDialog(PP_MS_SSC_BEND_BBAIMAGE) } }}
                  type="text"
                  value={pp_ms_ssc_bend_bbaimageValue || ''}
                  variant="outlined"
                />
              </Grid>

            </Grid>

          </CardContent>
        </Card>
      </div>
    </div>
  );
};

StressStrainCurveDetail.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};



const mapDispatchToProps = dispatch => {
  return bindActionCreators({

    updatePP_MS_SSC_TEN_DEGSTRETCH,
    updatePP_MS_SSC_TEN_DEGELA,
    updatePP_MS_SSC_TEN_RUPSTR,
    updatePP_MS_SSC_TEN_STTD,
    updatePP_MS_SSC_TEN_STPD,
    updatePP_MS_SSC_TEN_BAIMAGE,

    updatePP_MS_SSC_BEND_BDSTRETCH,
    updatePP_MS_SSC_BEND_BDELA,
    updatePP_MS_SSC_BEND_BRSTR,
    updatePP_MS_SSC_BEND_BTD,
    updatePP_MS_SSC_BEND_BPD,
    updatePP_MS_SSC_BEND_BBAIMAGE,

    updateOpenDialog,

  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    mechanicalArray: state.mechanicalArray,
    settings: state.settings
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StressStrainCurveDetail));



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  divider: {
    margin: theme.spacing(2, 0)
  },

}));
